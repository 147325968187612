<!--
  PACKAGE_NAME : src/pages/report/config/wizard
  FILE_NAME : detail-preview.vue
  AUTHOR : kwmoon
  DATE : 2025. 4. 1.
  DESCRIPTION :
-->
<template>
  <div class="py-4 px-4">
    <h3 class="text-xl font-medium ml-2 mb-2">미리 보기</h3>
    <DxDataGrid
      ref="previewGrid"
      :show-borders="false"
      :data-source="grid.dataSource"
      :show-column-headers="true"
      :show-column-lines="false"
      :show-row-lines="true"
      :height="120"
      :allow-column-reordering="false"
      :allow-column-resizing="false"
      column-resizing-mode="widget"
      :column-auto-width="false"
      no-data-text="미리 확인할 데이터가 존재하지 않습니다."
    >
      <template v-for="(item, i) in grid.columns">
        <template v-if="item.length > 0 && [null, ''].indexOf(item[0].multiHeaderNm) === -1">
          <DxColumn :caption="item[0].multiHeaderNm" :key="i">
            <DxColumn
              v-for="(item2, i2) in item"
              alignment="center"
              :key="`${i}_${i2}`"
              :allow-sorting="false"
              :caption="item2.caption"
              :dataField="item2.dataField"
              css-class="grid-header-border"
              :cellTemplate="cellTemplateByColumnFmt(item2.format)"
            />
          </DxColumn>
        </template>
        <template v-else>
          <template v-if="item.grouping">
            <DxColumn
              :key="i"
              :allow-sorting="false"
              :caption="item.caption"
              :dataField="item.dataField"
              :alignment="item.align"
              :width="item.width"
              :visible="item.visible"
              :fixed="item.fixed"
              :group-index="i"
              :cellTemplate="cellTemplateByColumnFmt(item.format)"
            ></DxColumn>
          </template>
          <template v-else>
            <DxColumn
              :key="i"
              :allow-sorting="false"
              :caption="item.caption"
              :dataField="item.dataField"
              :alignment="item.align"
              :width="item.width"
              :visible="item.visible"
              :fixed="item.fixed"
              :cellTemplate="cellTemplateByColumnFmt(item.format)"
            ></DxColumn>
          </template>
        </template>
      </template>

      <template #dateTemplate="{ data }">
        {{ fmtDate(data.value) }}
      </template>

      <template #percentTemplate="{ data }">
        {{ data.value + '%' }}
      </template>

      <template #timeTemplate="{ data }">
        {{ fmtTime(data.value) }}
      </template>

      <template #numberTemplate="{ data }">
        {{ fmtNumber(data.value) }}
      </template>

      <template #defaultTemplate="{ data }">
        {{ data.value }}
      </template>

      <template #treeTemplate="{ data }">
        {{ data.value }}
      </template>

      <template #linkTemplate="{ data }">
        <a>{{ data.value }}</a>
      </template>
    </DxDataGrid>
  </div>
</template>
<script>
  import { DxColumn, DxDataGrid } from 'devextreme-vue/data-grid';
  import { formatDate, isEmpty } from '@/utils/common-lib';

  export default {
    name: 'detail-preview',
    components: { DxDataGrid, DxColumn },
    props: {
      columns: {
        type: Array,
        default: () => [],
      },
    },
    watch: {
      columns: {
        handler(val) {
          this.grid.columns = []; // 갱신 주기 방어 (멀티헤더 이슈)
          this.$nextTick(() => {
            this.grid.columns = this.getReplaceMultiHeaderColumn(val);
          });
        },
        immediate: true,
        deep: true,
      },
    },
    data() {
      return {
        grid: {
          width: null,
          columns: [],
          dataSource: [],
        },
      };
    },
    computed: {},
    methods: {
      /**
       * XML 에서 정의된 컬럼을 multiHeaderNm 기준으로
       * 병합하여 반환하는 함수
       */
      getReplaceMultiHeaderColumn(columns) {
        let result = [];
        columns.forEach(col => {
          if (isEmpty(col.multiHeaderNm)) {
            result.push(col);
          } else {
            const preData = result.at(-1);
            if (Array.isArray(preData) && preData.at(-1).multiHeaderNm === col.multiHeaderNm) {
              result.at(-1).push(col);
            } else {
              result.push([col]);
            }
          }
        });
        return result;
      },

      /**
       * 컬럼 포맷에 따른 셀 템플릿 반환
       */
      cellTemplateByColumnFmt(fmt) {
        let cellTemplate = null;
        if (['date', 'fmDate'].includes(fmt)) cellTemplate = 'dateTemplate';
        else if (['number', 'fmNumber'].includes(fmt)) cellTemplate = 'numberTemplate';
        else if (['percent', 'fmPercent'].includes(fmt)) cellTemplate = 'percentTemplate';
        else if (['time', 'fmtime'].includes(fmt)) cellTemplate = 'timeTemplate';
        else if (['tree', 'fmTree'].includes(fmt)) cellTemplate = 'treeTemplate';
        else if (['link', 'fmLink'].includes(fmt)) cellTemplate = 'linkTemplate';
        return cellTemplate;
      },

      /**
       * 날짜 포맷 변경
       */
      fmtDate(value) {
        return formatDate(value, 'YYYYMMDD', 'YYYY-MM-DD');
      },

      /**
       * 숫자 포맷 변경
       */
      fmtNumber(value) {
        let rtn = parseInt(value);
        return rtn.toLocaleString('ko-KR');
      },

      /**
       * 시간 포맷 변경
       */
      lPadZero(num) {
        if (num < 10) {
          return `0${num}`;
        }
        return num;
      },

      /**
       * 시간 포맷 변경
       */
      fmtTime(seconds) {
        const hour = this.lPadZero(parseInt(seconds / 3600));
        const min = this.lPadZero(parseInt((seconds % 3600) / 60));
        const sec = this.lPadZero(parseInt(seconds % 60));
        return `${hour}:${min}:${sec}`;
      },
    },
    updated() {},
    created() {},
    mounted() {},
    activated() {},
    deactivated() {},
    beforeDestroy() {},
    destroyed() {},
  };
</script>
<style lang="scss" scoped></style>
