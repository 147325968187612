<template>
  <div class="header-tab flex items-center justify-between select-none">
    <div class="is-active-left-show px-32 flex-shrink-0">
    <!-- 메뉴바 고정일 시 같은 너비로 조정하기 위한 박스-->
    </div>
    <!-- 좌우 이동 버튼 -->
    <div class="tab-navigation flex items-center gap-2 px-2 flex-shrink-0">
      <span class="mdi mdi-chevron-left text-lg tab-button cursor-pointer" @click="scrollTabs('left')" title="탭 왼쪽으로 이동"></span>
      <span class="mdi mdi-chevron-right text-lg tab-button cursor-pointer" @click="scrollTabs('right')" title="탭 오른쪽으로 이동"></span>
    </div>

    <!-- 탭 목록 -->
    <div class="tabs-container flex gap-2 overflow-x-hidden min-w-0 flex-1" ref="tabsContainer" @wheel="handleWheelScroll">
      <drop class="flex gap-2 w-full" @drop="handleTabListDrop">
        <drag
          v-for="(tab, index) in getTabs"
          :key="tab.tabId"
          :transfer-data="{ index, tabId: tab.tabId }"
          :draggable="tab.tabId !== homeTab.tabId"
          :class="['flex', 'items-center', 'gap-1', 'drag-tab-item', { 'drag-tab-item--active': activeTab === tab.tabId }]"
          :title="tab.detailTabInfo ? tab.detailTabInfo.breadcrumb : tab.breadcrumb"
          :data-index="index"
        >
          <div class="tab-content flex items-center gap-1 w-full h-full" @click="switchTab(tab)">
            <!-- 잠금 아이콘 -->
            <img
              v-if="tab.tabId !== homeTab.tabId"
              :src="getIconSrc(isLockedTab(tab) ? 'tab_lock' : 'tab_unlock')"
              alt="Lock/Unlock"
              class="cursor-pointer tab-button"
              @click.stop="toggleTabLock(tab)"
              title="탭 잠금/해제"
            />
            <!-- 탭 라벨 -->
            <span v-if="tab.tabId !== homeTab.tabId">{{ tab.detailTabInfo ? tab.detailTabInfo.label : tab.label }}</span>
            <!-- 홈 아이콘 (컬러 변경 때문에 svg 통째로 넣었는데, 수정 필요) -->
            <svg v-else width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M4.16663 15.8332V8.58987C4.16663 8.37654 4.2144 8.17459 4.30996 7.98404C4.40551 7.79348 4.53718 7.63654 4.70496 7.5132L9.19246 4.11487C9.42746 3.93543 9.69579 3.8457 9.99746 3.8457C10.2991 3.8457 10.5691 3.93543 10.8075 4.11487L15.295 7.51237C15.4633 7.6357 15.595 7.79293 15.69 7.98404C15.7855 8.17459 15.8333 8.37654 15.8333 8.58987V15.8332C15.8333 16.0565 15.7502 16.2513 15.5841 16.4174C15.418 16.5835 15.2233 16.6665 15 16.6665H12.18C11.9888 16.6665 11.8288 16.6021 11.7 16.4732C11.5711 16.3438 11.5066 16.1838 11.5066 15.9932V12.019C11.5066 11.8285 11.4422 11.6688 11.3133 11.5399C11.1838 11.4104 11.0238 11.3457 10.8333 11.3457H9.16663C8.97607 11.3457 8.81635 11.4104 8.68746 11.5399C8.55801 11.6688 8.49329 11.8285 8.49329 12.019V15.994C8.49329 16.1846 8.42885 16.3443 8.29996 16.4732C8.17107 16.6021 8.01135 16.6665 7.82079 16.6665H4.99996C4.77663 16.6665 4.5819 16.5835 4.41579 16.4174C4.24968 16.2513 4.16663 16.0565 4.16663 15.8332Z"
                :style="{ fill: 'var(--themeColor)' }"
              />
            </svg>
            <!-- 닫기 아이콘 -->
            <img
              v-if="tab.tabId !== homeTab.tabId"
              src="@/assets/images/tab_clear.svg"
              alt="Close"
              class="cursor-pointer tab-button"
              @click.stop="closeTab(tab)"
            />
          </div>
        </drag>
      </drop>
    </div>

    <!-- 전체 탭 잠금/해제 및 일괄 닫기 아이콘 -->
    <div class="tab-controls flex gap-2 items-center justify-between px-4 flex-shrink-0 ml-auto">
      <img
        src="@/assets/images/tab_all_clear.svg"
        alt="Clear All"
        class="cursor-pointer tab-button"
        @click="closeAllTabs"
        :title="lockAllTabs ? '잠금 상태에서 사용할 수 없습니다' : '모든 탭 닫기'"
      />
      <img
        :src="getIconSrc(lockAllTabs ? 'tab_all_lock' : 'tab_all_unlock')"
        alt="Lock/Unlock All"
        class="cursor-pointer tab-button"
        @click="toggleAllTabsLock"
        title="전체 탭 잠금/해제"
      />
    </div>
  </div>
</template>

<script>
  import { stringToHash } from '@/utils/common-lib';
  import { Drag, Drop } from 'vue-drag-drop';

  const homeTab = Object.freeze({
    tabId: 'HOME',
    name: 'home',
    label: 'Home',
    path: '/',
    locked: true,
    breadcrumb: '홈',
    tabInfo: null,
    detailTabInfo: null,
  });

  export default {
    name: 'SystemTab',
    components: {
      Drag,
      Drop,
    },
    watch: {
      $route(to) {
        if (!to.name === null) {
          return;
        }
        this.addMenuTab(to);
      },
    },
    data() {
      return {
        activeTab: homeTab.tabId,
        lockAllTabs: false,
        fromIndex: null,
        toIndex: null,
      };
    },
    computed: {
      /**
       * @description 캐시된 탭 페이지 목록을 반환합니다.
       * @return {any}
       */
      getTabs() {
        return this.$store.getters.getCachedTabPages;
      },
      /**
       * @description 홈 탭을 반환합니다.
       * @return {*}
       */
      homeTab() {
        return homeTab;
      },
    },
    methods: {
      /**
       * @description 메인 페이지 목록을 반환합니다.
       * @return {any}
       */
      getMainPages() {
        return this.$store.getters.getUserMainPages;
      },
      /**
       * @description 메뉴 정보를 통해 브레드크럼을 생성합니다.
       * @param tabInfo
       * @return {string}
       */
      getBreadCrumb(tabInfo) {
        if (!tabInfo) {
          return this.homeTab.name; // menu가 없는 경우(HOME 탭) 처리
        }

        let menu = this.getMenuById(tabInfo?.isDetailPage ? tabInfo?.parentMenuId : tabInfo?.menuId);
        const menuArray = [menu];
        while (menu.menuDepth > 1) {
          menu = this.getMenuById(menu.parentId);
          menuArray.unshift(menu);
        }

        const breadCrumb = menuArray.map(v => v.menuNm).join(' > ');
        return tabInfo?.isDetailPage ? `${breadCrumb} > ${tabInfo?.menuNm}` : breadCrumb;
      },
      /**
       * @description 메뉴 ID로 메뉴 정보를 조회합니다.
       * @param id
       * @return {*}
       */
      getMenuById(id) {
        return this.$store.getters.getMenuList.find(menu => menu.id === id);
      },
      /**
       * @description 메뉴 path로 메뉴 정보를 조회합니다.
       * @param path
       * @return {*}
       */
      getRouteMenuByPath(path) {
        return this.$store.getters.getRouteMenuMap.get(path);
      },
      /**
       * @description 아이콘 파일명을 통해 아이콘 경로를 반환합니다.
       * @param {String} fileName - 아이콘 파일명
       * @returns {String} 아이콘 경로
       */
      getIconSrc(fileName) {
        return require(`@/assets/images/${fileName}.svg`);
      },
      /**
       * @description 메뉴 탭 추가 처리
       * @param route $route
       */
      addMenuTab(route) {
        // 메뉴 + 상세페이지 정보 $route path로 조회
        let tabInfo = this.getRouteMenuByPath(route.path);
        if (!tabInfo) {
          console.error('not found route menu.', route);
          return;
        }

        // 탭 아이디는 상세페이지 인 경우 연결된 부모 메뉴 아이디로 설정
        const routeInfo = route
          ? {
              path: route.path,
              fullPath: route.fullPath,
              name: route.name,
              params: { ...route.params }, // 얕은 복사
              query: { ...route.query }, // 얕은 복사
              meta: { ...route.meta }, // 얕은 복사
            }
          : null;
        const menuId = tabInfo?.isDetailPage ? tabInfo?.parentMenuId : tabInfo?.menuId;
        const tabId = stringToHash('TAB_' + menuId);
        const isHomePage = !tabInfo?.isDetailPage && !!this.getMainPages().find(page => page.menuId === tabInfo?.menuId);
        tabInfo = { ...tabInfo, tabId, routeInfo, isHomePage };

        // 메인 페이지 여부 판단 (상세페이지는 메인페이지 아님)
        if (isHomePage) {
          this.activeTab = this.homeTab.tabId;
          return;
        }

        // 생성된 탭 탐색
        const existTab = this.getTabs.find(tab => tab.tabId === tabInfo.tabId);
        if (existTab) {
          this.$store.commit(
            'setDetailCachedTabInfo',
            tabInfo.isDetailPage
              ? {
                  tabId: tabInfo.tabId,
                  detailTabInfo: {
                    tabId: tabInfo?.tabId,
                    label: tabInfo?.menuNm,
                    name: tabInfo?.routeInfo?.name,
                    path: tabInfo?.routeInfo?.fullPath,
                    locked: false,
                    breadcrumb: this.getBreadCrumb(tabInfo),
                    tabInfo: tabInfo,
                    detailTabInfo: null,
                  },
                }
              : { tabId: tabInfo.tabId, detailTabInfo: null },
          );
          this.setActiveTab(existTab.tabId);
          return;
        }
        this.setNewTab(tabInfo); // 새로운 탭 생성
      },
      /**
       * @description 새 탭을 설정합니다.
       * @param tabInfo 탭 정보
       */
      setNewTab(tabInfo) {
        // 새로고침 후 현재 추가할 새 탭이 상세페이지인 경우
        if (tabInfo.isDetailPage) {
          const activeTabInfo = this.$store.getters.getActiveTabInfo; // 캐시된 활성화 페이지 정보 가져오기
          this.$router.push(activeTabInfo.path); // 마지막 활성화된 탭으로 이동
          return;
        }

        const tab = {
          tabId: tabInfo?.tabId,
          label: tabInfo?.menuNm,
          name: tabInfo?.routeInfo?.name,
          path: tabInfo?.routeInfo?.fullPath,
          locked: false,
          breadcrumb: this.getBreadCrumb(tabInfo),
          tabInfo: tabInfo,
          detailTabInfo: null,
        };

        this.$store.commit('addCachedTabPage', tab);
        this.$nextTick(() => {
          this.scrollToEnd();
        });
        this.setActiveTab(tabInfo?.tabId);
      },
      /**
       * @description 현재 활성화된 탭을 업데이트합니다.
       * @param {Object} tab - 활성화할 탭 객체
       */
      switchTab(tab) {
        if (this.activeTab === tab.tabId) {
          return;
        }

        this.activeTab = tab.tabId;
        this.$router.push(tab.path);
      },
      /**
       * @description 특정 탭을 닫습니다.
       * @param {Object} tab - 닫을 탭 객체
       */
      closeTab(tab) {
        if (tab.locked) {
          return;
        }

        // 탭 데이터 삭제
        this.$store.commit('removeCachedTabPage', tab.tabId);

        // 탭이 닫힌 후 활성화된 탭이 없을 경우 마지막 탭으로 이동
        if (this.getTabs.length > 0) {
          this.$router.push(this.getTabs[this.getTabs.length - 1].path);
        } else {
          this.$router.push({ name: this.homeTab.name });
        }
      },
      /**
       * @description 모든 탭을 닫습니다.
       */
      closeAllTabs() {
        this.$store.commit('clearCachedTabPages');

        if (this.getTabs.length > 0) {
          this.$router.push(this.getTabs[this.getTabs.length - 1].path);
        } else {
          this.$router.push({ name: this.homeTab.name });
        }
      },
      /**
       * @description 특정 탭의 잠금 상태를 토글합니다.
       * @param {Object} tab - 잠금 상태를 변경할 탭 객체
       */
      toggleTabLock(tab) {
        this.$store.commit('toggleCachedTabLocked', tab.tabId);
      },
      /**
       * @description 모든 탭의 잠금 상태를 토글합니다.
       */
      toggleAllTabsLock() {
        this.lockAllTabs = !this.lockAllTabs;
        this.$store.commit('toggleAllCachedTabLocked', this.lockAllTabs);
      },
      /**
       * @description 탭을 좌우로 스크롤합니다.
       * @param {String} direction - 스크롤 방향 (left 또는 right)
       */
      scrollTabs(direction) {
        const container = this.$el.querySelector('.tabs-container');
        const scrollAmount = 100; // 한 번에 스크롤할 픽셀 수

        if (direction === 'left') {
          container.scrollLeft -= scrollAmount;
        } else if (direction === 'right') {
          container.scrollLeft += scrollAmount;
        }
      },
      /**
       * @description 탭 목록을 우측 끝으로 스크롤합니다.
       */
      scrollToEnd() {
        const container = this.$el.querySelector('.tabs-container');
        if (container) {
          container.scrollLeft = container.scrollWidth; // 스크롤을 가장 오른쪽으로 이동
        }
      },
      /**
       * @description 휠 스크롤 이벤트를 처리합니다.
       * @param event
       */
      handleWheelScroll(event) {
        if (event.deltaY !== 0) {
          this.$refs.tabsContainer.scrollLeft += event.deltaY;
        }
      },
      /**
       * @description 탭이 잠금 상태인지 확인합니다.
       * @param currentTab
       * @return {*}
       */
      isLockedTab(currentTab) {
        const findTab = this.getTabs.find(tab => tab.tabId === currentTab.tabId);
        return findTab ? findTab.locked : false;
      },
      /**
       * @description 탭을 활성화합니다.
       * @param tabId 활성화할 탭 ID
       */
      setActiveTab(tabId) {
        this.activeTab = tabId;
        // 활성화 탭 정보 업데이트(세션 스토리지에 저장)
        const activeTab = this.getTabs.find(tab => tab.tabId === tabId) ?? null;
        this.$store.commit('setActiveTabInfo', activeTab);
      },
      /**
       * @description 탭 목록에서 드래그 앤 드롭을 처리합니다.
       * @param data
       * @param event
       */
      handleTabListDrop(data, event) {
        // 드래그된 탭 정보 가져오기
        const { index: fromIndex, tabId } = data;

        // 홈 탭은 이동하지 않음
        if (tabId === this.homeTab.tabId) {
          console.warn('HOME 탭은 이동할 수 없습니다');
          return;
        }

        // 드롭된 위치의 인덱스 찾기 (가장 가까운 탭 요소 찾기)
        let toIndex = 0;
        const dropPosition = event.clientX;
        const tabElements = Array.from(this.$refs.tabsContainer.querySelectorAll('.drag-tab-item'));

        // 드롭 위치에 가장 가까운 탭 찾기
        let closestTab = null;
        let minDistance = Infinity;

        tabElements.forEach((tabEl, idx) => {
          const rect = tabEl.getBoundingClientRect();
          const tabCenter = rect.left + rect.width / 2;
          const distance = Math.abs(dropPosition - tabCenter);

          if (distance < minDistance) {
            minDistance = distance;
            closestTab = tabEl;
            toIndex = idx;
          }
        });

        // 왼쪽에 드롭했는지 오른쪽에 드롭했는지 결정
        if (closestTab) {
          const rect = closestTab.getBoundingClientRect();
          const tabCenter = rect.left + rect.width / 2;

          // 오른쪽에 드롭했다면 인덱스 증가
          if (dropPosition > tabCenter && toIndex < tabElements.length - 1) {
            toIndex++;
          }
        }

        // 인덱스가 동일하면 아무 작업도 수행하지 않음
        if (fromIndex === toIndex) {
          return;
        }
        this.$store.commit('moveCachedTab', { fromIndex, toIndex });
      },
    },
    created() {},
    mounted() {
      // home 탭 추가
      if (!this.getTabs.find(tab => tab.tabId === this.homeTab.tabId)) {
        this.$store.commit('addCachedTabPage', homeTab);
      }
      this.addMenuTab(this.$route);
    },
  };
</script>

<style lang="scss" scoped>
  .header-tab {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #f6f8fa; /* 전체 배경색 */
    border-top: 1px solid #ebedf0; /* 상단 테두리 */

    .tab-navigation {
      flex-shrink: 0;
      display: flex;
      align-items: center;

      .mdi {
        font-size: 1.5rem;
        cursor: pointer;
        line-height: 1;
        transition: color 0.2s ease;

        &:hover {
          color: var(--themeColor);
        }
      }

      /* 탭 네비게이션 구분선 */
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 1px;
        height: 50%; /* 선 길이 */
        background-color: #e0e0e0;
      }
    }

    .tabs-container {
      flex-grow: 1; /* 탭 리스트가 가능한 최대 공간 차지 */
      overflow-x: hidden;
      display: flex;
      align-items: center;
      gap: 0.5rem; /* 간격을 줄여 균형 조정 */

      .drag-tab-item {
        position: relative; /* 구분선을 위한 위치 설정 */
        padding: 5px 10px; /* 탭 내부 여백 */
        font-size: 11px;
        font-weight: 600;
        color: #333;
        cursor: pointer;
        flex-shrink: 0; /* 탭이 줄어들지 않도록 설정 */
        transition: all 0.2s ease;

        &:hover {
          color: var(--themeColor);
          background-color: var(--opacityThemeColor);
        }

        /* 활성화된 탭 상단 보더 */
        &--active {
          background-color: var(--opacityThemeColor);

          span {
            font-weight: 500; /* 굵기 추가 */
            color: var(--themeColor); /* 테마 컬러 */
          }

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 2px;
            background-color: var(--themeColor);
          }
        }

        /* 탭 사이 구분선 */
        &::after {
          content: '';
          position: absolute;
          top: 50%; /* 중앙 정렬 */
          right: -0.25rem; /* 구분선을 살짝 안쪽으로 조정 */
          transform: translateY(-50%); /* 수직 중앙 정렬 */
          width: 1px; /* 선의 두께 */
          height: 50%; /* 선의 길이 */
          background-color: #e0e0e0; /* 연한 회색 */
        }

        /* 마지막 탭 구분선 유지 */
        &:last-child::after {
          display: block;
        }

        &[draggable='true'] {
          user-select: none;
        }

        &.dragging {
          opacity: 0.4;
          background-color: var(--opacityThemeColor);
          border-radius: 4px;
          position: relative;
          z-index: 1000;
        }

        &.drag-over {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            left: -2px;
            top: 0;
            bottom: 0;
            width: 2px;
            background-color: var(--themeColor);
          }
        }
      }
    }
  }

  /* 잠금장치 및 삭제 버튼 공통 스타일 */
  .tab-button {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s ease, background-color 0.3s ease;
    border-radius: 50%; /* 테두리를 완전히 둥글게 */
  }

  /* Hover 상태 */
  .tab-button:hover {
    color: var(--themeColor);
    background-color: var(--opacityThemeColor);
  }

  /** 좌측 메뉴 열렸을 때 메뉴 width 만큼 활성화 시키는 역할 */
  #wrap.menuitemshow.left-show .is-active-left-show {
    display: block;
  }

  #wrap .is-active-left-show {
    display: none;
  }

  .drag-tab-item {
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 4px;
    white-space: nowrap;
    transition: background-color 0.2s;
  }

  .drag-tab-item--active {
    background-color: var(--activeTabColor, #e0e0e0);
  }

  /* vue-drag-drop 관련 스타일 */
  .drag-tab-item.drag-over {
    background-color: rgba(0, 0, 0, 0.1);
  }

  /* 탭 컨테이너 전체를 flex로 설정 */
  .tabs-container > .drop {
    display: flex;
    flex-grow: 1;
  }

  .tab-content {
    cursor: pointer;
    padding: 2px;
    width: 100%;
    height: 100%;
  }
</style>
