<!--
  PACKAGE_NAME : src/pages/esp/system/menu
  FILE_NAME : modal-menu-detail-page.vue
  AUTHOR : devyoon91
  DATE : 2025-02-26
  DESCRIPTION : 메뉴 상세페이지 등록 및 수정 모달 페이지
-->
<template>
  <dx-popup
    :visible="isOpen"
    :show-title="propOption.showTitle"
    :title="propOption.title"
    :min-width="propOption.minWidth"
    :width="propOption.width"
    :min-height="propOption.minHeight"
    :height="propOption.height"
    :resize-enabled="propOption.resizeEnabled"
    :drag-enabled="propOption.dragEnabled"
    :show-close-button="propOption.showCloseButton"
    :hide-on-outside-click="propOption.closeOnOutsideClick"
    @hiding="closeModal"
  >
    <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" @init-new-row="handleInitNewRow" @saving="handleSaving" />
  </dx-popup>
</template>

<script>
  import { DxPopup } from 'devextreme-vue/popup';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';
  import EspDeleteButton from '@/components/common/buttons/esp-delete-button.vue';
  import { mountComponent } from '@/utils/devextreme-util';
  import { isSuccess } from '@/utils/common-lib';
  import {resetRouter} from "@/router";

  export default {
    name: 'modal-menu-detail-page',
    components: { EspDxDataGrid, DxPopup },
    props: {
      isOpen: {
        type: Boolean,
        default: false,
      },
      menuData: Object,
    },
    watch: {
      isOpen: {
        handler(val) {
          if (val) {
            this.getMenuDetailPage();
          } else {
            this.dataGrid.dataSource = [];
            // $refs 존재 여부 확인 후 getInstance 호출
            const dataGridRef = this.$refs[this.dataGrid.refName];
            if (dataGridRef && dataGridRef.getInstance) {
              const instance = dataGridRef.getInstance;
              instance.cancelEditData();
              instance.refresh();
            }
          }
        },
        immediate: true,
      },
    },
    data() {
      return {
        propOption: {
          showTitle: true,
          title: '상세페이지 설정',
          minWidth: 700,
          width: 700,
          minHeight: 600,
          height: 600,
          resizeEnabled: true,
          dragEnabled: true,
          showCloseButton: true,
          closeOnOutsideClick: true,
          useToolbar: true,
          useSaveBtn: false,
          useCancelBtn: false,
        },
        dataGrid: {
          refName: 'menuDetailPageRef',
          dataSource: [],
          apiActionNm: {
            update: 'MENU_DETAIL_SAVE',
          },
          keyExpr: 'id',
          height: '500px',
          showActionButtons: {
            update: true,
            delete: false,
            customButtons: [
              {
                template: (data, index, element) => {
                  mountComponent(
                    element,
                    EspDeleteButton,
                    {},
                    {
                      handleClick: this.handleDelete,
                    },
                    this,
                  );
                },
                location: 'before',
              },
            ],
          },
          columns: [
            {
              dataField: 'id',
              caption: 'ID',
              visible: false,
            },
            {
              dataField: 'menuId',
              caption: 'menuId',
              visible: false,
            },
            {
              i18n: 'COMPONENTS.MENU_NAME',
              dataField: 'menuNm',
              caption: '메뉴명',
              requiredRule: {},
            },
            {
              dataField: 'pageUrl',
              caption: 'PAGE URL',
              requiredRule: {},
            },
          ],
        },
      };
    },
    methods: {
      /**
       * @description 모달 닫기
       * @param e
       */
      closeModal(e) {
        this.$emit('closeModal', e);
      },
      /**
       * @description 삭제 이벤트
       */
      async handleDelete() {
        const payload = {
          actionName: 'MENU_DETAIL_DELETE',
          data: this.$refs[this.dataGrid.refName].getInstance.getSelectedRowKeys(),
          useErrorPopup: true,
        };

        const res = await this.CALL_API(payload);

        if (isSuccess(res)) {
          await this.getMenuDetailPage();
          await resetRouter(); // 라우터 초기화
        }
      },
      /**
       * @description 신규 행 초기화
       * @param e
       */
      handleInitNewRow(e) {
        e.data.menuId = this.menuData.id;
      },
      /**
       * @description 저장 이벤트
       * @param e
       */
      async handleSaving(e) {
        const changedData = this.$_dxUtil.convertGridChangeEventToMergedData(e);
        const payload = {
          actionName: 'MENU_DETAIL_SAVE',
          data: changedData,
          useErrorPopup: true,
        };

        const res = await this.CALL_API(payload);

        if (isSuccess(res)) {
          await this.getMenuDetailPage();
          await resetRouter(); // 라우터 초기화
        }
      },
      /**
       * @description 메뉴 상세페이지 조회
       */
      async getMenuDetailPage() {
        const payload = {
          actionName: 'MENU_DETAIL_LIST',
          data: {
            menuId: this.menuData.id,
          },
          useErrorPopup: true,
        };

        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          this.dataGrid.dataSource = res.data.data;
        }
      },
    },
  };
</script>

<style lang="scss" scoped></style>
