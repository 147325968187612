import { TZDate } from '@toast-ui/calendar';

import { clone, addDate, addHours, subtractDate } from './utils';

const today = new TZDate();

export const events = [
  {
    id: '1',
    calendarId: '0',
    title: '🍞 TOAST UI Calendar Study',
    attendees: ['DEV'],
    category: 'time',
    start: clone(today, 1),
    end: addHours(today, 3),
  },
  {
    id: '2',
    calendarId: '0',
    title: '외근',
    location: '서울도시가스 염창역 본사',
    attendees: ['ERS', '브라더스비', '모음'],
    category: 'time',
    start: '2025-02-07T10:00:00',
    end: '2025-02-07T15:00:00',
  },
  {
    id: '3',
    calendarId: '1',
    title: 'R51 Workshop',
    attendees: ['R51'],
    location: '서초',
    state: 'Free',
    category: 'allday',
    start: subtractDate(today, 2),
    end: subtractDate(today, 1),
    isReadOnly: true,
    color: '#0a0a0a',
  },
  {
    id: 'weekly-meeting',
    calendarId: '1',
    title: '주간 회의',
    attendees: ['R51'],
    start: '2025-02-10T14:00:00',
    end: '2025-02-10T16:00:00',
    //recurrenceRule: 'FREQ=WEEKLY;BYDAY=MO', // MO: 월요일, TU: 화요일, ...
    category: 'time'
  }
];