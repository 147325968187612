import AgentConfig from '@/pages/cc/cti/config/agt';
import AttributeConfig from '@/pages/cc/cti/config/att';
import ContactTypeConfig from '@/pages/cc/cti/config/contact-type';
import InboundGroupConfig from '@/pages/cc/cti/config/ibg';
import NotReadyReasonConfig from '@/pages/cc/cti/config/nreason';
import SkillGroupConfig from '@/pages/cc/cti/config/skl';
import TeamConfig from '@/pages/cc/cti/config/team';
import InboundGroupCategory from '@/pages/cc/cti/ibg-category/index';
import SkillGroupCategory from '@/pages/cc/cti/skl-category/index';
import TeamGroupCategory from '@/pages/cc/cti/team-category/index';

export default function CTIConfigRoutes(commonComponents) {
  return [
    {
      path: '/cc/cti/config/ibg',
      name: 'InboundGroupConfig',
      components: { default: InboundGroupConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/cti/config/agt',
      name: 'AgentConfig',
      components: { default: AgentConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/cti/config/skl',
      name: 'SkillGroupConfig',
      components: { default: SkillGroupConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/cti/config/team',
      name: 'TeamConfig',
      components: { default: TeamConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/cti/config/att',
      name: 'AttributeConfig',
      components: { default: AttributeConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/cti/config/contact-type',
      name: 'ContactTypeConfig',
      components: { default: ContactTypeConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/cti/config/nreason',
      name: 'NotReadyReasonConfig',
      components: { default: NotReadyReasonConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/cti/ibg-category/index',
      name: 'InboundGroupCategory',
      components: { default: InboundGroupCategory, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/cti/team-category/index',
      name: 'TeamGroupCategory',
      components: { default: TeamGroupCategory, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/cti/skl-category/index',
      name: 'SkillGroupCategory',
      components: { default: SkillGroupCategory, ...commonComponents },
      meta: { requiresAuth: true },
    },

  ];
}
