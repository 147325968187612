
/**
 * @description 캐시된 탭 페이지 목록에 추가
 *
 * @param state
 * @param newTab
 */
export const addCachedTabPage = (state, newTab) => {
  const page = state.cachedTabPages.find(tab => tab.tabId === newTab.tabId);
  if (!page) {
    state.cachedTabPages.push(newTab);
  }
};

/**
 * @description 캐시된 탭 페이지 목록에 추가
 *
 * @param state
 * @param tabId
 */
export const removeCachedTabPage = (state, tabId) => {
  const index = state.cachedTabPages.findIndex(tab =>
    tab.tabId === tabId && tab.tabId !== 'HOME'
  );

  if (index !== -1) {
    state.cachedTabPages.splice(index, 1);
  }
};

/**
 * @description 캐시된 탭 페이지 목록 초기화
 *
 * @param state
 */
export const clearCachedTabPages = state => {
  // HOME 탭만 필터링하여 유지
  state.cachedTabPages = state.cachedTabPages.filter(tab => tab.tabId === 'HOME' || tab.locked === true);
};

/**
 * @description 캐시된 탭 페이지 목록 이동a
 *
 * @param state
 * @param payload
 */
export const moveCachedTab = (state, payload) => {
  const { fromIndex, toIndex } = payload;

  // HOME 탭 처리 (필요시)
  if (state.cachedTabPages[fromIndex].tabId === 'HOME' ||
    state.cachedTabPages[toIndex].tabId === 'HOME') {
    console.warn('HOME 탭은 이동할 수 없습니다');
    return;
  }

  const draggedTab = JSON.parse(JSON.stringify(state.cachedTabPages[fromIndex]));
  const updatedTabs = state.cachedTabPages.map(tab => ({...tab}));

  updatedTabs.splice(fromIndex, 1);
  updatedTabs.splice(toIndex, 0, draggedTab);
  state.cachedTabPages = updatedTabs;
}

/**
 * @description 캐시된 전체 탭 잠금 처리(토글)
 *
 * @param state
 * @param locked
 */
export const toggleAllCachedTabLocked = (state, locked) => {
  state.cachedTabPages = state.cachedTabPages.map(tab => {
    // HOME 탭은 제외(불변 객체)
    if (tab.tabId === 'HOME') {
      return tab;
    }

    return {
      ...tab,
      locked: locked
    };
  });
};

/**
 * @description 캐시된 특정 탭 잠금 처리(토글)
 *
 * @param state
 * @param tabId
 */
export const toggleCachedTabLocked = (state, tabId) => {
  let locked = state.cachedTabPages.find(tab => tab.tabId === tabId).locked;
  state.cachedTabPages.find(tab => tab.tabId === tabId).locked = !locked;
}

/**
 * @description 상세페이지 캐시된 탭 정보 설정
 * @param state
 * @param payload
 */
export const setDetailCachedTabInfo = (state, payload) => {
  const { tabId, detailTabInfo } = payload;
  const tab = state.cachedTabPages.find(tab => tab.tabId === tabId);
  if (tab) {
    tab.detailTabInfo = detailTabInfo;
  }
}

