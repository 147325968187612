<!--
  PACKAGE_NAME : src/pages/euc/phone/request/approval/list
  FILE_NAME : list.vue
  AUTHOR : jhcho
  DATE : 2024-05-28
  DESCRIPTION :
-->
<template>
  <div class="main">
    <div class="locker_setting_list sub_new_style01 sub_ui_box1">
      <Search
        codeKey="null"
        :customSearchList="searchType.list"
        :searchObj="searchType.obj"
        @change-search-type="$_changeSearchType"
        @onSearchClick="searchData($event)"
      >
        <template v-slot:before>
          <DxSelectBox
            v-model="searchType.customTypes.approverFl"
            placeholder="승인여부"
            :items="getApproverFlList"
            display-expr="label"
            value-expr="value"
            styling-mode="outlined"
            :width="120"
            :height="30"
            @value-changed="$_changeSearchCustomType('approverFl', $event)"
          />
        </template>
      </Search>
    </div>

    <esp-dx-data-grid :data-grid="dataGrid" ref="approvalGridRef" />
  </div>
</template>
<script>
  import Search from '@/components/common/search.vue';
  import { isEmpty, isSuccess } from '@/utils/common-lib';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      DxSelectBox,
      Search,
    },
    data() {
      return {
        searchType: {
          list: [
            {
              codeNm: '제목',
              codeValue: 'title',
            },
            {
              codeNm: '신청자',
              codeValue: 'userNm',
            },
          ],
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            approverFl: null,
          },
          paramsData: null,
        },
        dataGrid: {
          callApi: 'CALL_EUC_API',
          refName: 'approvalGridRef',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: 'calc(100vh - 225px)', // 주석처리시 100%
          apiActionNm: {},
          customEvent: {},
          showActionButtons: {
            customButtons: [],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '제목',
              dataField: 'title',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                const aTag = document.createElement('a');
                aTag.addEventListener('click', () => {
                  this.$store.commit('setDetailParams', { request: options.data });
                  this.$router.push({ path: '/euc/phone/approval/request', query: { requestId: options.data.id } });
                });
                aTag.innerText = options.data.title;
                container.appendChild(aTag);
              },
            },
            {
              caption: '신청자',
              i18n: 'UC.WORD.REQUESTER',
              dataField: 'userNm',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                const data = options.data;
                container.innerText = `${data?.deptNm || ''} ${data?.userNm || ''} ${data?.gradeNm || ''}`;
              },
            },
            {
              caption: '완료 희망일',
              dataField: 'dueDt',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateCellValue: row => this.formatDt(row.dueDt, 'YYYY.MM.DD'),
            },
            {
              caption: '승인여부',
              dataField: 'approverFl',
              width: 110,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              requiredRule: {
                message: '필수 항목입니다.',
              },
              cellTemplate: (container, options) => {
                const data = options.data;
                container.innerText = data.approverFl === 'Y' ? '승인' : '미승인';
              },
            },
            {
              caption: '승인일',
              dataField: 'approverDt',
              width: 160,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateCellValue: row => this.formatDt(row.approverDt),
            },
            {
              caption: '승인자',
              dataField: 'approverUserNm',
              width: 160,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                const data = options.data;
                container.innerText = `${data?.approverDeptNm || ''} ${data?.approverUserNm || ''} ${data?.approverGradeNm || ''}`;
              },
            },
            {
              caption: '진행상태',
              dataField: '',
              width: 110,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                let result = '';
                if (options.data.requestItems?.length) {
                  const totalCnt = options.data.requestItems.length;
                  const progressCnt = options.data.requestItems.filter(item => item.status === 'COMPLETE')?.length || 0;
                  if (progressCnt >= totalCnt) {
                    result = '완료';
                  } else if (totalCnt > progressCnt) {
                    result = `${progressCnt} / ${totalCnt}`;
                  }
                } else {
                  result = '0 / 0';
                }
                container.innerText = result;
              },
            },
            {
              caption: '신청일',
              dataField: 'regDt',
              width: 160,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateCellValue: row => this.formatDt(row.regDt),
            },
          ],
        },
      };
    },
    computed: {
      getApproverFlList() {
        return [{ label: '전체', value: null }, ...this.$_enums.common.stringUsedFlag.values];
      },
    },
    methods: {
      onAddData() {
        this.$store.commit('setDetailParams');
        this.$router.push({ path: '/euc/phone/approval/request' });
      },
      searchData(paramsData) {
        //검색 버튼 클릭시 이벤트
        if (paramsData) {
          this.searchType.paramsData = { ...this.searchType.paramsData, ...paramsData };
        } else {
          //검색 키워드의 값이 없으면
          //검색키는 설정하고, 검색어를 입력하지 않으면, 전체 목록 출력
          let codeValues = this.searchType.list.map(d => d.codeValue);
          if (!isEmpty(this.searchType.paramsData)) {
            Object.keys(this.searchType.paramsData).forEach(key => {
              if (codeValues.includes(key)) {
                delete this.searchType.paramsData[key]; //검색 조건 키 삭제
              }
            });
          }
        }
        this.selectDataList();
      },
      async selectDataList(sort = '-regDt') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }

            params.approverUserNo = this.$store.getters.getLoginId;
            params = { ...params, ...this.searchType.paramsData };

            const payload = {
              actionName: 'EUC_PHONE_REQUEST_SELECT',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
            }
            return rtnData;
          },
        });
      },
      formatDt(date, toFormat = 'YYYY.MM.DD HH:mm:ss') {
        if (date) {
          return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', toFormat);
        }
      },
    },
    mounted() {
      this.selectDataList();
    },
    created() {},
  };
</script>
<style scoped></style>