<!--
  PACKAGE_NAME : src/pages/report/config/wizard
  FILE_NAME : detail-visible-column.vue
  AUTHOR : kwmoon
  DATE : 2025. 4. 1.
  DESCRIPTION :
-->
<template>
  <div class="py-4 px-4">
    <h3 class="text-xl font-medium ml-2 mb-2">보고서 항목</h3>
    <DxDataGrid
      class="grid-box"
      id="grid"
      ref="grid"
      :data-source="columns"
      :show-borders="false"
      :show-column-headers="true"
      :show-column-lines="false"
      :show-row-lines="true"
      :row-alternation-enabled="false"
      :allow-column-resizing="true"
      height="calc(100vh - 580px)"
      :visible="true"
      :selected-row-keys="grid.selectedItemKeys"
      @selection-changed="grid.selectionChanged"
      :no-data-text="emptyDataNotice()"
    >
      <DxSorting mode="none" />
      <DxPaging :enabled="false" :page-size="1000" />
      <DxRowDragging :on-add="onDropFromInvisible" group="report-columns" :allow-reordering="true" :on-reorder="onDragChangeOrder" />
      <!-- 수정모드 -->
      <DxColumn caption="ID" data-field="id" :fixed="false" alignment="center" :visible="false" :allow-editing="false" />
      <DxColumn caption="멀티헤더명" :fixed="false" data-field="multiHeaderNm" alignment="center" :visible="true" :width="150" />
      <DxColumn
        caption="항목명"
        data-field="caption"
        :fixed="false"
        alignment="left"
        :visible="true"
        :allow-editing="false"
        :width="150"
        cell-template="titleTemplate"
      />
      <DxColumn
        caption="컬럼명"
        data-field="dataField"
        :fixed="false"
        alignment="left"
        :visible="true"
        :allow-editing="false"
        :width="150"
      />
      <DxColumn caption="넓이" data-field="width" :fixed="false" alignment="center" :allow-editing="true" :width="100" />
      <DxColumn caption="출력형식" data-field="format" :fixed="false" :allow-editing="true" alignment="center" :width="100">
        <DxLookup :data-source="format.items" value-expr="value" display-expr="label" />
      </DxColumn>
      <DxColumn caption="정렬" :fixed="false" data-field="align" alignment="center" :width="100">
        <DxLookup :data-source="align.items" value-expr="value" display-expr="label" />
      </DxColumn>
      <DxColumn caption="설명" data-field="description" :fixed="false" :allow-editing="true" alignment="left" />
      <DxColumn caption="삭제" cell-template="moveTemplate" :width="60" :fixed="false" alignment="center" />
      <template #moveTemplate="{ data }">
        <button class="btn-icon close" type="button" @click="onClickRemoveItem({ data })"></button>
      </template>
      <template #titleTemplate="{ data }">
        <a class="font-bold" @click="openColumnModal(data)">
          {{ data.value }}
        </a>
      </template>
      <DxScrolling row-rendering-mode="virtual" />
    </DxDataGrid>
  </div>
</template>
<script>
  import { DxColumn, DxDataGrid, DxLookup, DxPaging, DxRowDragging, DxScrolling, DxSorting } from 'devextreme-vue/data-grid';

  export default {
    name: 'detail-visible-column',
    components: { DxRowDragging, DxLookup, DxSorting, DxDataGrid, DxScrolling, DxPaging, DxColumn },
    props: {
      columns: {
        type: Array,
        default: () => [],
      },
    },
    watch: {},
    data() {
      return {
        grid: {
          dataSource: [], // 보고서항목 (visible: true)
          selectedItemKeys: [],
          selectionChanged: data => {
            this.grid.selectedItemKeys = data.selectedRowKeys;
          },
        },
        format: { items: this.$_enums.report.columnFormat.values },
        align: { items: this.$_enums.report.columnAlign.values },
      };
    },
    computed: {},
    methods: {
      /** 데이터 없을 때 텍스트 */
      emptyDataNotice() {
        if (this.grid.dataSource.length === 0) {
          return '선택된 항목이 없습니다. 좌측에서 드래그로 설정 할 수 있습니다.';
        }
      },

      /** invisible 항목이 드래그 앤 드롭으로 추가 이벤트 발생 시 */
      onDropFromInvisible(e) {
        this.$emit('drop-column', 'visible', e);
      },

      /** 미사용 항목으로 이동 */
      onClickRemoveItem({ data }) {
        const draggedItem = data.data;
        this.$emit('drop-column', 'invisible', {
          itemData: draggedItem,
          isClick: true,
        });
      },

      /** 항목 변경 이벤트 */
      onDragChangeOrder(e) {
        this.$emit('re-order', 'visible', e);
      },

      /**
       * 보고서 항목 수정 팝업 열기
       */
      openColumnModal({ data }) {
        this.$emit('open-column', true, 'visible', data);
      },
    },
    updated() {},
    created() {},
    mounted() {},
    activated() {},
    deactivated() {},
    beforeDestroy() {},
    destroyed() {},
  };
</script>
<style lang="scss" scoped></style>
