var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dx-popup',{attrs:{"show-title":true,"title":_vm.modal.title,"min-width":_vm.modal.minWidth,"width":_vm.modal.width,"min-height":_vm.modal.minHeight,"height":_vm.modal.height,"drag-enabled":_vm.modal.dragEnabled,"resize-enabled":_vm.modal.resizeEnabled,"hide-on-outside-click":_vm.modal.closeOnOutsideClick,"show-close-button":_vm.modal.showCloseButton,"visible":_vm.isOpen},on:{"hiding":_vm.closeModal},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',[_c('table',{staticClass:"table_form line-bin"},[_c('colgroup',[_c('col',{staticStyle:{"width":"130px"}}),_c('col',{staticStyle:{"width":"auto"}})]),_c('tbody',[_c('tr',[_c('th',{attrs:{"scope":"row"}},[_c('label',{attrs:{"for":"label5"}},[_vm._v(_vm._s(_vm.$_lang('COMPONENTS.TITLE', {defaultValue: '제목'}))+" "),_c('span',{staticClass:"icon_require"},[_vm._v("필수항목")])])]),_c('td',[_c('dx-text-box',{staticClass:"mar_ri10",attrs:{"max-length":_vm.limitNumberTexts.maxLengths.name,"styling-mode":_vm.config.stylingMode,"width":200},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}},[_c('dx-validator',{attrs:{"validation-group":"validationGroupName"}},[_c('dx-async-rule',{attrs:{"validation-callback":_vm.checkValidName,"message":_vm.$_lang('COMMON.MESSAGE.NOT_DUPLICATE_VALUE_IS',
                             { value: _vm.$_lang('COMPONENTS.TITLE', { defaultValue: '제목' }), defaultValue: '[제목] 값은 중복될 수 없습니다.' })}}),_c('dx-required-rule',{attrs:{"message":_vm.$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS',
                              { value: _vm.$_lang('COMPONENTS.TITLE', {defaultValue: '제목'}), defaultValue: '[제목] 은/는 필수값 입니다' })}})],1)],1),_c('span',[_vm._v(" "+_vm._s(_vm.limitNumberTexts.textLengths.name ? _vm.limitNumberTexts.textLengths.name : _vm.formData.name ? _vm.formData.name.length : 0)+" ")]),_vm._v("/"+_vm._s(_vm.limitNumberTexts.maxLengths.name)+" ")],1)]),_c('tr',[_c('td',{staticStyle:{"text-align":"center"},attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(_vm.$_lang('LLM_TESTER.MESSAGE.NEW_PROMPT_ADD_ALERT', { defaultValue: '선택한 프롬프트를 수정하지 않고 새로운 프롬프트로 생성됩니다' }))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$_lang('LLM_TESTER.MESSAGE.NEW_PROMPT_NAME_ALERT', { defaultValue: '새로운 프롬프트의 제목을 입력하세요.' }))+" ")])])])])])]},proxy:true}])},[_c('dx-toolbar-item',{attrs:{"widget":"dxButton","toolbar":"bottom","location":"center","visible":true,"options":{
          elementAttr: { class: 'default filled txt_S medium' },
          text: this.$_lang('COMPONENTS.SAVE', { defaultValue: '저장' }),
          width: '120',
          height: '40',
          useSubmitBehavior: true,
          validationGroup: 'validationGroupName',
          onClick: _vm.saveModal,
        }}}),_c('dx-toolbar-item',{attrs:{"widget":"dxButton","toolbar":"bottom","location":"center","visible":true,"options":{
          elementAttr: { class: 'white filled txt_S medium' },
          text: this.$_lang('COMPONENTS.CANCEL', { defaultValue: '취소' }),
          width: '120',
          height: '40',
          onClick: _vm.closeModal,
        }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }