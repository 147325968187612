var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dx-popup',{attrs:{"show-title":true,"title":_vm.modal.title,"min-width":_vm.modal.minWidth,"width":_vm.modal.width,"min-height":_vm.modal.minHeight,"height":_vm.modal.height,"drag-enabled":_vm.modal.dragEnabled,"resize-enabled":_vm.modal.resizeEnabled,"hide-on-outside-click":_vm.modal.closeOnOutsideClick,"show-close-button":_vm.modal.showCloseButton,"visible":_vm.isOpen},on:{"hiding":_vm.closeModal},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',[_c('table',{staticClass:"table_form line-bin"},[_c('colgroup',[_c('col',{staticStyle:{"width":"130px"}}),_c('col',{staticStyle:{"width":"auto"}})]),_c('tbody',[_c('tr',[_c('th',{attrs:{"scope":"row"}},[_c('label',{attrs:{"for":"label5"}},[_vm._v(_vm._s(_vm.$_lang('LLM_TESTER.WORD.TEST_NAME', { defaultValue: '실험 이름' }))+" "),_c('span',{staticClass:"icon_require"},[_vm._v("필수항목")])])]),_c('td',[_c('dx-text-box',{staticClass:"mar_ri10",attrs:{"max-length":_vm.limitNumberTexts.maxLengths.name,"styling-mode":_vm.config.stylingMode,"width":200},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}},[_c('dx-validator',{attrs:{"validation-group":"validationGroupName"}},[_c('dx-async-rule',{attrs:{"validation-callback":_vm.checkValidName,"message":_vm.$_lang('COMMON.MESSAGE.NOT_DUPLICATE_VALUE_IS',
                               { value: _vm.$_lang('LLM_TESTER.WORD.TEST_NAME', { defaultValue: '실험 이름' }), defaultValue: '[실험 이름] 값은 중복될 수 없습니다.' })}}),_c('dx-required-rule',{attrs:{"message":_vm.$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS',
                                { value: _vm.$_lang('LLM_TESTER.WORD.TEST_NAME', { defaultValue: '실험 이름' }), defaultValue: '[실험 이름] 은/는 필수값 입니다' })}})],1)],1),_c('span',[_vm._v(" "+_vm._s(_vm.limitNumberTexts.textLengths.name ? _vm.limitNumberTexts.textLengths.name : _vm.formData.name ? _vm.formData.name.length : 0)+" ")]),_vm._v("/"+_vm._s(_vm.limitNumberTexts.maxLengths.name)+" ")],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_c('label',{attrs:{"for":"label5"}},[_vm._v(_vm._s(_vm.$_lang('LLM_TESTER.WORD.EVALUATION_PROMPT', { defaultValue: '평가 프롬프트' }))+" "),_c('span',{staticClass:"icon_require"},[_vm._v("필수항목")])])]),_c('td',{staticClass:"search-container"},[_c('dx-select-box',{attrs:{"placeholder":_vm.$_lang('LLM_TESTER.WORD.SELECT_PROMPT', { defaultValue: '프롬프트 선택' }),"styling-mode":_vm.config.stylingMode,"height":30,"width":"200","items":_vm.promptList,"value-expr":"id","display-expr":"name"},model:{value:(_vm.formData.evaluation_prompt_id),callback:function ($$v) {_vm.$set(_vm.formData, "evaluation_prompt_id", $$v)},expression:"formData.evaluation_prompt_id"}},[_c('dx-validator',{attrs:{"validation-group":"validationGroupName"}},[_c('dx-required-rule',{attrs:{"message":_vm.$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS',
                                { value: _vm.$_lang('LLM_TESTER.WORD.EVALUATION_PROMPT', {defaultValue: '평가 프롬프트'}), defaultValue: '[평가 프롬프트] 은/는 필수값 입니다' })}})],1)],1)],1)])])])])]},proxy:true}])},[_c('dx-toolbar-item',{attrs:{"widget":"dxButton","toolbar":"bottom","location":"center","visible":true,"options":{
          elementAttr: { class: 'default filled txt_S medium' },
          text: this.$_lang('COMPONENTS.ADD', { defaultValue: '추가' }),
          width: '120',
          height: '40',
          useSubmitBehavior: true,
          validationGroup: 'validationGroupName',
          onClick: _vm.handleAddTest,
        }}}),_c('dx-toolbar-item',{attrs:{"widget":"dxButton","toolbar":"bottom","location":"center","visible":true,"options":{
          elementAttr: { class: 'white filled txt_S medium' },
          text: this.$_lang('COMPONENTS.CANCEL', { defaultValue: '취소' }),
          width: '120',
          height: '40',
          onClick: _vm.closeModal,
        }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }