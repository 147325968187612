import WizardReportList from '@/pages/report/config/wizard/list';
import WizardReportDetail from '@/pages/report/config/wizard/detail';
import ReportXmlConfig from '@/pages/report/config/query-management';
import QueryExecutePage from '@/pages/report/config/select';
import DashboardWidgetConfig from '@/pages/report/config/dash-board';

export default function ConfigRoutes(commonComponents) {
  return [
    {
      path: '/report/config/wizard/list',
      name: 'WizardReportList',
      components: { default: WizardReportList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/report/config/wizard/detail',
      name: 'WizardReportDetailNew',
      components: { default: WizardReportDetail, ...commonComponents },
      meta: { requiresAuth: true, isDetailPage: true },
    },
    {
      path: '/report/config/wizard/detail/:id',
      name: 'WizardReportDetailUpdate',
      components: { default: WizardReportDetail, ...commonComponents },
      meta: { requiresAuth: true, isDetailPage: true },
    },
    {
      path: '/report/config/query-management',
      name: 'ReportXmlConfig',
      components: { default: ReportXmlConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/report/config/select',
      name: 'QueryExecutePage',
      components: { default: QueryExecutePage, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/report/config/dash-board',
      name: 'DashboardWidgetConfig',
      components: { default: DashboardWidgetConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}
