import Vue from 'vue';
import VueRouter from 'vue-router';
import { Top, Bottom, LeftMenu, Breadcrumbs, NoneMain } from '@/layouts';
import NotAuthorized from '@/pages/esp/error/401.vue';
import NotFoundError from '@/pages/esp/error/404.vue';
import Main from '@/pages/main.vue';
import {
  updateMenuHistory,
  verifyTokenAndMemberState,
  initUserStore,
  checkPasswordChangeState,
  resetVuexState,
  getPostNavigationPath,
} from './router-utils';

import EspRoutes from '@/router/esp';
import ReportRoutes from '@/router/report';
import EucRoutes from '@/router/euc';
import AiRoutes from '@/router/ai';
import { getStoreInstance } from '@/store';
import CcRoutes from '@/router/cc';
import EwmRoutes from "@/router/ewm";
import ComponentRoutes from "@/router/component";

/**
 * vue-router 3.x 버전 push 할때 발생하는 NavigationDuplicated 에러만 무시하는 전역 설정
 */
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter); // Vue Router 설정

let routerInstance = null; // router 전역변수
const commonComponents = { top: Top, bottom: Bottom, left: LeftMenu, breadcrumbs: Breadcrumbs };
// 라우터 설정
const staticRoutes = [
  {
    path: '/',
    name: 'home',
    components: { default: Main, ...commonComponents },
    meta: { requiresAuth: true, noTrack: true },
  },
  ...EucRoutes(commonComponents),
  ...ReportRoutes(commonComponents),
  ...EspRoutes(commonComponents),
  ...AiRoutes(commonComponents),
  ...CcRoutes(commonComponents),
  ...EwmRoutes(commonComponents),
  ...ComponentRoutes(commonComponents),
  {
    path: '/error/401',
    name: 'Error401',
    components: { singlePage: NotAuthorized },
    meta: { requiresAuth: false, noTrack: true },
  },
  {
    path: '/error/404',
    name: 'Error404',
    components: { singlePage: NotFoundError },
    meta: { requiresAuth: false, noTrack: true },
  },
  {
    path: '/empty-main-page',
    name: 'EmptyMainPage',
    components: { default: NoneMain, ...commonComponents },
    meta: { requiresAuth: true, noTrack: true },
  },
];

// 권한필터 통과 white list route path
const whiteList = ['/', '/empty-main-page', '/password-change'];

/**
 * @description 라우터 객체 생성
 * @return {Promise<VueRouter>}
 */
export async function createRouter() {
  try {
    const router = new VueRouter({
      mode: 'history',
      routes: staticRoutes,
    });

    // BeforeEach 등록
    router.beforeEach(async (to, from, next) => {
      try {
        // 페이지 없음 404
        if (!to.matched.length) {
          return next({
            path: '/error/404',
            query: { toPath: to.path },
          });
        }

        await authFilter(to, from, next); // 인증 필터
      } catch (error) {
        Vue.$log.debug(error);

        // 로그 아웃 처리
        if (getStoreInstance().getters.getLoginId) {
          await getStoreInstance().dispatch('LOGOUT', {
            data: {
              loginId: getStoreInstance().getters.getLoginId,
            },
          });
        }

        await resetVuexState(); // Vuex Store State 초기화 -> 시스템 store 갱신
      }
    });

    // AfterEach 등록
    router.afterEach(async (to, from) => {
      await updateMenuHistory(to);
      document.title = getStoreInstance().getters.getThemeSetting?.customerNm || 'ESP';
    });

    routerInstance = router; // 인스턴스 전역 변수에 저장
    return router;
  } catch (error) {
    console.error('Error creating router:', error);
    await resetVuexState(); // Vuex Store State 초기화 -> 시스템 store 갱신
    throw error; // 초기화 실패 시 throw
  }
}

/**
 * @description 라우팅 사용자 인증 필터
 * @param to
 * @param from
 * @param next
 * @return {Promise<void>}
 */
const authFilter = async (to, from, next) => {
  // 권한이 필요 없는 페이지
  if (!to.matched.some(record => record.meta.requiresAuth)) {
    return next();
  }

  // Direct login 처리
  // const isLoggedIn = await handleDirectLogin(to, next);
  // if (isLoggedIn) {
  //   return;
  // }

  // 사용자 토큰 검증 및 상태 체크
  const isValidToken = await verifyTokenAndMemberState();
  if (!isValidToken) {
    Vue.$log.debug('token validation check failed, redirecting to /login');
    sessionStorage.setItem('redirectPath', to.path);
    return next('/login');
  } else {
    await initUserStore(); // 사용자 인증이 필요한 API, Store 갱신
  }

  // 상세페이지가 아니고 && 이동하는 라우팅 주소의 권한이 있는지 확인(일부 화이트리스트 제외)
  if (!to.matched.some(record => record.meta.isDetailPage) && isUnauthorizedRoute(to.path, whiteList)) {
    return next({
      path: '/error/401',
      query: { toPath: to.path },
    });
  }

  // 비밀번호 만료 및 임시 계정 체크
  if (to.path !== '/password-change' && checkPasswordChangeState(getStoreInstance().getters.getMemberState)) {
    return next('/password-change');
  }

  // 루트 페이지 이동 인터셉트(메인설정, 리다이렉트 페이지 확인)
  if (to.path === '/') {
    checkWhitelistAndClearSessionStorage(whiteList); // 권한이 필요 없는 페이지로 이동 시 세션 스토리지 redirectPath 초기화
    return next(getPostNavigationPath());
  }
  return next();
};

/**
 * @description 라우트 권한 체크
 * @param toPath path "/"
 * @param whiteList 권한이 필요 없는 페이지
 * @return {boolean}
 */
const isUnauthorizedRoute = (toPath, whiteList) => {
  // 화이트리스트 체크
  if (whiteList.some(path => path === toPath)) {
    return false;
  }

  // 메뉴 권한이 있는 라우트 체크
  const isAuthorizedRoute = getStoreInstance()
    .getters.getMenuList.filter(menu => menu.authUseFl === 'Y')
    .some(menu => menu.pageUrl === toPath);

  return !isAuthorizedRoute;
};

/**
 * @description 권한이 필요 없는 페이지로 이동 시 세션 스토리지 redirectPath 초기화
 * @param whiteList 권한이 필요 없는 페이지
 */
const checkWhitelistAndClearSessionStorage = whiteList => {
  const redirectPath = sessionStorage.getItem('redirectPath');
  if (redirectPath && whiteList.some(path => path === redirectPath)) {
    sessionStorage.removeItem('redirectPath');
  }
};

/**
 * @description vue-router 인스턴스 리턴
 * @return {null}
 */
export function getRouterInstance() {
  if (!routerInstance) {
    throw new Error('[Vue Router Error] Vue Router 초기화 오류');
  }
  return routerInstance;
}

/**
 * 라우터를 리셋하고 새로 경로와 매칭 테이블 추가
 */
export async function resetRouter() {
  if (!routerInstance) {
    throw new Error('[Vue Router Error] Vue Router 초기화 오류');
  }

  // 새 라우터 생성
  const newRouter = new VueRouter({
    mode: 'history',
    routes: staticRoutes,
  });

  // 기존 라우터 매칭 테이블 교체
  routerInstance.matcher = newRouter.matcher;
}
