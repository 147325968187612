<!--
  PACKAGE_NAME : src/pages/esp/system/menu
  FILE_NAME : menu-add-default-form.vue
  AUTHOR : devyoon91
  DATE : 2025-01-09
  DESCRIPTION : 
-->
<template>
  <div class="dx-fieldset">
    <div class="dx-fieldset-header" style="padding-top: 38px" v-if="showTitle">
      {{ getI18n('COMPONENTS.MENU_INFO', '메뉴 정보') }}
    </div>
    <div class="dx-field">
      <div class="dx-field-label">{{ getI18n('COMPONENTS.PARENT_MENU_NAME', '상위 메뉴') }}</div>
      <div class="dx-field-value">
        <div class="menu-hierarchy" v-for="(menu, index) in menuHierarchy" :key="menu.id">
          <span class="menu-item">{{ menu.menuNm }} </span>
          <span v-if="index < menuHierarchy.length - 1" class="separator"> > </span>
        </div>
      </div>
    </div>
    <div class="dx-field">
      <div class="dx-field-label">{{ getI18n('COMPONENTS.MENU_NAME', '메뉴명') }}*</div>
      <div class="dx-field-value">
        <dx-text-box v-model="formData.menuNm" styling-mode="outlined" :width="style.boxWidth">
          <dx-validator ref="menuNameValidatorRef" :validation-group="validationGroupName">
            <dx-required-rule
              :message="`${getI18n('COMMON.MESSAGE.REQUIRED_VALUE_IS', '[메뉴명] 은/는 필수값 입니다.', {
                value: getI18n('COMPONENTS.MENU_NAME', '메뉴명'),
              })}`"
            />
            <dx-custom-rule
              :message="
                getI18n('COMMON.MESSAGE.TEXT_BOX_LIMIT', `최대 ${limitLength.menuNm}자까지 입력 가능합니다.`, { limit: limitLength.menuNm })
              "
              :validation-callback="e => validateLength(e, limitLength.menuNm)"
            />
          </dx-validator>
        </dx-text-box>
        <div class="limit-length-box">
          <span>{{ formData?.menuNm ? formData.menuNm.length : 0 }}</span
          >/{{ limitLength.menuNm }}
        </div>
      </div>
    </div>
    <div class="dx-field">
      <div class="dx-field-label">{{ getI18n('COMPONENTS.MENU_TYPE', '메뉴 유형') }}*</div>
      <div class="dx-field-value">
        <dx-select-box
          v-model="formData.menuTypeCd"
          :items="enums.common.menuType.values"
          display-expr="label"
          value-expr="value"
          :styling-mode="style.stylingModeOutlined"
          :width="style.boxWidth"
          item-template="itemTemplate"
          @selectionChanged="setMenuType"
        >
          <dx-validator ref="menuTypeCdValidatorRef" :validation-group="validationGroupName">
            <dx-required-rule
              :message="`${getI18n('COMMON.MESSAGE.REQUIRED_VALUE_IS', '[메뉴 유형] 은/는 필수값 입니다.', {
                value: getI18n('COMPONENTS.MENU_TYPE', '메뉴 유형'),
              })}`"
            />
          </dx-validator>
          <template #itemTemplate="{ data }">
            <div :title="enums.common.menuType.getMenuTypeTooltip(data.value)">
              {{ data.label }}
            </div>
          </template>
        </dx-select-box>
      </div>
    </div>
    <div class="dx-field" v-if="formData.menuTypeCd === enums.common.menuType.NORMAL_MENU.value && formData.menuDepth === 1">
      <div class="dx-field-label">{{ getI18n('COMPONENTS.MENU_ICON', '메뉴 아이콘') }}</div>
      <div class="dx-field-value">
        <dx-select-box
          v-model="formData.menuIcon"
          :data-source="theme.menuIcons"
          display-expr="label"
          value-expr="value"
          item-template="item"
          field-template="field"
          :width="style.boxWidth"
          :styling-mode="style.stylingModeOutlined"
          :drop-down-options="{ maxHeight: 300 }"
        >
          <dx-validator ref="menuIconValidatorRef" :validation-group="validationGroupName">
            <dx-required-rule
              :message="`${getI18n('COMMON.MESSAGE.REQUIRED_VALUE_IS', '[메뉴 아이콘] 은/는 필수값 입니다.', {
                value: getI18n('COMPONENTS.MENU_ICON', '메뉴 아이콘'),
              })}`"
            />
          </dx-validator>
          <template #field="{ data }">
            <div v-if="data">
              <dx-text-box
                :styling-mode="style.stylingModeUnderLined"
                :value="data && data.label"
                :read-only="true"
                :placeholder="getI18n('COMPONENTS.SELECT', '선택')"
              >
                <div class="icon-image-box">
                  <img :src="data.src" class="icon-image" alt="icon image" />
                </div>
              </dx-text-box>
            </div>
            <div v-else>
              <dx-text-box
                :styling-mode="style.stylingModeUnderLined"
                :read-only="true"
                :placeholder="getI18n('COMPONENTS.SELECT', '선택')"
              />
            </div>
          </template>
          <template #item="{ data }">
            <div>
              <img :src="data.src" class="icon-image" alt="icon image" />
              <span>
                {{ data.label }}
              </span>
            </div>
          </template>
        </dx-select-box>
      </div>
    </div>
    <div class="dx-field" v-if="formData.menuTypeCd !== enums.common.menuType.NORMAL_MENU.value">
      <div class="dx-field-label">{{ getI18n('COMPONENTS.PAGE_PATH', '페이지 경로') }}</div>
      <div class="dx-field-value">
        <dx-text-box
          placeholder="예) /esp/menu/list"
          v-model="formData.pageUrl"
          :styling-mode="style.stylingModeOutlined"
          :width="style.boxWidth"
        >
          <dx-validator ref="pageUrlValidatorRef" :validation-group="validationGroupName">
            <dx-custom-rule
              :message="
                getI18n('COMMON.MESSAGE.TEXT_BOX_LIMIT', `최대 ${limitLength.pageUrl}자까지 입력 가능합니다.`, {
                  limit: limitLength.pageUrl,
                })
              "
              :validation-callback="e => validateLength(e, limitLength.pageUrl)"
            />
          </dx-validator>
        </dx-text-box>
        <div class="limit-length-box">
          <span>{{ formData?.pageUrl ? formData.pageUrl.length : 0 }}</span
          >/{{ limitLength.pageUrl }}
        </div>
      </div>
    </div>
    <div class="dx-field">
      <div class="dx-field-label">{{ getI18n('COMPONENTS.MENU_ORDER', '메뉴 순서') }}</div>
      <div class="dx-field-value">
        <dx-number-box v-model="formData.menuOrd" :styling-mode="style.stylingModeOutlined" :width="style.boxWidth" />
      </div>
    </div>
    <div class="dx-field">
      <div class="dx-field-label">{{ getI18n('COMPONENTS.USE_STATUS', '사용여부') }}</div>
      <div class="dx-field-value">
        <dx-switch @value-changed="setViewFl($event)" :value="formData.viewFl === enums.common.stringViewFlag.YES.value" />
      </div>
    </div>
    <div class="dx-field" v-if="isNewMenu">
      <div class="dx-field-label">
        {{ getI18n('COMPONENTS.AUTH_ADD', '권한 추가') }}
        <img class="img-question" id="img-question-tag" @mouseover="handleQuestionMouseEvent" @mouseleave="handleQuestionMouseEvent" />
        <dx-popover target="#img-question-tag" :visible="tooltip.visible" :hide-on-outside-click="false">
          <span
            v-html="
              getI18n(
                'COMMON.MESSAGE.MENU_AUTH_ADD_DESC',
                '최초 메뉴 등록 시 등록자의 권한그룹(상위 권한 포함)에 <br /> 메뉴 권한을 자동으로 추가할수 있습니다. <br /> 추가 권한설정은 권한 관리 메뉴에서만 가능합니다.',
              )
            "
          >
          </span>
        </dx-popover>
      </div>
      <div class="dx-field-value">
        <dx-switch @value-changed="setAddAuthUseFl($event)" :value="formData.addAuthUseFl === enums.common.stringUsedFlag.YES.value" />
      </div>
    </div>
    <div class="dx-field" v-if="!isNewMenu && validatedDetailPageMenuType(formData)">
      <div class="dx-field-label">{{ '상세 페이지' }}</div>
      <div class="dx-field-value">
        <dx-button
          :text="getI18n('COMPONENTS.ADD', '추가')"
          class="btn_XS default filled add1"
          :height="30"
          @click="handleOpenMenuDetailPageModal"
        />
      </div>
    </div>
    <div class="dx-field" v-if="!isNewMenu && !isNormalPage">
      <div class="dx-field-label">{{ getI18n('COMPONENTS.CHILD_MENU', '하위 메뉴') }}</div>
      <div class="dx-field-value">
        <dx-button
          :text="getI18n('COMPONENTS.ADD', '추가')"
          class="btn_XS default filled add1"
          :height="30"
          @click="handleOpenAddManuModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { DxRequiredRule, DxValidator, DxCustomRule } from 'devextreme-vue/validator';
  import DxSwitch from 'devextreme-vue/switch';
  import enums from '../../../../configs/enums';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxButton } from 'devextreme-vue/button';
  import { DxNumberBox } from 'devextreme-vue/number-box';
  import { DxPopover } from 'devextreme-vue/popover';

  export default {
    components: {
      DxPopover,
      DxButton,
      DxValidator,
      DxSwitch,
      DxRequiredRule,
      DxTextBox,
      DxSelectBox,
      DxNumberBox,
      DxCustomRule,
    },
    props: {
      menuData: Object, // 메뉴 데이터
      validationGroupName: {
        // 유효성 검사 그룹명
        type: String,
        default: 'menuAddFromValidationGroup',
      },
      showTitle: {
        // 타이틀 표시 여부
        type: Boolean,
        default: true,
      },
      isNewMenu: {
        // 신규 메뉴 여부
        type: Boolean,
        default: false,
      },
    },
    watch: {
      menuData: {
        handler(val) {
          this.formData = { ...this.formData, ...val };
        },
        deep: true,
      },
    },
    data() {
      return {
        formData: {
          menuNm: null,
          menuTypeCd: null,
          menuIcon: null,
          pageUrl: null,
          parentId: null,
          menuDepth: 1,
          viewFl: 'Y',
          addAuthUseFl: 'N',
        },
        limitLength: {
          menuNm: 50, // 메뉴명 최대 사이즈 100 byte
          pageUrl: 400, // 페이지 URL 최대 사이즈 400 byte
        },
        style: {
          boxWidth: '250px',
          stylingModeOutlined: 'outlined',
          stylingModeUnderLined: 'underlined',
        },
        tooltip: {
          visible: false,
        },
      };
    },
    computed: {
      enums() {
        return enums;
      },
      theme() {
        return this.$_theme;
      },
      /**
       * @description 메뉴 계층 구조 반환
       * @return {string[]|*[]}
       */
      menuHierarchy() {
        if (this.formData.parentId === null) {
          return [{ id: null, menuNm: '-' }];
        }
        return this.getGenerateMenuHierarchy();
      },
      /**
       * @description 일반 페이지 여부 체크
       */
      isNormalPage(){
        return this.formData.menuTypeCd === this.enums.common.menuType.NORMAL_PAGE.value;
      },
    },
    methods: {
      /**
       * @description 메뉴 유형 설정
       * @param e
       */
      setMenuType(e) {
        if (e?.selectedItem != null) {
          this.formData.menuTypeCd = e.selectedItem?.value;
        }
      },
      /**
       * @description 메뉴 목록 반환
       * @param id
       * @return {*}
       */
      getMenuById(id) {
        return this.$store.getters.getMenuList.find(menu => menu.id === id);
      },
      /**
       * @description 현재 메뉴의 상위 메뉴 계층 구조 반환
       * @return {*[]}
       */
      getGenerateMenuHierarchy() {
        let currentMenu = this.formData;
        const menuArray = [];

        while (currentMenu && currentMenu.menuDepth >= 1) {
          currentMenu = this.getMenuById(currentMenu.parentId);
          if (currentMenu) menuArray.unshift(currentMenu);
        }
        return menuArray;
      },
      /**
       * @description 메뉴 사용여부 설정
       * @param e
       */
      setViewFl(e) {
        if (e.value) {
          this.formData.viewFl = this.$_enums.common.stringViewFlag.YES.value;
        } else {
          this.formData.viewFl = this.$_enums.common.stringViewFlag.NO.value;
        }
      },
      /**
       * @description 메뉴 권한 설정
       * @param e
       */
      setAddAuthUseFl(e) {
        if (e.value) {
          this.formData.addAuthUseFl = this.$_enums.common.stringUsedFlag.YES.value;
        } else {
          this.formData.addAuthUseFl = this.$_enums.common.stringUsedFlag.NO.value;
        }
      },
      /**
       * @description 메뉴 유형 설정
       * @return {Object}
       */
      getFormData() {
        return this.formData;
      },
      /**
       * @description 하위메뉴 모달 오픈
       */
      handleOpenAddManuModal() {
        if (this.formData.menuDepth >= 4) {
          this.$_Msg(
            this.$_lang('COMMON.MESSAGE.CHECK_MENU_DEPTH', {
              defaultValue: '메뉴추가는 4단계까지만 가능합니다.',
            }),
          );
          return;
        }

        // 메뉴가 선택되어 있지 않은 경우
        if (!this.formData?.id) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }
        this.$emit('handleOpenAddManuModal', this.formData);
      },
      /**
       * @description 페이지 데이터 모달 오픈
       */
      handleOpenMenuDetailPageModal() {
        // 메뉴가 선택되어 있지 않은 경우
        if (!this.formData?.id) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }
        this.$emit('handleOpenMenuDetailPageModal', this.formData);
      },
      /**
       * @description 툴팁 마우스 이벤트
       */
      handleQuestionMouseEvent() {
        this.tooltip.visible = !this.tooltip.visible;
      },
      /**
       * @description 다국어 메시지 반환
       * @param key {string}
       * @param defaultValue {string}
       * @param params {Object}
       * @return {string}
       */
      getI18n(key, defaultValue, params) {
        return this.$_lang(key, { defaultValue: defaultValue, ...params });
      },
      /**
       * 메뉴 이름 길이 유효성 검사
       * @param {Object} e - dx-custom-rule에서 전달된 객체
       * @param {number} limitLength - 최대 길이
       * @return {boolean} 유효성 여부
       */
      validateLength(e, limitLength) {
        if (!e.value) return true; // 값이 없으면 유효
        return e.value.length <= limitLength; // 최대 길이 초과 여부 검사
      },
      /**
       * @description 상세 페이지 메뉴 유형 유효성 검사
       * @param formData
       * @return {boolean}
       */
      validatedDetailPageMenuType(formData) {
        return (
          !this.enums.common.menuType.NORMAL_MENU.equals(formData.menuTypeCd) &&
          !this.enums.common.menuType.LINK.equals(formData.menuTypeCd)
        );
      },
      /**
       * @description 폼 초기화
       */
      initData() {
        this.formData = {
          menuNm: null,
          menuTypeCd: null,
          menuIcon: null,
          pageUrl: null,
          parentId: null,
          menuDepth: 1,
          viewFl: 'Y',
          addAuthUseFl: 'N',
        };

        this.$nextTick(() => {
          // 유효성 검사 초기화
          this.$refs.menuNameValidatorRef?.instance.reset();
          this.$refs.menuTypeCdValidatorRef?.instance.reset();
          this.$refs.menuIconValidatorRef?.instance.reset();
          this.$refs.pageUrlValidatorRef?.instance.reset();
        });
      },
    },
    created() {},
    mounted() {},
  };
</script>

<style lang="scss" scoped>
  .limit-length-box {
    padding-left: 5px;
  }

  .icon-image-box {
    display: flex;
    align-items: center;
    padding: 5px;
  }

  .icon-image {
    width: 23px;
    height: 23px;
  }

  .separator {
    padding: 0 5px;
  }

  .img-question {
    width: 17px;
    height: 17px;
    margin-left: 5px;
    background: url('../../../../assets/images/contents_sprite.png') no-repeat -216px -16px;
    vertical-align: middle;
  }
</style>
