<template>
  <div id="esp-toast-calendar">
    <div class="button-group">
      <button
          @click="changeView('week')"
          :class="{ active: view === 'week' }"
      >
        주간 보기
      </button>
      <button
          @click="changeView('month')"
          :class="{ active: view === 'month' }"
      >
        월간 보기
      </button>
    </div>
    <div id="esp-toast-calendar-menu">
      <button type="button" class="calendar-btn calendar-move-today" @click="moveToNextOrPrevRange(0)">Today</button>
      <button type="button" class="calendar-btn calendar-move-day" @click="moveToNextOrPrevRange(-1)">
        <i class="calendar-icon ic-arrow-line-left"></i>
      </button>
      <button type="button" class="calendar-btn calendar-move-day" @click="moveToNextOrPrevRange(1)">
        <i class="calendar-icon ic-arrow-line-right"></i>
      </button>
      <span class="calendar-render-range">{{ selectedYearAndMonth }}</span>
      <esp-dx-button
        color="default"
        mode="filled"
        prefix-icon="filter"
        style="float: right;color: black"
      />
    </div>
    <toast-ui-calendar
        ref="calendar"
        style="height: 70vh"
        :view="view"
        :use-form-popup="true"
        :use-detail-popup="true"
        :use-creation-popup="true"
        :week="{
          showTimezoneCollapseButton: true,
          timezonesCollapsed: false,
          eventView: true,
          taskView: true,
        }"
        :month="month"
        :timezone="{ zones }"
        :theme="theme"
        :template="{
          milestone: getTemplateForMilestone,
          allday: getTemplateForAllday,
        }"
        :grid-selection="true"
        :calendars="calendars"
        :events="events"
        @beforeCreateEvent="handleBeforeCreateEvent"
        @beforeUpdateEvent="handleBeforeUpdateEvent"
        @beforeDeleteEvent="handleBeforeDeleteEvent"
        @afterRenderEvent="handleAfterRenderEvent"
        @clickDayName="handleClickDayName"
        @clickEvent="handleClickEvent"
        @clickTimezonesCollapseBtn="handleClickTimezonesCollapseBtn"
    />
  </div>
</template>

<script>
import ToastUiCalendar from "@toast-ui/vue-calendar";
import EspDxButton from "@/components/devextreme/esp-dx-button.vue";
import "@toast-ui/calendar/dist/toastui-calendar.css";
import 'tui-date-picker/dist/tui-date-picker.min.css';
import 'tui-time-picker/dist/tui-time-picker.min.css';
import { TZDate } from "@toast-ui/calendar";
import { theme } from './theme';
import { events } from './mock-data';

export default {
  components: {
    ToastUiCalendar,
    EspDxButton
  },
  data() {
    const today = new TZDate();
    const nextWeek = new TZDate(today);
    nextWeek.setDate(today.getDate() + 7);
    return {
      view: 'month',// 또는 week
      activeView: 'month',
      month: {
        dayNames: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
        visibleWeeksCount: 3,
        startDayOfWeek: 0
      },
      calendars: [
        {
          id: '0',
          name: '개인',
          backgroundColor: '#9e5fff',
          borderColor: '#9e5fff',
          dragBackgroundColor: '#9e5fff',
        },
        {
          id: '1',
          name: '공통',
          backgroundColor: '#00a9ff',
          borderColor: '#00a9ff',
          dragBackgroundColor: '#00a9ff',
          color: '#0a0a0a',
        },
      ],
      dateRangeText: "",
      events,
      zones: [
        {
          timezoneName: 'Asia/Seoul',
          displayLabel: 'Seoul',
          tooltip: 'UTC+09:00',
        },
        {
          timezoneName: 'Pacific/Guam',
          displayLabel: 'Guam',
          tooltip: 'UTC+10:00',
        },
      ],
      theme,
      buttons:{
        month: {
          i18n: 'COMPONENTS.MONTH', // 버튼 텍스트 다국어 처리 Key (text 옵션보다 우선 적용)
          text: '월', // 버튼 텍스트(i18n 옵션이 없는 경우 적용)
          color: 'default', // 버튼 컬러(기본 default) / ['default', 'green', 'red', 'white', 'gray', 'black']
          mode: 'filled', // 버튼 타입(기본 filled) / ['filled', 'outlined']
          size: 'sm', // 버튼 크기(기본 small) / ['xs', 'sm', 'md', 'lg']
          width: '60', // 버튼 너비(기본 auto) / width 옵션 설정시 width 옵션 우선 적용
          height: '', // 버튼 높이(기본 30) / height 옵션 설정시 height 옵션 우선 적용
        },
        week: {
          i18n: 'COMPONENTS.WEEK', // 버튼 텍스트 다국어 처리 Key (text 옵션보다 우선 적용)
          text: '주', // 버튼 텍스트(i18n 옵션이 없는 경우 적용)
          color: 'default', // 버튼 컬러(기본 default) / ['default', 'green', 'red', 'white', 'gray', 'black']
          mode: 'filled', // 버튼 타입(기본 filled) / ['filled', 'outlined']
          size: 'sm', // 버튼 크기(기본 small) / ['xs', 'sm', 'md', 'lg']
          width: '60', // 버튼 너비(기본 auto) / width 옵션 설정시 width 옵션 우선 적용
          height: '', // 버튼 높이(기본 30) / height 옵션 설정시 height 옵션 우선 적용
        },
      },
      selectedYearAndMonth: '',

    };
  },
  computed: {
    calendarInstance() {
      return this.$refs.calendar.getInstance();
    },
  },
  mounted() {
    this.setDateRangeText();
    this.formatTZDate()
  },
  methods: {
    formatTZDate() {
      const tzDate = this.$refs.calendar.getInstance().getDate();
      const date = tzDate.d; // LocalDate 객체에서 실제 Date 가져오기
      const year = date.getFullYear(); // 연도
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월 (0부터 시작하므로 +1)

      this.selectedYearAndMonth = `${year}.${month}`;
    },
    getTemplateForMilestone(event) {
      return `<span style="color: #fff; background-color: ${event.backgroundColor};">${event.title}</span>`;
    },
    getTemplateForAllday(event) {
      return `[All day] ${event.title}`;
    },
    changeView(view) {
      this.view = view;
      //this.calendar.changeView(view, true);
    },
    setDateRangeText() {
      const start = this.calendarInstance.getDateRangeStart();
      const end = this.calendarInstance.getDateRangeEnd();
      this.dateRangeText = `${start.getFullYear()}.${
          start.getMonth() + 1
      }.${start.getDate()} - ${end.getFullYear()}.${
          end.getMonth() + 1
      }.${end.getDate()}`;
    },
    handleBeforeCreateEvent(eventData) {
      const event = {
        calendarId: eventData.calendarId || '',
        id: String(Math.random()),
        title: eventData.title,
        isAllday: eventData.isAllday,
        start: eventData.start,
        end: eventData.end,
        category: eventData.isAllday ? 'allday' : 'time',
        dueDateClass: '',
        location: eventData.location,
        state: eventData.state,
        isPrivate: eventData.isPrivate,
      };

      this.calendarInstance.createEvents([event]);
    },
    handleBeforeUpdateEvent(updateData) {
      console.group('handleBeforeUpdateEvent');
      console.log(updateData);
      console.groupEnd();

      const targetEvent = updateData.event;
      const changes = {...updateData.changes};

      this.calendarInstance.updateEvent(targetEvent.id, targetEvent.calendarId, changes);
    },
    handleBeforeDeleteEvent({title, id, calendarId}) {
      console.group('handleBeforeDeleteEvent');
      console.log('Event Info : ', title);
      console.groupEnd();

      this.calendarInstance.deleteEvent(id, calendarId);
    },
    handleAfterRenderEvent({title}) {
      console.group('handleAfterRenderEvent');
      console.log('Event Info : ', title);
      console.groupEnd();
    },
    handleClickDayName({date}) {
      console.group('handleClickDayName');
      console.log('Date : ', date);
      console.groupEnd();
    },
    handleClickEvent({nativeEvent, event}) {
      console.group('handleClickEvent');
      console.log('MouseEvent : ', nativeEvent);
      console.log('Event Info : ', event);
      console.groupEnd();
    },
    handleClickTimezonesCollapseBtn(timezoneCollapsed) {
      console.group('handleClickTimezonesCollapseBtn');
      console.log('Is Timezone Collapsed?: ', timezoneCollapsed);
      console.groupEnd();

      const newTheme = {
        'week.daygridLeft.width': '100px',
        'week.timegridLeft.width': '100px',
      };

      this.calendarInstance.setTheme(newTheme);
    },
    moveToNextOrPrevRange(offset) {
      if (offset === -1) {
        this.$refs.calendar.getInstance().prev();
      } else if (offset === 1) {
        this.$refs.calendar.getInstance().next();
      } else{
        this.$refs.calendar.getInstance().today();
      }
      this.formatTZDate();
    }
  },
};
</script>

<style scoped>
/* 버튼 CSS */
  ::v-deep .toastui-calendar-floating-layer {
    z-index: 10000 !important; /* 충분히 높은 값으로 설정 */
    position: fixed; /* 또는 absolute */
    top: 0 !important;
    left: 0 !important;
  }

  .toastui-calendar .tui-full-calendar-month .tui-full-calendar-day {
    position: relative;
  }

  /* 버튼 그룹 스타일 */
  .button-group {
    margin-bottom: 20px;
  }

  /* 버튼 기본 스타일 */
  .button-group button {
    display: inline-block;
    vertical-align: middle;
    width: 140px;
    height: 30px;
    margin-right: 10px;
    border: none;
    border-radius: 999px;
    background-color: #164371;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  /* 활성화된 버튼 스타일 */
  .button-group button.active {
    background-color: #51cbee; /* 활성화된 버튼 배경색 */
    color: #201f2b; /* 활성화된 버튼 글자색 */
  }

  .calendar-btn {
    border-radius: 25px;
    border: 1px solid #ddd;
    font-size: 12px;
    color: #333;
    margin-right: 5px;
  }

  .calendar-btn.calendar-move-today {
    padding: 0 16px;
    line-height: 30px;
    font-weight: 700;
  }

  .calendar-btn.calendar-move-day {
    padding: 8px;
  }

  .ic-arrow-line-left {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAK1JREFUKBVjYKAFaGhoYMJlLjMuibS0NNZHjx6t1tHR0bx69eoBdHVYTQRpev/+/Uqg4sD////rYbOZEd0kZE1AufWCgoLhs2bN+o2uDkUjsZpAhsA1kqIJpBGrH0EShAA8VM+ePfvPxsZm3Y8fP3SAmgJBNIgPEsdmCFwjSJIUzRhOBYUgKCSB5qwH4kBQtBAVHSCbQQAWWIyMjJdWrVrVABYklsBmE7F6caoDAB4rYZDo034sAAAAAElFTkSuQmCC) no-repeat;
  }

  .calendar-icon {
    display: inline-block;
    width: 14px;
    height: 14px;
    vertical-align: middle;
  }

  .calendar-btn.calendar-move-day {
    padding: 8px;
  }

  .ic-arrow-line-right {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAKNJREFUKBVjYKA2aGhoYMJnJiM2ybCwsIb////rCQoKhs+aNes3NjUYpoJsAmkCKg58//79yrS0NFZsGrHaCFIM0gTSDMTrsdnMjM20s2fP/rOxsVn348cPHZBmEA3ig8Rh6rFqBEkS0ozhR5iJhGisfgRpIuRPrBoJaQIZjOFUUHQQClGQRozAOXDgwH8dHR1NoNx7bNEA0oQXEEpyeDXjkwQAiyNhSndGN/YAAAAASUVORK5CYII=) no-repeat;
  }
  .calendar-render-range {
    font-size: 19px;
    line-height: 30px;
    vertical-align: bottom;
    margin-left: 7px;
  }
</style>

<style scoped>
/* 일(1,2...31) 폰트 색상*/
::v-deep .toastui-calendar-template-monthGridHeader {
  color:inherit!important;
}

::v-deep .toastui-calendar-weekday-grid-date-decorator {
  color: white!important;
}

::v-deep .toastui-calendar-weekday-event-title {
  color:inherit!important;
}

::v-deep .toastui-calendar-template-allday {
  color:inherit!important;
}
</style>

<style scoped>
/* 팝업 CSS */
::v-deep .toastui-calendar-template-popupDetailTitle {
  font-size: 15px;
  font-weight: 700;
  line-height: 1.6;
  word-break: break-all;
}
</style>