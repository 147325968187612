import { EnumEntry, EnumMap } from '@/configs/enums/util';

/**
 * @description 보고서 유형 Enum 클래스
 */
class ReportType extends EnumEntry {
  DAILY = {
    ...EnumMap,
    key: 'REPORT_TYPE_DAILY',
    value: 'daily',
    label: '일별',
    group: 'REPORT_TYPE',
    group_label: '보고서 유형',
    equals: value => {
      return this.DAILY.value === value;
    },
  };

  MONTHLY = {
    ...EnumMap,
    key: 'REPORT_TYPE_MONTHLY',
    value: 'monthly',
    label: '월별',
    group: 'REPORT_TYPE',
    group_label: '보고서 유형',
    equals: value => {
      return this.MONTHLY.value === value;
    },
  };

  HOUR = {
    ...EnumMap,
    key: 'REPORT_TYPE_HOUR',
    value: 'hour',
    label: '시간별',
    group: 'REPORT_TYPE',
    group_label: '보고서 유형',
    equals: value => {
      return this.HOUR.value === value;
    },
  };

  I15 = {
    ...EnumMap,
    key: 'REPORT_TYPE_I15',
    value: 'i15',
    label: '15분별',
    group: 'REPORT_TYPE',
    group_label: '보고서 유형',
    equals: value => {
      return this.I15.value === value;
    },
  };

  I30 = {
    ...EnumMap,
    key: 'REPORT_TYPE_MIN_30',
    value: 'i30',
    label: '30분별',
    group: 'REPORT_TYPE',
    group_label: '보고서 유형',
    equals: value => {
      return this.I30.value === value;
    },
  };

  WEEKDAY = {
    ...EnumMap,
    key: 'REPORT_TYPE_WEEKDAY',
    value: 'weekday',
    label: '요일별',
    group: 'REPORT_TYPE',
    group_label: '보고서 유형',
    equals: value => {
      return this.WEEKDAY.value === value;
    },
  };

  DAYTIME = {
    ...EnumMap,
    key: 'REPORT_TYPE_DAYTIME',
    value: 'daytimes',
    label: '일+시간',
    group: 'REPORT_TYPE',
    group_label: '보고서 유형',
    equals: value => {
      return this.DAYTIME.value === value;
    },
  };

  values = [this.DAILY, this.MONTHLY, this.HOUR, this.I15, this.I30, this.DAYTIME, this.WEEKDAY];

  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 보고서 Query 유형 Enum 클래스
 */
class XmlType extends EnumEntry {
  WIZARD_TEMPLATE = {
    ...EnumMap,
    key: 'XML_TYPE_WIZARD_TEMPLATE',
    value: 'wizard-template',
    label: '위자드템플릿',
    group: 'XML_TYPE',
    group_label: '보고서 XML 유형',
    equals: value => {
      return this.WIZARD_TEMPLATE.value === value;
    },
  };

  BI_REPORT = {
    ...EnumMap,
    key: 'XML_TYPE_BI_REPORT',
    value: 'bi-report',
    label: 'BI 보고서',
    group: 'XML_TYPE',
    group_label: '보고서 XML 유형',
    equals: value => {
      return this.BI_REPORT.value === value;
    },
  };

  REPORT_TARGET = {
    ...EnumMap,
    key: 'XML_TYPE_REPORT_TARGET',
    value: 'report-target',
    label: '조회대상',
    group: 'XML_TYPE',
    group_label: '보고서 XML 유형',
    equals: value => {
      return this.REPORT_TARGET.value === value;
    },
  };

  values = [this.WIZARD_TEMPLATE, this.BI_REPORT, this.REPORT_TARGET];

  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 보고서 작업 유형 Enum 클래스
 */
class WorkType extends EnumEntry {
  SEARCH = {
    ...EnumMap,
    key: 'REPORT_WORK_TYPE',
    value: 'search',
    label: '조회',
    group: 'WORK_TYPE',
    group_label: '작업 유형',
    equals: value => {
      return this.SEARCH.value === value;
    },
  };

  DEFAULT_EXCEL = {
    ...EnumMap,
    key: 'REPORT_WORK_TYPE',
    value: 'default-excel',
    label: '기본 다운로드',
    group: 'WORK_TYPE',
    group_label: '작업 유형',
    equals: value => {
      return this.DEFAULT_EXCEL.value === value;
    },
  };

  LARGE_DATA_EXCEL = {
    ...EnumMap,
    key: 'REPORT_WORK_TYPE',
    value: 'large-data-excel',
    label: '대용량 다운로드',
    group: 'WORK_TYPE',
    group_label: '작업 유형',
    equals: value => {
      return this.LARGE_DATA_EXCEL.value === value;
    },
  };

  TARGET_EXCEL = {
    ...EnumMap,
    key: 'REPORT_WORK_TYPE',
    value: 'target-excel',
    label: '대상별 다운로드',
    group: 'WORK_TYPE',
    group_label: '작업 유형',
    equals: value => {
      return this.TARGET_EXCEL.value === value;
    },
  };

  values = [this.SEARCH, this.LARGE_DATA_EXCEL, this.DEFAULT_EXCEL, this.TARGET_EXCEL];

  findByValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value);
  };
}

class ColumnAlign extends EnumEntry {
  CENTER = {
    ...EnumMap,
    key: 'REPORT_COLUMN_ALIGN',
    value: 'center',
    label: '중앙',
    group: 'COLUMN_FORMAT',
    group_label: '컬럼 정렬',
    equals: value => {
      return this.LEFT.value === value;
    },
  };

  LEFT = {
    ...EnumMap,
    key: 'REPORT_COLUMN_ALIGN',
    value: 'left',
    label: '좌측',
    group: 'COLUMN_FORMAT',
    group_label: '컬럼 정렬',
    equals: value => {
      return this.CENTER.value === value;
    },
  };

  RIGHT = {
    ...EnumMap,
    key: 'REPORT_COLUMN_ALIGN',
    value: 'right',
    label: '우측',
    group: 'COLUMN_FORMAT',
    group_label: '컬럼 정렬',
    equals: value => {
      return this.RIGHT.value === value;
    },
  };

  values = [this.CENTER, this.LEFT, this.RIGHT];

  findByValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value);
  };
}

class ColumnFormat extends EnumEntry {
  TEXT = {
    ...EnumMap,
    key: 'REPORT_COLUMN_FORMAT',
    value: 'text',
    label: '문자열',
    group: 'COLUMN_FORMAT',
    group_label: '컬럼 포맷',
    equals: value => {
      return this.STRING.value === value;
    },
  };

  NUMBER = {
    ...EnumMap,
    key: 'REPORT_COLUMN_FORMAT',
    value: 'number',
    label: '숫자',
    group: 'COLUMN_FORMAT',
    group_label: '컬럼 포맷',
    equals: value => {
      return this.NUMBER.value === value;
    },
  };

  TIME = {
    ...EnumMap,
    key: 'REPORT_COLUMN_FORMAT',
    value: 'time',
    label: '시간',
    group: 'COLUMN_FORMAT',
    group_label: '컬럼 포맷',
    equals: value => {
      return this.TIME.value === value;
    },
  };

  PERCENT = {
    ...EnumMap,
    key: 'REPORT_COLUMN_FORMAT',
    value: 'percent',
    label: '퍼센트(%)',
    group: 'COLUMN_FORMAT',
    group_label: '컬럼 포맷',
    equals: value => {
      return this.PERCENT.value === value;
    },
  };

  DATE = {
    ...EnumMap,
    key: 'REPORT_COLUMN_FORMAT',
    value: 'date',
    label: '날짜',
    group: 'COLUMN_FORMAT',
    group_label: '컬럼 포맷',
    equals: value => {
      return this.DATE.value === value;
    },
  };

  values = [this.TEXT, this.NUMBER, this.TIME, this.PERCENT, this.DATE];

  /**
   * legacyValue 는 앞에 fmXXX 형태로 되어있으므로 변환
   * @param value
   * @returns {unknown|string}
   */
  parseLegacyValue = value => {
    const lowerValue = value?.toLowerCase();
    if (!lowerValue) {
      return this.TEXT.value;
    }
    // 레거시 값이 존재하는 경우
    const code = this.values.find(item => {
      if (lowerValue.indexOf(item.value) > -1) {
        return item;
      }
    });

    return code?.value || value;
  };
}

/**
 * @description 모든 코드 반환
 * @return {*[]}
 */
function getEnumAllCodes() {
  return [].concat(new ReportType().values, new XmlType().values);
}

export default {
  reportType: new ReportType(),
  xmlType: new XmlType(),
  workType: new WorkType(),
  columnAlign: new ColumnAlign(),
  columnFormat: new ColumnFormat(),
  getEnumAllCodes: getEnumAllCodes(),
};
