<!--
  PACKAGE_NAME : src\pages\ai\llm-tester\work-config
  FILE_NAME : question-config
  AUTHOR : hpmoon
  DATE : 2024-11-13
  DESCRIPTION : AI > LLM > LLM Tester > 프로젝트 실험 설정 > 질의 세트
-->
<template>
  <div>
    <esp-dx-data-grid id="questionConfigGrid" :data-grid="dataGrid" ref="questionConfigGrid" />

    <index-config
      v-if="modal.index.visible"
      :is-open="modal.index.visible"
      :project_id="project_id"
      :index-file-list="indexFileList"
      @saveModal="onSaveModal('index')"
      @closeModal="onCloseModal('index')"
      @updateFileList="indexFileList = $event"
    />

    <!-- 질의 세트 추가/수정 -->
    <question-save
      v-if="modal.save.visible"
      :is-open="modal.save.visible"
      :project_id="project_id"
      :index-file-list-org="indexFileList"
      :system-prompt-list="systemPromptList"
      :save-type="modal.save.saveType"
      :content-data="modal.save.contentData"
      :category-list="[...new Set(dataGrid.dataSource.flatMap(item => item.categories))]"
      @saveModal="onSaveModal('save')"
      @closeModal="onCloseModal('save')"
    />
  </div>
</template>

<script>
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';
  import IndexConfig from "@/pages/ai/llm-tester/work-config/index-config.vue";
  import QuestionSave from "@/pages/ai/llm-tester/work-config/question-save.vue";
  import { isEmpty } from "@/utils/common-lib";

  export default {
    components: { EspDxDataGrid, IndexConfig, QuestionSave },

    props: {
      project_id: String,
      systemPromptList: Array,
    },

    data() {
      return {
        dataGrid: {
          refName: 'questionConfigGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width: '100%',     // 주석처리시 100%
          height: '100%',    // 주석처리시 100%
          showActionButtons: {
            excel: false,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: this.$_lang('COMPONENTS.ADD', { defaultValue: '추가' }),
                  elementAttr: { class: 'btn_XS default filled add1' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.onOpenModal('save', 'add');
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: this.$_lang('COMPONENTS.DELETE', { defaultValue: '삭제' }),
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.onDelete();
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: this.$_lang('LLM_TESTER.WORD.INDEX_CONFIG', { defaultValue: '인덱스 관리' }),
                  elementAttr: { class: 'btn_XS white light_filled' },
                  width: 90,
                  height: 30,
                  onClick: () => {
                    this.onOpenModal('index', null);
                  },
                },
                location: 'before',
              },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick' // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple' // single multiple
          },
          // scrolling: { // 미사용시 주석처리
          //     mode: 'virtual' //스크롤 모드 : ['infinite', 'standard', 'virtual']
          // },
          remoteOperations: { // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: { // scrolling 미사용시만 적용됨
            enabled: false,
            pageSize: 20,
            pageIndex: 0 // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: "compact", //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowDeleting: false,
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: 'No',
              width: 80,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              allowSorting: false, // 넘버링은 정렬하지 않음
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                const dataGrid = options.component;
                const totalCount = dataGrid.getDataSource().totalCount(); // 전체 행 개수
                const rowIndex = totalCount - options.rowIndex; // 역순으로 인덱스 계산
                container.append(rowIndex);
              },
            },
            {
              caption: 'ID',
              dataField: 'id',
              alignment: 'center', // left center right
              visible: false,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },
            {
              caption: '질의 유형',
              i18n: 'LLM_TESTER.WORD.QUERY_TYPE',
              dataField: 'categories',
              height: 40,
              alignment: 'left',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '질의',
              i18n: 'LLM_TESTER.WORD.QUERY',
              dataField: 'messages',
              height: 40,
              alignment: 'left',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                const query = options.value.findLast(item => item.role === "user")?.content;

                let aTag = document.createElement('a');
                aTag.innerText = query;
                aTag.addEventListener('click', () => {
                  this.modal.save.contentData = options.data;
                  this.onOpenModal('save', 'update');
                });

                container.append(aTag);
              },
            },
            {
              caption: '올바른 답변',
              i18n: 'LLM_TESTER.WORD.CORRECT_ANSWER',
              height: 40,
              alignment: 'left',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                const answer = options.data.messages.findLast(item => item.role === "assistant")?.content

                container.append(answer);
              },
            },
          ]
        },

        modal: {
          index: {
            visible: false,
          },
          save: {
            visible: false,
            saveType: null,
            contentData: null,
          },
        },

        indexFileList: [],
      }
    },

    computed: {},

    methods: {
      /** @description 데이터 조회 메서드 */
      async selectDataList() {
        const payload = {
          actionName: 'LLM_TESTER_QUERY_SET_LIST',
          data: {
            project_id: this.project_id,
          },
          loading: false,
        }

        const res = await this.CALL_LLM_TESTER_API(payload);
        if (res.status === 200) {
          this.dataGrid.dataSource = res.data;
        } else {
          this.$_Msg(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          return false;
        }
      },

      /** @description 데이터 삭제 메서드 */
      async onDelete() {
        let selectedRows = this.$refs.questionConfigGrid.getInstance.getSelectedRowsData();

        if (selectedRows.length <= 0) {
          this.$_Msg(this.$_lang('CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다' }));
          return;
        }

        if (await this.$_Confirm(this.$_lang('CMN_CFM_DELETE_SELECTED', { defaultValue: '선택한 데이터를 삭제하시겠습니까?' }))) {
          const payload = {
            actionName: 'LLM_TESTER_QUERY_SET_DELETE',
            data: selectedRows,
            loading: false,
          };

          const res = await this.CALL_LLM_TESTER_API(payload);
          if (res.status === 200) {
            this.$_Toast(this.$_lang('CMN_SUC_DELETE', { defaultValue: '정상적으로 삭제되었습니다' }), { icon: 'success' });
            await this.selectDataList();
          } else {
            this.$_Msg(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
            return false;
          }
        }
      },

      /**
       * @description 팝업 열기
       * @param modalType 팝업 모달 타입(save, index)
       * @param saveType 저장 팝업 타입(add, update)
       * */
      onOpenModal(modalType, saveType) {
        if (!isEmpty(saveType)) {
          this.modal.save.saveType = saveType;
        }
        this.modal[modalType].visible = true;
      },

      /**
       * @description 팝업 저장
       * @param modalType 팝업 모달 타입(save, index)
       * */
      onSaveModal(modalType) {
        this.$_Toast(this.$_lang('COMMON.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
        this.selectDataList();
        if (modalType === 'save') {
          this.modal.save.contentData = null;
        }
        this.modal[modalType].visible = false;
      },

      /**
       * @description 팝업 닫기
       * @param modalType 팝업 모달 타입(save, index)
       * */
      onCloseModal(modalType) {
        if (modalType === 'save') {
          this.modal.save.contentData = null;
        }
        if (modalType === 'index') {
          this.selectDataList();
        }
        this.modal[modalType].visible = false;
      },

      /** @description Grid 높이 계산 */
      setGridHeight() {
        let height = this.topElement("#questionConfigGrid") + this.heightElement("#questionConfigGrid .dx-datagrid-header-panel") + 50;
        this.$refs.questionConfigGrid.grid.height = "calc(100vh - " + height + "px)";
      },
      topElement(e) {
        const divElement = document.querySelector(e);
        const rect = divElement.getBoundingClientRect();
        return rect.top;
      },
      heightElement(e) {
        const divElement = document.querySelector(e);
        const computedStyle = window.getComputedStyle(divElement);
        const divHeight = divElement.offsetHeight;
        const marginTop = parseFloat(computedStyle.marginTop);
        const marginBottom = parseFloat(computedStyle.marginBottom);
        return divHeight + marginTop + marginBottom;
      },
    },

    /** @description 라이프사이클 created 시 호출되는 메서드 */
    async created() {
      await this.selectDataList();

      const payload = {
        actionName: 'LLM_TESTER_INDEX_FILE_LIST',
        data: {
          project_id: this.project_id
        },
        loading: false,
      }
      const res = await this.CALL_LLM_TESTER_API(payload);
      if (res.status === 200) {
        this.indexFileList = res.data.map(item => {
          const newItem = { ...item }; // 객체 복사
          newItem.name = newItem.title; // title을 name으로 매핑
          newItem.dateModified = newItem.insert_date.slice(0, 10).replace(/-/g, "/");

          return newItem;
        });
      } else {
        this.$_Msg(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        return false;
      }
    },

    /** @description 라이프사이클 mounted 시 호출되는 메서드 */
    mounted() {

    },

  }
</script>
<style scoped>
</style>
