import GridDemo from '@/pages/ui/components/grid-demo';
import TreeDemo from '@/pages/ui/components/tree-demo.vue';
import CalendarDemo from '@/pages/ui/components/calendar-demo.vue';
import ButtonDemo from '@/pages/ui/components/button-demo.vue';
import PopDemo from '@/pages/ui/components/pop-demo.vue';

export default function ComponentConfigRoutes(commonComponents) {
  return [
    {
      path: '/ui/components/grid-demo',
      name: 'GridDemo',
      components: { default: GridDemo, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ui/components/tree-demo',
      name: 'TreeDemo',
      components: { default: TreeDemo, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ui/components/calendar-demo',
      name: 'CalendarDemo',
      components: { default: CalendarDemo, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ui/components/button-demo',
      name: 'ButtonDemo',
      components: { default: ButtonDemo, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ui/components/pop-demo',
      name: 'PopDemo',
      components: { default: PopDemo, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}
