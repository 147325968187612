<!--
  PACKAGE_NAME : src/pages/euc/phone/request
  FILE_NAME : supercopy.vue
  AUTHOR : jhsim
  DATE : 2024-05-01
  DESCRIPTION : 신규장치 그리드 -  착신전환 , 당겨받기 , 녹취옵션 영역은 유지하되 값 공백처리.
-->
<template>
  <div class="container content-themes">
    <div class="main clearfix ui-glid-box per33 ui-33" style="padding-bottom: 20px">
      <table class="fl" style="width: 49.5%">
        <thead class="sub_title_txt">
        <tr>
          <td colspan="4" style="padding: 18px 20px">
            <h2 style="width: 200px; height: 30px" class="fl">{{ $_lang('UC.WORD.TARGET_DEVICE') }}</h2>
          </td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td style="padding: 0">
            <div class="fl">
              <table class="table_form line-bin" style="table-layout: auto; width: 100%; table-layout: fixed">
                <colgroup>
                  <col style="width: 15%"/>
                  <col style="width: 34%"/>
                  <col style="width: 15%"/>
                  <col style="width: 34%"/>
                </colgroup>
                <tbody>
                <tr>
                  <th scope="row">
                    <label for="label01">{{ $_lang('UC.WORD.EXCHANGER') }}</label>
                  </th>
                  <th>{{ getCmNm(deviceInfo.cmname) }}</th>
                  <td colspan="2" rowspan="3" style="padding: 0">
                    <div class="fl" style="width: 100%; text-align: center">
                      <img v-if="deviceImage" :src="deviceImage" alt="Device"/>
                      <img
                          v-if="deviceExpImage"
                          :src="deviceExpImage"
                          alt="DeviceExp"
                          style="position: absolute; bottom: 0px"
                          class="fr"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="label02">{{ $_lang('UC.WORD.DEVICE_NAME') }}</label>
                  </th>
                  <th>{{ deviceInfo.name }}</th>
                </tr>

                <tr>
                  <th scope="row" class="alT">
                    <label for="label03">{{ $_lang('UC.WORD.DEVICEPOOL') }}</label>
                  </th>
                  <th>
                    {{ deviceInfo.devicepool }}
                  </th>
                </tr>
                <tr>
                  <th scope="row" class="alT">
                    <label for="label04">{{ $_lang('UC.WORD.STATUS') }}</label>
                  </th>
                  <th>{{ deviceInfo.status }}</th>
                  <th scope="row" class="alT">
                    <label for="label04">{{ $_lang('UC.WORD.SERIAL') }}</label>
                  </th>
                  <th>{{ deviceInfo.serial }}</th>
                </tr>

                <tr>
                  <th scope="row" class="alT">
                    <label for="label04">{{ $_lang('UC.WORD.PHONEBUTTON') }}</label>
                  </th>
                  <th>
                    {{ deviceInfo.phonetemplatename }}
                  </th>
                  <th scope="row" class="alT">
                    <label for="label04">{{ $_lang('UC.WORD.SOFTKEY') }}</label>
                  </th>
                  <th>
                    {{ deviceInfo.softkeytemplatename }}
                  </th>
                </tr>
                <tr>
                  <th scope="row" class="alT"><label for="label04">IP</label></th>
                  <th>{{ deviceInfo.ipaddress }}</th>
                  <th scope="row" class="alT">
                    <label for="label04">{{ $_lang('COMPONENTS.REGISTRATION_DATE_TIME') }}</label>
                  </th>
                  <th>{{ this.formatDt(deviceRegDt) }}</th>
                </tr>
                <tr>
                  <th scope="row" class="alT">
                    <label for="label04">{{ $_lang('UC.WORD.MODEL') }}</label>
                  </th>
                  <th colspan="3">{{ deviceInfo.model }}</th>
                </tr>
                <tr>
                  <th scope="row" class="alT">
                    <label for="label04">{{ $_lang('COMPONENTS.DESCRIPTION') }}</label>
                  </th>
                  <th colspan="3">{{ deviceInfo.description }}</th>
                </tr>
                <thead class="sub_title_txt">
                <tr>
                  <td colspan="4" class="border0">
                    <h2 style="width: 200px" class="fl">{{ $_lang('UC.WORD.LINE_INFO') }}</h2>
                  </td>
                </tr>
                </thead>
                <tr>
                  <td colspan="4" style="padding: 0">
                    <esp-dx-data-grid
                        :data-grid="getDataGrid('origin')"
                        :ref="getDataGridRef('origin')"
                        :auto-width="true"
                        @row-prepared="onRowPrepared"
                    ></esp-dx-data-grid>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <table class="fr" style="width: 49.5%">
        <thead class="sub_title_txt">
        <tr>
          <td colspan="3" style="padding: 18px 20px">
            <h2 style="width: 200px; height: 30px" class="fl">{{ $_lang('UC.WORD.NEW_DEVICE') }}</h2>
            <DxButton
                @click="onSuperCopy()"
                :text="$_lang('UC.WORD.APPLY')"
                :width="80"
                :height="30"
                class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fr"
                styling-mode="contained"
                type="default"
            />
            <DxButton
                @click="selectData()"
                :text="$_lang('UC.WORD.CHECK')"
                :width="80"
                :height="30"
                class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fr"
                styling-mode="contained"
                type="default"
            />
          </td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td style="padding: 0">
            <div class="fl">
              <table class="table_form line-bin" style="table-layout: auto; width: 100%; table-layout: fixed">
                <colgroup>
                  <col style="width: 15%"/>
                  <col style="width: 34%"/>
                  <col style="width: 15%"/>
                  <col style="width: 34%"/>
                </colgroup>
                <tbody>
                <tr>
                  <th scope="row" class="alT">
                    <label for="label01">{{ $_lang('UC.WORD.EXCHANGER') }}</label>
                  </th>
                  <th>{{ getCmNm(deviceInfo.cmname) }}</th>
                  <td colspan="2" rowspan="3" style="padding: 0">
                    <div class="fl" style="width: 100%; text-align: center">
                      <img v-if="deviceImage" :src="deviceImage" alt="Device"/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="label02">{{ $_lang('UC.WORD.DEVICE_NAME') }}</label>
                  </th>
                  <td style="padding: 3px 15px">
                    <DxTextBox
                        v-model="copyname"
                        :placeholder="$_lang('UC.MESSAGE.DEVICE_NAME_ENTER')"
                        :styling-mode="config.stylingMode"
                    />
                  </td>
                </tr>

                <tr>
                  <th scope="row" class="alT">
                    <label for="label03">{{ $_lang('UC.WORD.DEVICEPOOL') }}</label>
                  </th>
                  <th>
                    {{ deviceInfo.devicepool }}
                  </th>
                </tr>
                <tr>
                  <th scope="row" class="alT">
                    <label for="label04">{{ $_lang('UC.WORD.STATUS') }}</label>
                  </th>
                  <th>{{ deviceInfo.status }}</th>
                  <th scope="row" class="alT">
                    <label for="label04">{{ $_lang('UC.WORD.SERIAL') }}</label>
                  </th>
                  <th>{{ deviceInfo.serial }}</th>
                </tr>

                <tr>
                  <th scope="row" class="alT">
                    <label for="label04">{{ $_lang('UC.WORD.PHONEBUTTON') }}</label>
                  </th>
                  <th>
                    {{ deviceInfo.phonetemplatename }}
                  </th>
                  <th scope="row" class="alT">
                    <label for="label04">{{ $_lang('UC.WORD.SOFTKEY') }}</label>
                  </th>
                  <th>
                    {{ deviceInfo.softkeytemplatename }}
                  </th>
                </tr>
                <tr>
                  <th scope="row" class="alT"><label for="label04">IP</label></th>
                  <th>{{ deviceInfo.ipaddress }}</th>
                  <th scope="row" class="alT">
                    <label for="label04">{{ $_lang('COMPONENTS.REGISTRATION_DATE_TIME') }}</label>
                  </th>
                  <th></th>
                </tr>
                <tr>
                  <th scope="row" class="alT">
                    <label for="label04">{{ $_lang('UC.WORD.MODEL') }}</label>
                  </th>
                  <th colspan="3">{{ deviceInfo.model }}</th>
                </tr>
                <tr>
                  <th scope="row" class="alT">
                    <label for="label04">{{ $_lang('COMPONENTS.DESCRIPTION') }}</label>
                  </th>
                  <td colspan="3" style="padding: 3px 16px">
                    <DxTextBox
                        v-model="copydescription"
                        :placeholder="$_lang('UC.MESSAGE.DESCRIPTION_ENTER')"
                        :styling-mode="config.stylingMode"
                    />
                  </td>
                </tr>
                <thead class="sub_title_txt">
                <tr>
                  <td colspan="4" class="border0">
                    <h2 style="width: 200px" class="fl">{{ $_lang('COMPONENTS.DESCRIPTION') }}</h2>
                  </td>
                </tr>
                </thead>
                <tr>
                  <td colspan="4" style="padding: 0">
                    <esp-dx-data-grid
                        :data-grid="getDataGrid('copy')"
                        :ref="getDataGridRef('copy')"
                        :auto-width="true"
                        @row-updating="onRowUpdating"
                        @row-prepared="onRowPrepared"
                        @cell-prepared="onCellPrepared"
                        @editor-prepared="onEditorPrepared"
                    ></esp-dx-data-grid>
                  </td>
                </tr>
                </tbody>
              </table>
              <DxButton
                  @click="goListPage()"
                  :text="this.$_lang('UC.WORD.PREPAGE')"
                  :width="80"
                  :height="30"
                  class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fr"
                  styling-mode="contained"
                  style="margin-top: 10px"
                  type="default"
              />
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import DxButton from 'devextreme-vue/button';
import {isSuccess} from '@/utils/common-lib';
import {DxTextBox} from 'devextreme-vue/text-box';
import {getUCServerPath} from "@/pages/euc/common-uc";
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid-v2.vue";

export default {
  components: {
    DxButton,
    DxTextBox,
    EspDxDataGrid
  },
  props: {},
  watch: {},
  data() {
    return {
      stylingMode: 'outlined', //outlined, underlined, filled
      cmList: [],
      deviceImage: '',
      deviceExpImage: '',
      deviceData: {},
      refsList: [
        {name: 'origin', editBool: false},
        {name: 'copy', editBool: true},
      ],
      updateFlag: false,
      serverInfo: '',
      deviceRegDt: '',
      deviceInfo: {},
      deviceList: [],
      copyList: [],
      rowInfo: {},
      changedDeviceInfo: [],
      copyname: '',
      copydescription: '',
      pickupGroupList: [],
      dataGrid: {},
    };
  },
  methods: {
    getDataGrid(value) {
      return this.dataGrid[`dataGrid_${value}`];
    },
    getDataGridRef(value) {
      return `refDataGrid_${value}`;
    },
    /**@description : 라이프사이클 created시 호출되는 메서드 */
    createdData() {
      this.reqParams = this.$store.getters.getDetailParams;

      for (let i = 0; i < this.refsList.length; i++) {
        this.$set(this.dataGrid, `dataGrid_${this.refsList[i].name}`, {
          keyExpr: 'field',
          refName: `refDataGrid_${this.refsList[i].name}`,
          disableTotalCount: true,
          showActionButtons: {
            delete: false,
          },
          page: {
            enabled: false,
          },
          editing: {
            allowAdding: false,
            mode: 'cell', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: ' ',
              dataField: 'field',
              width: 50,
              allowEditing: false,
              cellTemplate: async (container, options) => {
                const imgTag = document.createElement('img');
                imgTag.setAttribute('src', `${getUCServerPath()}/euc/api/v1/resource/DEVICE_LINE_IMAGE_DIR/${options.value}.png`);
                container.append(imgTag);
              },
            },
            {
              caption: '내선번호',
              i18n: 'UC.WORD.NUMBER',
              dataField: 'number',
              width: 110,
              allowEditing: this.refsList[i].editBool,
              allowSorting: false,
              validationRules: [
                {
                  type: 'custom',
                  validationCallback: function (e) {
                    return e.value === '' || (!isNaN(e.value) && e.value !== null);
                  },
                  message: this.$_lang('UC.MESSAGE.NUMBER_ENTER'),
                },
              ],
            },
            {
              caption: '화면 표시',
              dataField: 'label',
              allowEditing: this.refsList[i].editBool,
              allowSorting: false,
            },
            {
              caption: '이력 표시',
              dataField: 'display',
              allowEditing: this.refsList[i].editBool,
              allowSorting: false,
            },
            {
              caption: '파티션',
              i18n: 'UC.WORD.PARTITION',
              dataField: 'routepartitionname',
              allowEditing: false,
              allowSorting: false,
              calculateDisplayCellValue: rowData => {
                if (rowData?.routepartitionname) {
                  return rowData.routepartitionname;
                }
                if (rowData.routepartitionname == null || rowData.routepartitionname == 'null') {
                  return 'None';
                }
                if (rowData.field !== 'LINE') {
                  return '-';
                }
              },
            },
            {
              caption: '발신검색',
              i18n: 'UC.WORD.CSS',
              dataField: 'callingsearchspacename',
              allowEditing: false,
              allowSorting: false,
              calculateDisplayCellValue: rowData => {
                if (rowData?.callingsearchspacename) {
                  return rowData.callingsearchspacename;
                }
                if (rowData.callingsearchspacename == null || rowData.callingsearchspacename == 'null') {
                  return 'None';
                }
                if (rowData.field !== 'LINE') {
                  return '-';
                }
              },
            },
            {
              caption: '외부발신번호',
              dataField: 'e164mask',
              allowEditing: this.refsList[i].editBool,
              allowSorting: false,
            },
          ],
        });
      }
      // }
    },

    /** @description: 단말장치 데이터 상세 조회 메서드 */
    async selectData() {
      if (!this.reqParams) {
        this.$_goPrePage();
        return;
      }

      const {deviceData} = this.reqParams;

      const payload = {
        actionName: 'EUC_DEVICE_CISCO_DETAIL',
        data: {
          mac: deviceData.name,
          cmNm: deviceData.cmNm,
          loginId: this.$store.getters.getLoginId,
          type: 'MAC',
        },
        loading: true,
      };
      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        const deviceInfo = res.data.data[0];
        this.deviceRegDt = deviceData.regDt;

        if (deviceInfo.LIST.length > 0) {
          this.deviceInfo = deviceInfo.LIST[0];
          this.deviceList = deviceInfo.LIST[0].devicelist;
          this.copyList = JSON.parse(JSON.stringify(deviceInfo.LIST[0].devicelist)).map(item => {
            if (item.field !== 'LINE') {
              return {...item, number: '', label: ''};
            } else {
              return {...item};
            }
          });
        } else {
          await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NO_DATA'));
          await this.$_goPrePage();
          return;
        }
        if (this.deviceInfo?.model) {
          this.deviceImage = `${getUCServerPath()}/euc/api/v1/resource/DEVICE_BIG_IMAGE_DIR/${this.deviceInfo.model}.png`;
        }
        if (this.deviceInfo?.addonmodules) {
          const addOnModules = this.deviceInfo.addonmodules.split(',');
          this.deviceExpImage = `${getUCServerPath()}/euc/api/v1/resource/DEVICE_BIG_IMAGE_DIR/${addOnModules[0]}.png`;
        } else {
          this.deviceExpImage = '';
        }
        this.copyname = this.deviceInfo.name;
        this.copydescription = this.deviceInfo.description;
        this.$set(this.dataGrid[`dataGrid_origin`], 'dataSource', this.deviceList);
        this.$set(this.dataGrid[`dataGrid_copy`], 'dataSource', this.copyList);
      } else {
        await this.$_Msg(res.data.header.resMsg);
        await this.$_goPrePage();
      }
    },

    /**@description 그리드 customEvent @onRowUpdating*/
    async onRowUpdating(e) {
      const column = Object.keys(e.newData)[0];
      const value = e.newData[column];
      const number = e.oldData.number;
      const newRow = { column, value, number };
      const existingRow = this.changedDeviceInfo.find(row => row.column === column && row.number === number);

      if (existingRow) {
        Object.assign(existingRow, newRow);
      } else {
        this.changedDeviceInfo.push(newRow);
      }

      if(newRow.column === 'number' && newRow.value !== number) {
        e.key.e164mask = await this.checkGroupPattern(newRow.value);
        newRow.column = 'e164mask';
        newRow.value = e.key.e164mask;

        const existingRow = this.changedDeviceInfo.find(row => row.column === newRow.column && row.number === number);

        if (existingRow) {
          Object.assign(existingRow, newRow);
        } else {
          this.changedDeviceInfo.push(newRow);
        }
      }
    },
    async checkGroupPattern(pattern) {
      const params = { pattern };
      const value = this.$_getSystemData('inout_type_emask').configValue;
      if (value) {
        params.inoutTypeCd = value;
      }

      const payload = {
        actionName: 'EUC_DEVICE_GROUP_CHECK',
        data: params,
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      console.log('res : ', res)
      if (isSuccess(res)) {
        if (res.data.data?.length) {
          return res.data.data[0];
        }
      }
    },
    /** @description  단말기 장치복사 */
    async onSuperCopy() {
      const targetMac = this.deviceInfo.name;
      const mac = this.copyname;

      let refs = this.getDataGridRef('copy');
      if (this.$refs[refs].hasEditData()) {
        await this.$_Msg('수정중인 항목이 있습니다.');
        return false;
      }

      const confirm = this.$_lang('COMMON.MESSAGE.CMN_CFM_DUPLICATE_SELECTED');
      if (!(await this.$_Confirm(confirm))) {
        return;
      }

      const gridData = this.$refs[this.getDataGridRef('copy')].getItems;
      const parseData = [];
      for (let i = 0; i < gridData.length; i++) {
        const list = {};
        list.INDEX = gridData[i].seq;

        //등록할 내선번호 없을경우 제외.
        if (gridData[i].number === '' || gridData[i].number == null) {
          continue;
        }

        list.NUMBER = gridData[i].number;
        if (gridData[i].label === '' || gridData[i].label == null) {
          list.LABEL = gridData[i].number;
        } else {
          list.LABEL = gridData[i].label;
        }
        //display
        list.DISPLAY = gridData[i].display;
        list.E164MASK = gridData[i]?.e164mask || 'NONE';
        parseData.push(list);
      }

      const payload = {
        actionName: 'EUC_DEVICE_SUPERCOPY',
        data: {
          cmNm: this.deviceInfo.cmname,
          mac: mac,
          targetMac: targetMac,
          description: this.copydescription,
          list: JSON.stringify(parseData),
          loginId: this.$store.getters.getLoginId,
        },
        loading: true,
      };

      const res = await this.CALL_EUC_API(payload);
      console.log('res : ', res)
      if (isSuccess(res)) {
        await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS'));
        await this.$_goPrePage();
      } else {
        await this.$_Msg(res.data.header.resMsg);
      }
    },
    /** @description : 날짜 형식 변환 메서드 */
    formatDt(date) {
      return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
    },
    /**@description 교환기 목록 가져오는 메서드*/
    async selectCmList(sort = '+cmOrd') {
      const params = {sort: sort, viewFl: 'Y'};
      const payload = {
        actionName: 'EUC_CM_SELECT',
        data: params,
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      console.log('res : ', res)
      if (isSuccess(res)) {
        this.cmList = res.data.data.map((item) => {
          return {
            cmNm: item.cmNm,
            cmOrd: item.cmOrd,
            description: item.description,
            editId: item.editId,
            editDt: item.editDt,
            regId: item.regId,
          };
        });
      }
    },
    /** @description 교환기 이름 한글 변환 메서드
     *  @param {String} cm Name
     *  @returns {String} 한글명
     */
    getCmNm(cm) {
      return this.cmList?.find(d => d.cmNm === cm)?.description || '';
    },
    /**@description 당겨받기그룹 목록 가져오는 메서드 */
    async selectPickupGroupList(sort = '+callpickupGroupOrd') {
      const payload = {
        actionName: 'EUC_PICKUPGROUP_SELECT',
        data: {
          sort,
          viewFl: 'Y'
        },
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      console.log('res : ', res)
      if (isSuccess(res)) {
        this.pickupGroupList = res.data.data;
      }
    },
    /**@description grid row 생성전 호출되는 메서드 */
    onRowPrepared(e) {
      if (e.rowType === 'data') {
        e.rowElement.style.backgroundColor = '#f5f5f5';
      }
    },
    /** @description : 그리드 셀 관련 준비 커스텀 이벤트 */
    onCellPrepared(e) {
      if (e.rowType === 'data') {
        if (e.columnIndex === 0 || e.data.field === 'SURL' || e.column.dataField === 'routepartitionname' || e.column.dataField === 'callingsearchspacename') {
          e.cellElement.style.backgroundColor = '#f5f5f5';
        }

        if (e.data.field !== 'LINE') {
          if (e.data.field === 'SURL') {
            e.cellElement.style.backgroundColor = '#f5f5f5';
          } else if (e.data.field === 'SD' || e.data.field === 'BLF') {
            if (e.columnIndex > 3) {
              e.cellElement.style.backgroundColor = '#f5f5f5';
            }
          }
        }
      }
    },
    /** @description 그리드 셀 편집기가 생성된 후 실행되는 함수 */
    onEditorPrepared(e) {
      if(e?.row) {
        if (e.row.cells[0].value === 'SURL') {
          e.component.cancelEditData();
        }

        if (e.row.cells[0].value === 'SD' || e.row.cells[0].value === 'BLF') {
          e.component.cancelEditData();
        }
      }
    },
    /** @description 이전페이지 이동 함수 */
    goListPage() {
      if (this.$store.getters?.getPreviousPath) {
        this.$_goPrePage();
      } else {
        this.$router.push('/euc/device');
      }
    },
    /**@description : 라이프사이클 mounted시 호출되는 메서드 */
    async mountedData() {
      await this.selectCmList();
      await this.selectPickupGroupList();
      await this.selectData();
    },
    /**@description : 라이프사이클 destroyed시 호출되는 메서드 */
    destroyedData() {
      this.$store.commit('setDetailParams', null);
    },
  },
  async created() {
    await this.createdData();
  },
  async mounted() {
    await this.mountedData();
  },
  destroyed() {
    this.destroyedData();
  },
};
</script>
<style scoped>
.device-img > img {
  align-self: center;
}

.fl {
  border-right: 0 !important;
}

tbody {
  display: table-row-group;
  vertical-align: top;
}

.table_form tbody th {
  border: 1px solid #ddd;
  padding: 10px 20px;
}

.table_form tbody td {
  border: 1px solid #ddd;
  padding: 10px 20px;
}

.border0 td {
  border: 0px;
}
</style>