var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dx-fieldset"},[(_vm.showTitle)?_c('div',{staticClass:"dx-fieldset-header",staticStyle:{"padding-top":"38px"}},[_vm._v(" "+_vm._s(_vm.getI18n('COMPONENTS.MENU_INFO', '메뉴 정보'))+" ")]):_vm._e(),_c('div',{staticClass:"dx-field"},[_c('div',{staticClass:"dx-field-label"},[_vm._v(_vm._s(_vm.getI18n('COMPONENTS.PARENT_MENU_NAME', '상위 메뉴')))]),_c('div',{staticClass:"dx-field-value"},_vm._l((_vm.menuHierarchy),function(menu,index){return _c('div',{key:menu.id,staticClass:"menu-hierarchy"},[_c('span',{staticClass:"menu-item"},[_vm._v(_vm._s(menu.menuNm)+" ")]),(index < _vm.menuHierarchy.length - 1)?_c('span',{staticClass:"separator"},[_vm._v(" > ")]):_vm._e()])}),0)]),_c('div',{staticClass:"dx-field"},[_c('div',{staticClass:"dx-field-label"},[_vm._v(_vm._s(_vm.getI18n('COMPONENTS.MENU_NAME', '메뉴명'))+"*")]),_c('div',{staticClass:"dx-field-value"},[_c('dx-text-box',{attrs:{"styling-mode":"outlined","width":_vm.style.boxWidth},model:{value:(_vm.formData.menuNm),callback:function ($$v) {_vm.$set(_vm.formData, "menuNm", $$v)},expression:"formData.menuNm"}},[_c('dx-validator',{ref:"menuNameValidatorRef",attrs:{"validation-group":_vm.validationGroupName}},[_c('dx-required-rule',{attrs:{"message":`${_vm.getI18n('COMMON.MESSAGE.REQUIRED_VALUE_IS', '[메뉴명] 은/는 필수값 입니다.', {
              value: _vm.getI18n('COMPONENTS.MENU_NAME', '메뉴명'),
            })}`}}),_c('dx-custom-rule',{attrs:{"message":_vm.getI18n('COMMON.MESSAGE.TEXT_BOX_LIMIT', `최대 ${_vm.limitLength.menuNm}자까지 입력 가능합니다.`, { limit: _vm.limitLength.menuNm }),"validation-callback":e => _vm.validateLength(e, _vm.limitLength.menuNm)}})],1)],1),_c('div',{staticClass:"limit-length-box"},[_c('span',[_vm._v(_vm._s(_vm.formData?.menuNm ? _vm.formData.menuNm.length : 0))]),_vm._v("/"+_vm._s(_vm.limitLength.menuNm)+" ")])],1)]),_c('div',{staticClass:"dx-field"},[_c('div',{staticClass:"dx-field-label"},[_vm._v(_vm._s(_vm.getI18n('COMPONENTS.MENU_TYPE', '메뉴 유형'))+"*")]),_c('div',{staticClass:"dx-field-value"},[_c('dx-select-box',{attrs:{"items":_vm.enums.common.menuType.values,"display-expr":"label","value-expr":"value","styling-mode":_vm.style.stylingModeOutlined,"width":_vm.style.boxWidth,"item-template":"itemTemplate"},on:{"selectionChanged":_vm.setMenuType},scopedSlots:_vm._u([{key:"itemTemplate",fn:function({ data }){return [_c('div',{attrs:{"title":_vm.enums.common.menuType.getMenuTypeTooltip(data.value)}},[_vm._v(" "+_vm._s(data.label)+" ")])]}}]),model:{value:(_vm.formData.menuTypeCd),callback:function ($$v) {_vm.$set(_vm.formData, "menuTypeCd", $$v)},expression:"formData.menuTypeCd"}},[_c('dx-validator',{ref:"menuTypeCdValidatorRef",attrs:{"validation-group":_vm.validationGroupName}},[_c('dx-required-rule',{attrs:{"message":`${_vm.getI18n('COMMON.MESSAGE.REQUIRED_VALUE_IS', '[메뉴 유형] 은/는 필수값 입니다.', {
              value: _vm.getI18n('COMPONENTS.MENU_TYPE', '메뉴 유형'),
            })}`}})],1)],1)],1)]),(_vm.formData.menuTypeCd === _vm.enums.common.menuType.NORMAL_MENU.value && _vm.formData.menuDepth === 1)?_c('div',{staticClass:"dx-field"},[_c('div',{staticClass:"dx-field-label"},[_vm._v(_vm._s(_vm.getI18n('COMPONENTS.MENU_ICON', '메뉴 아이콘')))]),_c('div',{staticClass:"dx-field-value"},[_c('dx-select-box',{attrs:{"data-source":_vm.theme.menuIcons,"display-expr":"label","value-expr":"value","item-template":"item","field-template":"field","width":_vm.style.boxWidth,"styling-mode":_vm.style.stylingModeOutlined,"drop-down-options":{ maxHeight: 300 }},scopedSlots:_vm._u([{key:"field",fn:function({ data }){return [(data)?_c('div',[_c('dx-text-box',{attrs:{"styling-mode":_vm.style.stylingModeUnderLined,"value":data && data.label,"read-only":true,"placeholder":_vm.getI18n('COMPONENTS.SELECT', '선택')}},[_c('div',{staticClass:"icon-image-box"},[_c('img',{staticClass:"icon-image",attrs:{"src":data.src,"alt":"icon image"}})])])],1):_c('div',[_c('dx-text-box',{attrs:{"styling-mode":_vm.style.stylingModeUnderLined,"read-only":true,"placeholder":_vm.getI18n('COMPONENTS.SELECT', '선택')}})],1)]}},{key:"item",fn:function({ data }){return [_c('div',[_c('img',{staticClass:"icon-image",attrs:{"src":data.src,"alt":"icon image"}}),_c('span',[_vm._v(" "+_vm._s(data.label)+" ")])])]}}],null,false,1496018016),model:{value:(_vm.formData.menuIcon),callback:function ($$v) {_vm.$set(_vm.formData, "menuIcon", $$v)},expression:"formData.menuIcon"}},[_c('dx-validator',{ref:"menuIconValidatorRef",attrs:{"validation-group":_vm.validationGroupName}},[_c('dx-required-rule',{attrs:{"message":`${_vm.getI18n('COMMON.MESSAGE.REQUIRED_VALUE_IS', '[메뉴 아이콘] 은/는 필수값 입니다.', {
              value: _vm.getI18n('COMPONENTS.MENU_ICON', '메뉴 아이콘'),
            })}`}})],1)],1)],1)]):_vm._e(),(_vm.formData.menuTypeCd !== _vm.enums.common.menuType.NORMAL_MENU.value)?_c('div',{staticClass:"dx-field"},[_c('div',{staticClass:"dx-field-label"},[_vm._v(_vm._s(_vm.getI18n('COMPONENTS.PAGE_PATH', '페이지 경로')))]),_c('div',{staticClass:"dx-field-value"},[_c('dx-text-box',{attrs:{"placeholder":"예) /esp/menu/list","styling-mode":_vm.style.stylingModeOutlined,"width":_vm.style.boxWidth},model:{value:(_vm.formData.pageUrl),callback:function ($$v) {_vm.$set(_vm.formData, "pageUrl", $$v)},expression:"formData.pageUrl"}},[_c('dx-validator',{ref:"pageUrlValidatorRef",attrs:{"validation-group":_vm.validationGroupName}},[_c('dx-custom-rule',{attrs:{"message":_vm.getI18n('COMMON.MESSAGE.TEXT_BOX_LIMIT', `최대 ${_vm.limitLength.pageUrl}자까지 입력 가능합니다.`, {
                limit: _vm.limitLength.pageUrl,
              }),"validation-callback":e => _vm.validateLength(e, _vm.limitLength.pageUrl)}})],1)],1),_c('div',{staticClass:"limit-length-box"},[_c('span',[_vm._v(_vm._s(_vm.formData?.pageUrl ? _vm.formData.pageUrl.length : 0))]),_vm._v("/"+_vm._s(_vm.limitLength.pageUrl)+" ")])],1)]):_vm._e(),_c('div',{staticClass:"dx-field"},[_c('div',{staticClass:"dx-field-label"},[_vm._v(_vm._s(_vm.getI18n('COMPONENTS.MENU_ORDER', '메뉴 순서')))]),_c('div',{staticClass:"dx-field-value"},[_c('dx-number-box',{attrs:{"styling-mode":_vm.style.stylingModeOutlined,"width":_vm.style.boxWidth},model:{value:(_vm.formData.menuOrd),callback:function ($$v) {_vm.$set(_vm.formData, "menuOrd", $$v)},expression:"formData.menuOrd"}})],1)]),_c('div',{staticClass:"dx-field"},[_c('div',{staticClass:"dx-field-label"},[_vm._v(_vm._s(_vm.getI18n('COMPONENTS.USE_STATUS', '사용여부')))]),_c('div',{staticClass:"dx-field-value"},[_c('dx-switch',{attrs:{"value":_vm.formData.viewFl === _vm.enums.common.stringViewFlag.YES.value},on:{"value-changed":function($event){return _vm.setViewFl($event)}}})],1)]),(_vm.isNewMenu)?_c('div',{staticClass:"dx-field"},[_c('div',{staticClass:"dx-field-label"},[_vm._v(" "+_vm._s(_vm.getI18n('COMPONENTS.AUTH_ADD', '권한 추가'))+" "),_c('img',{staticClass:"img-question",attrs:{"id":"img-question-tag"},on:{"mouseover":_vm.handleQuestionMouseEvent,"mouseleave":_vm.handleQuestionMouseEvent}}),_c('dx-popover',{attrs:{"target":"#img-question-tag","visible":_vm.tooltip.visible,"hide-on-outside-click":false}},[_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.getI18n(
              'COMMON.MESSAGE.MENU_AUTH_ADD_DESC',
              '최초 메뉴 등록 시 등록자의 권한그룹(상위 권한 포함)에 <br /> 메뉴 권한을 자동으로 추가할수 있습니다. <br /> 추가 권한설정은 권한 관리 메뉴에서만 가능합니다.',
            )
          )}})])],1),_c('div',{staticClass:"dx-field-value"},[_c('dx-switch',{attrs:{"value":_vm.formData.addAuthUseFl === _vm.enums.common.stringUsedFlag.YES.value},on:{"value-changed":function($event){return _vm.setAddAuthUseFl($event)}}})],1)]):_vm._e(),(!_vm.isNewMenu && _vm.validatedDetailPageMenuType(_vm.formData))?_c('div',{staticClass:"dx-field"},[_c('div',{staticClass:"dx-field-label"},[_vm._v(_vm._s('상세 페이지'))]),_c('div',{staticClass:"dx-field-value"},[_c('dx-button',{staticClass:"btn_XS default filled add1",attrs:{"text":_vm.getI18n('COMPONENTS.ADD', '추가'),"height":30},on:{"click":_vm.handleOpenMenuDetailPageModal}})],1)]):_vm._e(),(!_vm.isNewMenu && !_vm.isNormalPage)?_c('div',{staticClass:"dx-field"},[_c('div',{staticClass:"dx-field-label"},[_vm._v(_vm._s(_vm.getI18n('COMPONENTS.CHILD_MENU', '하위 메뉴')))]),_c('div',{staticClass:"dx-field-value"},[_c('dx-button',{staticClass:"btn_XS default filled add1",attrs:{"text":_vm.getI18n('COMPONENTS.ADD', '추가'),"height":30},on:{"click":_vm.handleOpenAddManuModal}})],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }