import { render, staticRenderFns } from "./reevaluate-test.vue?vue&type=template&id=0dbfafc8"
import script from "./reevaluate-test.vue?vue&type=script&lang=js"
export * from "./reevaluate-test.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports