export const getters = {
	/**
	 * 사용자 정보 가져오기
	 * @param state
	 * @return {*} || null
	 */
	getUser(state) {
		return state?.user ?? null;
	},
	/**
	 * 사용자의 액세스 토큰 가져오기
	 * @param state
	 * @return {*} || null
	 */
	getAccessToken(state) {
		return state?.user?.accessToken ?? null;
	},
	/**
	 * 라우팅 목록 가져오기 <br>
	 * !!조건문 생성시 .length를 사용 할 것 <br>
	 *
	 * 응답값 <br>
	 * { <br>
	 *  "host": "http://localhost:8081/esp/v1", <br>
	 *  "path": "/action", <br>
	 *  "method": "post", <br>
	 *  "timeOut": 5000, <br>
	 *  "actionNm": "ACTION_NAME" <br>
	 * }, <br>
	 * @param state
	 * @return {*|*[]}
	 */
	getRoutingInfo(state) {
		return state && state.routingInfo ? state.routingInfo : [];
	},
	/**
	 * 메뉴 목록 가져오기 <br>
	 * !!조건문 생성시 .length를 사용 할 것
	 * @param state
	 * @return {*|*[]}
	 */
	getMenuList(state) {
		return state && state.menuList ? state.menuList : [];
	},
	/**
	 * 공통코드 목록 가져오기 <br>
	 * @param state
	 * @return {*} || null
	 */
	getCommonCode(state) {
		return state?.commonCode ?? null;
	},
	/**
	 * 로그인 ID 가져오기
	 * @param state
	 * @return {*} || null
	 */
	getLoginId(state) {
		return state?.user?.loginId ?? null;
	},
	/**
	 * 로그인 이름 가져오기
	 * @param state
	 * @return {*} || null
	 */
	getLoginNm(state) {
		return state?.user?.loginNm ?? null;
	},
	/**
	 * 사이트 목록 가져오기 <br>
	 * !!조건문 생성시 .length를 사용 할 것
	 * @param state
	 * @return {*|*[]}
	 */
	getSiteList(state) {
		return state && state.siteList ? state.siteList : [];
	},
	/**
	 * 테넌트 목록 가져오기 <br>
	 * !!조건문 생성시 .length를 사용 할 것
	 * @param state
	 * @return {*|*[]}
	 */
	getTenantList(state) {
		return state && state.tenantList ? state.tenantList : [];
	},
	/**
	 * 공휴일 목록 Map 객체 가져오기
	 * @param state
	 * @return {Map<string, any>|null} 공휴일 목록을 담고 있는 Map 객체, 없으면 null 반환
	 */
	getHolidayMap(state) {
		return state?.holidayMap ?? null;
	},
	/**
	 * @description 테마 설정 가져오기
	 * @param state
	 * @return {*} || null
	 */
	getThemeSetting(state) {
		return state?.themeSetting ?? null;
	},
	/**
	 * 마이레이어 레이아웃 표시 여부 가져오기
	 * @param state
	 * @return {boolean}
	 */
	getIsShowMyLayerLayout(state) {
		return state && state.isShowMyLayerLayout ? state.isShowMyLayerLayout : false;
	},
	/**
	 * 시스템 설정 목록 가져오기 <br>
	 * @param state
	 * @return {*|*[]}
	 */
	getSystemCodeList(state) {
		return state && state.systemCodeList ? state.systemCodeList : [];
	},
	/**
	 * 암호화 타입 가져오기
	 * @param state
	 * @return {*} || null
	 */
	getEncryptionType(state) {
		return state?.encryptionType ?? null;
	},
	/**
	 * 권한 가져오기
	 * @param state
	 * @return {*} || null
	 */
	getAuthId(state) {
		return state?.user?.authId ?? null;
	},
	/**
	 * 사용자 정보 가져오기
	 *
	 * @param state
	 * @returns {*} || null
	 */
	getUserInfo(state) {
		return state?.user?.userInfo ?? null;
	},
	/**
	 * 즐겨찾기 리스트
	 *
	 * @param state
	 * @returns {*[]}
	 */
	getFavoriteMenu(state) {
		return state?.favoriteMenu || [];
	},
	/**
	 * 사용자 상태 가져오기
	 *
	 * @param state
	 * @return {*} || null
	 */
	getMemberState(state) {
		return state?.user?.memberState ?? null;
	},
	/**
	 * 관리자 여부 가져오기
	 *
	 * @param state
	 * @return {*} || null
	 */
	getIsAdmin(state) {
		return state?.user?.isAdmin ?? null;
	},
	/**
	 * 사용자 메인 페이지 가져오기
	 *
	 * @param state
	 * @return {*|*[]}
	 */
	getUserMainPages(state) {
		return state && state?.user?.mainPage ? state.user.mainPage : [];
	},
	/**
	 * 웹소켓 정보 가져오기
	 *
	 * @param state
	 * @return {*} || null
	 */
	getStompClient(state) {
		return state?.stompClient ?? null;
	},
	/**
	 * 웹소켓 세션 아이디 가져오기
	 *
	 * @param state
	 * @return {*} || null
	 */
	getSessionId(state) {
		return state?.sessionId ?? null;
	},
	/**
	 * @description 라우트 메뉴 맵 가져오기(상세페이지 정보 포함)
	 *
	 * @param state
	 * @return {null}
	 */
	getRouteMenuMap(state) {
		return state?.routeMenuMap ?? null;
	},
	/**
	 * @description 캐시된 탭 페이지 목록 가져오기
	 *
	 * @param state
	 * @return {[]|*[]}
	 */
	getCachedTabPages(state) {
		return state?.cachedTabPages ?? [];
	},
};
