<template>
  <transition>
    <div class="flex-modal-container">
      <div class="flex-modal-item">
        <div v-if="options.useHeader" class="flex-between">
          <h2 class="section-title"></h2>
          <div class="pb-10">
            <span class="mr-10">XML 업로드*</span>
            <DxButton type="button" class="btn_XS default filled add" text="파일 선택" @click="importXmlFile" />
          </div>
        </div>
        <div class="editor-style">
          <CodeMirrorEditor ref="codeMirror" v-model="cmEditor" beautyType="html" />
        </div>
      </div>
      <div v-if="options.useRight" class="flex-modal-item" :style="{ width: options.rsWidth }">
        <h2 class="section-title">보고서 정보</h2>
        <table class="table_form line-bin th-bold">
          <colgroup>
            <col style="width: 110px" />
            <col style="width: auto" />
          </colgroup>
          <tbody>
            <tr>
              <th>소메뉴 *</th>
              <td class="clearfix">
                <DxSelectBox
                  value-expr="id"
                  display-expr="menuNm"
                  placeholder="선택"
                  styling-mode="outlined"
                  width="100%"
                  :height="30"
                  :grouped="true"
                  :items="menus.group"
                  :show-clear-button="true"
                  v-model="menus.selectedId"
                >
                  <DxValidator validation-group="validationGroupName">
                    <DxRequiredRule message="소메뉴 선택은 필수 입니다." />
                  </DxValidator>
                </DxSelectBox>
              </td>
            </tr>
            <tr>
              <th>보고서명 *</th>
              <td class="clearfix">
                <DxTextBox v-model="reportNm" width="100%" :height="30" :styling-mode="stylingMode">
                  <DxValidator validation-group="validationGroupName2">
                    <DxRequiredRule message="필수 값 입니다." />
                  </DxValidator>
                </DxTextBox>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </transition>
</template>
<script>
  import CodeMirrorEditor from '@/components/codemirror/codemirror-xml-editor.vue';
  import { DxButton } from 'devextreme-vue/button';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
  import { isEmpty } from '@/utils/common-lib';

  export default {
    components: {
      DxButton,
      DxTextBox,
      DxValidator,
      DxSelectBox,
      DxRequiredRule,
      //Custom
      CodeMirrorEditor,
    },
    props: {
      isOn: {
        type: Boolean,
        default: false,
      },
      options: {
        type: Object,
        default: () => {
          return { useHeader: false, useRight: false };
        },
      },
    },
    watch: {
      formData: {
        handler(str) {
          if (str) this.$emit('input', str);
        },
        deep: true,
      },
      isOn(bool) {
        if (!bool) this.clearForm();
      },
    },
    data() {
      return {
        cmEditor: null,
        description: '',
        stylingMode: 'outlined', //[outlined, filled, underlined]
        // XML Form
        xmlForm: {
          file: null,
          fileUrl: null,
          queryFile: null,
        },
        // 레포트명
        reportNm: '',
        // 소메뉴
        menus: {
          group: this.groupingMenuList(),
          depth3: this.getMenuList(3, 'REPORT'),
          selectedId: null,
        },
      };
    },
    computed: {},
    methods: {
      createFileInputElem() {
        const inputElem = document.createElement('input');
        inputElem.type = 'file';
        inputElem.id = 'fileInput';
        inputElem.accept = 'xml'; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"
        return inputElem;
      },
      importXmlFile() {
        let vm = this;
        // 1. 임시 사용할 Input 엘리먼트 생성
        const inputElem = this.createFileInputElem();

        //이벤트리스너동작시
        inputElem.onchange = e => {
          vm.xmlForm.file = e.target.files[0];
          const xmlFile = e.target.files[0];
          const fileReader = new FileReader();
          const fileReader2 = new FileReader();

          vm.xmlForm.queryFile = inputElem.value.replace(/C:\\fakepath\\/i, '');
          //xmlFile url로 변환
          if (xmlFile) fileReader2.readAsDataURL(xmlFile);

          fileReader.onload = () => {
            const xml = fileReader.result;
            vm.cmEditor = xml;
          };

          fileReader.readAsText(inputElem.files[0]);

          //파일이 로드되면 - 파일 저장하는 링크
          fileReader2.onload = () => {
            let inputData = fileReader2.result;
            vm.xmlForm.fileUrl = inputData;
            // let replaceValue = inputData.split(',')[0];
            //파일 URL
            // let base64String = inputData.replace(replaceValue + '.', '');
            // vm.$log.debug(base64String); //파일 링크
          };
        };
        inputElem.click();
      },

      /**
       * 소메뉴 그룹핑
       */
      groupingMenuList() {
        const depth1 = this.getMenuList(1);
        const depth2 = this.getMenuList(2);

        return depth1.reduce((prev, curr) => {
          prev.push({
            key: curr.menuNm,
            items: depth2.filter(menu => menu.parentId === curr.id),
          });

          return prev;
        }, []);
      },

      /**
       * 메뉴 필터 및 조회 함수
       * */
      getMenuList(menuDepth, typeCd) {
        const menuList = this.$store.getters.getMenuList;
        if (isEmpty(typeCd)) {
          // 2뎁스 메뉴 전체 조회
          return menuList.filter(v => v.menuDepth === menuDepth);
        }
        // 보고서 메뉴 조회
        return menuList.filter(v => v.menuDepth === menuDepth && v.menuTypeCd === typeCd);
      },

      clearForm() {
        this.reportNm = '';
        this.cmEditor = null;
        this.xmlForm.fileUrl = null;
        this.xmlForm.queryFile = null;
        this.menus.selectedId = null;
      },
      getCreateParam() {
        return {
          menuNm: this.reportNm,
          parentMenuId: this.menus.selectedId,
          xmlData: this.cmEditor,
        };
      },
      initMounted() {
        const { useRight } = this.options;
        if (useRight) this.setInitRight();
      },
    },
    created() {},
    mounted() {
      this.initMounted();
    },
    destroyed() {},
  };
</script>
<style scoped>
  .mr-10 {
    margin-right: 10px;
  }

  .flex-modal-container {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .flex-between {
    display: flex;
    justify-content: space-between;
  }

  .flex-modal-item {
    padding-left: 5px;
    padding-right: 5px;
  }

  .flex-modal-item:nth-child(1) {
    flex-grow: 1;
  }

  .flex-modal-item:nth-child(2) {
    flex-shrink: 0;
  }

  .editor-style {
    width: 100%;
    height: 90%;
  }

  .section-title {
    font-size: 18px;
    color: #545454;
    font-weight: 500;
    padding-bottom: 16px;
  }

  .pb-10 {
    padding-bottom: 10px;
  }

  .th-bold > tbody > tr > th {
    font-weight: 400;
  }
</style>
