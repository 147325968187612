import DNISList from '@/pages/cc/ivr/dnis/list';
import NoticeList from '@/pages/cc/ivr/notice/list';
import NoticeConfig from '@/pages/cc/ivr/notice/config';
import ScheduleList from '@/pages/cc/ivr/schedule/list';
import ScheduleConfig from '@/pages/cc/ivr/schedule/config/index';
import ServiceTemplatesList from '@/pages/cc/ivr/service-templates/list';
import IVRServerConfig from '@/pages/cc/ivr/ivrsvr-config';
import IVRMent from '@/pages/cc/ivr/ment/ivr-ment';

export default function IVRRoutes(commonComponents) {
  return [
    {
      path: '/cc/ivr/dnis/list',
      name: 'DNISList',
      components: { default: DNISList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/ivr/notice/list',
      name: 'NoticeList',
      components: { default: NoticeList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/ivr/notice/config/:id',
      name: 'NoticeConfig',
      components: { default: NoticeConfig, ...commonComponents },
      meta: { requiresAuth: true, isDetailPage: true },
    },
    {
      path: '/cc/ivr/schedule/list',
      name: 'ScheduleList',
      components: { default: ScheduleList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/ivr/schedule/config/index/:id',
      name: 'ScheduleConfig',
      components: { default: ScheduleConfig, ...commonComponents },
      meta: { requiresAuth: true, isDetailPage: true },
    },
    {
      path: '/cc/ivr/service-templates/list',
      name: 'ServiceTemplatesList',
      components: { default: ServiceTemplatesList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/ivr/ivrsvr-config',
      name: 'IVRServerConfig',
      components: { default: IVRServerConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/ivr/ment/ivr-ment',
      name: 'IVRMent',
      components: { default: IVRMent, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}
