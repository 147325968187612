export default {
  MESSAGE: {
    WARNING_DELETE_PROJECT: '프로젝트 삭제시 모든 데이터가 함께 삭제됩니다. <br>삭제하시겠습니까?',
    UNSAVED_PROMPT_CHANGE_ALERT: '작업한 프롬프트 내용이 저장되지 않았습니다. <br>변경 하시겠습니까?',
    UNSAVED_PROMPT_MOVE_ALERT: '작업한 프롬프트 내용이 저장되지 않았습니다. <br>이동 하시겠습니까?',
    NEW_PROMPT_ADD_ALERT: '선택한 프롬프트를 수정하지 않고 새로운 프롬프트로 생성됩니다.',
    NEW_PROMPT_NAME_ALERT: '새로운 프롬프트의 제목을 입력하세요.',
    DELETE_SYSTEM_PROMPT_ALERT: '시스템 프롬프트 삭제 시, 해당 프롬프트를 사용하고 있는 질의 세트도 함께 삭제 됩니다.<br/> 삭제 하시겠습니까?',
    ENCRYPTED_INDEX_FILE: '보안상의 이유로 암호가 설정된 파일은 업로드할 수 없습니다. 암호를 해제한 후 다시 시도해 주세요.',
    SUPPORTED_INDEX_FILE: '지원하는 파일 형식 : {extension}',
    DUPLICATE_INDEX_FILE: '이미 중복된 파일이 등록되어 있습니다.',
    DELETE_INDEX_FILE_ALERT1: '인덱스 파일 삭제 시, 질의에 사용된 검색 문서도 함께 삭제 됩니다.',
    DELETE_INDEX_FILE_ALERT2: '선택한 {value}개 파일을 삭제하시겠습니까?',
    DUPLICATE_MODEL: '동일 조건의 모델이 이미 존재합니다.',
    UNSET_PROMPT: '프롬프트가 선택되어 있지 않습니다.',
    UNSET_CONFIG: '설정값이 존재하지 않습니다.',
    PLEASE_ADD_INDEX: '등록된 인덱스가 없습니다. 새 인덱스를 추가해주세요.',
    DONT_EXIST_FILE: '선택할 검색 문서가 없습니다.',
    DONT_EXITS_SEARCH_RESULT: '검색 결과가 존재하지 않습니다.',
    UNSELECT_SEARCH_DOCUMENT_ALERT: '검색 여부 해제시 검색 문서가 초기화 됩니다. <br/>해제 하시겠습니까?',
    INPUT_1_TO_2000: '1부터 2000까지의 정수만 입력 가능합니다.',
    ADD_TEST_ALERT1: '실험 진행에는 다소 시간이 걸릴 수 있습니다.',
    ADD_TEST_ALERT2: '실험을 추가 하시겠습니까?',
    CANCEL_TEST_ALERT: '해당 실험을 취소하시겠습니까.',
    SUC_CANCEL: '정상적으로 취소되었습니다.',
    EXIST_PLAYGROUND_SESSION: '현재 Playground를 다른 사용자가 작업 중이므로 사용할 수 없습니다.',
    DO_ENTER_QUERY: '질의을 입력하여 Playground를 이용하세요',
    INIT_SESSION_ALERT: '세션 초기화시 현재의 질의및 답변이 모두 삭제됩니다.<br/> 정말 초기화 하시겠습니까?',
    DISCONNECT_SESSION_ALERT: 'Playground 세션이 종료되었습니다. <br/>사용하시려면 페이지를 재진입 해주세요.',
    LIMIT_MESSAGE: '[메시지]의 [질의/답변]의 길이는 {limitNumber}자를 초과할 수 없습니다.',
    INVALID_JSON: '[{value}]의 값은 Json 형식만 가능합니다.(“…{preview}…”)',
    INVALID_MODEL: '유효하지 않은 모델입니다.',
    DELETE_LOCAL_MODEL_ALERT: '로컬 모델 삭제 시, 해당 모델을 사용하고 있는 프로젝트의 모델도 함께 삭제 됩니다.<br/>삭제 하시겠습니까?',
  },
  WORD: {
    PROJECT: '프로젝트',
    PROJECT_NAME: '프로젝트 이름',
    PROJECT_ID: '프로젝트 아이디',
    TEST_CONFIG: '실험 설정',
    ADD_TEST: '실험 추가',
    SEARCHING_TEST: '실험 조회',
    DETAIL: '보기',
    PROMPT: '프롬프트',
    QUERY_REWRITING_PROMPT: '질의 재작성 프롬프트',
    SYSTEM_PROMPT: '시스템 프롬프트',
    EVALUATION_PROMPT: '평가 프롬프트',
    PROMPT_ADD: '새 프롬프트 추가',
    SYSTEM_PROMPT_ADD: '새 시스템 프롬프트 추가',
    QUERY_REWRITING_PROMPT_ADD: '새 질의 재작성 프롬프트 추가',
    EVALUATION_PROMPT_ADD: '새 평가 프롬프트 추가',
    SELECT_PROMPT: '프롬프트 선택',
    QUERY_SET: '질의 세트',
    INDEX_CONFIG: '인덱스 관리',
    ADD_DATE: '추가일',
    PARSING_RESULT: '파싱 결과',
    QUERY_TYPE: '질의 유형',
    QUERY: '질의',
    ROLE: '역할',
    ANSWER: '답변',
    CORRECT_ANSWER: '올바른 답변',
    SUPPORTING_DOCUMENT: '검색 문서',
    REPEAT_COUNT: '반복 횟수',
    SYNTHETIC_OPTION: '변형 옵션',
    SUBSTANTIVE_SYNONYM: '체언 유의어',
    SUBSTANTIVE_TYPO: '체언 오타',
    PREDICATE_SYNONYM: '용언 유의어',
    PREDICATE_TYPO: '용언 오타',
    PREDICATE_ENDING: '용언 어미',
    SYNTHETIC_COUNT: '변형 횟수',
    QUERY_ADD: '질의 세트 추가',
    QUERY_UPDATE: '질의 세트 수정',
    COPY_ADD: '새로 추가',
    CONTENT: '내용',
    MODEL: '모델',
    LOCAL_MODEL: '로컬 모델',
    RUN: '실행',
    TEST_NAME: '실험 이름',
    STATUS: '상태',
    PROGRESS: '진행률',
    REEVALUATE: '재평가',
    RESULT_DOWNLOAD: '결과 다운로드',
    END_DATE_TIME: '종료일시',
    RETURN: '돌아가기',
    SESSION_ID: '세션 아이디',
    SEARCH_FLAG: '검색 여부',
    SELECT_PROJECT: '프로젝트 선택',
    SHOW_PROMPT: '프롬프트 보기',
    DOCUMENT_NAME: '문서 이름',
    CHUNK_ORDER: '청크 순서',
    SHOW_DOCUMENT: '검색 문서 보기',
    CLOSE_DOCUMENT: '검색 문서 닫기',
    SEARCH_DOCUMENT_NAME: '검색 문서명',
    FILE_NAME: '파일명',
    FIX_SEARCHING: '고정 검색',
    MESSAGE: '메시지',
    QUERY_ANSWER_CONTENT: '질의/답변 내용',
    LOCAL_MODEL_NAME: '로컬 모델명',
    TOKEN: '토큰',
    HYPERPARAMETERS:'초매개 변수',
    RESPONSE_STRUCTURE: '응답 구조',
    KEYWORD_ARGUMENTS: '기타 매개 변수',
  },
};
