import enums from '../configs/enums';
import { deleteObjectKey, isSuccess, isTrue } from '@/utils/common-lib';
import { ActionTypes } from '@/store/types';
import { espApi } from '@/api';
import { getStoreInstance } from '@/store';

/**
 * @description 시스템 관련 store 초기화(인증 필요없는 내용들)
 * @return {Promise<void>}
 */
export const initSystemStore = async () => {
  const store = getStoreInstance();
  const promises = [];

  // 라우팅 관리 (API 정보)
  if (store.getters.getRoutingInfo.length === 0) {
    promises.push(
      espApi.getRoutingInfoList().then(res => {
        if (isSuccess(res)) {
          store.commit('setRoutingInfo', res.data.data);
        }
      }),
    );
  }

  // 시스템 코드 목록
  if (store.getters.getSystemCodeList.length === 0) {
    promises.push(
      espApi.getSystemSettings().then(res => {
        if (isSuccess(res)) {
          store.commit('setSystemCodeList', res.data.data);
        }
      }),
    );
  }

  // 테마정보
  if (!store.getters.getThemeSetting) {
    promises.push(
      espApi.getThemeSettings().then(res => {
        if (isSuccess(res)) {
          store.commit('setThemeSetting', res.data.data);
        }
      }),
    );
  }
  await Promise.all(promises);
};

/**
 * 인증이 필요한 API store 데이터 갱신
 * @return {Promise<void>}
 */
export const initUserStore = async () => {
  const createApiPayload = (actionName, paramsData, loading = false) => ({
    actionName,
    data: paramsData,
    loading,
  });

  let apiList = [];

  // 공통코드 store 초기화
  if (!getStoreInstance().getters.getCommonCode) {
    apiList.push({
      api: createApiPayload('CODE_VIEW_LIST', { type: 0 }),
      callback: data => {
        getStoreInstance().commit('setCommonCode', data);
      },
    });
  }

  // 메뉴 목록 store 초기화
  if (getStoreInstance().getters.getMenuList.length === 0) {
    apiList.push({
      api: createApiPayload('MENU_LIST_ALL', {
        authId: getStoreInstance().getters.getAuthId,
        viewFl: enums.common.stringViewFlag.YES.value,
        isCompact: true, // 간략 조회 여부
      }),
      callback: data => {
        getStoreInstance().commit('setMenuList', data);
      },
    });
  }

  // 휴일 목록이 없는 경우 휴일 목록을 불러옵니다.
  if (!getStoreInstance().getters.getHolidayMap) {
    apiList.push({
      api: createApiPayload('HOLIDAY_DATE_LIST', {
        startYear: 2022,
        endYear: 2050,
        hasFieldName: true,
      }),
      callback: data => {
        const holidayMap = new Map(
          data.map(holiday => [holiday.date, { date: holiday.date, field: holiday.field }])
        );
        getStoreInstance().commit('setHolidayMap', holidayMap);
      },
    });
  }

  // 즐겨찾기 메뉴 목록이 없는 경우 즐겨찾기 메뉴 목록을 불러옵니다.
  if (getStoreInstance().getters.getFavoriteMenu.length === 0) {
    apiList.push({
      api: createApiPayload('MENU_FAVORITE_LIST', {}),
      callback: data => {
        getStoreInstance().commit('setFavoriteMenu', data);
      },
    });
  }

  // 사이트 목록이 없는 경우 사이트 목록을 불러옵니다.
  if (getStoreInstance().getters.getSiteList.length === 0) {
    apiList.push({
      api: createApiPayload('SITE_LIST_ALL', { loginId: getStoreInstance().getters.getLoginId }),
      callback: data => {
        getStoreInstance().commit('setSiteList', data);
      },
    });
  }

  // 테넌트 목록이 없는 경우 테넌트 목록을 불러옵니다.
  if (getStoreInstance().getters.getTenantList.length === 0) {
    apiList.push({
      api: createApiPayload('TENANT_LIST_ALL', { loginId: getStoreInstance().getters.getLoginId }),
      callback: data => {
        getStoreInstance().commit('setTenantList', data);
      },
    });
  }

  const apiCalls = apiList.map(({ api, callback }) => async () => {
    try {
      const res = await getStoreInstance().dispatch(ActionTypes.CALL_API, api);
      if (isSuccess(res)) {
        await callback(res.data.data); // 비동기 콜백 호출
      }
    } catch (error) {
      console.error(`initStore Call error for ${api.actionName}: `, error);
    }
  });

  // 모든 API 호출이 완료될 때까지 대기
  await Promise.all(apiCalls.map(fn => fn()));

  // 메뉴정보 + 메뉴 상세 페이지 정보 조회
  const res = await espApi.getSystemRouteInfo();
  if (isSuccess(res)) {
    const routeMenuMap = new Map(
      res.data.data.map(routeMenu => [routeMenu.pageUrl, routeMenu])
    );
    getStoreInstance().commit('setRouteMenuMap', routeMenuMap);
  }
};

/**
 * @description route 최종 페이지 path 생성
 * @return {object}
 */
export const getPostNavigationPath = () => {
  const redirectPath = sessionStorage.getItem('redirectPath');
  if (redirectPath) {
    sessionStorage.removeItem('redirectPath');
    const pageData = getStoreInstance().getters.getMenuList.find(menu => menu.pageUrl === redirectPath);
    if (!pageData) {
      return {
        path: '/error/404',
        query: {
          toPath: redirectPath,
        },
      };
    }

    if (pageData && pageData.authUseFl === 'Y') {
      return {
        path: pageData.pageUrl,
      };
    }
    return {
      path: '/error/401',
      query: {
        toPath: redirectPath,
      },
    };
  }
  return {
    path: getUserMainPage(),
  };
};

/**
 * @description 유저 메인 페이지 URL 조회
 * @return {string} route path
 */
export const getUserMainPage = () => {
  const userMainPages = getStoreInstance().getters.getUserMainPages;
  // TODO : 메인페이지 설정은 현재 '개인' 설정이 없는 관계로 'ALL' 인 경우만 고정하여 사용, 추후 개인 설정이 추가되면 수정 필요
  const authMenuMainPage = userMainPages.find(page => page.targetId === 'ALL');
  const pageData = getStoreInstance().getters.getMenuList.find(menu => menu.id === authMenuMainPage?.menuId);
  if (pageData && pageData.pageUrl) {
    return pageData?.pageUrl;
  } else {
    return '/empty-main-page';
  }
};

/**
 * @description: 이전페이지 path 저장
 * @param previousData 이전 path 정보
 * @param currentData 현재 path 정보
 */
export const setPreviousPath = (previousData, currentData) => {
  if (previousData.name !== null) {
    //이전페이지 path로 설정
    getStoreInstance().commit('setPreviousPath', previousData.path);
  }
};

/**
 * @description: 목록 캐시 유지 설정
 *               1. 상세페이지 -> 목록으로 이동시
 * @param previousData
 * @param currentData
 */
export const setListCacheFl = (previousData, currentData) => {
  let previousPath;
  if (previousData.name !== null) {
    previousPath = previousData.path;
  } else {
    previousPath = currentData.path;
  }
  const previousParentPath = previousPath.substring(0, previousPath.lastIndexOf('/'));
  const currentPath = currentData.path;
  const currentParentPath = currentPath.substring(0, currentPath.lastIndexOf('/'));
  console.log('previousPath >> ', previousPath);
  console.log('currentPath >> ', currentPath);

  // 상세페이지 -> 리스트로 이동시
  if (previousParentPath === currentParentPath && currentPath.includes('/list')) {
    getStoreInstance().commit('setListCacheFl', true);
  } else {
    //다른 페이지로 이동시
    getStoreInstance().commit('setListCacheFl', false);
  }

  // 다른 리스트로 이동시 검색 이력 삭제
  if (previousParentPath !== currentParentPath) {
    let searchCacheFl = getStoreInstance().getters.getSystemCodeList
      ? getStoreInstance().getters.getSystemCodeList.find(val => val.configKey === 'search_cache_fl').configValue
      : false;
    searchCacheFl = isTrue(searchCacheFl.toString());
    if (!searchCacheFl && getStoreInstance().getters.getSearchHists) {
      //검색 캐시 저장이 false일 경우
      //해당 페이지 검색 캐시 삭제
      let searchHists = deleteObjectKey(getStoreInstance().getters.getSearchHists, previousPath);
      getStoreInstance().commit('setSearchHists', searchHists);
    }
  }
};

/**
 * @description: 메뉴 히스토리 업데이트
 * @param {Object} to - 라우터 이동 대상 route 객체.
 */
export const updateMenuHistory = async to => {
  // noTrack 메타 속성이 없는 경우 메뉴 히스토리 저장
  if (!to?.meta?.noTrack) {
    const menuPayload = {
      actionName: 'MENU_HISTORY_SAVE',
      data: {
        menuId: to?.meta?.menuId,
      },
    };
    await getStoreInstance().dispatch(ActionTypes.CALL_API, menuPayload);
  }
};

/**
 * @description 다이렉트 로그인 처리
 * @param to
 * @param next
 * @return {Promise<boolean>}
 */
export const handleDirectLogin = async (to, next) => {
  if (to.query?.loginId) {
    const loginId = to.query.loginId;
    const res = await getStoreInstance().dispatch('LOGIN_DIRECT', { loginId });
    if (isSuccess(res)) {
      const url = new URL(to.fullPath, window.location.origin);
      url.searchParams.delete('loginId');
      const newPath = url.pathname + url.search;
      next(newPath || '/');
      return true;
    }
  }
  return false;
};

/**
 * @description 토큰 검증 및 사용자 상태 확인
 * @return {Promise<boolean>}
 */
export const verifyTokenAndMemberState = async () => {
  const accessToken = getStoreInstance().getters.getPersistedAccessToken;
  if (!accessToken) {
    return false;
  }

  const res = await getStoreInstance().dispatch(ActionTypes.VERIFY_TOKEN_AND_MEMBER_STATE, { accessToken });
  return !!isSuccess(res);
};

/**
 * @description 로그인 후 유저 상태값에 따라 페이지 라우팅
 * @param memberState 유저 상태값
 */
export const checkPasswordChangeState = memberState => {
  return memberState === enums.common.memberState.TEMPORARY.value || memberState === enums.common.memberState.EXPIRED.value;
};

/**
 * @description Vuex Store State 초기화 -> 시스템 store 갱신
 * @return {Promise<void>}
 */
export const resetVuexState = async () => {
  getStoreInstance().commit('resetState');
  getStoreInstance().commit('resetSessionState');
  await initSystemStore();
};
