import { resetStateKeys } from './state.js';
import {
  addCachedTabPage,
  removeCachedTabPage,
  clearCachedTabPages,
  moveCachedTab,
  toggleAllCachedTabLocked,
  toggleCachedTabLocked,
  setDetailCachedTabInfo,
} from '@/store/modules/system-tab-mutations';

export const mutations = {
  setUser(state, payload) {
    state.user = payload;
  },
  setRoutingInfo(state, payload) {
    state.routingInfo = payload?.length ? payload : [];
  },
  setMenuList(state, payload) {
    state.menuList = payload?.length ? payload : [];
  },
  setCommonCode(state, payload) {
    state.commonCode = payload ? payload : {};
  },
  setLoginNm(state, payload) {
    state.user.loginNm = payload;
  },
  setSiteList(state, payload) {
    state.siteList = payload?.length ? payload : [];
  },
  setTenantList(state, payload) {
    state.tenantList = payload?.length ? payload : [];
  },
  setHolidayMap(state, payload) {
    state.holidayMap = payload ? payload : {};
  },
  setThemeSetting(state, payload) {
    state.themeSetting = payload;
  },
  setIsShowMyLayerLayout(state, payload) {
    state.isShowMyLayerLayout = payload ? payload : false;
  },
  setSystemCodeList(state, payload) {
    state.systemCodeList = payload?.length ? payload : [];
  },
  setEncryptionType(state, payload) {
    state.encryptionType = payload;
  },
  setAuthId(state, payload) {
    state.user.authId = payload;
  },
  setFavoriteMenu(state, payload) {
    state.favoriteMenu = payload?.length ? payload : [];
  },
  resetState(state) {
    resetStateKeys.forEach(key => {
      state[key] = null;
    });
  },
  resetStateByKey(state, key) {
    state[key] = null;
  },
  setStompClient(state, client) {
    state.stompClient = client;
  },
  setSessionId(state, sessionId) {
    state.sessionId = sessionId;
  },
  setRouteMenuMap(state, payload) {
    state.routeMenuMap = payload ? payload : {};
  },
  addCachedTabPage,
  removeCachedTabPage,
  clearCachedTabPages,
  moveCachedTab,
  toggleAllCachedTabLocked,
  toggleCachedTabLocked,
  setDetailCachedTabInfo,
};
