<!--
  PACKAGE_NAME : src\pages\ai\llm-tester\work-config
  FILE_NAME : question-save
  AUTHOR : hpmoon
  DATE : 2024-11-13
  DESCRIPTION : AI > LLM > LLM Tester > 프로젝트 실험 설정 > 질의 세트 추가/수정
-->
<template>
  <dx-popup
    :show-title="true"
    :title="modal.title"
    :min-width="modal.minWidth"
    :width="modal.width"
    :max-height="modal.maxHeight"
    :height="modal.height"
    :drag-enabled="modal.dragEnabled"
    :resize-enabled="modal.resizeEnabled"
    :hide-on-outside-click="modal.closeOnOutsideClick"
    :show-close-button="modal.showCloseButton"
    :visible="isOpen"
    @hiding="closeModal"
  >
    <template #content>
      <dx-scroll-view>
        <div class="page-sub-box">
          <table class="table_form line-bin">
            <colgroup>
              <col style="width: 130px" />
              <col style="width: auto" />
            </colgroup>

            <tbody>
              <tr>
                <th scope="row">
                  <label for="label5">{{ $_lang('LLM_TESTER.WORD.QUERY_TYPE', { defaultValue: '질의 유형' }) }} <span class="icon_require">필수항목</span></label>
                </th>
                <td>
                  <dx-tag-box
                    :value="tagBoxValue"
                    placeholder=""
                    :items="categoryList"
                    :max-length="limitNumberTexts.maxLengths.category"
                    :search-enabled="true"
                    :hide-selected-items="true"
                    :accept-custom-value="true"
                    @value-changed="handleTagValueChanged"
                    :styling-mode="stylingMode"
                    class="mar_ri10"
                    :width="800"
                    @key-up="handleKeyUpTabBox"
                  >
                    <dx-validator>
                      <dx-required-rule validation-group="validationGroupName"
                                      :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS',
                                    { value: $_lang('LLM_TESTER.WORD.QUERY_TYPE', {defaultValue: '질의 유형'}), defaultValue: '[질의 유형] 은/는 필수값 입니다' })" />
                    </dx-validator>
                  </dx-tag-box>
                  <span>
                    {{
                      limitNumberTexts.textLengths.category
                        ? limitNumberTexts.textLengths.category
                        : formData.categories.at(-1)
                          ? formData.categories.at(-1).length
                          : 0
                    }}
                  </span>/{{ limitNumberTexts.maxLengths.category }}
                </td>
              </tr>

              <tr>
                <th scope="row">
                  <label for="label5">{{ $_lang('LLM_TESTER.WORD.MESSAGE', { defaultValue: '메시지' }) }} <span class="icon_require">필수항목</span></label>
                </th>
                <td>
                  <esp-dx-data-grid-v2 :ref="messageDataGrid.refName" class="inline-block mar_ri10 alB" :data-grid="messageDataGrid" @saving="" @selection-changed="handleSelectionChanged" />
                  <span :class="{ 'font-red': getMessageLength > limitNumberTexts.maxLengths.messages }" >{{ getMessageLength }}/{{ limitNumberTexts.maxLengths.messages }}</span>
                </td>
              </tr>

              <tr>
                <th scope="row">
                  <label for="label5">{{ $_lang('LLM_TESTER.WORD.SUPPORTING_DOCUMENT', { defaultValue: '검색 문서' }) }}</label>
                </th>
                <td>
                  <!-- 파일 목록 DropDown -->
                  <dx-drop-down-box
                    :disabled="!formData.search_chunks"
                    :opened="dropdownOpened"
                    :on-opened="() => (dropdownOpened = true)"
                    :on-closed="() => (dropdownOpened = false)"
                    :width="710"
                    class="dis_inblock mar_bo5 docs-box"
                    content-template="dropDownContentTemplate"
                  >
                    <template #dropDownContentTemplate>
                      <div>
                        <!-- 파일 검색 결과 List -->
                        <dx-list
                          :data-source="indexFileList"
                          display-expr="name"
                          :search-enabled="true"
                          search-mode="contains"
                          search-expr="name"
                          selection-mode="single"
                          page-load-mode="scrollBottom"
                          :no-data-text="getNoDataText"
                          :height="300"
                          @selectionChanged="onItemSelected"
                        />
                      </div>
                    </template>
                  </dx-drop-down-box>

                  <dx-check-box
                    :text="$_lang('LLM_TESTER.WORD.SEARCH_FLAG', { defaultValue: '검색 여부' })"
                    class="mar_le10 dis_inblock"
                    v-model="formData.search_chunks"
                    @value-changed="onChangedSearchChunks"
                  />
                  <esp-dx-data-grid :data-grid="documentDataGrid" />
                </td>
              </tr>

              <tr>
                <th scope="row">
                  <label for="label5">{{ $_lang('LLM_TESTER.WORD.REPEAT_COUNT', { defaultValue: '반복 횟수' }) }} <span class="icon_require">필수항목</span></label>
                </th>
                <td>
                  <dx-number-box
                    v-model="formData.repeat_count"
                    :min="1"
                    :max="100"
                    :show-spin-buttons="true"
                    validation-message-position="right"
                    class="mar_ri10"
                    :width="200"
                    :styling-mode="stylingMode"
                    format="#"
                  >
                    <dx-validator>
                      <dx-required-rule validation-group="validationGroupName"
                                      :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS',
                                    { value: $_lang('LLM_TESTER.WORD.REPEAT_COUNT', {defaultValue: '반복 횟수'}), defaultValue: '[반복 횟수] 은/는 필수값 입니다' })" />
                    </dx-validator>
                  </dx-number-box>
                </td>
              </tr>

              <tr>
                <th scope="row">
                  <label for="label5">{{ $_lang('LLM_TESTER.WORD.SYNTHETIC_OPTION', { defaultValue: '변형 옵션' }) }}</label>
                </th>
                <td>
                <span class="check-type col">
                  <dx-check-box :text="$_lang('LLM_TESTER.WORD.SUBSTANTIVE_SYNONYM', { defaultValue: '체언 유의어' })" v-model="formData.substantive_synonym" />
                </span>
                  <span class="check-type col">
                  <dx-check-box :text="$_lang('LLM_TESTER.WORD.SUBSTANTIVE_TYPO', { defaultValue: '체언 오타' })" v-model="formData.substantive_typo" />
                </span>
                  <span class="check-type col">
                  <dx-check-box :text="$_lang('LLM_TESTER.WORD.PREDICATE_SYNONYM', { defaultValue: '용언 유의어' })" v-model="formData.predicate_synonym" />
                </span>
                  <span class="check-type col">
                  <dx-check-box :text="$_lang('LLM_TESTER.WORD.PREDICATE_TYPO', { defaultValue: '용언 오타' })" v-model="formData.predicate_typo" />
                </span>
                  <span class="check-type col">
                  <dx-check-box :text="$_lang('LLM_TESTER.WORD.PREDICATE_ENDING', { defaultValue: '용언 어미' })" v-model="formData.predicate_ending" />
                </span>
                </td>
              </tr>

              <tr>
                <th scope="row">
                  <label for="label5">{{ $_lang('LLM_TESTER.WORD.SYNTHETIC_COUNT', { defaultValue: '변형 횟수' }) }} <span class="icon_require">필수항목</span></label>
                </th>
                <td>
                  <dx-number-box
                    v-model="formData.synthetic_query_count"
                    :disabled="isCheckedSyntheticOptions"
                    :min="1"
                    :max="100"
                    :show-spin-buttons="true"
                    validation-message-position="right"
                    class="mar_ri10"
                    :width="200"
                    :styling-mode="stylingMode"
                    format="#"
                  >
                    <dx-validator>
                      <dx-required-rule validation-group="validationGroupName"
                                      :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS',
                                    { value: $_lang('LLM_TESTER.WORD.SYNTHETIC_COUNT', {defaultValue: '변형 횟수'}), defaultValue: '[변형 횟수] 은/는 필수값 입니다' })" />
                    </dx-validator>
                  </dx-number-box>
                </td>
              </tr>

              <tr>
                <th scope="row">
                  <label for="label5">{{ $_lang('LLM_TESTER.WORD.SYSTEM_PROMPT', { defaultValue: '시스템 프롬프트' }) }}</label>
                </th>
                <td class="search-container">
                  <dx-switch v-model="systemPromptFlag" class="mar_ri10" />
                  <dx-select-box
                    :disabled="!systemPromptFlag"
                    :placeholder="$_lang('LLM_TESTER.WORD.SELECT_PROMPT', { defaultValue: '프롬프트 선택' })"
                    v-model="formData.system_prompt_id"
                    :styling-mode="stylingMode"
                    :style="{ marginRight: '9px' }"
                    :height="30"
                    width="200"
                    :items="systemPromptList"
                    value-expr="id"
                    display-expr="name"
                  >
                    <dx-validator validation-group="validationGroupName">
                      <dx-required-rule v-if="systemPromptFlag"
                                      :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS',
                                  { value: $_lang('LLM_TESTER.WORD.PROMPT', {defaultValue: '프롬프트'}), defaultValue: '[프롬프트] 은/는 필수값 입니다' })"
                      />
                    </dx-validator>
                  </dx-select-box>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </dx-scroll-view>
    </template>

    <dx-toolbar-item
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :visible="true"
      :options="{
            elementAttr: { class: 'default filled txt_S medium' },
            text: this.$_lang('COMPONENTS.SAVE', { defaultValue: '저장' }),
            width: '120',
            height: '40',
            useSubmitBehavior: true,
            onClick: saveModal,
          }"
    />
    <dx-toolbar-item
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :visible="true"
      :options="{
            elementAttr: { class: 'white filled txt_S medium' },
            text: this.$_lang('COMPONENTS.CANCEL', { defaultValue: '취소' }),
            width: '120',
            height: '40',
            onClick: closeModal,
          }"
    />
  </dx-popup>
</template>

<script>
  import DxList from 'devextreme-vue/list';
  import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
  import { DxNumberBox } from "devextreme-vue/number-box";
  import { DxCheckBox } from "devextreme-vue/check-box";
  import DxDropDownBox from 'devextreme-vue/drop-down-box';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';
  import EspDxDataGridV2 from '@/components/devextreme/esp-dx-data-grid-v2.vue';
  import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";
  import { cloneObj, isEmpty } from "@/utils/common-lib";
  import { DxScrollView } from "devextreme-vue/scroll-view";
  import { DxTagBox } from "devextreme-vue/tag-box";
  import DxSwitch from "devextreme-vue/switch";
  import { DxSelectBox } from 'devextreme-vue/select-box';

  export default {
    components: {
      DxTagBox,
      DxScrollView,
      DxPopup,
      DxToolbarItem,
      EspDxDataGrid,
      EspDxDataGridV2,
      DxCheckBox,
      DxNumberBox,
      DxDropDownBox,
      DxList,
      DxValidator,
      DxRequiredRule,
      DxSwitch,
      DxSelectBox,
    },

    props: {
      isOpen: Boolean,
      project_id: String,
      indexFileListOrg: Array,
      systemPromptList: Array,
      saveType: String,
      contentData: Object,
      categoryList: Array,
    },

    watch: {
      formData: {
        handler(val) {
          let filterData = val;
          if (filterData) {
            this.$emit('input', filterData);
          }
        },
        deep: true,
      },
    },

    data() {
      return {
        messageDataGrid: {
          refName: 'messageDataGrid',
          keyExpr: 'id',
          showBorders: true, //border 유무
          height: '300',
          width: '800',
          editing: {
            allowUpdating: true,
            allowDeleting: false,
            allowAdding: false,
            mode: 'cell',
          },
          excel: {
            autoFilterEnabled: false, // 엑셀 필터 사용 유무
          },
          loadPanel: {
            enabled: false, // 로딩바 표시 유무
          },
          filterRow: { visible: false }, // 행 검색 필터
          page: { enabled: false }, // paging, pager 설정(하나라 합침)
          allowColumnResizing: false,
          scrolling: {
            scrollByContent: false,
          },
          selecting: { // 로우 선택 설정
            mode: 'multiple', // 행 단일/멀티 선택 타입 : ['single', 'multiple']
            allowSelectAll: false,
          },
          selectedRowKeys: [],
          disableTotalCount: true, // 상단 조회 건수 노출 설정값
          showActionButtons: { // 상단 버튼 노출 설정값
            update: false, // 추가/저장/취소 한번에 설정 / true이거나 생략시 add, save, cancel 개별 설정 사용 가능 / true가 기본
            add: false, // 추가 개별 설정 / update 옵션이 true이거나 생략시 사용 가능 / true가 기본
            save: false, // 저장 개별 설정 / update 옵션이 true이거나 생략시 사용 가능 / true가 기본
            cancel: false, // 취소 개별 설정 / update 옵션이 true이거나 생략시 사용 가능 / true가 기본
            delete: false, // 삭제 / true가 기본
            customButtons: [ // 커스텀 버튼 / []가 기본
              {
                widget: 'dxButton',
                options: {
                  text: this.$_lang('COMPONENTS.ADD', { defaultValue: '추가' }),
                  elementAttr: { class: 'btn_XS default filled add1' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.messageDataGrid.dataSource.push({ id: crypto.randomUUID(), role: 'user', content: '' }, { id: crypto.randomUUID(), role: 'assistant', content: '' });
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: this.$_lang('COMPONENTS.DELETE', { defaultValue: '삭제' }),
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.handleDeleteMessage();
                  },
                },
                location: 'before',
              },
            ],
          },
          dataSource: [], //dataSource 설정
          columns: [
            {
              caption: '역할',
              i18n: 'LLM_TESTER.WORD.ROLE',
              dataField: 'role',
              width: 100,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                let text = '';
                if (options.value === 'user') {
                  text = this.$_lang('LLM_TESTER.WORD.QUERY', { defaultValue: '질의' });
                } else if (options.value === 'assistant') {
                  if (options.rowIndex === options.component.getVisibleRows().length - 1) {
                    text = this.$_lang('LLM_TESTER.WORD.CORRECT_ANSWER', { defaultValue: '올바른 답변' });
                  } else {
                    text = this.$_lang('LLM_TESTER.WORD.ANSWER', { defaultValue: '답변' });
                  }
                }
                container.append(text);
              },
            },
            {
              caption: '내용',
              i18n: 'LLM_TESTER.WORD.CONTENT',
              dataField: 'content',
              height: 40,
              alignment: 'left', // left center right
              visible: true,
              allowEditing: true,
              allowSorting: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              validationRules: [
                {
                  type: 'custom',
                  validationCallback: e => {
                    let dataSource = this.messageDataGrid.dataSource;
                    return !(dataSource[dataSource.length - 1].id !== e.data.id && isEmpty(e.data.content));
                  },
                  message: this.$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS', {
                    value: this.$_lang('LLM_TESTER.WORD.QUERY_ANSWER_CONTENT', '[질의/답변 내용]'),
                    defaultValue: '[질의/답변 내용] 은/는 필수값 입니다.',
                  }),
                },
              ],
            },
          ]
        },

        documentDataGrid: {
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: true, //border 유무
          showRowLines: true, //컬럼 가로선 유무
          showColumnHeaders: true,
          disableTotalCount: true,
          dataSource: [],
          width: '800',     // 주석처리시 100%
          height: '200',    // 주석처리시 100%
          remoteOperations: { filtering: false, sorting: false, grouping: false, paging: false },
          pager: { visible: false },
          filterRow: { visible: false },
          headerFilter: { visible: false },
          editing: { allowUpdating: false, allowDeleting: false, allowAdding: false },
          customEvent: {},
          dragging: {
            sortColumn: 'sort',
            allowReordering: false,
            dropFeedbackMode: 'push', // 설정하면 dragging 할때 기존리스트가 아래로 움직이는 효과
          },
          columns: [
            {
              caption: '순서',
              i18n: 'COMPONENTS.ORDER',
              width: 80,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                container.append(options.rowIndex + 1);
              },
            },
            {
              caption: '파일명',
              i18n: 'LLM_TESTER.WORD.FILE_NAME',
              dataField: 'name',
              height: 40,
              alignment: 'left', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },
            {
              caption: '고정 검색',
              i18n: 'LLM_TESTER.WORD.FIX_SEARCHING',
              dataField: 'is_filter',
              width: 100,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              cellTemplate: (container, options) => {
                const checkbox = new DxCheckBox({
                  propsData: {
                    value: options.data?.is_filter,
                    disabled: false,
                    onValueChanged: e => {
                      options.data.is_filter = e.value;
                    },
                  },
                });
                checkbox.$mount();
                container.append(checkbox.$el);
              },
            },
            {
              caption: '삭제',
              i18n: 'COMPONENTS.DELETE',
              width: 80,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                let buttonTag = document.createElement('button');
                buttonTag.className = 'btn-icon close';
                buttonTag.setAttribute('type', 'button');
                buttonTag.addEventListener('click', () => {
                  this.onRemoveDocs(options.data);
                });
                container.append(buttonTag);
              },
            },
          ]
        },

        modal: {
          title: this.saveType === 'add'
            ? this.$_lang('LLM_TESTER.WORD.QUERY_ADD', { defaultValue: '질의 세트 추가' })
            : this.$_lang('LLM_TESTER.WORD.QUERY_UPDATE', { defaultValue: '질의 세트 수정' }),
          minWidth: '1100',
          width: '1100',
          maxHeight: '100vh',
          height: '820',
          dragEnabled: true,
          resizeEnabled: false,
          closeOnOutsideClick: false,
          showCloseButton: true,
        },

        dropdownOpened: false,  // 드롭다운 열림 여부

        tagBoxValue: [],

        indexFileList: [],

        stylingMode: 'outlined', //[outlined, filled, underlined]

        formData: {
          project_id: null,
          categories: [],                 // 질의 유형
          messages: [{ id: crypto.randomUUID(), role: 'user', content: '' }, { id: crypto.randomUUID(), role: 'assistant', content: '' }], // 질의&답변 리스트
          search_chunks: false,          // 검색 문서 선택 여부
          contexts: [],                 // 검색 문서 리스트 (knowledge_id:파일 IDs, is_filter: 고정 검색 여부)
          repeat_count: 1,              // 반복 횟수
          substantive_synonym: false,   // 체언 유의어 선택 여부
          substantive_typo: false,      // 체언 오타 선택 여부
          predicate_synonym: false,     // 용언 유의어 선택 여부
          predicate_typo: false,        // 용언 오타 선택 여부
          predicate_ending: false,      // 용언 어미 선택 여부
          synthetic_query_count: 1,     // 변형 횟수
          system_prompt_id: null,       // 시스템 프롬프트
        },

        systemPromptFlag: false,      // 시스템 프롬프트 여부

        limitNumberTexts: {
          textLengths: {
            category: 0
          },
          maxLengths: {
            category: 20,
            messages: 2000,
          },
        },
      };
    },

    computed: {
      /** @description 메시지 길이 반환 */
      getMessageLength() {
        return this.messageDataGrid.dataSource.reduce((sum, item) => {
          return sum + (item.content ? item.content.length : 0);
        }, 0);
      },

      /** @description 검색 문서 리스트 NoDataText */
      getNoDataText() {
        if (this.$_commonlib.isEmpty(this.indexFileList) && this.$_commonlib.isEmpty(this.indexFileListOrg)) {
          return this.$_lang('LLM_TESTER.MESSAGE.PLEASE_ADD_INDEX', { defaultValue: '등록된 인덱스가 없습니다. 새 인덱스를 추가해주세요.' });
        } else if (this.$_commonlib.isEmpty(this.indexFileList)) {
          return this.$_lang('LLM_TESTER.MESSAGE.DONT_EXIST_FILE', { defaultValue: '선택할 검색 문서가 없습니다.' });
        } else {
          return this.$_lang('LLM_TESTER.MESSAGE.DONT_EXITS_SEARCH_RESULT', { defaultValue: '검색 결과가 존재하지 않습니다.' });
        }
      },

      /** @description 변형 옵션 선택 여부 */
      isCheckedSyntheticOptions() {
        return !this.formData.substantive_synonym && !this.formData.substantive_typo &&
          !this.formData.predicate_synonym && !this.formData.predicate_typo &&
          !this.formData.predicate_ending;
      }
    },

    methods: {
      /** @description 모달 저장 */
      async saveModal(e) {
        if (!e.validationGroup.validate().isValid) {
          e.validationGroup.validate().validators.forEach((validator) => {
            validator.$element().addClass("dx-state-focused");
          });
          return;
        }

        const dataSource = this.messageDataGrid.dataSource;
        const gridRef = this.$refs.messageDataGrid.getInstance;

        await gridRef.saveEditData();
        if (gridRef.hasEditData()) {
          return;
        }

        let isValid = true;
        dataSource.some((item, index) => {
          if (isEmpty(item.content) && !(dataSource.length - 1 === index)) {
            gridRef.editCell(index, 'content');
            gridRef.cellValue(index, 'content', '');
            isValid = false;
            return true;
          }
        });

        if (!isValid) {
          return;
        }

        if (this.getMessageLength > this.limitNumberTexts.maxLengths.messages) {
          this.$_Toast(this.$_lang('LLM_TESTER.MESSAGE.LIMIT_MESSAGE',
                    {
                              limitNumber: this.limitNumberTexts.maxLengths.messages,
                              defaultValue: `[메시지]의 [질의/답변]의 길이는 ${ this.limitNumberTexts.maxLengths.messages }자를 초과할 수 없습니다.`
                            }));
          return false;
        }

        const payload = {
          actionName: this.saveType === 'add' ? 'LLM_TESTER_QUERY_SET_INSERT' : 'LLM_TESTER_QUERY_SET_UPDATE',
          data: this.formData,
          loading: true,
        };

        this.formData.contexts = this.documentDataGrid.dataSource.map(item => ({
          knowledge_id: item.id,
          is_filter: item.is_filter
        }));

        const res = await this.CALL_LLM_TESTER_API(payload);
        if (res.status === 200) {
          this.$emit('saveModal');
        } else {
          this.$_Msg(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          return false;
        }
      },

      /** @description 질의 유형 TagBox 값 변경 Handler */
      handleTagValueChanged(e) {
        this.formData.categories = e.value;
        this.limitNumberTexts.textLengths.category = this.formData.categories.at(-1) ? this.formData.categories.at(-1).length : 0;
      },

      /** @description 질의 유형 TagBoxKey Up Handler */
      handleKeyUpTabBox(e) {
        this.limitNumberTexts.textLengths.category = e.event.currentTarget.value.length;
      },

      /** @description 메시지 Selection 변경 Handler */
      handleSelectionChanged(e) {
        this.messageDataGrid.selectedRowKeys = e.selectedRowKeys;
        const dataSource = this.messageDataGrid.dataSource;
        const selectFl = e.currentSelectedRowKeys.length!==0;
        const rowKey = selectFl ? e.currentSelectedRowKeys[0] : e.currentDeselectedRowKeys[0];

        for (let i = 0; i < dataSource.length; i++) {
          if (dataSource[i].id === rowKey) {
            let rowIndex = dataSource[i].role === 'user' ? i+1 : i-1;

            if (selectFl) {
              if (!this.messageDataGrid.selectedRowKeys.includes(dataSource[rowIndex].id)) {
                this.messageDataGrid.selectedRowKeys.push(dataSource[rowIndex].id);
              }
            } else {
              const idx = this.messageDataGrid.selectedRowKeys.indexOf(dataSource[rowIndex].id);
              if (idx !== -1) {
                this.messageDataGrid.selectedRowKeys.splice(idx, 1);
              }
            }
            break;
          }
        }
      },

      /** @description 메시지 Delete Handler */
      handleDeleteMessage() {
        if (this.messageDataGrid.selectedRowKeys.length === 0) {
          return this.$_Toast(this.$_lang('PLEASE_SELECT_TARGET', { defaultValue: '대상을 선택해주세요.' }));
        } else if (this.messageDataGrid.selectedRowKeys.length === this.messageDataGrid.dataSource.length) {
          return this.$_Toast(this.$_lang('', { defaultValue: '최소 1개이상의 질문/답변은 필수 입니다.' }));
        }
        this.messageDataGrid.dataSource = this.messageDataGrid.dataSource.filter(item => !this.messageDataGrid.selectedRowKeys.includes(item.id));
        this.formData.messages = this.messageDataGrid.dataSource;
        this.messageDataGrid.selectedRowKeys = [];
      },

      /** @description 검색 여부 체크값 변경 */
      async onChangedSearchChunks(e) {
        if (!e.value && this.documentDataGrid.dataSource.length !== 0) {
          if (await this.$_Confirm(this.$_lang('LLM_TESTER.MESSAGE.UNSELECT_SEARCH_DOCUMENT_ALERT', { defaultValue: '검색 여부 해제시 검색 문서가 초기화 됩니다. <br/>해제 하시겠습니까?' }))) {
            this.documentDataGrid.dataSource = [];
            this.indexFileList = cloneObj(this.indexFileListOrg);
          } else {
            this.formData.search_chunks = true;
          }
        }
      },

      /** @description 드롭다운 아이템 선택 */
      onItemSelected({ addedItems }) {
        if (addedItems.length > 0) {
          const selectedItem = addedItems[0];
          selectedItem.sort = this.documentDataGrid.dataSource.length === 0 ? 1 : Math.max(...this.documentDataGrid.dataSource.map((obj) => obj["sort"])) + 1;
          selectedItem.is_filter = false;
          this.documentDataGrid.dataSource.push(selectedItem);
          this.dropdownOpened = false;  // 선택 후 드롭다운 닫기
          this.indexFileList = this.indexFileList.filter(d => d.id !== selectedItem.id);
        }
      },

      /** @description 검색 문서 리스트 삭제 */
      onRemoveDocs(data) {
        this.documentDataGrid.dataSource = this.documentDataGrid.dataSource.filter(d => d.id !== data.id);
        this.indexFileList.push(data);
      },

      /** @description 모달 닫기 */
      closeModal() {
        this.$emit('closeModal');
      },
    },

    /** @description 라이프사이클 created 시 호출되는 메서드 */
    created() {
      this.indexFileList = cloneObj(this.indexFileListOrg);
      this.formData.project_id = this.project_id;
      this.formData = JSON.parse(JSON.stringify({ ...this.formData, ...this.contentData }));
      if (this.contentData){
        this.tagBoxValue = cloneObj(this.formData.categories);
      }

      this.formData.contexts.forEach(context => {
        const file = this.indexFileList.filter(d => d.id === context.knowledge_id);
        this.indexFileList = this.indexFileList.filter(d => d.id !== context.knowledge_id);
        file[0].is_filter = context.is_filter;
        this.documentDataGrid.dataSource.push(file[0]);
      });

      this.messageDataGrid.dataSource = this.formData.messages;

      this.systemPromptFlag = !isEmpty(this.formData.system_prompt_id);
    },
  };
</script>

<style lang="scss" scoped>
  ::v-deep {
    .dx-placeholder {
      padding-left: 20px;
    }

    .item-context > div {
      padding-left: 10px;
      float: left;
    }

    .dx-revert-button {
      display: none;
    }

    .dx-datagrid .dx-row-lines > td {
      border-bottom: 1px solid #efefef !important;
    }

    .docs-box {
      border-radius: 4px;
      border: 1px solid #dcdcdc;
      background-color: transparent;
    }

    .dx-texteditor.dx-editor-filled.dx-state-hover::after {
      border-bottom: unset;
    }

    .dx-tagbox.dx-editor-filled .dx-tag-container, .dx-tagbox.dx-editor-outlined .dx-tag-container {
      padding: 5px 8px 2px;
    }
  }

  .page-sub-box {
    padding: 0 !important;
  }

  .font-red {
    color: red;
  }
</style>
