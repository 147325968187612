<!--
  PACKAGE_NAME : src/pages/report/config/wizard
  FILE_NAME : detail-invisible-column.vue
  AUTHOR : kwmoon
  DATE : 2025. 4. 1.
  DESCRIPTION :
-->
<template>
  <div>
    <h3 class="text-xl font-medium ml-2 mb-2">항목</h3>
    <DxDataGrid
      class="grid-box"
      id="grid"
      ref="grid"
      :data-source="columns"
      :show-borders="false"
      :show-column-headers="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="false"
      :allow-column-reordering="true"
      :no-data-text="emptyDataNotice()"
      :selected-row-keys="grid.selectedItemKeys"
      @selection-changed="grid.selectionChanged"
      width="100%"
      height="calc(100vh - 380px)"
    >
      <DxFilterRow :visible="true">
        <DxOperationDescriptions contains="포함" />
      </DxFilterRow>
      <DxPaging :page-size="2000" />
      <DxRowDragging :on-add="onDropFromVisible" group="report-columns" />
      <DxColumn caption="항목명" data-field="caption" :fixed="false" alignment="left" :visible="true" cell-template="titleTemplate" />

      <template #titleTemplate="{ data }">
        <a class="font-bold" @click="openColumnModal(data)">
          {{ data.value }}
        </a>
      </template>
      <DxColumn caption="컬럼명" 기 data-field="dataField" :fixed="false" alignment="left" />
      <DxColumn caption="적용" cell-template="moveTemplate" :fixed="false" alignment="center" width="80" />
      <template #moveTemplate="{ data }">
        <DxButton
          text="선택"
          type="button"
          class="btn_XS white light_filled"
          :width="50"
          :height="30"
          @click="onClickRemoveItem({ data })"
        />
      </template>
    </DxDataGrid>
  </div>
</template>
<script>
  import { DxColumn, DxDataGrid, DxFilterRow, DxOperationDescriptions, DxPaging, DxRowDragging } from 'devextreme-vue/data-grid';
  import { DxButton } from 'devextreme-vue/button';

  export default {
    name: 'detail-invisible-column',
    components: { DxRowDragging, DxOperationDescriptions, DxFilterRow, DxButton, DxDataGrid, DxPaging, DxColumn },
    props: {
      columns: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        grid: {
          dataSource: [],
          selectedItemKeys: [],
          selectionChanged: data => {
            this.grid.selectedItemKeys = data.selectedRowKeys;
          },
        },
      };
    },
    computed: {},
    methods: {
      /** 데이터 없을 때 텍스트 */
      emptyDataNotice() {
        if (this.grid.dataSource.length === 0) {
          return '미사용 항목이 존재하지 않습니다.';
        }
      },

      /** visible 항목이 드래그 앤 드롭으로 추가 이벤트 발생 시 */
      onDropFromVisible(e) {
        this.$emit('drop-column', 'invisible', e);
      },

      /**
       * 사용 항목으로 이동
       * */
      onClickRemoveItem({ data }) {
        const draggedItem = data.data;
        this.$emit('drop-column', 'visible', {
          itemData: draggedItem,
          isClick: true,
        });
      },

      /** 항목 변경 이벤트 */
      onDragChangeOrder(e) {
        this.$emit('re-order', 'visible', e);
      },

      /**
       * 보고서 항목 수정 팝업 열기
       */
      openColumnModal({ data }) {
        this.$emit('open-column', true, 'invisible', data);
      },
    },
    updated() {},
    created() {},
    mounted() {},
    activated() {},
    deactivated() {},
    beforeDestroy() {},
    destroyed() {},
  };
</script>
<style lang="scss" scoped></style>
