<template>
	<div class="container content-themes">
		<div class="main clearfix ui-glid-box per33 ui-33">
			<table class="table_form line-bin">
				<thead class="sub_title_txt">
					<tr>
						<td colspan="3">
							<h2>이미지 {{ imageData.id ? '수정' : '등록' }}</h2>
						</td>
					</tr>
				</thead>
				<colgroup>
					<col style="width:250px;">
					<col style="width:auto;">
				</colgroup>

				<tbody>
					<tr>
						<th scope="row">
							<label for="label01">
								<span>사이트</span>
								<span class="icon_require">필수항목</span>
							</label>
						</th>
						<td class="clearfix">
							<DxSelectBox
								v-model="imageData.siteId"
								placeholder="사이트 선택"
								:items="infoPushSiteList"
								display-expr="siteNm"
								value-expr="siteId"
								:styling-mode="stylingMode"
								:width="300"
								:height="30"
								:disabled="imageData.id ? true : false"
							/>
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label for="label01">
								<span>URL</span>
								<span class="icon_require">필수항목</span>
							</label>
						</th>
						<td class="clearfix">
							<DxTextBox
								v-model="imageData.url"
								:stylingMode="stylingMode"
								class="mar_ri10 alB"
								:width="300"
								placeholder="URL을 입력해주세요."
								:disabled="imageData.id ? true : false"
							/>
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label for="label01">
								<span>이미지</span>
								<span class="icon_require">필수항목</span>
							</label>
						</th>
						<td class="clearfix">
							<div
								id="dropzone-external"
								class="flex-box"
								:class="[isDropZoneActive ? 'dx-theme-accent-as-border-color dropzone-active' : 'dx-theme-border-color']"
							>
								<img
									v-if="imageSource"
									id="dropzone-image"
									:src="imageSource"
									alt=""
								/>
								<div
									v-else
									id="dropzone-text"
									class="flex-box"
								>
									<span>이미지 선택 또는 Drag & Drop</span>
								</div>
								<DxFileUploader
									ref="imageRef"
									accept="image/*"
									:visible="false"
									dialog-trigger="#dropzone-external"
									drop-zone="#dropzone-external"
									@drop-zone-enter="onDropZoneEnter"
									@drop-zone-leave="onDropZoneLeave"
									@value-changed="(e) => onFileChanged(e)"
									upload-mode="useButton"
									:allowed-file-extensions="allowedFileExtensions"
								/>
							</div>
							<DxButton
								v-if="imageSource"
								class="close-btn"
								icon="close"
								:stylingMode="stylingMode"
								:onClick="() => onFileClear()"
							/>
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label for="label01">
								<span>설명</span>
								<span class="icon_require">필수항목</span>
							</label>
						</th>
						<td class="clearfix">
							<DxTextBox
								v-model="imageData.description"
								:stylingMode="stylingMode"
								class="mar_ri10 alB"
								:width="300"
								placeholder="이미지 설명"
							/>
						</td>
					</tr>
					<tr>
						<th scope="row" rowspan="3">
							<label for="label01">
								<span>텍스트</span>
							</label>
						</th>
						<td class="clearfix">
							<DxTextBox
								:stylingMode="stylingMode"
								class="mar_ri10 alB"
								:width="240"
								:disabled="true"
								:value="text1"
							/>
							<DxButton
								:text="imageData.textId1 ? '삭제' : '추가'"
								:width="50"
								:height="31"
								class="white filled txt_S medium"
								:onClick="() => onChangeContent('textId1')"
							/>
						</td>
					</tr>
					<tr>
						<td class="clearfix">
							<DxTextBox
								:stylingMode="stylingMode"
								class="mar_ri10 alB"
								:width="240"
								:disabled="true"
								:value="text2"
							/>
							<DxButton
								name="textId2"
								:text="imageData.textId2 ? '삭제' : '추가'"
								:width="50"
								:height="31"
								class="white filled txt_S medium"
								:onClick="() => onChangeContent('textId2')"
							/>
						</td>
					</tr>
					<tr>
						<td class="clearfix">
							<DxTextBox
								:stylingMode="stylingMode"
								class="mar_ri10 alB"
								:width="240"
								:disabled="true"
								:value="text3"
							/>
							<DxButton
								name="textId3"
								:text="imageData.textId3 ? '삭제' : '추가'"
								:width="50"
								:height="31"
								class="white filled txt_S medium"
								:onClick="() => onChangeContent('textId3')"
							/>
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label for="label01">
								<span>순서</span>
							</label>
						</th>
						<td class="clearfix">
							<DxNumberBox
								v-model="imageData.imgOrd"
								:stylingMode="stylingMode"
								class="mar_ri10 alB"
								:width="300"
								placeholder="순서"
							/>
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label for="label01">
								<span>사용여부</span>
							</label>
						</th>
						<td class="clearfix">
							<DxSwitch v-model="viewCd"/>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<DxPopup
			v-model="modal.isOpened"
			:show-title="true"
			:title="modal.initData ? modal.initData.title : null"
			:width="modal.initData ? modal.initData.width : null"
			:height="modal.initData ? modal.initData.height : null"
			:drag-enabled="true"
			:resize-enabled="true"
			:show-close-button="true"
			:hide-on-outside-click="false"
			:visible="modal.isOpened"
			@hiding="isOpenModal(false)"
		>
			<template #content>
				<div>
					<component
						ref="modalRef"
						v-if="modal.sendData"
						:is="modal.currentComponent"
						:modalData="modal.sendData"
						:isModal="modal.isModal"
					/>
				</div>
			</template>

			<DxToolbarItem
				widget="dxButton"
				toolbar="bottom"
				location="center"
				:options="{
						elementAttr: {
							class: 'default filled txt_S medium',
						},
						text: modal.initData.buttons.save.text,
						width: '120',
						height: '40',
						onClick: () => selectText()
					}"
			/>

			<DxToolbarItem
				widget="dxButton"
				toolbar="bottom"
				location="center"
				:options="{
						elementAttr: {
							class: 'white filled txt_S medium',
						},
						text: modal.initData.buttons.cancel.text,
						width: '120',
						height: '40',
						onClick: () => isOpenModal(false)
					}"
			/>
		</DxPopup>
		<section class="terms bottom-btn-box">
			<div class="main">
				<div class="bottom-btn-wrap">
					<DxButton text="저 장" :width="120" :height="40" class="default filled txt_S medium" :onClick="() => onSave()" />
					<DxButton text="취 소" :width="120" :height="40" class="white filled txt_S medium" :onClick="() => $_goPrePage()" />
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import { DxButton } from 'devextreme-vue/button';
import { DxFileUploader } from 'devextreme-vue/file-uploader';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxNumberBox } from 'devextreme-vue/number-box';
import { DxCustomRule, DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxSwitch } from 'devextreme-vue/switch';
import ModalChangeContent from '../text.vue';
import { isSuccess } from '../../../../utils/common-lib';


let vm = this;

export default {
	components: {
		DxToolbarItem,
		DxCustomRule, DxPopup,
		DxRequiredRule, DxValidator,
		DxTextBox,
		DxNumberBox,
		DxSelectBox,
		DxButton,
		DxFileUploader,
		DxSwitch,
		ModalChangeContent
	},
	props: {},
	watch: {
		'imageData.textId1': function(newVal) {
			if(newVal) {
				vm.getText(newVal).then((textTitle) => vm.text1 = textTitle);
			} else {
				vm.text1 = null;
			}
		},
		'imageData.textId2': function(newVal) {
			if(newVal) {
				vm.getText(newVal).then((textTitle) => vm.text2 = textTitle);
			} else {
				vm.text2 = null;
			}
		},
		'imageData.textId3': function(newVal) {
			if(newVal) {
				vm.getText(newVal).then((textTitle) => vm.text3 = textTitle);
			} else {
				vm.text3 = null;
			}
		},
		file: function(newVal) {
			if(newVal) {
				vm.imageData.image = newVal.name;
				vm.onFilePreview(newVal)
			} else {
				vm.onFileClear();
			}

		}
	},
	data() {
		return {
			infoPushSiteList: [],
			viewCd: true,
			modal: {
				isOpened: false,
				currentComponent: null,
				initData: {
					buttons: {
						save: {

						},
						cancel: {

						}
					}
				},
				sendData: null,
			},
			imageSource: '',
			isDropZoneActive: false,
			stylingMode: 'outlined',
			files: [],
			config: {
				pageSetting: {
					//pageData pageSetting 관련
					config: {},
				},
			},
			text1: null,
			text2: null,
			text3: null,
			imageData: {},
			file: null,
			allowedFileExtensions: ['.jpg', '.jpeg', '.gif', '.png'],
		};
	},
	computed: {},
	methods: {
		/** @description: 데이터 저장 메서드 */
		async onSave() {
			if(!this.imageData.siteId) {
				this.$_Msg("사이트는 필수입니다.");
				return;
			} else if(!this.imageData.url) {
				this.$_Msg("URL은 필수입니다.");
				return;
			} else if(!this.imageData.image) {
				this.$_Msg("이미지는 필수입니다.");
				return;
			} else if(!this.imageData.description) {
				this.$_Msg("설명은 필수입니다.");
				return;
			}

			if(this.file){
				await this.uploadFile();
			}

			this.imageData.viewCd = this.viewCd ? this.$_enums.common.stringUsedFlag.YES.value : this.$_enums.common.stringUsedFlag.NO.value;

			const payload = {
				actionName: 'INFOPUSH_IMG_UPDATE',
				data: {
					data: [this.imageData]
				}
			};

			const res = await this.CALL_API(payload);
			if (isSuccess(res)) {
				await this.$_Msg("저장되었습니다.");
				this.$_goPrePage();
			}

		},
		/** @description: 파일 업로드 메서드 */
		async uploadFile(){
			const formData = new FormData();
			formData.append('file', this.file);
			formData.append('subFilePathConfigNm', 'INFOPUSH_IMAGE_DIR');

			const payload = {
				actionName: 'FILE_UPLOAD',
				data: formData,
				loading: false,
			}

			const res = await this.CALL_API(payload);
			if(res.status === 200) {
				this.imageData.image = res.data.pop();
			} else {
				this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		/** @description: 선택한 문구의 id를 받아 textTitle 가져오는 메서드 */
		async getText(id) {
			if (id) {
				const payload = {
					actionName: 'INFOPUSH_TEXT_LIST_ALL',
					data: {
						params: {
							id
						}
					}
				};

				const res = await vm.CALL_API(payload);
				if (isSuccess(res)) {
					if(res.data.data?.length) {
						const { textTitle } = res.data.data[0];
						return textTitle;
					}
				}
			}
		},
		/** @description: 문구 모달에서 선택한 데이터를 적용하는 메서드 */
		selectText() {
			const textData = this.$refs.modalRef.getSelectedTextData();
			if(!textData) {
				this.$_Msg("문구를 선택해주세요.");
				return;
			}
			const target = this.modal.target;
			this.imageData[target] = textData.id;
			this.isOpenModal(false);
		},
		/** @description: 팝업이 열렸는지 체크하는 메서드(true: 열림/false: 닫힘) */
		isOpenModal(data) {
			this.modal.isOpened = data;
			if(!data) {
				this.modal.currentComponent = null;
				this.modal.initData = {
					buttons: {
						save: {},
						cancel: {}
					}
				};
				this.modal.target = null;
			}
		},
		/** @description: 팝업 창 열때 이벤트 */
		onOpenModal(componentNm, componentInitData, sendData){
			this.modal.currentComponent = componentNm;  //set dynamic component name in modal body slot
			this.modal.initData = componentInitData;  //set init modal templet
			this.modal.sendData = sendData;
			this.modal.isModal = true;
			this.isOpenModal(true);
		},
		/** @description: 이미지 영역에 이미지를 끌어올 때 메서드 */
		onDropZoneEnter(e) {
			if (e.dropZoneElement.id === 'dropzone-external') {
				this.isDropZoneActive = true;
			}
		},
		/** @description: 이미지 영역에 이미지를 끌어온 마우스가 영역을 벗어나는 메서드 */
		onDropZoneLeave(e) {
			if (e.dropZoneElement.id === 'dropzone-external') {
				this.isDropZoneActive = false;
			}
		},
		/** @description: 업로드 한 파일을 읽어 미리보기로 표현 */
		onFilePreview(file) {
			const fileReader = new FileReader();
			fileReader.onload = () => {
				this.isDropZoneActive = false;
				this.imageSource = fileReader.result;
			};
			fileReader.readAsDataURL(file);
		},
		/** @description: 문구 버튼 메서드 */
		async onChangeContent(target) {
			if(this.imageData[target]) {
				this.imageData[target] = 0;
			} else {
				const sendData = this.imageData;
				this.modal.target = target;
				await this.onOpenModal(
					'ModalChangeContent',
					{
						title: '문구 추가',
						width: '1400',
						height: '700',
						buttons: {
							save: { text: '적용' },
							cancel: { text: '닫기' },
						}
					},
					sendData
				);
			}
		},
		async getInfoPushSiteList() {
			const payload = {
				actionName: 'INFOPUSH_SITE_LIST_ALL',
				data: {
					params: {
            viewFl: this.$_enums.common.stringUsedFlag.YES.value,
						sort: '+siteOrd'
					}
				}
			}
			const res = await this.CALL_API(payload);
			if(isSuccess(res)) {
				if(res.data.data.length) {
					this.infoPushSiteList = res.data.data
				} else {
					this.infoPushSiteList = [];
				}
			}
		},
		/** @description: 파일 변경 시 동작하는 메서드 */
		onFileChanged(e) {
			if(e.value.length > 1) {
				this.$_Msg("한 개의 이미지 파일을 올려주세요.");
				return false;
			}
			this.file = e.value[0];
		},
		/** @description: 이미지 우측 상단에 Close 버튼 동작 메서드 */
		onFileClear() {
			this.imageSource = null;
			this.imageData.image = '';
		},
		/**@description : 라이프사이클 mounted시 호출되는 메서드 */
		mountedData() {},
		createdData() {
			this.getInfoPushSiteList();
			this.imageData = this.$store.getters.getDetailParams?.imageData || { textId1: 0, textId2: 0, textId3: 0 };
			if(this.imageData?.image) {
				this.imageSource = this.$_getAttachFileURL(null, this.imageData.image);
			}

			if(this.imageData?.viewCd) {
				this.viewCd = this.imageData?.viewFl === this.$_enums.common.stringUsedFlag.YES.value;
			}
			vm = this;
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountedData();
	},
	destroyed() {},
};
</script>

<style scoped>
.device-img > img {
	align-self: center;
}

tbody {
    display: table-row-group;
    vertical-align: top;
}

#dropzone-external {
    width: 300px;
    height: 250px;
    background-color: rgba(183, 183, 183, 0.1);
    border-width: 2px;
    border-style: dashed;
    padding: 10px;
}

#dropzone-external > * {
    pointer-events: none;
}

#dropzone-external.dropzone-active {
    border-style: solid;
}

.widget-container > span {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 16px;
}

#dropzone-image {
    max-width: 100%;
    max-height: 100%;
}

#dropzone-text > span {
    font-weight: 100;
    opacity: 0.5;
}

.flex-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.close-btn {
	top: 5px;
    left: 300px;
    position: absolute;
	display: flex;
    border: 1px solid;
}
</style>
