<!--
  PACKAGE_NAME : src/pages/euc/phone/request/history
  FILE_NAME : index.vue
  AUTHOR : jhcho
  DATE : 24. 9. 2.
  DESCRIPTION :
-->
<template>
  <div class="main">
    <esp-dx-data-grid :data-grid="dataGrid" ref="phoneHistoryGrid"/>
  </div>
</template>
<script>
import {isSuccess} from '@/utils/common-lib';
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid-v2.vue";

export default {
  components: {
    EspDxDataGrid
  },
  data() {
    return {
      typeList: [],
      detailTypeList: [],
      stylingMode: 'outlined',
      dataGrid: {
        callApi: 'CALL_EUC_API',
        refName: 'phoneHistoryGrid',
        dataSourceDefaultSortColumn: '-regDt',
        apiActionNm: {
          select: 'EUC_PHONE_HISTORY_SELECT',
        },
        excel: {
          title: '차이니즈월 이력'
        },
        customEvent: {
          initNewRow: true,
        },
        columns: [
          {
            caption: '작업 ID',
            i18n: 'UC.WORD.WORK_ID',
            dataField: 'requestId',
            allowEditing: false,
          },
          {
            caption: '유형',
            i18n: 'UC.WORD.TYPE',
            dataField: 'typeId',
            allowEditing: false,
            lookup: {}
          },
          {
            caption: '상세유형',
            i18n: 'UC.WORD.DETAIL_TYPE',
            dataField: 'detailId',
            allowEditing: false,
            lookup: {}
          },
          {
            caption: '사용자',
            i18n: 'UC.WORD.USER',
            dataField: 'userNo',
            allowEditing: false,
            cellTemplate: (container, options) => {
              const user = [];
              if (options.data?.deptNm && options.data.deptNm !== '-') {
                user.push(options.data.deptNm);
              }
              if (options.data?.userNm && options.data.userNm !== '-') {
                user.push(options.data.userNm);
              }
              if (options.data?.gradeNm && options.data.gradeNm !== '-') {
                user.push(options.data.gradeNm);
              }
              if (options?.value && options.value !== '-') {
                user.push(`(${options.value})`);
              }
              container.append(user.join(' '))
            }
          },
          {
            caption: '녹취여부',
            i18n: 'UC.WORD.REC_FL',
            dataField: 'recordFl',
            allowEditing: false,
            cellTemplate: (container, options) => {
              if (!options?.value) {
                container.append('-')
              } else if (options.value === 'Y') {
                container.append('녹취 사용')
              } else if (options.value === 'N') {
                container.append('녹취 미사용')
              }
            }
          },
          {
            caption: '시작일',
            i18n: 'UC.WORD.START_DATE',
            dataField: 'startDt',
            allowEditing: false,
            calculateCellValue: (data) => {
              if (data?.startDt) {
                return this.formatDt(data.startDt, 'YYYY.MM.DD')
              } else {
                return '-';
              }
            },
          },
          {
            caption: '결과',
            i18n: 'COMPONENTS.RESULT',
            dataField: 'status',
            allowEditing: false,
            cellTemplate: (container, options) => {
              // @TODO : 새탭으로 띄우기 대신 확장형으로 변화 줄 것
              let textContent = '';
              if (options.value === 'COMPLETE') {
                textContent = '작업 완료';
              } else if (options.value === 'APPROVAL') {
                textContent = '작업 대기';
              } else if (options.value === 'RETURN') {
                textContent = '작업 반려';
              } else {
                textContent = '승인 대기';
              }
              const aTag = document.createElement('a');
              aTag.textContent = textContent;
              aTag.href = `/euc/phone/worker/request?requestId=${options.data.requestId}`;
              aTag.target = '_blank';

              aTag.onclick = (event) => {
                event.preventDefault();
                window.open(aTag.href, '_blank');
              };
              container.append(aTag);
            }
          },
          {
            caption: '작업 내용',
            dataField: 'completeComment',
            allowEditing: false,
          },
          {
            caption: '작업자',
            i18n: 'UC.WORD.WORKER',
            dataField: 'workerUserNm',
            allowEditing: false,
            cellTemplate: (container, options) => {
              if (options.value) {
                const worker = [];
                worker.push(options.value);
                if (options.data.workerGradeNm) {
                  worker.push(options.data.workerGradeNm);
                }
                container.append(worker.join(' '))
              } else {
                container.append('-')
              }
            }
          },
          {
            caption: '작업일시',
            i18n: 'UC.WORD.WORK_DATE_TIME',
            dataField: 'completeDt',
            allowEditing: false,
            calculateCellValue: (data) => this.formatDt(data.completeDt, 'YYYY.MM.DD HH:mm:ss'),
          },
          {
            caption: '등록일시',
            i18n: 'COMPONENTS.REGISTRATION_DATE_TIME',
            dataField: 'regDt',
            allowEditing: false,
            calculateCellValue: (data) => this.formatDt(data.regDt, 'YYYY.MM.DD HH:mm:ss'),
          },
        ],
      },
    }
  },
  watch: {
    typeList: {
      handler: function (val) {
        const column = this.dataGrid.columns.find((d) => d.dataField === 'typeId');
        column.lookup = {
          dataSource: val.map((d) => {
            return {
              value: d.id,
              label: d.typeNm
            }
          }),
          valueExpr: 'value',
          displayExpr: 'label',
        }
      },
      deep: false
    },
    detailTypeList: {
      handler: function (val) {
        const column = this.dataGrid.columns.find((d) => d.dataField === 'detailId');
        column.lookup = {
          dataSource: val.map((d) => {
            return {
              value: d.id,
              label: d.detailNm
            }
          }),
          valueExpr: 'value',
          displayExpr: 'label',
        }
      },
      deep: false
    },
    'searchType.customTypes.dayStart'(newVal) {
      const startDate = newVal.replace(/\D/g, '');
      const endDate = this.searchType.customTypes.dayEnd.replace(/\D/g, '');
      if (startDate > endDate) {
        this.searchType.customTypes.dayEnd = startDate;
      }
    },
    'searchType.customTypes.dayEnd'(newVal) {
      const endDate = newVal.replace(/\D/g, '');
      const startDate = this.searchType.customTypes.dayStart.replace(/\D/g, '');
      if (endDate < startDate) {
        this.searchType.customTypes.dayStart = endDate;
      }
    },
  },
  methods: {
    /** @description : 날짜 형식 변환 메서드 */
    formatDt(value, format = 'YYYY.MM.DD HH:mm:ss') {
      if (value) {
        return this.$_commonlib.formatDate(value, 'YYYYMMDDHHmmssSSS', format);
      }
    },
    async selectTypeList() {
      const payload = {
        actionName: 'EUC_PHONE_REQUEST_TYPE_SELECT',
        data: {},
        loading: false,
      }
      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.typeList = res.data.data;
      }
    },
    async selectDetailTypeList() {
      const payload = {
        actionName: 'EUC_PHONE_REQUEST_TYPE_DETAIL_SELECT',
        data: {},
        loading: false,
      }
      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.detailTypeList = res.data.data;
      }
    },
  },
  created() {
    this.selectTypeList();
    this.selectDetailTypeList();
  }
}
</script>
<style scoped>

</style>