import LocalModelConfig from '@/pages/ai/llm-tester/local-model-config.vue';
import LLMTesterList from '@/pages/ai/llm-tester/list.vue';
import LLMWorkingConfigList from '@/pages/ai/llm-tester/work-config/list.vue';
import LLMTestWorkList from '@/pages/ai/llm-tester/test-work/list.vue';
import LLMPlaygroundList from '@/pages/ai/llm-tester/playground/list.vue';
import LLMPlaygroundRunning from '@/pages/ai/llm-tester/playground/running-playground.vue';

export default function ConfigRoutes(commonComponents) {
  return [
    {
      path: '/ai/llm-tester/local-model-config',
      name: 'LocalModelConfig',
      components: { default: LocalModelConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ai/llm-tester/list',
      name: 'LLMTesterList',
      components: { default: LLMTesterList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ai/llm-tester/local-model-config',
      name: 'LLMTesterList',
      components: { default: LLMTesterList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ai/llm-tester/work-config/list',
      name: 'LLMWorkingConfigList',
      components: { default: LLMWorkingConfigList, ...commonComponents },
      meta: { requiresAuth: true, isDetailPage: true },
    },
    {
      path: '/ai/llm-tester/test-work/list',
      name: 'LLMTestWorkList',
      components: { default: LLMTestWorkList, ...commonComponents },
      meta: { requiresAuth: true, isDetailPage: true },
    },
    {
      path: '/ai/llm-tester/playground/list',
      name: 'LLMPlaygroundList',
      components: { default: LLMPlaygroundList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ai/llm-tester/playground/running-playground',
      name: 'LLMPlaygroundRunning',
      components: { default: LLMPlaygroundRunning, ...commonComponents },
      meta: { requiresAuth: true, isDetailPage: true },
    },
  ];
}
