<!--
  PACKAGE_NAME : src\pages\op\user\list.vue
  FILE_NAME : list
  AUTHOR : ycnam
  DATE : 2024-07-10
  DESCRIPTION : 운영관리 > CTI > 사용자관리 화면
-->
<template>
  <div class="container">
    <div class="page-sub-box content-box">
      <esp-dx-data-grid :data-grid="dataGrid" ref="userGrid" @row-click="onRowClick" />
    </div>
    <ModalPageJoinUser
      :isOpen="popup.visible"
      :userData="popup.userData"
      :current-save-type="popup.currentSaveType"
      style="overflow-y: auto"
      saveBtnTxt="선택"
      title="결재자 정보"
      @closeModal="onClosePopup"
      @saveModal="onSavePopup"
    />
  </div>
</template>

<script>
  import ModalPageJoinUser from '@/pages/esp/hr/user/modal-page-join-user.vue';
  import { isSuccess } from '@/utils/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: { EspDxDataGrid, ModalPageJoinUser },
    data() {
      return {
        showForm: false, // 입력폼 표시여부
        openModal: false,
        rightBoxClassName: 'right-box',
        leftBoxClassName: 'left-box',
        labelMode: 'outside',
        labelLocation: 'left',
        labelMinColWidth: 200,
        stylingMode: 'outlined', //outlined, underlined, filled
        popup: {
          visible: false,
          userData: {},
          PopupTemplate: {
            name: 'PopupTemplate',
            contents: {
              title: '사용자 등록',
              buttons: {
                save: { text: '저장' },
                cancel: { text: '취소' },
              },
              width: '2000',
              height: '1200',
            },
          },

          isOpened: false,
          currentComponent: null, // 현재 modal의 modal 명 ( 해당 값을 넘겨줘야 함 )
          currentSaveType: 'INSERT', // 현재 modal이 INSERT인지 UPDATE인지 구분이 필요. 그에 따라 API URL이 변경되어야 함.
          component: {},
          initData: {
            title: '사용자 등록',
          },
        },
        dataGrid: {
          keyExpr: 'id', // 그리드 키값
          refName: 'userGrid',
          excel: {
            title: '상담원 목록',
            autoFilterEnabled: true,
          },
          focusedRowEnabled: true, // 포커스 행 표시 유무
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: '645', // 주석처리시 100%
          apiActionNm: {},
          scrolling: {
            mode: 'standard',
          },
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            cellPrepared: true,
            rowClick: true,
          },
          showActionButtons: {
            excel: false,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '등록',
                  hint: '사용자 등록',
                  elementAttr: { class: 'btn_XS default filled add1' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.onOpenModal();
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.onDeleteUser();
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '엑셀',
                  elementAttr: { class: 'btn_XS white light_filled excel' },
                  height: 30,
                  onClick: () => {
                    this.onDownloadExcel();
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '일괄 업로드',
                  hint: '엑셀 파일로 상담원을 일괄 등록 합니다.',
                  elementAttr: { class: 'btn_XS white light_filled' },
                  width: 100,
                  height: 30,
                  onClick: () => {
                    // TODO : 김병윤, 엑셀업로드 구현 필요
                    this.$_Msg('일괄 업로드 기능은 준비중입니다.');
                  },
                },
                location: 'before',
              },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: true,
            grouping: false,
            paging: true,
          },
          paging: {
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: false, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: false, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '사용자 ID',
              dataField: 'id',
              visible: false,
              sortOrder: 'none',
            },
            {
              caption: '사이트',
              dataField: 'siteNm',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: true,
              allowFiltering: true,
              allowGrouping: false,
            },
            {
              caption: '센터',
              dataField: 'tenantNm',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: true,
              allowFiltering: true,
              allowGrouping: false,
            },
            {
              caption: '팀',
              dataField: 'teamNm',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: true,
              allowFiltering: true,
              allowGrouping: false,
            },
            {
              caption: '이름[사번]',
              dataField: 'user',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: true,
              allowFiltering: true,
              allowGrouping: false,
              calculateCellValue: this.mergeUserInfo,
            },
            {
              // width: 130,
              caption: '사용여부',
              dataField: 'viewFl',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowHeaderFiltering: false,
              allowFiltering: true,
              allowGrouping: false,
              lookup: {
                dataSource: this.$_enums.common.stringViewFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: 'CTI ID',
              dataField: 'ctiId',
              visible: false,
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      onRowClick(e) {
        this.onOpenModal();
        // this.openModal = true;
        this.popup.currentSaveType = 'UPDATE';
        let selectedRowsData = [];
        selectedRowsData.push(e.data);
        this.popup.userData.rowData = selectedRowsData;
      },
      /* @desc: 현재 모달 상태값 설정 */
      isOpenModal(data) {
        this.popup.isOpened = data;
        if (!data) {
          this.setInitModal();
        }
      },
      onSaveModal() {
        this.setInitModal();
      },
      closeModalAndReloadData() {
        this.setInitModal();
      },
      onDownloadExcel() {
        this.$_Msg('엑셀 파일로 다운로드 합니다.');
      },
      async onDeleteUser() {
        let selectedRows = this.$refs.userGrid.getInstance.getSelectedRowsData();
        let deleteCtiId = [];

        for (let row of selectedRows) {
          deleteCtiId.push(row.ctiId);
        }

        const payload = {
          actionName: 'USER_DELETE',
          data: {
            data: {
              from: 'core',
              data: deleteCtiId,
            },
          },
          loading: true,
        };

        const res = await this.CALL_API(payload);
        if (res.status === 200) {
          this.$_Msg('삭제성공');
          this.selectDataList();
        } else {
          this.$_Msg('삭제실패');
        }
      },
      /* @desc: 모달창이 close될 때, modal 객체 설정 값 초기화 */
      setInitModal() {
        this.openModal = false;
        this.popup.isOpened = false;
        this.popup.currentComponent = null;
        this.popup.userData.rowData = [];
      },
      /** @description : 데이터 조회 메서드 */
      async selectDataList(sort = '+id') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);

            if (!params.sort) {
              params.sort = sort;
            }

            params = { ...params };

            const payload = {
              actionName: 'USER_SELECT',
              data: { params },
              loading: false,
            };

            const res = await this.CALL_API(payload);
            if (isSuccess(res)) {
              this.dataGrid.dataSource = res.data.data;
            }
          },
        });
      },
      /* @desc  Modal 이 열릴 때, Modal 에 전달할 값 셋팅
       * @params componentNm : Modal name
       * @params saveType : INSERT, UPDATE */
      onOpenModal() {
        this.popup.currentComponent = 'ModalPageJoinUser'; // 보여줄 Modal의 명
        this.popup.visible = true;
        // this.openModal = true;
        this.isOpenModal(true);
      },
      /**
       * @description : 팝업 닫기
       * @param e
       */
      onClosePopup(e) {
        this.popup.visible = false;
        this.popup.userData = {};
        this.popup.currentSaveType = 'INSERT'; // 기본값으로 리셋
      },
      /**
       * @description : 팝업 저장
       * @param e
       */
      onSavePopup(e) {
        this.$_Msg('저장완료');
        this.selectDataList();
        this.popup.visible = false;
        this.popup.userData = {};
      },
      /** @description : 상담사 이름 + 상담사 로그인아이디 병합  */
      mergeUserInfo(rowData) {
        if (rowData === null || rowData === undefined) {
          return '';
        }
        return `${rowData.userNm} [${rowData.userNo}]`;
      },
    },
    created() {},
    mounted() {
      this.selectDataList();
    },
  };
</script>
<style lang="scss" scoped>
  .page_search_box .inner div {
    display: inline-block;
  }

  .page_search_box .inner > div:not(.box-btn-search) {
    vertical-align: middle;
    margin-right: 10px;
  }

  .page_search_box .spec-type-box {
    display: inline-block;
  }

  .page_search_box .inner .box-btn-search {
    margin-right: 15px;
  }

  .page_search_box #searchTooltip {
    margin-right: 5px;
    font-size: 22px;
    color: #808080;
  }

  .content-box {
    min-height: 800px;
    height: 800px;
  }

  .left-box {
    width: 100%;
    height: 100%;
    margin-top: 20px;
    padding-right: 10px;
    float: left;
  }

  .left-box-75 {
    width: 75%;
    height: 100%;
    margin-top: 20px;
    padding-right: 10px;
    float: left;
  }

  .right-box {
    //display: none;
    width: 25%;
    height: 100%;
    visibility: hidden;
    border-left: 2px solid #dddddd;
    margin-top: 20px;
    padding-left: 10px;
    position: relative;
    float: right;
  }

  .right-box-25 {
    width: 25%;
    height: 100%;
    border-left: 2px solid #dddddd;
    margin-top: 20px;
    padding-left: 10px;
    position: relative;
    float: right;
  }

  .left-box {
    transition: all 0.3s ease;
  }

  .left-box-75 {
    transition: all 0.1s ease;
  }

  .slide-right-enter-active {
    transition: all 0.1s ease;
  }

  .slide-right-leave-active {
    transition: all 1ms ease;
  }

  .slide-right-enter {
    transform: translateX(100%);
    opacity: 0;
  }

  .slide-right-leave-to {
    transform: translateX(100%);
    opacity: 0;
  }
</style>
