<template>
  <div class="container content-themes">
    <template v-for="(item, i) in myDeviceInfo">
      <div class="main clearfix per33 ui-33" style="padding-bottom: 20px" :key="i">
        <table class="table_form line-bin fl" style="width: 40%">
          <colgroup>
            <col style="width: 20%"/>
            <col style="width: 30%"/>
            <col style="width: 20%"/>
            <col style="width: 30%"/>
          </colgroup>
          <thead class="sub_title_txt">
          <tr>
            <td colspan="4">
              <h2 style="width: 200px" class="fl">{{ $_lang('UC.WORD.DEVICE_INFO') }}</h2>
              <DxButton
                  v-if="i === 0"
                  @click="fetchDeviceSync()"
                  text="전화표시 동기화"
                  :width="115"
                  :height="26"
                  class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fr"
                  styling-mode="contained"
                  type="default"
              />
              <DxButton
                  v-if="i === 0"
                  @click="selectData()"
                  :text="$_lang('UC.WORD.CHECK', { defaultValue: '조회' })"
                  :width="80"
                  :height="26"
                  class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fr"
                  styling-mode="contained"
                  type="default"
              />
            </td>
          </tr>
          </thead>
          <tbody style="height: 250px">
          <tr>
            <th scope="row" class="alT">
              <label for="label01">{{ $_lang('UC.WORD.EXCHANGER') }}</label>
            </th>
            <th>{{ getCmName(myDeviceInfo[i].cmname) }}</th>
            <td colspan="2" rowspan="3" style="padding: 0">
              <div class="fl" style="width: 100%; text-align: center">
                <img v-if="deviceImage[i].src" :src="deviceImage[i].src" alt="Device"/>
                <img
                    v-if="deviceExpImage[i].src"
                    :src="deviceExpImage[i].src"
                    style="cursor: pointer; position: absolute; bottom: 0"
                    alt="DeviceExp"
                    class="fr"
                    @click="isOpenModal(true, i)"
                />
              </div>
            </td>
          </tr>
          <tr>
            <th scope="row">
              <label for="label02">{{ $_lang('UC.WORD.DEVICE_NAME') }}</label>
            </th>
            <th>{{ myDeviceInfo[i].name }}</th>
          </tr>

          <tr>
            <th scope="row" class="alT"><label for="label04">IP</label></th>
            <th>{{ myDeviceInfo[i].ipaddress }}</th>
          </tr>
          <tr>
            <th scope="row" class="alT">
              <label for="label04">{{ $_lang('UC.WORD.MODEL') }}</label>
            </th>
            <th colspan="3">{{ myDeviceInfo[i].model }}</th>
          </tr>
          <tr>
            <th scope="row" class="alT">
              <label for="label04">{{ $_lang('COMPONENTS.DESCRIPTION') }}</label>
            </th>
            <td colspan="3">
              <DxTextBox
                  v-model="myDeviceInfo[i].description"
                  :placeholder="$_lang('UC.MESSAGE.DESCRIPTION_ENTER')"
                  :styling-mode="stylingMode"
                  @value-changed="onChangeInput($event, i)"
              />
            </td>
          </tr>
          </tbody>
        </table>
        <!-- <table class="table_form fr" style="width: 1120px; border-top: 0px"> -->
        <table style="width: 60%; border-top: 0">
          <thead class="sub_title_txt">
          <tr>
            <td colspan="3">
              <h2 style="width: 200px" class="fl">{{ $_lang('UC.WORD.LINE_INFO', {defaultValue: '회선 정보'}) }}</h2>
              <DxButton
                  @click="onUpdateDevice(i)"
                  :text="$_lang('UC.WORD.APPLY', { defaultValue: '적용' })"
                  :width="80"
                  :height="26"
                  class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fr"
                  styling-mode="contained"
                  type="default"
              />
            </td>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td style="padding: 0">
              <esp-dx-data-grid
                  :data-grid="getDataGrid(i)"
                  :ref="getDataGridRef(i)"
                  :auto-width="true"
                  @select="selectData"
                  @row-updating="onRowUpdating($event, i)"
                  @cell-prepared="onCellPrepared"
                  @editor-prepared="onEditorPrepared"
              >
              </esp-dx-data-grid>

              <Transition
                  name="slide-right"
                  @before-enter="beforeEnter"
                  v-for="(device, j) in myDeviceInfo[i].devicelist"
                  :key="j"
              >
                <div
                    v-show="device.isShowRightBox"
                    ref="rightBox"
                    class="z-10 w-full h-full absolute slide-right-box"
                >
                  <div
                      class="p-2 h-full absolute rounded-l-xl right-0 bg-white border-solid border-slate-50/[.55] shadow-lg"
                      style="width: calc(100% - 200px); border-top-width: 1px; border-right-width: 1px;">

                    <DxScrollView width="100%" height="100%">
                      <div class="w-full flex justify-start">
                        <DxButton
                            @click="device.isShowRightBox = false"
                            icon="chevronnext"
                            class="white light_filled txt_S"
                        />
                        <span class="content-center ml-2">
                          내선번호 : {{ device.number }}
                        </span>
                      </div>
                      <hr class="w-full border-t border-gray-300 mt-2 mb-2"/>
                      <div class="w-full h-auto">
                        <table class="table_form line-bin">
                          <colgroup>
                            <col style="width: 20%"/>
                            <col style="width: 30%"/>
                            <col style="width: 20%"/>
                            <col style="width: 30%"/>
                          </colgroup>
                          <tbody>
                          <tr>
                            <th scope="row" class="alT content-center" style="text-align: center;">
                              <label for="label04">{{ $_lang('UC.WORD.FORWARDNO') }}</label>
                            </th>
                            <td>
                              <DxTextBox
                                  v-model="device.forwardno"
                                  width="100%"
                                  placeholder="외부 발신의 경우 9로 시작"
                                  @value-changed="onChangeRightBoxNoAnswerForward($event, device, i)"
                              />
                            </td>
                            <th scope="row" class="alT content-center" style="text-align: center;">
                              <label for="label04">수신제한</label>
                            </th>
                            <td>
                              <DxSelectBox
                                  placeholder="사용여부"
                                  :items="[
                                    {
                                      label: '사용',
                                      value: 'Y'
                                    },
                                    {
                                      label: '미사용',
                                      value: 'N'
                                    }
                                  ]"
                                  display-expr="label"
                                  value-expr="value"
                                  styling-mode="outlined"
                                  width="100%"
                                  :value="getRightBoxReceiverBlock(device)"
                                  @value-changed="onChangeRightBoxReceiverBlock($event, device, i)"
                              />
                            </td>
                          </tr>
                          <tr>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </DxScrollView>
                  </div>
                </div>
              </Transition>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>

    <DxPopup
        :show-title="true"
        :title="modal.title"
        :width="modal.width"
        :height="modal.height"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :close-on-outside-click="false"
        :visible="modal.isOpened"
        @hiding="isOpenModal(false)"
    >
      <DxScrollView width="100%" height="100%">
        <ModalExpansion
            :deviceInfo="this.deviceInfo"
            :serverInfo="this.serverInfo"
            :cmList="this.cmList"
            :isOpened="this.modal.isOpened"
            :authority="this.authority"
        ></ModalExpansion>
      </DxScrollView>
    </DxPopup>
    <DxPopup
        :show-title="true"
        :title="pickupModal.title"
        :width="pickupModal.width"
        :height="pickupModal.height"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :close-on-outside-click="false"
        :visible="pickupModal.isOpened"
        @hiding="isOpenPickupPopup(false)"
    >
      <ModalPickup
          :deviceInfo="this.deviceInfo"
          :serverInfo="this.serverInfo"
          :rowInfo="this.rowInfo"
          :cmList="this.cmList"
          :isOpened="this.pickupModal.isOpened"
          :gridNumber="this.gridNumber"
          :authority="this.authority"
          :pickupKeyword="this.pickupKeyword"
          :columnIndex="this.columnIndex"
          @closePickup="setPickup"
          @deletePickup="deletePickup"
      ></ModalPickup>
    </DxPopup>
    <DxPopup
        :show-title="true"
        :title="sdModal.title"
        :width="sdModal.width"
        :height="sdModal.height"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :close-on-outside-click="false"
        :visible="sdModal.isOpened"
        @hiding="isOpenSdPopup(false)"
    >
      <ModalSd
          :deviceInfo="this.deviceInfo"
          :cmList="this.cmList"
          :sdData="this.sdModal.data"
          :isOpened="this.sdModal.isOpened"
          :idx="this.sdModal.idx"
          :authority="this.authority"
          @closeSd="setLine"
      ></ModalSd>
    </DxPopup>
  </div>
</template>

<script>
import DxButton from 'devextreme-vue/button';
import {DxSwitch} from "devextreme-vue/switch";
import ModalExpansion from '../device/cisco/modal-set-expansion.vue';
import ModalPickup from '../device/cisco/modal-set-pickup.vue';
import {DxPopup} from 'devextreme-vue/popup';
import PlusIcon from '@/assets/images/write.png';
import DelIcon from '@/assets/images/del.png';
import {isSuccess} from '@/utils/common-lib';
import {DxTextBox} from 'devextreme-vue/text-box';
import {DxScrollView} from 'devextreme-vue/scroll-view';
import ModalSd from '@/pages/euc/device/cisco/modal-set-sd.vue';
import {DxSelectBox} from "devextreme-vue/select-box";
import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';
import {getUCServerPath} from "@/pages/euc/common-uc";

export default {
  components: {
    DxSelectBox,
    DxButton,
    DxTextBox,
    DxPopup,
    ModalExpansion,
    ModalPickup,
    DxScrollView,
    ModalSd,
    EspDxDataGrid
  },
  props: {},
  watch: {},
  data() {
    return {
      isShowRightBox: false,
      stylingMode: 'outlined',
      devicePoolData: [],
      selectedPool: '',
      authority: 'user',
      deviceCnt: 0,
      phoneButtonData: [],
      selectedBtn: '',
      softKeyData: [],
      selectedKey: '',
      cssData: [],
      myDeviceInfo: [],
      partitionData: [],
      cmList: [],
      gridNumber: '',
      deviceImage: [],
      deviceExpImage: [],
      userDept: '',
      userInfo: {},
      columnIndex: -1,
      pickupKeyword: '',
      modal: {
        isOpened: false,
        // title: '확장 모듈 설정',
        title: this.$_lang('UC.WORD.EXPANSION_MODULE_SET'),
        width: '50%',
        height: '900',
      },
      pickupModal: {
        isOpened: false,
        // title: '당겨받기 설정',
        title: this.$_lang('UC.WORD.PICKUPGROUP_SET'),
        width: '60%',
        height: '750',
      },
      sdModal: {
        isOpened: false,
        data: [],
        idx: '',
        title: '단축다이얼 설정',
        width: '30%',
        height: '300',
      },
      pickupGroupList: [],
      updateFlag: false,
      serverInfo: '',
      deviceInfo: {},
      deviceList: [],
      rowInfo: {},
      changedDeviceInfo: [],
      dataGrid: {},
      rootEucLimitCallCodeMap: null,
      cssGroupList: []
    };
  },
  computed: {
    getEucLimitCallCodes() {
      return this.rootEucLimitCallCodeMap.codes;
    },
  },
  methods: {
    getRightBoxReceiverBlock(item) {
      const busyTrigger = Number(item.busyTrigger);
      const maxNumCalls = Number(item.maxNumCalls);

      const result = this.getEucLimitCallCodes.children.find((eucLimitCall) =>
          eucLimitCall.children.some((code) => {
            if (this.matchCodeValue(code, 'busyTrigger', busyTrigger)) {
              return code.children.some((code2) => this.matchCodeValue(code2, 'maxNumCalls', maxNumCalls));
            }
            if (this.matchCodeValue(code, 'maxNumCalls', maxNumCalls)) {
              return code.children.some((code2) => this.matchCodeValue(code2, 'busyTrigger', busyTrigger));
            }
            return false;
          })
      );

      return result?.codeValue || 'N';
    },
    beforeEnter(el) {
      el.style.backgroundColor = 'rgba(0, 0, 0, 0.3)';
    },
    /**@description index에 따른 그리드 리턴 */
    getDataGrid(index) {
      return this.dataGrid[`dataGrid_${index}`];
    },
    /**@description index에 따른 그리드 ref 리턴 */
    getDataGridRef(index) {
      return `refDataGrid_${index}`;
    },
    /**@description 그리드 dataSource set 함수 */
    setDataSource(index, data) {
      let gridDataSource = [];

      for (let i = 0; i < data.length; i++) {
        if (data[i].addonmodule === 'false') {
          gridDataSource.push(data[i]);
        }
      }
      this.$set(this.dataGrid[`dataGrid_${index}`], 'dataSource', gridDataSource);
      this.$set(this.dataGrid[`dataGrid_${index}`], 'totalCount', gridDataSource.length);
    },
    /**@description index에 따른 그리드 dataSource 리턴 */
    getDataSource(index) {
      return this.dataGrid[`dataGrid_${index}`].dataSource;
    },
    /**@description deviceList length 따른 그리드 다중 생성 */
    createGrid() {
      for (let i = 0; i < this.deviceCnt; i++) {
        this.$set(this.dataGrid, `dataGrid_${i}`, {
          refName: `refDataGrid_${i}`,
          disableTotalCount: true,
          dataSource: [],
          height: '251', // 주석처리시 100%
          apiActionNm: {},
          showActionButtons: {
            delete: false,
          },
          sorting: {
            mode: 'single', // single multiple
          },
          paging: {
            enabled: false,
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowAdding: false,
          },
          selecting: {
            mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: ' ',
              dataField: 'field',
              width: 50,
              allowEditing: false,
              allowSorting: false,
              cellTemplate: async (container, options) => {
                const imgTag = document.createElement('img');
                imgTag.setAttribute('src', `${getUCServerPath()}/euc/api/v1/resource/DEVICE_LINE_IMAGE_DIR/${options.value}.png`);
                container.append(imgTag);
              },
            },
            {
              caption: '내선번호',
              i18n: 'UC.WORD.NUMBER',
              dataField: 'number',
              width: 150,
              allowEditing: false,
              allowSorting: false,
              cellTemplate: (container, options) => {
                if (options.data.field === 'LINE' || options.data.field === 'SURL') {
                  if (options.value == null || options.value === 'null') {
                    options.value = '';
                  }
                  container.append(options.value);
                } else {
                  if (options.value === null || options.value === 'null') {
                    options.value = '';
                  }
                  const value = options.value ? options.value + ' ' : options.value;
                  container.append(value);
                  const divTag = document.createElement('div');
                  divTag.classList.add('fr');
                  const imgTag = document.createElement('img');
                  imgTag.setAttribute('src', PlusIcon);
                  imgTag.style.cursor = 'pointer';
                  imgTag.addEventListener('click', async () => {
                    await this.isOpenSdPopup(true, i);
                  });

                  divTag.append(imgTag);
                  if (value !== '') {
                    const del = document.createElement('img');
                    del.setAttribute('src', DelIcon);
                    del.style.cursor = 'pointer';
                    del.addEventListener('click', async () => {
                      await this.setCellDelete(options, i);
                    });
                    divTag.append(del);
                  }
                  container.append(divTag);
                }
              },
            },
            {
              caption: '표시',
              i18n: 'UC.WORD.LABEL',
              dataField: 'label',
              width: 200,
              allowSorting: false,
            },
            {
              caption: '부재중 착신전환',
              i18n: 'UC.WORD.FORWARDNO',
              dataField: 'cfnoansin',
              width: 200,
              allowSorting: false,
              validationRules: [
                {
                  type: 'custom',
                  validationCallback: (e) => {
                    if (!e?.value) {
                      return true;
                    }
                    return !isNaN(e.value) && e.value !== null && e.value !== '';
                  },
                  message: this.$_lang('UC.MESSAGE.NUMBER_ENTER'),
                },
              ],
            },
            {
              caption: '당겨받기',
              i18n: 'UC.WORD.PICKUPGROUP',
              dataField: 'pickupgroupname',
              allowEditing: false,
              allowSorting: false,
              cssClass: '',
              cellTemplate: (container, options) => {
                if (options.value === null || typeof options.value === 'undefined') {
                  options.value = '';
                }
                if (options.data.field === 'LINE') {
                  if (options.data.number == null || options.data.number == '') {
                    return;
                  }
                  let description = '';
                  for (let k = 0; k < this.pickupGroupList.length; k++) {
                    if (this.pickupGroupList[k].name === options.value) {
                      description = this.pickupGroupList[k].description;
                    }
                  }
                  if (description == null) {
                    description = '설명 없음';
                  }
                  const value = description;
                  container.append(value + ' ');

                  const divTag = document.createElement('div');
                  divTag.classList.add('fr');

                  const imgTag = document.createElement('img');
                  imgTag.setAttribute('src', PlusIcon);
                  imgTag.addEventListener('click', async () => {
                    options.data.pickupDescription = value;
                    await this.setRowInfo(options);
                    await this.isOpenPickupPopup(true, i);
                  });
                  imgTag.style.cursor = 'pointer';

                  divTag.append(imgTag);
                  if (value) {
                    const del = document.createElement('img');
                    del.setAttribute('src', DelIcon);
                    del.addEventListener('click', async () => {
                      await this.setCellDelete(options, i);
                    });
                    del.style.cursor = 'pointer';
                    divTag.append(del);
                  }
                  container.append(divTag);
                }
              },
            },
            {
              caption: '수신제한',
              i18n: 'UC.WORD.RECEIVER_BLOCK',
              dataField: 'receiverBlock',
              visible: this.$_getSystemData('use_limit_call')?.configValue === 'Y',
              allowEditing: false,
              allowSorting: false,
              cellTemplate: async (container, options) => {
                if (options.data?.field === 'LINE' && options.data?.number) {
                  const busyTrigger = Number(options.data.busyTrigger);
                  const maxNumCalls = Number(options.data.maxNumCalls);

                  const result = this.getEucLimitCallCodes.children.find((eucLimitCall) =>
                      eucLimitCall.children.some((code) => {
                        if (this.matchCodeValue(code, 'busyTrigger', busyTrigger)) {
                          return code.children.some((code2) => this.matchCodeValue(code2, 'maxNumCalls', maxNumCalls));
                        }
                        if (this.matchCodeValue(code, 'maxNumCalls', maxNumCalls)) {
                          return code.children.some((code2) => this.matchCodeValue(code2, 'busyTrigger', busyTrigger));
                        }
                        return false;
                      })
                  );
                  const switchBtn = new DxSwitch({
                    propsData: {
                      value: result?.codeValue === 'Y',
                      onValueChanged: (e) => this.onChangedReceiverBlock(e, options.data),
                    },
                  });
                  switchBtn.$mount();
                  container.append(switchBtn.$el);
                }
              },
            },
            {
              caption: '',
              i18n: 'COMPONENTS.OTHERS',
              dataField: '',
              width: 50,
              alignment: 'center',
              visible: this.$_getSystemData('use_device_detail_etc')?.configValue === 'Y',
              allowEditing: false,
              allowSorting: false,
              cssClass: '',
              cellTemplate: (container, options) => {
                if (options.data?.useRightBox) {
                  const button = new DxButton({
                    propsData: {
                      text: '',
                      icon: 'chevronprev',
                      elementAttr: {class: 'white light_filled txt_XS'},
                      value: options.data,
                      onClick: () => {
                        options.data.isShowRightBox = true;
                      },
                    },
                  });
                  button.$mount();
                  container.append(button.$el);
                }
              },
            },
          ],
        });
      }
    },
    matchCodeValue(code, key, value) {
      return code.codeKey === key && Number(code.codeValue) === value
    },
    onChangeRightBoxNoAnswerForward(e, data, index) {
      const changeData = [];
      changeData.push({
        newData: {
          forwardno: e.value,
        },
        oldData: data,
      });

      changeData.forEach((item) => {
        this.onRowUpdating(item, index);
      });
    },
    onChangeRightBoxReceiverBlock(e, data, index) {
      let busyTrigger, maxNumCalls;
      if (e.value === 'Y') {
        const eucLimitCallCode = this.getEucLimitCallCodes.children.find((eucLimitCallCode) => eucLimitCallCode.codeValue === 'Y');
        const busyTriggerCode = eucLimitCallCode.children.find((code) => code.codeKey === 'busyTrigger');
        const maxNumCallsCode = busyTriggerCode.children.find((code) => code.codeKey === 'maxNumCalls');
        busyTrigger = busyTriggerCode.codeValue;
        maxNumCalls = maxNumCallsCode.codeValue;
      } else {
        const eucLimitCallCode = this.getEucLimitCallCodes.children.find((eucLimitCallCode) => eucLimitCallCode.codeValue === 'N');
        const busyTriggerCode = eucLimitCallCode.children.find((code) => code.codeKey === 'busyTrigger');
        const maxNumCallsCode = busyTriggerCode.children.find((code) => code.codeKey === 'maxNumCalls');
        busyTrigger = busyTriggerCode.codeValue;
        maxNumCalls = maxNumCallsCode.codeValue;
      }
      const changeData = [];
      changeData.push({
        newData: {
          busyTrigger
        },
        oldData: data,
      });
      changeData.push({
        newData: {
          maxNumCalls
        },
        oldData: data,
      });

      changeData.forEach((item) => {
        this.onRowUpdating(item, index);
      });
    },
    // 수신제한 변경 시 이벤트
    async onChangedReceiverBlock(e, data) {
      const target = this.myDeviceInfo[0].devicelist.find((device) => device.seq === data.seq);
      const payload = {
        actionName: 'EUC_DEVICE_CISCO_LIMIT_CALL_UPDATE',
        data: {
          cmNm: this.myDeviceInfo[0].cmname,
          mac: this.myDeviceInfo[0].name,
          description: this.myDeviceInfo[0].description,
          list: JSON.stringify([{
            BUSYTRIGGER: this.getBusyTrigger(e.value),
            MAXNUMCALLS: this.getMaxNumCalls(e.value),
            TYPE: 'BUSYTRIGGER',
            DEVICE: target.number,
            ROUTEPARTITION: target.routepartitionname,
          }]),
          loginId: this.$store.getters.getLoginId,
        },
        loading: true,
      }

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS', {defaultValue: '정상적으로 처리되었습니다.'}), {icon: 'success'});
      } else {
        this.$_Msg(res.data.header?.resMsg || this.$_lang('COMMON.MESSAGE.CMN_ERROR', {defaultValue: '데이터 처리 중 오류가 발생하였습니다.'}), {icon: 'error'});
      }
    },

    getBusyTrigger(status = true) {
      status = status ? 'Y' : 'N';
      const eucLimitCallCode = this.getEucLimitCallCodes.children.find((eucLimitCallCode) => eucLimitCallCode.codeValue === status);
      let busyTriggerCode = eucLimitCallCode.children.find((code) => code.codeKey === 'busyTrigger');
      if (busyTriggerCode) {
        return busyTriggerCode.codeValue;
      } else {
        const maxNumCallsCode = eucLimitCallCode.children.find((code) => code.codeKey === 'maxNumCalls');
        if (maxNumCallsCode) {
          busyTriggerCode = maxNumCallsCode.children.find((code) => code.codeKey === 'busyTrigger');
          if (busyTriggerCode) {
            return busyTriggerCode.codeValue;
          } else {
            return null;
          }
        } else {
          return null;
        }
      }
    },
    getMaxNumCalls(status = true) {
      status = status ? 'Y' : 'N';
      const eucLimitCallCode = this.getEucLimitCallCodes.children.find((eucLimitCallCode) => eucLimitCallCode.codeValue === status);
      let maxNumCallsCode = eucLimitCallCode.children.find((code) => code.codeKey === 'maxNumCalls');
      if (maxNumCallsCode) {
        return maxNumCallsCode.codeValue;
      } else {
        const busyTriggerCode = eucLimitCallCode.children.find((code) => code.codeKey === 'busyTrigger');
        if (busyTriggerCode) {
          maxNumCallsCode = busyTriggerCode.children.find((code) => code.codeKey === 'maxNumCalls');
          if (maxNumCallsCode) {
            return maxNumCallsCode.codeValue;
          } else {
            return null;
          }
        } else {
          return null;
        }
      }
    },
    async fetchDeviceSync() {
      const payload = {
        actionName: 'EUC_LABEL_DEVICE_SYNC',
        data: {
          device: this.userInfo.dn,
          id: this.$store.getters.getLoginId,
        },
        loading: true
      }

      const res = await this.CALL_EUC_API(payload);
      if(isSuccess(res)) {
        this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS', {defaultValue: '정상적으로 처리되었습니다.'}), {icon: 'success'});
        await this.selectData();
      } else {
        this.$_Msg(res.data.header?.resMsg || this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }), { icon: 'error' });
      }
    },
    /** @description: 단말장치 데이터 상세 조회 메서드 */
    async selectData() {
      if (!this.userInfo) {
        await this.$router.push({path: '/euc/contact'});
        return;
      }
      this.changedDeviceInfo = []; //변경내역 초기화
      const payload = {
        actionName: 'EUC_DEVICE_CISCO_DETAIL',
        data: {
          loginId: this.$store.getters.getLoginId,
          type: 'DEVICE',
          device: this.userInfo.dn
        },
        loading: true,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        if (res.data.data?.length) {
          const deviceInfo = res.data.data[0];
          this.deviceCnt = deviceInfo.LIST.length;
          await this.createGrid();
          await this.setGridDataSource(deviceInfo);
        }
      } else {
        await this.$_Msg(res.data.header.resMsg);
        await this.$router.push({path: '/euc/contact'});
      }
    },

    /** @description: 단말장치 데이터 상세 조회 메서드 */
    setGridDataSource(json) {
      const list = json.LIST;
      for (let i = 0; i < list.length; i++) {
        const refDeviceData = {
          ...list[i],
          devicelist: list[i].devicelist.map((item) => {
            item.useRightBox = item.field === 'LINE' && item?.number;
            item.isShowRightBox = false;
            return item;
          })
        };
        if (i > -1 && this.myDeviceInfo.length === list.length) {
          Object.assign(this.myDeviceInfo[i], refDeviceData);
        } else {
          this.myDeviceInfo.push(refDeviceData);
        }

        const refGridData = refDeviceData.devicelist;
        const img = {index: '', src: ''};
        const imgExp = {index: '', src: ''};
        if (refDeviceData?.model) {
          img.index = i;
          img.src = `${getUCServerPath()}/euc/api/v1/resource/DEVICE_BIG_IMAGE_DIR/${refDeviceData.model}.png`;
          if (i > -1 && this.deviceImage.length === list.length) {
            Object.assign(this.deviceImage[i], img);
          } else {
            this.deviceImage.push(img);
          }
        } else {
          img.index = i;
          img.src = null;
          if (i > -1 && this.deviceImage.length === list.length) {
            Object.assign(this.deviceImage[i], img);
          } else {
            this.deviceImage.push(img);
          }
        }
        if (refDeviceData?.addonmodules) {
          const addOnModules = refDeviceData.addonmodules.split(',');

          imgExp.index = i;
          imgExp.src = `${getUCServerPath()}/euc/api/v1/resource/DEVICE_BIG_IMAGE_DIR/${addOnModules[0]}.png`;

          if (i > -1 && this.deviceExpImage.length === list.length) {
            Object.assign(this.deviceExpImage[i], imgExp);
          } else {
            this.deviceExpImage.push(imgExp);
          }
        } else {
          if (i > -1 && this.deviceExpImage.length === list.length) {
            Object.assign(this.deviceExpImage[i], imgExp);
          } else {
            this.deviceExpImage.push(imgExp);
          }
        }
        this.setDataSource(i, refGridData);
        //그리드 개수만큼 초기화
        this.changedDeviceInfo.push([]);
      }
    },
    /**@description 그리드 customEvent @onRowUpdating*/
    onRowUpdating(e, index) {
      const column = Object.keys(e.newData)[0];
      const number = e.oldData.number;
      const newRow = {
        column,
        number,
        value: e.newData[column],
        field: e.oldData.field
      };
      const existingRow = this.changedDeviceInfo[index].find(row => row.column === column && row.number === number);

      if (existingRow) {
        Object.assign(existingRow, newRow);
      } else {
        this.changedDeviceInfo[index].push(newRow);
      }
    },
    /** @description 로그인 유저의 인사정보 조회  */
    async getUserInfo() {
      this.userInfo = this.$store.getters.getUserInfo;
      if (this.userInfo == null) {
        await this.$_Msg(this.$_lang('UC.MESSAGE.NO_PERSON_INFO'));
        await this.$router.push({path: '/euc/contact'});
      } else {
        if (this.userInfo.dn == null) {
          await this.$_Msg('로그인한 유저의 내선정보가 없습니다.');
          await this.$router.push({path: '/euc/contact'});
        } else {
          await this.selectData();
        }
      }
    },
    /**@description  단말기 정보 업데이트
     * @param index
     * */
    async onUpdateDevice(index) {
      const changeArr = [];
      let refs = this.getDataGridRef(index);
      if (this.$refs[refs][0].hasEditData()) {
        await this.$_Msg('수정중인 항목이 있습니다.');
        return false;
      }
      for (let i = 0; i < this.changedDeviceInfo[index].length; i++) {
        const row = {
          TYPE: '',
          DEVICE: '',
          ROUTEPARTITION: '',
        };
        const routeRow = this.getDataSource(index).find(
            row => row.number === this.changedDeviceInfo[index][i].number,
        );

        row.DEVICE = this.changedDeviceInfo[index][i].number;
        if (routeRow.field === 'LINE') {
          row.ROUTEPARTITION = routeRow.routepartitionname;
        } else {
          row.ROUTEPARTITION = '';
        }

        const isContinue = changeArr.some((data) => {
          return data.DEVICE === row.DEVICE && data?.BUSY_TRIGGER && data?.MAX_NUM_CALLS;
        });

        switch (this.changedDeviceInfo[index][i].column) {
          case 'label':
            row.TYPE = this.changedDeviceInfo[index][i].field + 'LABEL';
            row.LABEL = this.changedDeviceInfo[index][i].value;
            //display 추가
            row.DISPLAY =
                this.userInfo != null ? this.userInfo.deptNm + ' ' + this.userInfo.userNm : routeRow.display;
            break;
          case 'forwardno':
            row.TYPE = 'FORWARDCALL';
            row.FORWARD_NO = this.changedDeviceInfo[index][i].value;
            row.FORWARD_TYPE = 'ALL';
            row.CSS = this.getCSSItem(row.DEVICE);
            break;
          case 'cfnoansin':
            row.TYPE = 'FORWARDCALL';
            row.FORWARD_NO = this.changedDeviceInfo[index][i].value;
            row.FORWARD_TYPE = 'NOANS';
            row.CSS = this.getCSSItem(row.DEVICE);
            break;
          case 'pickupgroupname':
            row.TYPE = 'PICKUP';
            row.PICKUPGROUP = this.changedDeviceInfo[index][i].value;
            break;
          case 'receiverblock':
            row.RECEIVER_BLOCK = this.changedDeviceInfo[index][i].value;
            break;
          case 'busyTrigger':
          case 'maxNumCalls':
            if (isContinue) {
              continue;
            }

            row.BUSY_TRIGGER = this.changedDeviceInfo[index].find((data) => data.column === 'busyTrigger').value;
            row.MAX_NUM_CALLS = this.changedDeviceInfo[index].find((data) => data.column === 'maxNumCalls').value;
            break;
        }
        changeArr.push(row);
      }

      if (!changeArr?.length && this.updateFlag === false) {
        await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NO_CHANGED'));
      } else if (changeArr?.length || this.updateFlag === true) {

        const confirm = this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE');

        if (!(await this.$_Confirm(confirm))) {
          return;
        }
        const payload = {
          actionName: 'EUC_DEVICE_CISCO_UPDATE',
          data: {
            cmNm: this.myDeviceInfo[index].cmname,
            mac: this.myDeviceInfo[index].name,
            description: this.myDeviceInfo[index].description,
            list: JSON.stringify(changeArr),
            loginId: this.$store.getters.getLoginId,
          },
          loading: true,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS'));
          this.changedDeviceInfo = []; //업데이트 성공후 초기화
          await this.selectData();
        } else {
          await this.$_Msg(res.data.header.resMsg);
        }
      }
    },
    getCSSItem(number) {
      const item = this.cssGroupList.find((css) => {
        const regex = new RegExp(css.pattern);
        return regex.test(number);
      });
      return item?.groupCd || null;
    },
    /**@description : 설명 입력창 onChange이벤트*/
    onChangeInput(event, index) {
      this.updateFlag = true;
      this.myDeviceInfo[index].description = event.value;
    },
    /**@description : 확장모듈 설정 팝업 Open/Close */
    isOpenModal(bool, index) {
      if (typeof index != 'undefined') {
        this.deviceInfo = this.myDeviceInfo[index];
      }
      this.modal.isOpened = bool;
    },
    /**@description : 당겨받기 설정 팝업 Open/Close */
    isOpenPickupPopup(bool, index) {
      if (typeof index != 'undefined') {
        this.gridNumber = index.toString();
        this.deviceInfo = this.myDeviceInfo[index];
        this.pickupKeyword = this.userInfo.deptNm;
      }
      this.pickupModal.isOpened = bool;
    },
    isOpenSdPopup(bool, index) {
      if (typeof index != 'undefined') {
        this.sdModal.data = JSON.parse(
            JSON.stringify(this.getDataSource(index).filter(d => d.field === 'SD' || d.field === 'BLF')),
        );
        this.sdModal.idx = index.toString();
      }
      this.sdModal.isOpened = bool;
    },
    async setLine(sdData, idx) {
      this.isOpenSdPopup(false, idx);
      const ref = 'refDataGrid_' + idx;
      const gridData = this.getDataSource(idx);

      for (let i = 0; i < gridData.length; i++) {
        for (let j = 0; j < sdData.length; j++) {
          if (
              gridData[i].index === sdData[j].index &&
              gridData[i].seq === sdData[j].seq &&
              gridData[i].field === sdData[j].field
          ) {
            this.$refs[ref][0].getInstance.cellValue(i, 1, sdData[j].number);
            this.$refs[ref][0].getInstance.cellValue(i, 2, sdData[j].label);
            this.$refs[ref][0].getInstance.cellValue(i, 3, '');
          }
        }
      }
      await this.$refs[ref][0].getInstance.saveEditData();
      await this.onCreateDevice(idx);
    },
    /**@description : 내선 설정 함수 */
    async onCreateDevice(idx) {
      const dataSource = this.getDataSource(idx);
      const mergeArray = [];
      for (let i = 0; i < dataSource.length; i++) {
        if (dataSource[i].number != null && dataSource[i].number !== '') {
          let data = {};
          data.INDEX = dataSource[i].seq;
          data.FIELD = dataSource[i].field;
          data.PATTERN = dataSource[i].number == null ? '' : dataSource[i].number;
          data.LABEL = dataSource[i].label == null ? '' : dataSource[i].label;
          if (dataSource[i].field === 'LINE') {
            data.ROUTEPARTITION =
                dataSource[i].routepartitionname == null ? 'None' : dataSource[i].routepartitionname;
            data.CSS = dataSource[i].callingsearchspacename == null ? 'None' : dataSource[i].callingsearchspacename;
          }
          mergeArray.push(data);
        }
      }

      const payload = {
        actionName: 'EUC_DEVICE_CISCO_MERGE',
        data: {
          mac: this.myDeviceInfo[idx].name,
          cmNm: this.myDeviceInfo[idx].cmname,
          loginId: this.$store.getters.getLoginId,
          list: JSON.stringify(mergeArray),
        },
        loading: true,
      };
      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS'));
      } else {
        await this.$_Msg(res.data.header.resMsg);
      }
      await this.selectData();
    },

    /**@description 할당된 내선 삭제하는 메서드 */
    async onDeleteDevice(idx) {
      const dataSource = this.getDataSource(idx);
      const mergeArray = [];
      for (let i = 0; i < dataSource.length; i++) {
        if (dataSource[i].number != null && dataSource[i].number !== '') {
          let data = {};
          data.INDEX = dataSource[i].seq;
          data.FIELD = dataSource[i].field;
          data.PATTERN = dataSource[i].number == null ? '' : dataSource[i].number;
          data.LABEL = dataSource[i].label == null ? '' : dataSource[i].label;
          if (dataSource[i].field === 'LINE') {
            data.ROUTEPARTITION =
                !dataSource[i]?.routepartitionname ? 'None' : dataSource[i].routepartitionname;
            data.CSS = !dataSource[i]?.callingsearchspacename ? 'None' : dataSource[i].callingsearchspacename;
          }
          mergeArray.push(data);
        }
      }

      const payload = {
        actionName: 'EUC_DEVICE_CISCO_MERGE',
        data: {
          mac: this.myDeviceInfo[idx].name,
          cmNm: this.myDeviceInfo[idx].cmname,
          loginId: this.$store.getters.getLoginId,
          list: JSON.stringify(mergeArray),
        },
        loading: true,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS'));
      } else {
        await this.$_Msg(res.data.header.resMsg);
      }
      await this.selectData();
    },
    /**@description : 당겨받기 데이터 저장하는 메서드 */
    setRowInfo(rowData) {
      this.rowInfo = rowData.data;
      this.columnIndex = rowData.columnIndex;
    },
    /**@description 교환기 목록 가져오는 메서드 */
    async selectCmList(sort = '+cmOrd') {
      const payload = {
        actionName: 'EUC_CM_SELECT',
        data: {
          sort,
          viewFl: 'Y'
        },
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.cmList = res.data.data.map((item) => {
          return {
            cmNm: item.cmNm,
            cmOrd: item.cmOrd,
            description: item.description,
            editId: item.editId,
            editDt: item.editDt,
            regId: item.regId,
          };
        });
      }
    },
    /**@description 당겨받기그룹 목록 가져오는 메서드 */
    async selectPickupGroupList(sort = '+callpickupGroupOrd') {
      const payload = {
        actionName: 'EUC_PICKUPGROUP_SELECT',
        data: {
          sort,
          viewFl: 'Y'
        },
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.pickupGroupList = res.data.data;
      }
    },
    /**@description 교환기 영문명 -> 한글명 교체 */
    getCmName(cm) {
      return this.cmList?.find(d => d.cmNm === cm)?.description || '';
    },
    /** @description : 날짜 형식 변환 메서드 */
    formatDt(date) {
      return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD');
    },
    /** @description : 그리드 셀 관련 준비 커스텀 이벤트 */
    onCellPrepared(e) {
      if (e.rowType === 'data') {
        if (e.data.field !== 'LINE') {
          if (e.columnIndex > 2) {
            e.cellElement.classList.add('grid-edit-false');
          }
        } else {
          if (e.columnIndex === 1) {
            e.cellElement.classList.add('grid-edit-false');
          }
          if(e.column.dataField === 'cfnoansin' && !e.isEditing) {
            const isValue = Boolean(e?.value?.trim());
            const isNumber = Boolean(e?.data.number?.trim());
            if(!isValue && isNumber) {
              e.cellElement.style.color = '#ccc';
              e.cellElement.textContent = '외부 발신의 경우 9로 시작';
            }
          }
        }
      }
    },
    /** @description 그리드 셀 편집기가 생성된 후 실행 */
    onEditorPrepared(e) {
      if (e.dataField === 'label' || e.dataField === 'forwardno') {
        if ((e.row.cells[0].value === 'SD' || e.row.cells[0].value === 'BLF') && e.dataField === 'forwardno') {
          e.component.cancelEditData();
        }
      }
      if (e.row.rowType === 'data' && (e.row.data.number === null || e.row.data.number === '')) {
        e.component.cancelEditData();
      }
    },
    /** @description 당겨받기 팝업창 콜백함수 */
    async setPickup(row, pickupName, index, columnIndex) {
      this.isOpenPickupPopup(false, index);
      const ref = 'refDataGrid_' + index;
      const gridData = this.getDataSource(index);
      for (let i = 0; i < gridData.length; i++) {
        if (gridData[i].index === row.index && gridData[i].seq === row.seq && gridData[i].field === row.field) {
          this.$refs[ref][0].getInstance.cellValue(i, columnIndex, pickupName);
          this.$refs[ref][0].getInstance.saveEditData();
        }
      }
    },
    async deletePickup(rowInfo, gridIndex, columnIndex) {
      this.isOpenPickupPopup(false, gridIndex);
      const row = {
        rowIndex: Number(rowInfo.index) - 1,
        columnIndex: columnIndex,
        rowType: 'data'
      }
      this.setCellDelete(row, gridIndex);
    },
    /**@description 그리드 내부 X버튼 클릭 이벤트. 셀 내부 데이터 삭제 */
    setCellDelete(row, index) {
      const ref = 'refDataGrid_' + index;
      this.$refs[ref][0].getInstance.cellValue(row.rowIndex, row.columnIndex, '');
      this.$refs[ref][0].getInstance.saveEditData();

      if (row.columnIndex === 1 && row.rowType === 'data') {
        this.onDeleteDevice(index);
      }
    },
    /**@description : 라이프사이클 created시 호출되는 메서드 */
    async createdData() {
      this.selectCSSGroupList();
      await this.getUserInfo();
    },
    /**@description : 라이프사이클 mounted시 호출되는 메서드 */
    async mountedData() {
      await this.selectCmList();
      await this.selectPickupGroupList();
    },
    /**@description : 라이프사이클 destroyed시 호출되는 메서드 */
    destroyedData() {
      this.$store.commit('setDetailParams', null);
    },
    selectCSSGroupList() {
      const params = {};
      const configValue = this.$_getSystemData('inout_type_all_trans')?.configValue;
      if(configValue) {
        params.inoutTypeCd = configValue;
      }

      const payload = {
        actionName: 'EUC_DEVICE_CISCO_GROUP_SELECT',
        data: params,
        loading: false,
      }
      this.CALL_EUC_API(payload).then((res) => {
        if (isSuccess(res)) {
          this.cssGroupList = res.data.data;
        }
      });
    },
  },
  async created() {
    this.$_fetchCodesByDepth('root_euc_limit_call', 1).then((res) => {
      this.rootEucLimitCallCodeMap = res;
    });
    await this.createdData();
  },
  async mounted() {
    await this.mountedData();
  },
  destroyed() {
    this.destroyedData();
  },
};
</script>

<style scoped>
.device-img > img {
  align-self: center;
}

.fl {
  border-right: 0 !important;
}

tbody {
  display: table-row-group;
  vertical-align: top;
}

.dx-datagrid-headers {
  border-top: 0px;
}

.table_form tbody td {
  border: 1px solid #ddd;
  height: 50px;
  padding: 8px 20px;
}

.table_form tbody th {
  border: 1px solid #ddd;
  height: 50px;
  padding: 8px 20px;
}

.dx-datagrid .dx-row-lines > td {
  border-bottom: 1px solid #efefef;
}

.grid-edit-false {
  background-color: #f9f9f9;
}

.slide-right-box {
  transition: all 0.3s ease;
}

.slide-right-enter, .slide-right-leave-to /* .slide-right-leave-active in <2.1.8 */
{
  transform: translateX(100%);
  opacity: 0;
}
</style>