<!--
  PACKAGE_NAME : src\pages\report\wizard\detail.vue
  FILE_NAME : detail
  AUTHOR : kwmoon
  DATE : 2024-04-22
  DESCRIPTION : 보고서 위자드 상세 페이지 (추가, 수정 시)
-->
<template>
  <div>
    <div class="page-sub-box">
      <!-- 헤더 -->
      <detail-header
        ref="header"
        :isEditMode="isEditMode"
        @init="init"
        @save="saveReport"
        @openTemplateModal="toggleTemplateModal"
        @openXmlModal="toggleXmlEditorModal"
      />

      <!-- Main  -->
      <div class="flex gap-x-4 divide-x items-stretch">
        <div class="w-3/12 py-4">
          <!-- 항목 (invisible) -->
          <detail-invisible-column
            ref="invisible"
            :columns="columns.invisible"
            @drop-column="onDropColumnByFromAndEvent"
            @re-order="onDragChangeOrder"
            @open-column="toggleColumnModal"
          />
        </div>
        <div class="w-8/12 flex flex-col gap-y-6 divide-y">
          <!-- 미리 보기 -->
          <detail-preview ref="preview" :columns="columns.visible" />
          <!-- 보고서 항목 (visible) -->
          <detail-visible-column
            ref="visible"
            :columns="columns.visible"
            @drop-column="onDropColumnByFromAndEvent"
            @re-order="onDragChangeOrder"
            @open-column="toggleColumnModal"
          />
        </div>

        <!-- 옵션 -->
        <div class="w-1/12 py-4">
          <detail-option ref="option" />
        </div>
      </div>
      <!-- XML 팝업 -->
      <CustomDxPopup
        :isOpen="popup.xml.isOpen"
        :option="popup.xml.option"
        @saveModal="saveXmlEditorModal"
        @closeModal="toggleXmlEditorModal(false)"
      >
        <template #content>
          <XmlEditor ref="xmlEditor" :options="popup.xml.editorOption" :history="popup.xml.histories" :reportId="reportId" />
        </template>
      </CustomDxPopup>

      <!-- 컬럼 설정 팝업 -->
      <CustomDxPopup
        :isOpen="popup.column.isOpen"
        :option="popup.column.option"
        @saveModal="updateColumnInfo"
        @closeModal="toggleColumnModal(false)"
      >
        <template #content>
          <UpdateColumnPopup ref="columnModal" />
        </template>
      </CustomDxPopup>

      <!-- 템플릿 -->
      <CustomDxPopup
        :isOpen="popup.template.isOpen"
        :option="popup.template.option"
        @saveModal="asyncApplyTemplatePopup"
        @closeModal="onClickTogglePopup('template', false)"
      >
        <template #content>
          <Tabs id="wizard-detail-page" ref="templateTabs" @selectedIndex="selectedTemplatePopupTab">
            <Tab :key="tab.id" :title="tab.name" v-for="tab in popup.template.tabs">
              <DxDataGrid
                width="100%"
                :ref="getTemplateGridRefFromTab(tab.id)"
                :data-source="popup.template[`${tab.id}List`]"
                :show-borders="false"
                :show-column-headers="true"
                :show-column-lines="false"
                :show-row-lines="true"
                :row-alternation-enabled="false"
                :allow-column-resizing="true"
                :height="420"
                :visible="true"
                :no-data-text="noDataTextList(popup.template[`${tab.id}List`].length)"
              >
                <DxSorting mode="none" />
                <DxSelection mode="single" />
                <DxPaging :enabled="false" :page-size="1000" />
                <DxColumn
                  :key="dataField"
                  :caption="caption"
                  :data-field="dataField"
                  :width="width"
                  :alignment="align ? align : 'center'"
                  v-for="{ caption, dataField, align, width } in tab.columns"
                />
              </DxDataGrid>
            </Tab>
          </Tabs>
        </template>
      </CustomDxPopup>
    </div>
  </div>
</template>

<script>
  import {
    DxDataGrid,
    DxColumn,
    DxSorting,
    DxScrolling,
    DxRowDragging,
    DxLookup,
    DxPaging,
    DxSelection,
    DxFilterRow,
    DxOperationDescriptions,
  } from 'devextreme-vue/data-grid';
  import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxButton } from 'devextreme-vue/button';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import Tabs from '@/components/common/tabs.vue';
  import Tab from '@/components/common/tab.vue';
  import DxCheckBox from 'devextreme-vue/check-box';
  import CustomDxPopup from '@/components/devextreme/esp-dx-modal-popup.vue';
  import XmlEditor from '@/pages/report/config/modal-xml-editor-update';
  import { isSuccess, getResData, formatDate, isTrue, isEmpty } from '@/utils/common-lib';
  import UpdateColumnPopup from '@/pages/report/config/wizard/popup/update-column-popup.vue';
  import DetailHeader from '@/pages/report/config/wizard/detail-header.vue';
  import DetailInvisibleColumn from '@/pages/report/config/wizard/detail-invisible-column.vue';
  import DetailPreview from '@/pages/report/config/wizard/detail-preview.vue';
  import DetailOption from '@/pages/report/config/wizard/detail-option.vue';
  import DetailVisibleColumn from '@/pages/report/config/wizard/detail-visible-column.vue';

  export default {
    name: 'report-wizard-detail',
    components: {
      DetailVisibleColumn,
      DetailOption,
      DetailPreview,
      DetailInvisibleColumn,
      DetailHeader,
      DxOperationDescriptions,
      Tab,
      Tabs,
      // 모달
      CustomDxPopup,
      XmlEditor,
      // Dev-Extreme
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxTextBox,
      DxButton,
      DxSelectBox,
      DxRowDragging,
      DxLookup,
      DxCheckBox,
      UpdateColumnPopup,
      DxPaging,
      DxSelection,
      DxValidator,
      DxRequiredRule,
      DxFilterRow,
      DxSorting,
    },
    props: {},
    watch: {},
    data() {
      return {
        isEditMode: false,
        reportId: null, // 보고서 ID (EditMode 일 시 할당)

        // 2번째 라인 Input 박스
        form: {
          name: null, //보고서명
          fileNm: null,
          //소메뉴
          parentMenuList: this.getMenuList(2),
          parentMenuId: null,
          //솔루션 및 서브패스
          solution: null,
          subPath: null,
        },
        reportMenu: {
          depth2: this.getMenuList(2),
          depth3: this.getMenuList(3, 'REPORT'),
          selectedId: null,
        },
        // 항목
        columns: {
          visible: [],
          invisible: [],
        },
        popup: {
          template: {
            selected: { type: null, id: null },
            isOpen: false,
            option: {
              title: '템플릿',
              width: '800px',
              height: '650px',
              minWidth: null,
              minHeight: null,
            },
            tabs: [
              {
                id: 'query',
                name: '템플릿 쿼리',
                columns: [
                  { caption: '솔루션', dataField: 'solution', width: 100 },
                  { caption: '구분', dataField: 'subPath', width: 100 },
                  { caption: '이름', dataField: 'name', width: 250 },
                  { caption: '설명', dataField: 'description', align: 'left' },
                ],
              },
              {
                id: 'menu',
                name: '보고서 메뉴',
                columns: [
                  { caption: '솔루션', dataField: 'solution', width: 100 },
                  { caption: '구분', dataField: 'subPath', width: 100 },
                  { caption: '소메뉴', dataField: 'parentMenu', width: 150 },
                  { caption: '메뉴명', dataField: 'menuNm' },
                ],
              },
            ],
            selectedTabId: null, // 선택한 탭 인덱스
            // 탭 별 그리드 내 데이터
            queryList: [],
            menuList: [],
          },
          column: {
            isOpen: false,
            selectedType: null,
            option: {
              title: '항목 정보',
              width: '520',
              height: '550',
              minWidth: null,
              minHeight: null,
            },
          },
          xml: {
            isOpen: false,
            option: {
              title: 'XML 관리',
              width: '80%',
              height: '85%',
              minWidth: null,
              minHeight: null,
            },
            editorOption: {
              type: 'REPORT',
              reportNm: '',
              reportId: this.reportId,
              useRight: true,
              rsWidth: '30%', // 우측 섹션 넓이
            },
            histories: [],
          },
        },
      };
    },
    computed: {},
    methods: {
      /**
       * 메뉴 찾기 함수d
       */
      getMenuList(menuDepth, typeCd) {
        if (isEmpty(typeCd)) {
          return this.$store.getters.getMenuList.filter(v => v.menuDepth === menuDepth);
        }
        return this.$store.getters.getMenuList.filter(v => v.menuDepth === menuDepth && v.menuTypeCd === typeCd);
      },

      /**
       * XML 모달 토글
       * @param bool
       */
      async toggleXmlEditorModal(bool) {
        this.$refs.xmlEditor.clearVueData();
        if (bool) {
          this.popup.xml.editorOption.reportId = this.reportId;
          this.popup.xml.editorOption.reportNm = this.form.name;
          await this.asyncSetXmlHistoryByReportId(this.reportId);
        }
        this.popup.xml.isOpen = bool;
      },

      /**
       * XML 히스토리 조회
       */
      async asyncSetXmlHistoryByReportId(reportId) {
        const res = await this.CALL_REPORT_API({
          actionName: 'REPORT_XML_HISTORY_LIST',
          path: `/${reportId}`,
          loading: true,
        });

        if (isSuccess(res)) {
          this.popup.xml.histories = getResData(res);
        }
      },

      /**
       * XML 데이터 업데이트
       */
      async asyncUpdateReportXmlData(useErrorPopup) {
        const { cmEditor: reportXml, description } = this.$refs.xmlEditor;
        //XML, 설명, 레포트 ID
        return await this.CALL_REPORT_API({
          actionName: 'REPORT_XML_UPDATE',
          data: { xmlData: reportXml, reportId: this.reportId, description: description },
          loading: true,
          useErrorPopup: useErrorPopup,
        });
      },

      /**
       * XML 에디터 업데이트
       */
      async saveXmlEditorModal() {
        //XML, 설명, 레포트 ID
        const res = await this.asyncUpdateReportXmlData(false);
        const { resCode, resMsg } = res.data.header;
        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('REPORT.MESSAGE.UPDATE_REPORT_INFO', { defaultValue: '보고서가 수정되었습니다.' }));
          await this.init();
          await this.toggleXmlEditorModal(false);
        }
      },

      /**
       * 보고서 항목 수정 모달 토글
       * @param bool
       * @param type : 'visible' | 'invisible'
       * @param column
       */
      toggleColumnModal(bool, type, column) {
        this.popup.column.isOpen = bool;
        if (bool) {
          this.popup.column.selectedType = type;
          this.$refs.columnModal.update(column);
        }
      },

      /**
       * 컬럼 정보 업데이트
       */
      updateColumnInfo() {
        const formData = this.$refs.columnModal.getForm();
        const type = this.popup.column.selectedType;
        this.columns[type].forEach(v => {
          if (v.dataField === formData.dataField) {
            Object.keys(formData).forEach(key => {
              v[key] = formData[key];
            });
          }
        });

        this.toggleColumnModal(false);
      },

      /**
       * 데이터 비었을 시 출력
       */
      noDataTextList(length) {
        if (length === 0) {
          return '선택된 항목이 없습니다. 좌측에서 드래그로 설정 할 수 있습니다.';
        }
      },

      /** @description: 보고서 XML 정보 조회하는 API를 통해 데이터 셋팅 */
      async getReportXMLInfo(reportId) {
        const res = await this.CALL_REPORT_API({
          actionName: 'REPORT_INFO_BY_ID',
          data: { reportId: reportId },
          loading: true,
        });

        if (isSuccess(res)) {
          return getResData(res).at(0);
        }
      },
      /** @description: 보고서 XML 정보 조회하는 API를 통해 데이터 셋팅 */
      async getWizardTemplateXMLInfo(id) {
        const res = await this.CALL_REPORT_API({
          actionName: 'WIZARD_TEMPLATE_INFO',
          data: { id: id },
          loading: true,
        });

        if (isSuccess(res)) {
          return getResData(res)[0];
        }
      },

      /**
       * U_XML_FILE 타입 별 리스트 반환 API 호출 메서드
       */
      async asyncGetXmlFileListFromType(type) {
        try {
          const res = await this.CALL_REPORT_API({
            actionName: 'MASTER_QUERY_LIST',
            data: { type: type },
            loading: false,
          });

          if (isSuccess(res)) {
            return getResData(res);
          }
        } catch (e) {
          console.warn('XML 파일 리스트를 불러오는데 실패하였습니다.', e);
          return [];
        }
      },
      /** @description menuId 로 메뉴 정보 얻는 메서드  */
      getMenuInfo(menuId) {
        return this.reportMenu.depth3.find(menu => menu.id === menuId);
      },
      /** @description 보고서 메뉴(U_REPORT_MENU) 가지고 오는 API */
      async asyncGetReportMenu() {
        try {
          const res = await this.CALL_REPORT_API({
            actionName: 'REPORT_WIZARD_MENU_LIST',
            data: { sort: '-regDt', pagesize: 1000 },
            loading: false,
          });

          if (isSuccess(res)) {
            return getResData(res).map(item => {
              const targetMenu = this.getMenuInfo(item.menuId);
              const parentId = targetMenu?.parentId ?? null;
              item.parentMenu = null;
              if (parentId !== null) {
                const parentMenu = this.reportMenu.depth2.find(menu => menu.id === parentId);
                item.parentMenu = parentMenu?.menuNm ?? null;
              }
              return { ...item };
            });
          }
          return [];
        } catch (e) {
          console.error('보고서 메뉴 리스트를 불러오는데 실패하였습니다.', e);
          return [];
        }
      },

      /**
       * 템플릿 모달 토글
       * @param bool
       */
      toggleTemplateModal(bool) {
        this.popup.template.isOpen = bool;
      },

      /**
       * 팝업 리스트
       * 1. 템플릿 (추가, 수정)
       * 2. 항목 설정 (추가, 수정)
       * 3. XML 편집창 (수정)
       */
      onClickTogglePopup(type, bool) {
        this.popup[type].isOpen = bool;
      },
      /** @description [팝업] 템플릿 - 그리드 ref 값 조회 */
      getTemplateGridRefFromTab(id) {
        return `template-grid-${id}`;
      },
      /** @description [팝업] 템플릿 - 탭 변경(최초 or 클릭) 시 실행 */
      selectedTemplatePopupTab(index) {
        this.popup.template.selectedTabId = this.popup.template.tabs[index].id;
      },

      /**
       * 텝플릿 모달 내 탭 및 ID 값으로 XML 데이터 조회
       */
      async getXmlDataByTabAndId(tab, id) {
        if (tab === 'query') {
          return await this.getWizardTemplateXMLInfo(id);
        }
        // 보고서 메뉴 정보
        return await this.getReportXMLInfo(id);
      },

      /**
       * 보고서 수정 시 헤더 정보 추가 셋팅
       */
      setHeaderEditInfo(xmlData) {
        const { menuNm, fileNm, menuId } = xmlData;
        this.$refs.header.setName(menuNm);
        this.$refs.header.setFileNm(fileNm);
        this.$refs.header.setParentMenuId(menuId);
      },

      /**
       * 레거시 컬럼 포맷 변환
       */
      parseLegacyColumnFormat(columns) {
        return columns.map(col => {
          return {
            ...col,
            format: this.$_enums.report.columnFormat.parseLegacyValue(col.format),
          };
        });
      },
      /**
       * XML 데이터 셋팅 (header, option, columns)
       */
      setXmlData(xmlData) {
        const { solution, subPath } = xmlData;
        // 솔루션, 구분 셋팅
        this.$refs.header.setSolutionAndSubPath(solution, subPath);
        // search 및 targets 데이터 셋팅
        this.$refs.option.updateOptionsByXmlData(xmlData);
        // 항목(visible 기준) 셋팅

        const columns = this.parseLegacyColumnFormat(xmlData.columns);
        this.columns.visible = this.getColumnsByVisibility(columns, true);
        this.columns.invisible = this.getColumnsByVisibility(columns, false);
      },

      /** @description [팝업] 템플릿 - 선택 후 저장 로직 */
      async asyncApplyTemplatePopup() {
        // 선택한 보고서 템플릿 조회
        const tab = this.popup.template.selectedTabId;
        const templateGridIdRef = this.$refs[this.getTemplateGridRefFromTab(tab)];
        const selectedItems = templateGridIdRef.at(0).instance.getSelectedRowsData();
        if (isEmpty(selectedItems)) {
          return this.$_Msg('보고서 템플릿을 선택해주시기 바랍니다.');
        }

        try {
          const id = selectedItems.at(0).id;
          this.popup.template.selected.id = id;
          this.popup.template.selected.type = tab;
          const xmlData = await this.getXmlDataByTabAndId(tab, id);
          this.setXmlData(xmlData);
        } catch (e) {
          console.error('템플릿 데이터 설정 중 오류가 발생하였습니다.', e);
          return; // 오류 발생 시 모달 유지
        }
        // 모달 닫기
        this.toggleTemplateModal(false);
      },

      /**
       * 보고서 항목 그리드 간 드래그&드랍
       */
      onDropColumnByFromAndEvent(to, e) {
        const from = to === 'visible' ? 'invisible' : 'visible';
        const isVisible = to === 'visible';
        const draggedItem = { ...e.itemData, visible: isVisible }; // 드래그한 항목

        // 중복 방지: 이미 존재하면 추가하지 않음
        const exists = this.columns[to].some(col => col.dataField === draggedItem.dataField);
        if (exists) return;

        // 현재 위치에 추가
        if (e.isClick) {
          this.columns[to].push(draggedItem);
        } else {
          const newList = [...this.columns[to]];
          newList.splice(e.toIndex, 0, draggedItem);
          this.columns[to] = newList;
        }

        // 이전 위치에서 제거
        const removedList = this.columns[from].filter(col => col.dataField !== draggedItem.dataField);
        this.columns[from] = [...removedList];
      },

      /**
       * 보고서 항목 순서 변경 이벤트
       * */
      onDragChangeOrder(type, e) {
        const { fromIndex, toIndex } = e;
        const movedItem = this.columns[type][fromIndex];

        // 동일 인덱스면 패스
        if (fromIndex === toIndex) return;

        // 복사해서 조작
        const newList = [...this.columns[type]];
        newList.splice(fromIndex, 1); // 기존 위치에서 제거
        newList.splice(toIndex, 0, movedItem); // 새 위치에 삽입

        this.columns[type] = newList;
      },

      /**
       * 보고서 항목 visible 기준 필터
       */
      getColumnsByVisibility(columns, isVisible = true) {
        return columns.filter(col => {
          const hasVisible = Object.hasOwn(col, 'visible');
          const isVisibleValue = isTrue(col.visible);

          // visible = true
          if (isVisible) {
            return !hasVisible || isVisibleValue;
          }

          // visible = false
          return hasVisible && !isVisibleValue;
        });
      },

      /**
       * 보고서 수정
       */
      async updateReport(params) {
        const res = await this.CALL_REPORT_API({
          actionName: 'REPORT_WIZARD_MENU_UPDATE',
          data: { reportId: this.reportId, ...params },
          loading: true,
        });

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('REPORT.MESSAGE.UPDATE_REPORT_INFO', { defaultValue: '보고서가 수정되었습니다.' }));
          await this.init(); // 갱신된 정보로 다시 조회
        }
      },
      /**
       * 보고서 생성
       */
      async createReport(params) {
        const { id: templateId, type: templateType } = this.popup.template.selected;
        const res = await this.CALL_REPORT_API({
          actionName: 'REPORT_WIZARD_MENU_ADD',
          data: { templateId, templateType, ...params },
          loading: true,
        });

        if (isSuccess(res)) {
          const reportId = getResData(res);
          this.$_Toast(this.$_lang('REPORT.MESSAGE.CREATE_REPORT', { defaultValue: '보고서가 생성되었습니다.' }));
          this.$router.push(`/report/config/wizard/detail/${reportId}`);
        }
      },

      /** @description 저장 버튼 클릭 시 발생 이벤트 (위자드를 활용한 보고서 추가 및 수정) */
      async saveReport() {
        if ((await this.$_Confirm('보고서를 저장하시겠습니까?')) === false) {
          return;
        }

        // 헤더 데이터 셋팅 (보고서명, 파일명, 소메뉴, 솔루션, 구분)
        const formData = this.$refs.header.getFromData();
        // 보고서 항목
        const { visible, invisible } = this.columns;
        const columns = [...visible, ...invisible];
        // 옵션 (search, targets)
        const { search, targets } = this.$refs.option.getOptions();
        // 최종 파라미터
        const params = { ...formData, columns, search, targets };

        // 추가 또는 수정
        this.isEditMode ? await this.updateReport(params) : await this.createReport(params);
      },

      /**
       * 템플릿 조회 및 설정
       */
      async getXmlTemplate() {
        // 보고서 메뉴
        this.popup.template.menuList = await this.asyncGetReportMenu();
        // 공통 XML 관리 페이지 (위자드 템플릿)
        this.popup.template.queryList = await this.asyncGetXmlFileListFromType('wizard-template');
      },

      /**
       * 라우트 uri 이용해서 모드 및 보고서 ID 설정
       */
      setInitModeAndReportId() {
        const id = this.$route.params.id;
        this.isEditMode = !!id;
        if (this.isEditMode) {
          this.reportId = id;
        }
      },
      /**
       * 초기화
       */
      async init() {
        // 보고서 정보 조회
        const xmlData = await this.getReportXMLInfo(this.reportId);
        this.setHeaderEditInfo(xmlData);
        this.setXmlData(xmlData);
      },
    },
    updated() {},
    created() {
      this.setInitModeAndReportId(); // 라우트 uri 이용해서 모드 및 보고서 ID 설정
    },
    async mounted() {
      // 공통
      await this.getXmlTemplate();
      // 업데이트 모드일 시 동작
      if (this.isEditMode) {
        await this.init();
      }
    },
    beforeDestroy() {},
    destroyed() {},
  };
</script>
<style scoped></style>
