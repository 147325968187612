const initialState = {
    tabHists: {},           // 탭 관련 이력(수정한 뒤 해당 탭으로 이동)
    listCacheFl: null,      // 목록 캐시 여부(검색과 페이지목록수옵션 캐시저장 관련 처리를 위해 필요)
    searchHists: {},        // 검색 관련 이력(시스템설정의 검색캐시저장여부(search_cache_fl)에 따라 데이터 유지)
    pagingHists: {},        // 페이지목록수옵션(pageSize, pageIndex) 관련 이력, 시스템설정의 페이지목록수옵션캐시저장여부(page_size_cache_fl)에 따라 데이터 유지
    previousPath: null,     // 이전페이지 path
    listParams: null,       // 목록페이지 데이터 관리
    detailParams: null,     // 상세페이지 데이터 관리, getParams -> getDetailParams로 변경
    accessToken: null,      // 액세스 토큰
    activeTabInfo: null,     // 현재 활성화된 탭 정보
};

const state = {
    ...initialState
};

const getters = {
    getPreviousPath: state => state.previousPath,       // 이전페이지 path
    getTabHists: state => state.tabHists,               // 탭 관련 이력
    getListCacheFl: state => state.listCacheFl,         // 목록 캐시 여부
    getSearchHists: state => state.searchHists,         // 검색 이력
    getPagingHists: state => state.pagingHists,         // 페이지 목록 수 옵션 관련 이력
    getListParams: state => state.listParams,           // 목록페이지 데이터 관련
    getDetailParams: state => state.detailParams,       // 상세페이지 데이터 관련, getParams -> getDetailParams로 변경
    getPersistedAccessToken: state => state.accessToken, // 엑세스 토큰
    getActiveTabInfo: state => state.activeTabInfo, // 현재 활성화된 탭 정보
};

const mutations = {
  setPreviousPath(state, data) {
    state.previousPath = data;
  },
  setTabHists(state, data) {
    state.tabHists = data;
  },
  setListCacheFl(state, data) {
    state.listCacheFl = data;
  },
  setSearchHists(state, data) {
    state.searchHists = data;
  },
  setPagingHists(state, data) {
    state.pagingHists = data;
  },
  setListParams(state, data) {
    state.listParams = data;
  },
  setDetailParams(state, data) {
    state.detailParams = data;
  },
  resetSessionState(state) {
    Object.assign(state, initialState);
  },
  setPersistedAccessToken(state, payload) {
    state.accessToken = payload;
  },
  setActiveTabInfo(state, payload) {
    console.log('setActiveTabInfo::payload', payload);
    state.activeTabInfo = payload;
  },
};

const actions = {
    setPreviousPath({ commit }, data) {
        commit('setPreviousPath', data);
    },
    setTabHists({ commit }, data) {
        commit('setTabHists', data);
    },
    setListCacheFl({ commit }, data) {
        commit('setListCacheFl', data);
    },
    setSearchHists({ commit }, data) {
        commit('setSearchHists', data);
    },
    setPagingHists({ commit }, data) {
        commit('setPagingHists', data);
    },
    setPageHists({ commit }, data) {
        commit('setPageHists', data);
    },
    setListParams({ commit }, data) {
        commit('setListParams', data);
    },
    setDetailParams({ commit }, data) {
        commit('setDetailParams', data);
    },
    resetSessionState(state) {
        Object.assign(state, initialState);
    },
    setPersistedAccessToken({ commit }, data) {
        commit('setPersistedAccessToken', data);
    },
    setActiveTabInfo({ commit }, data) {
        commit('setActiveTabInfo', data);
    },
};

export default {
    strict: process.env.NODE_ENV !== "production",
    state: {
        ...state
    },
    getters,
    mutations,
    actions
};
