<!--
  PACKAGE_NAME : src/pages/report/config/wizard
  FILE_NAME : detail-header.vue
  AUTHOR : kwmoon
  DATE : 2025. 4. 1.
  DESCRIPTION :
-->
<template>
  <div class="locker_setting_list sub_new_style01 sub_ui_box1">
    <!-- Buttons-->
    <div class="page_search_box line_bottom_1px pb-0">
      <div class="flex justify-between">
        <div class="flex py-4 space-x-1">
          <DxButton text="목록 가기" class="btn_XS white light_filled" :height="30" @click="goWizardListPage" />
          <DxButton text="쿼리 불러오기" class="btn_XS default filled" :height="30" @click="handleClickTemplateModalBtn" />
        </div>
        <div class="flex py-4 space-x-1">
          <DxButton v-if="isEditMode" text="초기화" class="btn_XS white light_filled" :height="30" @click="handleClickInit" />
          <DxButton
            text="보고서 저장"
            class="btn_XS default filled"
            id="Save"
            :height="30"
            @click="handleSaveReport"
            validation-group="validationGroupName"
          />
        </div>
      </div>
    </div>
    <!-- Buttons-->

    <!-- Form -->
    <div class="page_search_box line_bottom_1px pb-0">
      <div class="flex justify-between">
        <div class="flex py-4 space-x-5">
          <div class="flex space-x-2">
            <div class="mt-1">보고서명*</div>
            <DxTextBox v-model="form.name" :width="200" :height="30" styling-mode="outlined">
              <DxValidator validation-group="validationGroupName">
                <DxRequiredRule message="보고서명은 필수 입니다." />
              </DxValidator>
            </DxTextBox>
          </div>
          <div class="flex space-x-2">
            <div class="mt-1">파일명(영문)*</div>
            <DxTextBox v-model="form.fileNm" :width="200" :height="30" styling-mode="outlined">
              <DxValidator validation-group="validationGroupName">
                <DxRequiredRule message="파일명은 필수 입니다." />
              </DxValidator>
            </DxTextBox>
          </div>
          <div class="flex space-x-2">
            <div class="mt-1">소메뉴*</div>
            <DxSelectBox
              value-expr="id"
              display-expr="menuNm"
              placeholder="선택"
              styling-mode="outlined"
              :width="150"
              :height="30"
              :grouped="true"
              :items="menus.group"
              :readOnly="isEditMode"
              :show-clear-button="true"
              v-model="form.parentMenuId"
            >
              <DxValidator validation-group="validationGroupName">
                <DxRequiredRule message="소메뉴 선택은 필수 입니다." />
              </DxValidator>
            </DxSelectBox>
          </div>
          <div class="flex space-x-2">
            <div class="mt-1">솔루션*</div>
            <DxTextBox v-model="form.solution" styling-mode="outlined" :width="120" :height="30" :readOnly="true" />
          </div>
          <div class="flex space-x-2">
            <div class="mt-1">구분*</div>
            <DxTextBox v-model="form.subPath" styling-mode="outlined" :width="120" :height="30" :readOnly="true" />
          </div>
        </div>
        <div class="flex py-4">
          <!-- XML 버튼 (수정&권한 할당된 경우에만 노출) -->
          <DxButton
            v-if="isEditMode && isXmlEditAuthUser"
            text="XML"
            class="btn_XS white light_filled"
            :height="30"
            @click="handleClickXmlEditorModal"
          />
        </div>
      </div>
    </div>
    <!-- Form -->
  </div>
</template>
<script>
  import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxButton } from 'devextreme-vue/button';
  import { isEmpty } from '@/utils/common-lib';

  export default {
    name: 'detail-header',
    components: { DxButton, DxTextBox, DxSelectBox, DxRequiredRule, DxValidator },
    props: {
      isEditMode: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        form: {
          name: '',
          fileNm: '',
          parentMenuId: null,
          solution: '',
          subPath: '',
        },
        menus: {
          group: this.groupingMenuList(),
          depth3: this.getMenuList(3, 'REPORT'),
          selectedId: null,
        },
      };
    },
    computed: {
      /**
       * XML 편집 권한은 시스템 계정만 가능하므로 시스템 설정에서 계정 추가 가능
       * */
      isXmlEditAuthUser() {
        const systemCodeList = this.$store.getters.getSystemCodeList;
        const systemValue = systemCodeList.find(v => v.configKey === 'report_xml_edit_login_id');
        if (isEmpty(systemValue)) {
          return false;
        }

        const loginIds = systemValue.configValue.split(',').map(v => v.trim());
        return loginIds.includes(this.$store.getters.getLoginId);
      },
    },
    methods: {
      /**
       * 솔루션&서브패스 설정
       */
      setSolutionAndSubPath(solution, subPath) {
        this.form.solution = solution;
        this.form.subPath = subPath;
      },

      /**
       * 보고서명 셋팅
       */
      setName(menuNm) {
        this.form.name = menuNm;
      },

      /**
       * 파일명 셋팅
       */
      setFileNm(fileNm) {
        this.form.fileNm = fileNm;
      },

      /**
       * 소메뉴 셋팅
       */
      setParentMenuId(menuId) {
        this.form.parentMenuId = this.getParentMenuId(menuId);
      },

      /**
       * 위자드 목록 페이지 가기
       * */
      goWizardListPage() {
        this.$router.push('/report/config/wizard/list');
      },

      /**
       * 소메뉴 그룹핑
       */
      groupingMenuList() {
        const depth1 = this.getMenuList(1);
        const depth2 = this.getMenuList(2);

        return depth1.reduce((prev, curr) => {
          prev.push({
            key: curr.menuNm,
            items: depth2.filter(menu => menu.parentId === curr.id),
          });

          return prev;
        }, []);
      },

      /**
       * 부모 메뉴 ID 반환
       */
      getParentMenuId(menuId) {
        const menuList = this.$store.getters.getMenuList;
        const menu = menuList.find(v => v.id === menuId);
        if (menu) {
          return menu.parentId;
        }
        return null;
      },

      /**
       * 메뉴 필터 및 조회 함수
       * */
      getMenuList(menuDepth, typeCd) {
        const menuList = this.$store.getters.getMenuList;
        if (isEmpty(typeCd)) {
          // 2뎁스 메뉴 전체 조회
          return menuList.filter(v => v.menuDepth === menuDepth);
        }
        // 보고서 메뉴 조회
        return menuList.filter(v => v.menuDepth === menuDepth && v.menuTypeCd === typeCd);
      },

      /**
       * 보고서 저장 버튼
       */
      handleSaveReport() {
        this.$emit('save');
      },

      /**
       * XML 모달 토글
       */
      handleClickXmlEditorModal() {
        this.$emit('openXmlModal', true);
      },

      /**
       * 템플릿 모달 열기
       */
      handleClickTemplateModalBtn() {
        this.$emit('openTemplateModal', true);
      },

      /**
       * 양식(form) 반환
       */
      getFromData() {
        return {
          menuNm: this.form.name,
          fileNm: this.form.fileNm,
          parentMenuId: this.form.parentMenuId,
          solution: this.form.solution,
          subPath: this.form.subPath,
        };
      },

      /**
       * 초기화 버튼 실행
       */
      async handleClickInit() {
        const message = this.$_lang('REPORT.MESSAGE.INIT_REPORT_ALERT', {
          defaultValue: '편집하던 보고서를 초기화 하시겠습니까?',
        });

        // 초기화 시, 모든 데이터 초기화
        if (await this.$_Confirm(message)) {
          await this.$emit('init');
        }
      },
    },
    updated() {},
    created() {},
    mounted() {},
    beforeDestroy() {},
    destroyed() {},
  };
</script>
