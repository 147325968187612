<!--
  PACKAGE_NAME : src/pages/report/config/wizard
  FILE_NAME : detail-option.vue
  AUTHOR : kwmoon
  DATE : 2025. 4. 1.
  DESCRIPTION :
-->
<template>
  <div class="px-4">
    <table class="line-bin">
      <tbody>
        <tr v-for="{ id, title, col, type, disabled } in checkListLayout" :key="id">
          <template v-if="type === 'title'">
            <td :colspan="col" class="pt-4">
              <h2 class="font-medium text-xl pb-1.5">{{ title }}</h2>
            </td>
          </template>
          <template v-if="type === 'checkbox'">
            <td>
              <DxCheckBox v-model="search[id]" class="ml-2" :disabled="disabled" />
            </td>
            <td
              class="cursor-pointer indent-1 py-0.5"
              :class="{ 'text-gray-400 pointer-events-none select-none': disabled }"
              @click="onClickLabel(`search.${id}`)"
            >
              {{ title }}
            </td>
          </template>
        </tr>
        <tr>
          <td colspan="2" class="pt-4">
            <h2 class="font-medium text-xl pb-1.5">조회 대상</h2>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="py-0.5">
            <dx-list
              placeholder=""
              :items="target.items"
              :selected-item-keys="target.selectedItems"
              @selection-changed="onSelectionChanged"
              :height="400"
              :show-selection-controls="true"
              :select-by-click="true"
              key-expr="key"
              display-expr="label"
              selection-mode="multiple"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
  import { DxList } from 'devextreme-vue/list';
  import DxCheckBox from 'devextreme-vue/check-box';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
  import { isTrue } from '@/utils/common-lib';

  export default {
    name: 'detail-option',
    components: { DxList, DxSelectBox, DxRequiredRule, DxValidator, DxCheckBox },
    props: {},
    data() {
      return {
        // 체크박스
        search: {
          //검색기간
          periodDay: false, //일
          periodMonth: false, //월
          periodYear: false, //년
          periodRange: false, //기간
          //보고서 양식
          reportDay: false, //일별
          reportMonth: false, //월별
          reportHour: false, //시간별
          reportMin30: false, //30분별
          reportMin15: false, //15분별
          reportDayHour: false, //일+시간별
          reportWeekDay: false, //요일별
          //요일, 시간
          days: false, //요일
          times: false, //시간
          //페이징 처리 여부
          paging: false,
        },
        //TODO: 템플릿 또는 보고서에서 존재하지 않는 태그는 disabled 처리해야 하는데,
        //TODO: API 에서 타입이 boolean 으로 정의되어 있으므로 보류 (급하지 않아 보류)
        checkListLayout: [
          //검색 기간
          { id: 'title-search-date', title: '기간', type: 'title', col: 2 },
          { id: 'periodDay', title: '일별', type: 'checkbox', disabled: false },
          { id: 'periodMonth', title: '월별', type: 'checkbox', disabled: false },
          { id: 'periodYear', title: '연도별', type: 'checkbox', disabled: false },
          { id: 'periodRange', title: '특정기간', type: 'checkbox', disabled: false },
          //보고서 양식
          { id: 'title-report-type', title: '보고서 양식', type: 'title', col: 2 },
          { id: 'reportDay', title: '일별', type: 'checkbox', disabled: false },
          { id: 'reportMonth', title: '월별', type: 'checkbox', disabled: false },
          { id: 'reportHour', title: '시간별', type: 'checkbox', disabled: false },
          { id: 'reportMin30', title: '30분별', type: 'checkbox', disabled: false },
          { id: 'reportMin15', title: '15분별', type: 'checkbox', disabled: false },
          { id: 'reportWeekDay', title: '요일별', type: 'checkbox', disabled: false },
          { id: 'reportDayHour', title: '일+시간별', type: 'checkbox', disabled: false },
          //요일/시간
          { id: 'title-day-time', title: '요일/시간', type: 'title', col: 2 },
          { id: 'days', title: '요일', type: 'checkbox', disabled: false },
          { id: 'times', title: '시간', type: 'checkbox', disabled: false },
          //페이징 처리
          { id: 'title-paging', title: '페이징', type: 'title', col: 2 },
          { id: 'paging', title: '사용', type: 'checkbox', disabled: false },
        ],
        target: {
          items: [],
          selectedItems: [],
          getName: () => {
            return this.reportTarget.items.find(item => item.id === this.reportTarget.selectedId)?.name;
          },
        },
      };
    },
    computed: {},
    methods: {
      /**
       * 라벨 클릭시 체크박스 토글
       */
      onClickLabel(id) {
        const ids = id.split('.');
        let that = this;
        for (let i = 0; i < ids.length - 1; i++) {
          const key = ids[i];
          that = that[key];
          if (that === undefined) return;
        }

        if (that) {
          const lastKey = ids[ids.length - 1];
          that[lastKey] = !that[lastKey];
        }
      },

      /**
       * <search> 태그 옵션 변경
       */
      updateSearch(key, value) {
        this.search[key] = value;
      },

      onSelectionChanged(e) {
        console.log(e);
        this.target.selectedItems = e.component.option('selectedItemKeys');
      },

      /**
       * XML 데이터 (search, targets) 데이터 셋팅
       */
      updateOptionsByXmlData(obj) {
        // 옵션
        this.checkListLayout.forEach(item => {
          if (item.type === 'checkbox') {
            const value = obj[item.id];
            if (value === undefined) {
              item.disabled = true;
              this.updateSearch(item.id, false);
              return;
            }
            this.updateSearch(item.id, obj[item.id]);
          }
        });

        // 조회대상
        this.target.items = obj.targets;
        this.target.selectedItems = this.getVisibleTargets(obj.targets);
      },

      /**
       * 조회대상에서 visible이 false 가 아닌 것인 것만 필터링 (속성 없을 시도 true 로 판단)
       */
      getVisibleTargets(targets) {
        return targets.filter(item => isTrue(item.visible)).map(item => item.key);
      },

      /**
       * 조회대상에서 선택 여부에 따라 visible = true | false, 로 변경
       */
      updateVisibleTargets() {
        return this.target.items.map(item => {
          item.visible = this.target.selectedItems.includes(item.key);
          return item;
        });
      },

      /**
       * XML 데이터 (search, targets) 데이터 반환
       */
      getOptions() {
        console.log('getOptions', this.target.selectedItems, this.updateVisibleTargets());
        return {
          search: this.search,
          targets: this.updateVisibleTargets(),
        };
      },
    },
    updated() {},
    created() {},
    mounted() {},
    activated() {},
    deactivated() {},
    beforeDestroy() {},
    destroyed() {},
  };
</script>
<style lang="scss" scoped>
  ::v-deep .dx-list-item-before-bag.dx-list-select-checkbox-container,
  .dx-list-item-before-bag.dx-list-select-radiobutton-container {
    width: 36px;
  }
  ::v-deep .dx-list-item .dx-ripple {
    display: none !important;
  }
</style>
