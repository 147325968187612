<template>
  <div class="main">
    <esp-dx-data-grid :data-grid="dataGrid" ref="lineGrid"/>
    <DxPopup
        :show-title="true"
        :title="modal.title"
        :width="modal.width"
        :height="modal.height"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :hide-on-outside-click="false"
        :visible="modal.isOpened"
        @hiding="isOpenModal(false)"
    >
      <DxScrollView width="100%" height="100%">
        <ModalAddLine
            :isOpened="this.modal.isOpened"
            :cmList="this.cmList"
            :partitionList="this.partitionList"
            :cssList="this.cssList"
            :pickupGroupList="this.pickupGroupList"
            @closeModal="closeModal"
        ></ModalAddLine>
      </DxScrollView>
    </DxPopup>
  </div>
</template>
<script>
import {isSuccess} from '@/utils/common-lib';
import {DxPopup} from 'devextreme-vue/popup';
import ModalAddLine from './modal-add-line.vue';
import {DxScrollView} from 'devextreme-vue/scroll-view';
import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';

export default {
  components: {
    EspDxDataGrid,
    DxPopup,
    ModalAddLine,
    DxScrollView,
  },
  data() {
    return {
      stylingMode: 'outlined', //outlined, underlined, filled
      modal: {
        isOpened: false,
        title: this.$_lang('UC.WORD.LINE_ADD'),
        width: '60%',
        height: '650',
      },
      cmList: [],
      partitionList: [],
      pickupGroupList: [],
      cssList: [],
      dataGrid: {
        callApi: 'CALL_EUC_API',
        excel: {
          title: this.$_lang('UC.WORD.LINE_INFO', {defaultValue: '회선 정보'}),
        },
        refName: 'lineGrid',
        dataSourceDefaultSortColumn: '+regDt,+id',
        apiActionNm: {
          select: 'EUC_LINE_SELECT',
        },
        showActionButtons: {
          excel: true,
          delete: false,
          customButtons: [
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: this.$_lang('COMPONENTS.DELETE'),
                elementAttr: {class: 'btn_XS white light_filled trash'},
                height: 30,
                onClick: () => {
                  this.onDeleteLine();
                },
              },
              location: 'before',
            },
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: this.$_lang('COMPONENTS.ADD'),
                elementAttr: {class: 'btn_XS default filled '},
                height: 30,
                onClick: () => {
                  this.isOpenModal(true);
                },
              },
              location: 'before',
            },
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: this.$_lang('UC.WORD.LINE_SYNC'),
                elementAttr: {class: 'btn_XS default filled '},
                height: 30,
                onClick: () => {
                  this.onSyncLine();
                },
              },
              location: 'before',
            }
          ],
        },
        editing: {
          allowAdding: false,
          allowUpdating: false,
        },
        columns: [
          {
            caption: '교환기',
            i18n: 'UC.WORD.EXCHANGER',
            dataField: 'cmNm',
            allowEditing: false,
            calculateDisplayCellValue: rowData => this.getCmNm(rowData.cmNm),
          },
          {
            caption: '내선번호',
            i18n: 'UC.WORD.NUMBER',
            dataField: 'pattern',
            allowEditing: false,
          },

          {
            caption: '표시(경고이름)',
            i18n: 'UC.WORD.ALERTINGNAME',
            dataField: 'alertingname',
            allowEditing: false,
          },
          {
            caption: '파티션',
            i18n: 'UC.WORD.PARTITION',
            dataField: 'routepartitionname',
            allowEditing: false,
          },
          {
            caption: '발신검색공간',
            i18n: 'UC.WORD.CSS',
            dataField: 'css',
            allowEditing: false,
          },

          {
            caption: '당겨받기그룹',
            i18n: 'UC.WORD.PICKUPGROUP',
            dataField: 'callpickupgroupname',
            allowEditing: false,
          },
          {
            caption: '외부통화제어',
            i18n: 'UC.WORD.EXTERNALCALLCONTROLPROFILE',
            dataField: 'externalcallcontrolprofile',
            allowEditing: false,
          },
          {
            caption: '수정일',
            i18n: 'COMPONENTS.MODIFY_DATE',
            dataField: 'editDt',
            allowEditing: false,
            calculateCellValue: this.formatDt,
          },
        ],
      },
    };
  },
  methods: {
    /** @description : 라이프사이클 mounted시 호출되는 메서드 */
    mountData() {
      Promise.all([
        this.selectCmList(),
        this.selectPartitionList(),
        this.selectCssList(),
        this.selectPickupGroupList(),
      ]);
    },
    /** @description : 날짜 형식 변환 메서드 */
    formatDt(rowData) {
      if (rowData?.editDt) {
        return this.$_commonlib.formatDate(rowData.editDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
      }
    },
    /**@description 교환기 목록 가져오는 메서드 */
    async selectCmList(sort = '+cmOrd') {
      const payload = {
        actionName: 'EUC_CM_SELECT',
        data: {
          sort,
          viewFl: 'Y'
        },
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        if (res.data.data?.length) {
          this.cmList = res.data.data.map((item) => {
            return {
              cmNm: item.cmNm,
              cmOrd: item.cmOrd,
              description: item.description,
              editId: item.editId,
              editDt: item.editDt,
              regId: item.regId,
              viewFl: item.viewFl
            };
          });
        }
      }
    },
    /**@description 파티션 목록 가져오는 메서드 */
    async selectPartitionList(sort = '+regDt,+id') {
      const payload = {
        actionName: 'EUC_PARTITION_SELECT',
        data: {
          sort,
          viewFl: 'Y'
        },
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        if (res.data.data?.length) {
          this.partitionList = res.data.data;
        }
      }
    },
    /**@description 발신검색 목록 가져오는 메서드 */
    async selectCssList(sort = '+regDt,+id') {
      const payload = {
        actionName: 'EUC_CSS_SELECT',
        data: {
          sort,
          viewFl: 'Y'
        },
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        if (res.data.data?.length) {
          this.cssList = res.data.data;
        }
      }
    },
    /**@description 당겨받기 목록 가져오는 메서드 */
    async selectPickupGroupList(sort = '+regDt,+id') {
      const payload = {
        actionName: 'EUC_PICKUPGROUP_SELECT',
        data: {
          sort,
          viewFl: 'Y'
        },
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        if (res.data.data?.length) {
          this.pickupGroupList = res.data.data;
        }
      }
    },
    /**@description 내선삭제 메서드 */
    async onDeleteLine() {
      const selectedRowsData = this.$refs.lineGrid.getInstance.getSelectedRowsData();
      if (!selectedRowsData?.length) {
        this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', {defaultValue: '대상이 선택되어 있지 않습니다.'}));
      } else {
        let flag = true;
        let prevCm = '';
        for (let i = 0; i < selectedRowsData.length; i++) {
          if (i === 0) {
            prevCm = selectedRowsData[i].cmNm;
            continue;
          }
          if (prevCm !== selectedRowsData[i].cmNm) {
            flag = false;
          }
          prevCm = selectedRowsData[i].cmNm;
        }

        if (!flag) {
          await this.$_Msg(this.$_lang('UC.MESSAGE.CHECK_EXCHANGER_TYPE'));
          return;
        }

        const payload = {
          actionName: 'EUC_LINE_DELETE',
          data: {
            cmNm: prevCm,
            loginId: this.$store.getters.getLoginId,
            linelist: selectedRowsData.map(item => {
              return {
                pattern: item.pattern,
                routepartitionname: item.routepartitionname
              };
            }),
          },
          loading: true,
        };

        const confirm = this.$_lang('COMMON.MESSAGE.CMN_CFM_DELETE');
        if (!(await this.$_Confirm(confirm))) {
          return;
        }
        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS', {defaultValue: '정상적으로 처리되었습니다.'}));
          await this.$refs.lineGrid.getInstance.clearSelection();
          await this.$refs.lineGrid.getInstance.refresh();
        } else {
          await this.$_Msg(res.data.header.resMsg);
        }
      }
    },
    /** @description 전화기 동기화 메서드*/
    async onSyncLine() {
      const confirm = this.$_lang('UC.MESSAGE.START_SYNC');
      if (!(await this.$_Confirm(confirm))) {
        return;
      }

      const requests = this.cmList.map(async (item) => {
        const payload = {
          actionName: 'EUC_LINE_SYNC',
          data: {
            cmNm: item.cmNm,
            loginId: this.$store.getters.getLoginId,
          },
          loading: true,
        };

        const res = await this.CALL_EUC_API(payload);
        if (!isSuccess(res)) {
          const errorMessage = res.data.data?.length
              ? `교환기 : ${item.cmNm} ${res.data.header.resMsg}`
              : res.data.header.resMsg;
          await this.$_Msg(errorMessage);
        }
        return res;
      });

      const results = await Promise.all(requests);
      if (results.some(isSuccess)) {
        this.$refs.lineGrid.getInstance.refresh();
      }
    },
    /** @description 교환기 이름 한글 변환 메서드
     *  @param {String} cm Name
     *  @returns {String} 한글명
     */
    getCmNm(cm) {
      return this.cmList?.find(d => d.cmNm === cm)?.description || '';
    },
    /**@description : 내선관리 모달 Open/Close */
    isOpenModal(bool) {
      this.modal.isOpened = bool;
    },
    /**@description : 내선관리 모달 close 콜백함수 */
    async closeModal(flag) {
      if (flag) {
        this.isOpenModal(false);
      }
    },
  },
  mounted() {
    this.mountData();
  },
};
</script>
