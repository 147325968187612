import moment from 'moment';
import SHA256 from 'crypto-js/sha256';
import iconv from 'iconv-lite';
import { getAesUtil } from '@/utils/aes-util';

/**
 * 정규식 패턴 모음
 * 다양한 문자열 검사를 위한 정규식을 미리 정의하고 객체로 관리합니다.
 */
export const regexPatterns = Object.freeze({
  isHangul: {
    type: 'isHangul',
    pattern: /[ㄱ-ㅎㅏ-ㅣ가-힣]/,
    description: '한글 문자(자음, 모음, 완성형 한글)를 포함하는 정규식',
  },
  isEngAndNumber: {
    type: 'isEngAndNumber',
    pattern: /^[A-Za-z0-9+]*$/,
    description: '영문자(A-Z, a-z)와 숫자(0-9)로만 구성된 문자열인지 확인하는 정규식',
  },
  isNumber: {
    type: 'isNumber',
    pattern: /[^0-9]/g,
    description: '숫자를 포함하는 정규식',
  },
  isTrimSpaces: {
    type: 'isTrimSpaces',
    pattern: /^\s+|\s+$/g,
    description: '문자열의 앞뒤에 있는 공백을 찾는 정규식',
  },
  isComma: {
    type: 'isComma',
    pattern: /,/g,
    description: '문자열 내 모든 콤마(,)를 찾는 정규식',
  },
  isLowercase: {
    type: 'isLowercase',
    pattern: /[a-z]/,
    description: '소문자 알파벳 문자를 포함하는 정규식',
  },
  isUppercase: {
    type: 'isUppercase',
    pattern: /[A-Z]/,
    description: '대문자 알파벳 문자를 포함하는 정규식',
  },
});

/**
 * @description : 패스워드를 지정된 암호화 방식(SHA256 또는 AES256)으로 암호화하는 함수
 * @param {string} id - 사용자 ID (SHA256 암호화 시 조합에 사용)
 * @param {string} pwd - 암호화할 비밀번호
 * @param {string} [type='SHA256'] - 암호화 방식 (기본값: SHA256. AES256 또는 SHA256 지정 가능)
 * @returns {string} - 암호화된 비밀번호 문자열
 */
export const encryptPassword = (id, pwd, type = 'SHA256') => {
  if (type === 'AES256') {
    const aesUtil = getAesUtil();
    return aesUtil.encrypt(pwd);
  } else {
    return SHA256(pwd + id).toString();
  }
};

/**
 * @description : HttpStatus 200 체크 && ESP API resCode === 'success' 체크(DefaultResponse 객체)
 * @param response DefaultResponse 객체
 * @return true || false
 */
export const isSuccess = response => {
  try {
    return response.status === 200 && response.data.header.resCode === 'success';
  } catch (e) {
    return false;
  }
};

/**
 * @description ESP API 결과 데이터 가져오기(DefaultResponse 객체)
 * @param response DefaultResponse 객체
 * @return {*}
 */
export const getResData = response => {
  return response?.data?.data;
};

/**
 * @description : 서버 응답으로 받은 Blob 데이터를 사용하여 파일을 다운로드하는 함수
 * @param {Object} response - 서버 응답 객체
 * @param {Blob} response.data - 다운로드할 데이터를 포함하는 Blob 객체
 * @param {string} response.headers['content-disposition'] - 파일 이름 정보가 포함된 Content-Disposition 헤더
 */
export const downloadBlobFile = response => {
  const url = createBlobURL(response.data);
  const fileName = extractFileName(response.headers['content-disposition'], { decodeType: 'iconv' });
  triggerFileDownload(url, fileName);
};

/**
 * @description : Blob 데이터를 URL 객체로 변환하여 다운로드 가능한 URL을 생성하는 함수
 * @param {BlobPart} data - Blob 데이터를 구성하는 데이터 (예: ArrayBuffer, TypedArray)
 * @returns {string} - Blob 데이터를 참조하는 URL
 */
export const createBlobURL = data => {
  return window.URL.createObjectURL(new Blob([data]));
};

/**
 * @description : Content-Disposition 헤더에서 파일 이름 정보를 추출하는 함수
 * @param {string} contentDisposition - Content-Disposition 헤더 문자열
 * @param {Object} options - 옵션 객체
 * @param {string} [options.decodeType=null] - 디코딩 방식 ("uri" | "iconv" | null)
 * @param {string} [options.charset='utf-8'] - "iconv" 디코딩 선택 시 사용할 문자셋 (기본값: "utf-8")
 * @returns {string} - 추출된 파일 이름, 파일 이름이 없을 경우 "unknown" 반환
 */
export const extractFileName = (contentDisposition, options = {}) => {
  const { decodeType = null, charset = 'utf-8' } = options;
  let fileName = 'unknown';

  if (contentDisposition) {
    // "filename" 파싱
    const fileNameMatch = contentDisposition
      .split(';')
      .find(str => str.includes('filename'));
    if (fileNameMatch) {
      [, fileName] = fileNameMatch.split('=');
      fileName = fileName.replace(/"/g, '').trim(); // 불필요한 따옴표 제거
    }

    // 디코딩 옵션 처리
    if (fileName) {
      try {
        if (decodeType === 'uri') {
          // URI Percent-Encoding 디코딩
          fileName = decodeURIComponent(fileName);
        } else if (decodeType === 'iconv') {
          // iconv를 사용한 Binary 데이터 디코딩
          const buffer = Buffer.from(fileName, 'binary');
          fileName = iconv.decode(buffer, charset);
        }
      } catch (e) {
        console.warn(`파일명 디코딩(${decodeType}) 실패: ${fileName}`, e);
      }
    }
  }

  return fileName;
};


/**
 * @description : Blob 데이터를 참조하는 URL과 파일 이름을 기반으로 사용자의 브라우저에서 파일 다운로드를 트리거하는 함수
 * @param {string} url - Blob 데이터를 참조하는 URL
 * @param {string} fileName - 다운로드 대상 파일의 이름
 * @example
 * triggerFileDownload('blob:http://example.com', 'example-file.txt');
 */
export const triggerFileDownload = (url, fileName) => {
  const decodedFilename = fileName;
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${decodedFilename}`);
  link.style.cssText = 'display:none'; // 링크를 숨김
  document.body.appendChild(link); // 링크를 DOM에 추가
  link.click(); // 사용자 다운로드 동작 호출
  document.body.removeChild(link); // 링크를 명시적으로 DOM에서 제거
};


/**
 * @description : 객체(Object)를 깊은 복사(deep copy)하여 새 객체를 반환하는 함수
 * @param {Object} obj - 깊은 복사를 할 대상 객체
 * @returns {Object} - 입력된 객체 복사본
 */
export const cloneObj = obj => {
  return JSON.parse(JSON.stringify(obj));
};

/**
 * @description : 데이터가 비어있는지 확인하는 함수
 *                 (빈 객체, 빈 배열, undefined, null, '', 0 등을 체크)
 * @param {*} data - 검사할 데이터
 * @returns {boolean} - 비어있으면 true, 그렇지 않으면 false
 */
export const isEmpty = data => {
  if (!data) {
    return true;
  } else {
    if (data.constructor === Object && Object.keys(data).length === 0) {
      return true;
    }
    return data.constructor === Array && data.length === 0;
  }
};

/**
 * @description : 주어진 날짜(date)를 fromFormat 형식에서 toFormat 형식으로 변환하는 함수
 *                (Moment.js를 활용하여 형식 변환)
 * @param {*} date - 변환할 대상 날짜
 * @param {*} fromFormat - 입력 날짜의 기존 형식
 * @param {*} toFormat - 변환할 형식 (기본값: null, 형식을 지정하지 않으면 moment 객체 반환)
 * @returns {string|Object} - 변환된 날짜 문자열(toFormat 지정 시) 또는 moment 객체(toFormat이 null일 경우)
 */
export const formatDate = (date, fromFormat, toFormat = null) => {
  let formatDate;
  if (toFormat !== null) {
    formatDate = moment(date, fromFormat).format(toFormat);
  } else {
    formatDate = moment(date, fromFormat); //.format(fromFormat);
  }
  return formatDate;
};

/**
 * @description : 주어진 날짜를 지정된 형식(toFormat)으로 변환하는 함수
 * @param {string|Date|moment.Moment} date - 변환할 대상 날짜
 * @param {string} toFormat - 출력하고자 하는 날짜 포맷 (예: 'YYYY-MM-DD', 'MM/DD/YYYY')
 * @returns {string} - 변환된 날짜 문자열 (유효하지 않은 값은 빈 문자열 반환)
 */
export const convertDateFormat = (date, toFormat) => {
  if (!date || !toFormat) {
    return '';
  }
  return moment(date).format(toFormat);
};

/**
 * @description : 주어진 날짜(date)에 년, 월, 일을 추가하거나 빼는 함수
 * @param {'year'|'month'|'day'} type - 계산할 단위 ('year', 'month', 'day' 중 하나)
 * @param {string|Date} date - 기준이 되는 날짜 (문자열 형식 또는 Date 객체)
 * @param {number} value - 추가하거나 뺄 값 (양수는 더하기, 음수는 빼기)
 * @returns {Date} - 계산된 새로운 날짜
 */
export const setCalculateDate = (type, date, value) => {
  date = new Date(date);
  switch (type) {
    case 'year':
      date.setFullYear(date.getFullYear() + value);
      break;
    case 'month':
      date.setMonth(date.getMonth() + value);
      break;
    case 'day':
      date.setDate(date.getDate() + value);
      break;
  }
  return date;
};

/**
 * @description 오늘 날짜를 기준으로 과거의 날짜를 반환합니다.
 * @param {number} number - 변경할 값 (양수로 날짜를 빼는 값)
 * @param {string} unit - moment.js에서 사용하는 단위(e.g., 'days', 'months', 'years')
 * @param {string} [format='YYYYMMDD'] - 반환할 날짜의 포맷
 * @returns {string} 포맷팅된 과거의 날짜 문자열
 * @throws {Error} 유효하지 않은 입력이 들어오면 예외 발생
 */
export const getPastFromToday = (number, unit, format = 'YYYYMMDD') => {
  if (typeof number !== 'number' || isNaN(number)) {
    throw new Error('`number`는 숫자여야 합니다.');
  }
  if (typeof unit !== 'string' || !['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'].includes(unit)) {
    throw new Error(`'unit'는 유효한 moment.js 단위 중 하나여야 합니다.`);
  }
  if (typeof format !== 'string') {
    throw new Error('`format`은 문자열이어야 합니다.');
  }

  return moment().subtract(number, unit).format(format);
};

/**
 * @description 주어진 값이 논리적 true인지 확인합니다.
 *
 * 숫자형, 문자열, boolean 모두 지원하며, 값이 'true'(대소문자 무관)로 표현되는지 확인합니다.
 *
 * 처리되는 타입과 동작:
 * - `boolean`: true/false 그대로 반환
 * - `undefined` 및 `null`: false 반환
 * - `string`: 문자열이 'true'일 경우 true 반환 (대소문자 무관)
 * - 다른 값: 기본적으로 false 반환
 *
 * @param {any} value 검사할 값
 * @returns {boolean} 값이 true로 평가되면 true, 그렇지 않으면 false
 */
export const isTrue = value => {
  // Boolean 타입 처리
  if (typeof value === 'boolean') {
    return value; // true/false 그대로 반환
  }

  // undefined 또는 null 처리
  if (value === undefined || value === null) {
    return false; // undefined, null은 false 처리
  }

  // 문자열 케이스 처리
  if (typeof value === 'string') {
    return value.trim().toLowerCase() === 'true'; // 대소문자 무관, 공백 제거 후 체크
  }

  // 기타 타입 처리 (숫자, 객체 등은 false로 간주)
  return false;
};

/**
 * @description 객체에서 특정 key를 삭제한 새로운 객체를 반환합니다. <br>
 * - 원본 객체는 변경되지 않습니다. (불변성 유지) <br>
 * - 삭제할 키가 존재하지 않더라도, 원본 객체의 사본이 반환됩니다. <br>
 *
 * @param {Object} object 수정할 대상 객체
 * @param {string} deletedKey 삭제할 키의 이름
 * @returns {Object} 삭제된 키를 제외한 새로운 객체
 */
export const deleteObjectKey = (object, deletedKey) => {
  return Object.keys(object)
    .filter(key => key !== deletedKey)
    .reduce((obj, key) => {
      obj[key] = object[key];
      return obj;
    }, {});
};

/**
 * @description : startTime, endTime 사이의 경과시간을 계산 하는 함수
 * @param {date} startTime 시작일시 (포맷 : YYYY-MM-DDTHH:mm:SS)
 * @param {date} endTime 종료일시 (포맷 : YYYY-MM-DDTHH:mm:SS)
 * @param {string} delimiter 경과시분초별 구분자
 * @param {number} formatDigitNum 시분초 포맷 (기본값: 00)
 * @returns {string or object{hours,minutes,seconds}}
 */
export const calculateElapsedTime = (startTime, endTime, delimiter, formatDigitNum = 2) => {
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);

  const timeDifference = endDate - startDate;

  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  const formatTwoDigits = number => String(number).padStart(formatDigitNum, '0');

  if (delimiter) {
    return `${formatTwoDigits(hours)}${delimiter}${formatTwoDigits(minutes)}${delimiter}${formatTwoDigits(seconds)}`;
  } else {
    return {
      hours: formatTwoDigits(hours),
      minutes: formatTwoDigits(minutes),
      seconds: formatTwoDigits(seconds),
    };
  }
};

/**
 * 숫자 두자리 수 생성(10 이하 정수 앞에 0 붙이기)
 * @param str
 * @param len
 * @returns {*|string}
 */
export const lPadZero = (str, len) => {
  str = str + '';
  return str.length >= len ? str : new Array(len - str.length + 1).join('0') + str;
};

/**
 * 문자열을 CamelCase로 변환
 *
 * @param str
 * @return {string}
 */
export const toCamelCase = str => {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};

/**
 * @description 문자열의 바이트 수를 계산하는 함수 (utf-8 기준)
 * @param str
 * @return {number}
 */
export const calculateByteLength = (str) => {
  return Buffer.from(str, 'utf8').length;
}

/**
 * @description 입력값이 최대 바이트 수를 넘지 않는지 확인하는 함수
 * @param input
 * @param maxBytes
 * @return {boolean}
 */
export const isInputValidByByteLength = (input, maxBytes) => {
  return calculateByteLength(input) <= maxBytes;
}

/**
 * @description 문자열을 해시값으로 변환하는 함수
 * @param str
 * @return {string}
 */
export const stringToHash = (str) => {
  let hash = 0;

  if (str.length === 0) {
    return hash.toString();
  }

  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash; // 32비트 정수로 변환
  }

  // 항상 양수 해시값을 반환하도록 함
  return Math.abs(hash).toString(16);
}

export { moment };
