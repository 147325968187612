export default {
  MESSAGE: {
    WARNING_DELETE_PROJECT: 'When deleting a project, all data is deleted together.<br>Do you want to delete?',
    UNSAVED_PROMPT_CHANGE_ALERT: 'Your prompt work has not been saved. <br>Do you want to change it?',
    UNSAVED_PROMPT_MOVE_ALERT: 'Your prompt work has not been saved. <br>Do you want to move?',
    NEW_PROMPT_ADD_ALERT: 'A new prompt is created without modifying the selected prompt.',
    NEW_PROMPT_NAME_ALERT: 'Enter a title for the new prompt.',
    DELETE_SYSTEM_PROMPT_ALERT: 'When deleting a system prompt, the query sets that are using that prompt will also be deleted.<br/> Do you want to delete it?',
    ENCRYPTED_INDEX_FILE: 'For security reasons, password-protected files cannot be uploaded. Please unlock the password and try again.',
    SUPPORTED_INDEX_FILE: 'Supported file formats : {extension}',
    DUPLICATE_INDEX_FILE: 'Duplicate file is already registered.',
    DELETE_INDEX_FILE_ALERT1: 'When deleting an index file, the search documents used in the query are also deleted.',
    DELETE_INDEX_FILE_ALERT2: 'Do you want to delete the selected {value} files?',
    DUPLICATE_MODEL: 'A model with the same conditions already exists.',
    UNSET_PROMPT: 'No prompt is selected.',
    UNSET_CONFIG: 'Setting value does not exist.',
    PLEASE_ADD_INDEX: 'There are no registered indexes. Please add a new index.',
    DONT_EXIST_FILE: 'There are no search documents to select.',
    DONT_EXITS_SEARCH_RESULT: 'No search results exist\n.',
    UNSELECT_SEARCH_DOCUMENT_ALERT: 'When you disable search, the search documents are initialized. <br/>Do you want to turn it off?',
    INPUT_1_TO_2000: 'Only integers from 1 to 2000 can be entered.',
    ADD_TEST_ALERT1: 'The testing process may take some time.',
    ADD_TEST_ALERT2: 'Do you want to add the test?',
    CANCEL_TEST_ALERT: 'Are you sure you want to cancel this test?',
    SUC_CANCEL: 'Canceled successfully.',
    EXIST_PLAYGROUND_SESSION: 'Playground is currently unavailable because another user is working on it.',
    DO_ENTER_QUERY: 'Enter your question to use Playground',
    INIT_SESSION_ALERT: 'When you reset the session, all current questions and answers will be deleted.<br/> Do you really want to reset?',
    DISCONNECT_SESSION_ALERT: 'The Playground session has ended. <br/>Please re-enter the page to use.',
    LIMIT_MESSAGE: 'The length of [Query/Answer] in [Message] cannot exceed {limitNumber} characters.',
    INVALID_JSON: 'The value of [{value}] can only be in JSON format .(“…{preview}…”)',
    INVALID_MODEL: 'Invalid model.',
    DELETE_LOCAL_MODEL_ALERT: 'When deleting local model, models in projects that are using the model will also be deleted.<br/>Do you want to delete it?',
  },
  WORD: {
    PROJECT: 'Project',
    PROJECT_NAME: 'Project Name',
    PROJECT_ID: 'Project ID',
    TEST_CONFIG: 'Test Config',
    ADD_TEST: 'Adding Test',
    SEARCHING_TEST: 'Searching Test',
    DETAIL: 'Detail',
    PROMPT: 'Prompt',
    QUERY_REWRITING_PROMPT: 'Query Rewrite Prompt',
    SYSTEM_PROMPT: 'System Prompt',
    EVALUATION_PROMPT: 'Evaluation Prompt',
    PROMPT_ADD: 'Add New Prompt',
    SYSTEM_PROMPT_ADD: 'Add New System Prompt',
    QUERY_REWRITING_PROMPT_ADD: 'Add New Query Rewrite Prompt',
    EVALUATION_PROMPT_ADD: 'Add New Evaluation Prompt',
    SELECT_PROMPT: 'Select Prompt',
    QUERY_SET: 'Query Set',
    INDEX_CONFIG: 'Index Config',
    ADD_DATE: 'Added Date',
    PARSING_RESULT: 'Parsing Result',
    QUERY_TYPE: 'Category',
    QUERY: 'Query',
    ROLE: 'Role',
    ANSWER: 'Answer',
    CORRECT_ANSWER: 'Correct Answer',
    SUPPORTING_DOCUMENT: 'Supporting Document',
    REPEAT_COUNT: 'Repeat Count',
    SYNTHETIC_OPTION: 'Synthetic Option',
    SUBSTANTIVE_SYNONYM: 'Substantive Synonym',
    SUBSTANTIVE_TYPO: 'Substantive Typo',
    PREDICATE_SYNONYM: 'Predicate Synonym',
    PREDICATE_TYPO: 'Predicate Typo',
    PREDICATE_ENDING: 'Predicate Ending',
    SYNTHETIC_COUNT: 'Synthetic Count',
    QUERY_ADD: 'Add New Query',
    QUERY_UPDATE: 'Update Query',
    COPY_ADD: 'New Add',
    CONTENT: 'Content',
    MODEL: 'Model',
    LOCAL_MODEL: 'Local Model',
    RUN: 'Run',
    TEST_NAME: 'Test Name',
    STATUS: 'Status',
    PROGRESS: 'Progress',
    REEVALUATE: 'Reevaluate',
    RESULT_DOWNLOAD: 'Result Download',
    END_DATE_TIME: 'Ended Date',
    RETURN: 'Return',
    SESSION_ID: 'Session ID',
    SEARCH_FLAG: 'Search Flag',
    SELECT_PROJECT: 'Select Project',
    SHOW_PROMPT: 'Show Prompt',
    DOCUMENT_NAME: 'Document Name',
    CHUNK_ORDER: 'Chunk Order',
    SHOW_DOCUMENT: 'Show Document List',
    CLOSE_DOCUMENT: 'Close Document List',
    SEARCH_DOCUMENT_NAME: 'Search Document Name',
    FILE_NAME: 'File Name',
    FIX_SEARCHING: 'Fix Searching',
    MESSAGE: 'Message',
    QUERY_ANSWER_CONTENT: 'Query/Answer Content',
    LOCAL_MODEL_NAME: 'Local Model Name',
    TOKEN: 'Token',
    HYPERPARAMETERS:'Hyperparameters',
    RESPONSE_STRUCTURE: 'Response Structure',
    KEYWORD_ARGUMENTS: 'Keyword Arguments',
  },
};
