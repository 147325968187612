var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DxPopup',{attrs:{"show-title":true,"title":_vm.modal.title,"min-width":_vm.modal.minWidth,"width":_vm.modal.width,"max-height":_vm.modal.maxHeight,"height":_vm.modal.height,"drag-enabled":_vm.modal.dragEnabled,"resize-enabled":_vm.modal.resizeEnabled,"hide-on-outside-click":_vm.modal.closeOnOutsideClick,"show-close-button":_vm.modal.showCloseButton,"visible":_vm.isOpen},on:{"hiding":function($event){return _vm.$emit('closeModal')}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',[_c('table',{staticClass:"table_form line-bin",attrs:{"id":"tableForm"}},[_c('colgroup',[_c('col',{staticStyle:{"width":"130px"}}),_c('col',{staticStyle:{"width":"calc(50vh - 130px)"}}),_c('col',{staticStyle:{"width":"130px"}}),_c('col',{staticStyle:{"width":"calc(50vh - 130px)"}})]),_c('tbody',[_c('tr',[_c('th',{attrs:{"scope":"row"}},[_c('label',{attrs:{"for":"label5"}},[_vm._v(_vm._s(_vm.$_lang('LLM_TESTER.WORD.SEARCH_DOCUMENT_NAME', { defaultValue: '검색 문서명' })))])]),_c('td',[_vm._v(" "+_vm._s(_vm.contentData.title)+" ")]),_c('th',{attrs:{"scope":"row"}},[_c('label',{attrs:{"for":"label5"}},[_vm._v(_vm._s(_vm.$_lang('LLM_TESTER.WORD.CHUNK_ORDER', { defaultValue: '청크 순서' })))])]),_c('td',[_vm._v(" "+_vm._s(_vm.contentData.chunk_order)+" ")])])])]),_c('dx-scroll-view',{staticClass:"dx-texteditor dx-editor-outlined mar_to10",attrs:{"width":"100%","height":_vm.scrollViewHeight}},[_c('div',{staticClass:"pad_5",domProps:{"innerHTML":_vm._s(_vm.convertDocument)}})])],1)]},proxy:true}])},[_c('DxToolbarItem',{attrs:{"widget":"dxButton","toolbar":"bottom","location":"center","visible":true,"options":{
          elementAttr: { class: 'white filled txt_S medium' },
          text: this.$_lang('COMPONENTS.CLOSE', { defaultValue: '닫기' }),
          width: '120',
          height: '40',
          onClick: () => {
            this.$emit('closeModal');
          },
        }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }