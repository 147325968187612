<template>
	<div class="main">
      <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" />

    <!-- 대표번호 설정 -->
    <modal-dnis
      v-if="modal.dnis.visible"
      :is-open="modal.dnis.visible"
      :dnis-data="modal.dnis.data"
      @saveModal="onSaveModal('dnis')"
      @closeModal="onCloseModal('dnis')"
    />
	</div>
</template>

<script>
import CustomStore from "devextreme/data/custom_store";
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid-v2.vue";
import EspDxButton from "@/components/devextreme/esp-dx-button.vue";
import EspDeleteButton from "@/components/common/buttons/esp-delete-button.vue";
import ModalDnis from "@/pages/cc/ivr/schedule/modal-dnis.vue";

import { mountComponent } from "@/utils/devextreme-util";
import { isSuccess, formatDate } from '@/utils/common-lib';

export default {
	components: {
    ModalDnis,
    EspDxDataGrid,
	},
	data() {
		return {
      modal: {
        dnis: {
          visible: false,
          data: {},
        },
      },
			stylingMode: 'outlined', //outlined, underlined, filled
      dnisList: [], //대표번호 리스트
			dataGrid: {
				refName: 'scheduleGrid',
        keyExpr:'id',
				allowColumnResizing: true, //컬럼 사이즈 허용
        allowReordering: true, //inline속성 느낌
				showBorders: false, //border 유무
				showColumnHeaders: true, //컬럼 헤더 유무
				showColumnLines: false, //컬럼 세로선 유무
				showRowLines: true, //컬럼 가로선 유무
				rowAlternationEnabled: false,
				hoverStateEnabled: true,
        dataSourceDefaultSortColumn: '-regDt,+id', // 주석처리하면 keyExpr 컬럼으로 sorting됨 + 오름차순 - 내림차순
        dataSource: [],
				apiActionNm: {},
				showActionButtons: { // 그리드 버튼 노출 설정값
          select: true,
					update: false,
					delete: false,
					customButtons: [
						{
              template: (data, index, element) => {
                mountComponent(
                  element,
                  EspDxButton,
                  {
                    i18n: 'COMPONENTS.REGISTER',
                    prefixIcon: 'add1',
                  },
                  {
                    handleClick: () => this.onDetailPage(-1)
                  },
                  this,
                );
              },
							location: 'before',
						},
            {
              template: (data, index, element) => {
                mountComponent(
                  element,
                  EspDeleteButton,
                  {},
                  {
                    handleClick: () => this.onDeleteData()
                  },
                  this,
                );
              },
              location: 'before',
            },
					],
				},
				columns: [
					{
						caption: '스케줄명',
            i18n: 'CC.WORD.SCHEDULE_NAME',
						dataField: 'scheduleNm',
						alignment: 'left', // left center right
            allowEditing: false,
						cellTemplate: (container, options) => {
							const aTag = document.createElement('a');
							aTag.innerText = options.value;
							aTag.addEventListener('click', () => {
								this.onDetailPage(options.data.id);
							});
							container.append(aTag);
						},
					},
					{
						caption: '설명',
            i18n: 'CC.WORD.DESCRIPTION',
						dataField: 'description',
						alignment: 'left',
            allowEditing: false,
					},
          {
            caption: '대표번호',
            i18n: 'CC.WORD.MAIN_NUMBER',
            dataField: 'dnisNotices',
            width: '10%',
            allowEditing: false,
            calculateCellValue: (rowData) => {
              if (!rowData.dnisSchedules) return 0;
              return rowData.dnisSchedules.length;
            },
            cellTemplate: (container, options) => {
              if( options.value > 0 ) {
                //링크 클릭시 팝업
                const aTag = document.createElement('a');
                aTag.innerText = `${options.value}개`;
                aTag.addEventListener('click', () => {
                  this.onOpenModal('dnis', options.data); //대표번호 설정 팝업 오픈
                });

                container.append(aTag);
              }else {
                mountComponent(
                  container,
                  EspDxButton,
                  {
                    i18n: 'COMPONENTS.SETTING',
                    color: 'white',
                    mode: 'outlined',
                    width: '45',
                    height: '25',
                    value: options.data.id
                  },
                  {
                    handleClick: () => this.onOpenModal('dnis', options.data)
                  },
                  this // Vue 인스턴스 컨텍스트 전달
                );
              }
            },
          },
          {
            caption: '사용여부',
            i18n: 'COMPONENTS.USE_STATUS',
            dataField: 'viewFl',
            allowEditing: false,
            lookup: {
              dataSource: this.$_enums.common.stringUsedFlag.values,
              displayExpr: 'label',
              valueExpr: 'value'
            },
          },
					{
            caption: '수정자',
            i18n: 'COMPONENTS.MODIFIER',
						dataField: 'editId',
            allowEditing: false,
					},
					{
            caption: '수정일시',
            i18n: 'COMPONENTS.RECENT_MODIFY_DATE',
						dataField: 'editDt',
            allowEditing: false,
						calculateCellValue: this.editDt,
					},
					{
            caption: '등록자',
            i18n: 'COMPONENTS.REGISTRANT',
						dataField: 'regId',
            allowEditing: false,
					},
					{
            caption: '등록일시',
            i18n: 'COMPONENTS.REGISTRATION_DATE',
						dataField: 'regDt',
						alignment: 'center',
            allowEditing: false,
						calculateCellValue: this.regDt,
					},
				],
			},
		};
	},
	computed: {},
	methods: {
    /** @description: 팝업 열기
     * @param settingType 설정 타입(dnis)
     * @param settingData 모달로 넘길 설정 데이터 */
    onOpenModal(settingType, settingData) {
      this.modal[settingType].visible = true;
      this.modal[settingType].data = settingData;
    },
    /**
     * @description : 팝업 저장
     * @param settingType : 설정 타입(dnis)
     */
    async onSaveModal(settingType) {
      this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', {defaultValue: '정상적으로 저장되었습니다.'}));

      this.modal[settingType].visible = false;
      this.modal[settingType].data = {};
      if( settingType === 'dnis' ){
        await this.selectDnisList();
      }

      this.$refs[this.dataGrid.refName].refreshData();
    },
    /** @description : 팝업 닫기
     * @param settingType : 설정 타입(dnis)
     **/
    onCloseModal(settingType) {
      this.modal[settingType].visible = false;
      this.modal[settingType].data = {};
    },
    /** @description : 운영스케줄 상세 페이지 이동 */
    onDetailPage(id) {
      this.$router.push({
        path: `/cc/ivr/schedule/config/index/${id}`,
        params: { id },
      });
    },
		/** @description: 운영스케줄 관리 리스트 조회 */
		selectDataList() {
			this.dataGrid.dataSource = new CustomStore({
				key: 'id',
        load: async(loadOptions) => {
          const params = this.$_getDxDataGridParam(loadOptions);

					if (!params.sort) {
						params.sort = this.dataGrid.dataSourceDefaultSortColumn;
					}

          const payload = {
						actionName: 'CC_SCHEDULE_LIST',
						data: params,
					};

					const res = await this.CALL_CC_API(payload);
          let rtnData = {
            data: [],
            totalCount: 0,
          };
					if (isSuccess(res)) {
            const scheduleList = res.data.data;
            scheduleList.forEach(schedule => {
              const dnisSchedules = this.dnisList.filter(dnis => dnis.scheduleId === schedule.id);
              if( dnisSchedules.length > 0 ) {
                schedule.dnisSchedules = dnisSchedules;
              }
            });
						rtnData = {
							data: scheduleList,
							totalCount: res.data.header.totalCount,
						};
						this.$refs[this.dataGrid.refName].totalCount = rtnData.totalCount;
					}
          return rtnData;
				},
			});
		},
    /** @description : 대표번호 리스트 조회 메서드 */
    async selectDnisList() {
      const payload = {
        actionName: 'CC_IVR_DNIS_LIST',
      };

      const res = await this.CALL_CC_API(payload);
      if (isSuccess(res)) {
        this.dnisList = res.data.data;
      }
    },
		/** @description : 데이터 삭제 */
		async onDeleteData() {
			const selectedRowsData = this.$refs[this.dataGrid.refName].getInstance.getSelectedRowsData();
      if (selectedRowsData.length === 0) {
        this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', {defaultValue: '대상이 선택되어 있지 않습니다.'}));
        return;
      }

			//채널관리에 스케줄 관련 데이터가 있는지 체크
			const isCheckDnisSchedule = await this.checkDnisSchedule(selectedRowsData.map(d => d.id));
			if (isCheckDnisSchedule) {
        this.$_Msg(this.$_lang('CC.MESSAGE.NO_DELETE_DNIS_SCHEDULE', { defaultValue : '대표번호에 게시 중인 운영 스케줄이 있어 삭제할 수 없습니다.'}));
        return;
			}

      const confirmed = await this.$_Confirm(
        this.$_lang('CMN_CFM_DELETE_SELECTED', {
          defaultValue: '선택한 데이터를 삭제하시겠습니까?'
        })
      );
      if (!confirmed) {
        return;
      }

      const payload = {
        actionName: 'CC_SCHEDULE_DELETE',
        data: selectedRowsData,
        loading: true,
      };

      const res = await this.CALL_CC_API(payload);
      if (isSuccess(res)) {
        this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_DELETE', { defaultValue: '정상적으로 삭제되었습니다.' }));
        this.$refs[this.dataGrid.refName].refreshData();
      }
		},
		/** @description : 대표번호관리에 스케줄 관련 데이터가 있는지 체크 */
		async checkDnisSchedule(scheduleIds) {
			const payload = {
				actionName: 'CC_IVR_DNIS_LIST',
				data: { scheduleId: scheduleIds },
			};

			const res = await this.CALL_CC_API(payload);
			if (isSuccess(res)) {
        return res.data.data.length > 0;
			}
		},
		/** @description: 수정일시 출력 */
		editDt(rowData) {
			return formatDate(rowData.editDt, 'YYYYMMDDHHmmssSSS', 'YYYY-MM-DD HH:mm:ss');
		},
		/** @description: 등록일시 출력 */
		regDt(rowData) {
			return formatDate(rowData.regDt, 'YYYYMMDDHHmmssSSS', 'YYYY-MM-DD HH:mm:ss');
		},
	},
	async mounted() {
    await this.selectDnisList();
		await this.selectDataList();
	},
  activated() {
    this.$refs[this.dataGrid.refName].refreshData();
  }
};
</script>

