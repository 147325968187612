<template>
  <div class="container content-themes">
    <div class="main clearfix per33 ui-33" style="padding-bottom: 20px">
      <table class="table_form line-bin">
        <!-- fl -->
        <!-- style="width: 40%" -->
        <colgroup>
          <col style="width: 10%"/>
          <col style="width: 15%"/>
          <col style="width: 10%"/>
          <col style="width: 15%"/>
          <col style="width: 10%"/>
          <col style="width: 15%"/>
          <col style="width: 10%"/>
          <col style="width: 15%"/>
        </colgroup>
        <thead class="sub_title_txt">
        <tr>
          <td colspan="4">
            <h2 class="fl">{{ $_lang('UC.WORD.DEVICE_INFO') }}</h2>
            <DxButton
                @click="onSelectDevice()"
                :text="$_lang('UC.WORD.CHECK')"
                :width="80"
                :height="30"
                class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fr"
                styling-mode="contained"
                type="default"
            />
          </td>
          <td colspan="4">
            <DxButton
                @click="onUpdateDevice()"
                :text="$_lang('UC.WORD.APPLY')"
                :width="80"
                :height="30"
                class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fr"
                styling-mode="contained"
                type="default"
            />
          </td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th scope="row" class="alT">
            <label for="label01">{{ $_lang('UC.WORD.EXCHANGER') }}</label>
          </th>
          <th>{{ getCmNm(deviceInfo.cmname) }}</th>
          <td colspan="2" rowspan="3" style="padding: 0">
            <div class="fl" style="width: 100%; text-align: center">
              <img v-if="deviceImage" :src="deviceImage" alt="Device"/>
              <img
                  v-if="deviceExpImage"
                  :src="deviceExpImage"
                  alt="DeviceExp"
                  style="cursor: pointer; position: absolute; bottom: 0"
                  class="fr"
                  @click="isOpenModal(true, 'expansion')"
              />
            </div>
          </td>
          <th scope="row" class="alT">
            <label for="label04">{{ $_lang('UC.WORD.STATUS') }}</label>
          </th>
          <th>{{ deviceInfo.status }}</th>
          <th scope="row" class="alT">
            <label for="label04">{{ $_lang('UC.WORD.SERIAL') }}</label>
          </th>
          <th>{{ deviceInfo.serial }}</th>
        </tr>
        <tr>
          <th scope="row">
            <label for="label02">{{ $_lang('UC.WORD.DEVICE_NAME') }}</label>
          </th>
          <th>{{ deviceInfo.name }}</th>
          <th scope="row" class="alT">
            <label for="label04">{{ $_lang('UC.WORD.PHONEBUTTON') }}</label>
          </th>
          <th>{{ deviceInfo.phonetemplatename }}</th>
          <th scope="row" class="alT">
            <label for="label04">{{ $_lang('UC.WORD.SOFTKEY') }}</label>
          </th>
          <th>{{ deviceInfo.softkeytemplatename }}</th>
        </tr>
        <tr>
          <th scope="row" class="alT">
            <label for="label03">{{ $_lang('UC.WORD.DEVICEPOOL') }}</label>
          </th>
          <td>
            <DxSelectBox
                :placeholder="$_lang('COMPONENTS.SELECT')"
                :items="devicePoolList"
                display-expr="name"
                value-expr="name"
                :styling-mode="stylingMode"
                :width="180"
                :height="30"
                :value="selectedPool"
                @value-changed="onChangeSelectPool($event)"
            />
          </td>
          <th scope="row" class="alT"><label for="label04">IP</label></th>
          <th>{{ deviceInfo.ipaddress }}</th>
          <th scope="row" class="alT">
            <label for="label04">{{ $_lang('COMPONENTS.REGISTRATION_DATE_TIME') }}</label>
          </th>
          <th>{{ this.formatDt(deviceRegDt) }}</th>
        </tr>
        <tr>
          <th scope="row" class="alT">
            <label for="label04">{{ $_lang('COMPONENTS.DESCRIPTION') }}</label>
          </th>
          <td colspan="3">
            <DxTextBox
                v-model="deviceInfo.description"
                :placeholder="$_lang('UC.MESSAGE.DESCRIPTION_ENTER')"
                :styling-mode="config.stylingMode"
                @value-changed="onChangeInput($event)"
            />
          </td>
          <th scope="row" class="alT">
            <label for="label04">{{ $_lang('UC.WORD.MODEL') }}</label>
          </th>
          <th>{{ deviceInfo.model }}</th>
          <th scope="row" class="alT">
            <label for="label04">{{ $_lang('UC.WORD.EXPANSION_MODULE') }}</label>
          </th>
          <td>
            <DxSelectBox
                v-if="expFlag"
                :placeholder="$_lang('COMPONENTS.SELECT')"
                :items="expansionList"
                display-expr="description"
                value-expr="moduleNm"
                :styling-mode="stylingMode"
                :width="200"
                :height="30"
                :value="expansion"
                @value-changed="onChangeExpansion($event)"
            />
          </td>
        </tr>
        </tbody>
      </table>
      <table style="border-top: 0; margin-top: 20px">
        <tbody>
        <tr>
          <td style="padding: 0">
            <esp-dx-data-grid
                :data-grid="dataGrid"
                ref="deviceGrid"
                @row-updating="onRowUpdating"
                @cell-prepared="onCellPrepared"
                @editor-prepared="onEditorPrepared"
            />
          </td>
        </tr>
        <DxButton
            @click="goListPage()"
            :text="this.$_lang('UC.WORD.PREPAGE')"
            :width="80"
            :height="30"
            class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fr"
            styling-mode="contained"
            style="margin-top: 10px"
            type="default"
        />
        </tbody>
      </table>
      <DxPopup
          :show-title="true"
          :title="modal.expansion.title"
          :width="modal.expansion.width"
          :height="modal.expansion.height"
          :drag-enabled="true"
          :resize-enabled="true"
          :show-close-button="true"
          :close-on-outside-click="false"
          :visible="modal.expansion.isOpened"
          @hiding="isOpenModal(false, 'expansion')"
      >
        <DxScrollView width="100%" height="100%">
          <ModalExpansion
              :deviceInfo="this.deviceInfo"
              :cmList="this.cmList"
              :authority="this.authority"
              :isOpened="this.modal.expansion.isOpened"
              @closeModal="onCloseModal"
          ></ModalExpansion>
        </DxScrollView>
      </DxPopup>
      <DxPopup
          :show-title="true"
          :title="modal.pickup.title"
          :width="modal.pickup.width"
          :height="modal.pickup.height"
          :drag-enabled="true"
          :resize-enabled="true"
          :show-close-button="true"
          :close-on-outside-click="false"
          :visible="modal.pickup.isOpened"
          @hiding="isOpenModal(false, 'pickup')"
      >
        <ModalPickup
            :deviceInfo="this.deviceInfo"
            :cmList="this.cmList"
            :partitionList="this.partitionList"
            :authority="this.authority"
            :pickupKeyword="this.pickupKeyword"
            :rowInfo="this.rowInfo"
            :columnIndex="this.columnIndex"
            :isOpened="this.modal.pickup.isOpened"
            @closePickup="setPickup"
        ></ModalPickup>
      </DxPopup>
      <DxPopup
          :show-title="true"
          :title="modal.line.title"
          :width="modal.line.width"
          :height="modal.line.height"
          :drag-enabled="true"
          :resize-enabled="true"
          :show-close-button="true"
          :close-on-outside-click="false"
          :visible="modal.line.isOpened"
          @hiding="isOpenModal(false, 'line')"
      >
        <ModalLine
            :deviceInfo="this.deviceInfo"
            :cmList="this.cmList"
            :partitionList="this.partitionList"
            :cssList="this.cssList"
            :pickupGroupList="this.pickupGroupList"
            :rowInfo="this.rowInfo"
            :isOpened="this.modal.line.isOpened"
            @closeLine="setLine"
        ></ModalLine>
      </DxPopup>
      <DxPopup
          :show-title="true"
          :title="modal.sd.title"
          :width="modal.sd.width"
          :height="modal.sd.height"
          :drag-enabled="true"
          :resize-enabled="true"
          :show-close-button="true"
          :close-on-outside-click="false"
          :visible="modal.sd.isOpened"
          @hiding="isOpenModal(false, 'sd')"
      >
        <ModalSd
            :deviceInfo="this.deviceInfo"
            :cmList="this.cmList"
            :sdData="this.modal.sd.data"
            :isOpened="this.modal.sd.isOpened"
            :authority="this.authority"
            @closeSd="setLine"
        ></ModalSd>
      </DxPopup>
    </div>
  </div>
</template>

<script>
import DxButton from 'devextreme-vue/button';
import {DxSelectBox} from 'devextreme-vue/select-box';
import ModalExpansion from './modal-set-expansion.vue';
import ModalPickup from './modal-set-pickup.vue';
import ModalLine from './modal-set-line.vue';
import ModalSd from './modal-set-sd.vue';
import {DxPopup} from 'devextreme-vue/popup';
import PlusIcon from '@/assets/images/write.png';
import DelIcon from '@/assets/images/del.png';
import {isSuccess} from '@/utils/common-lib';
import {DxTextBox} from 'devextreme-vue/text-box';
import {DxScrollView} from 'devextreme-vue/scroll-view';
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid-v2.vue";
import {getUCServerPath} from "@/pages/euc/common-uc";

export default {
  components: {
    DxButton,
    DxTextBox,
    DxSelectBox,
    DxPopup,
    ModalExpansion,
    ModalPickup,
    ModalLine,
    DxScrollView,
    ModalSd,
    EspDxDataGrid
  },
  props: {},
  watch: {},
  data() {
    return {
      cssGroupList: [],
      recList: [],
      stylingMode: 'outlined', //outlined, underlined, filled
      devicePoolList: [],
      selectedPool: '',
      deviceRegDt: '',
      cssList: [],
      partitionList: [],
      cmList: [],
      pickupGroupList: [],
      expansion: '',
      expansionList: [],
      modelList: [],
      groupList: [],
      allTransList: [],
      expFlag: false,
      pickupKeyword: '',
      deviceImage: '',
      deviceExpImage: '',
      deviceData: {},
      authority: 'manager',
      modal: {
        expansion: {
          isOpened: false,
          title: this.$_lang('UC.WORD.EXPANSION_MODULE_SET', {defaultValue: '확장모듈 설정'}),
          width: 'calc(100vw - 10%)',
          height: '900',
        },
        pickup: {
          isOpened: false,
          title: this.$_lang('UC.WORD.PICKUPGROUP_SET', {defaultValue: '당겨받기 설정'}),
          width: '70%',
          height: '750',
        },
        line: {
          isOpened: false,
          title: this.$_lang('UC.WORD.LINE_SET', {defaultValue: '전화번호 설정'}),
          width: '80%',
          height: '700',
        },
        sd: {
          isOpened: false,
          data: [],
          title: '단축다이얼 설정',
          width: '30%',
          height: '300',
        },
      },
      updateFlag: false,
      deviceInfo: {},
      devicelist: [],
      rowInfo: {},
      rowIndex: '',
      columnIndex: -1,
      changedDeviceInfo: [],
      dataGrid: {
        disableTotalCount: true,
        callApi: 'CALL_EUC_API',
        refName: 'deviceGrid',
        dataSourceDefaultSortColumn: '+regDt,+id',
        apiActionNm: {
          select: '',
        },
        showActionButtons: {
          delete: false,
        },
        loadPanel: {
          enabled: false, // 로딩바 표시 유무
        },
        sorting: {
          mode: 'single', // single multiple
        },
        page: {
          enabled: false,
        },
        filterRow: {
          visible: false,
        },
        headerFilter: {
          visible: false,
        },
        editing: {
          allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
          allowDeleting: false,
          mode: 'cell', //수정 모드: ['row', 'cell', 'batch']
          startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
          selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
        },
        selecting: {
          mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
          selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
          showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
        },
        columns: [
          {
            caption: ' ',
            dataField: 'field',
            width: 50,
            allowEditing: false,
            cellTemplate: async (container, options) => {
              const imgTag = document.createElement('img');
              imgTag.setAttribute('src', `${getUCServerPath()}/euc/api/v1/resource/DEVICE_LINE_IMAGE_DIR/${options.value}.png`);
              container.append(imgTag);
            },
          },
          {
            caption: '내선번호',
            i18n: 'UC.WORD.NUMBER',
            dataField: 'number',
            width: 140,
            allowEditing: false,
            cellTemplate: async (container, options) => {
              const divTag = document.createElement('div');

              // 'SURL' 필드일 경우 조기 반환
              if (options.data.field === 'SURL') {
                divTag.innerText = '-';
                container.append(divTag);
                return;
              }

              // 필드 유형 결정 함수
              const getFieldType = (field) => {
                return field === 'LINE' ? 'line' : (field === 'SD' || field === 'BLF' ? 'sd' : 'line');
              };

              const field = getFieldType(options.data.field);
              const value = options?.value && options.value !== 'null' ? options.value + ' ' : '';

              container.append(value);
              divTag.classList.add('fr');

              // 아이콘 생성 함수
              const createIcon = (src, clickHandler) => {
                const img = document.createElement('img');
                img.setAttribute('src', src);
                img.style.cursor = 'pointer';
                img.addEventListener('click', clickHandler);
                return img;
              };

              // '+' 버튼 추가
              divTag.append(
                  createIcon(PlusIcon, async () => {
                    await this.setRowInfo(options);
                    await this.isOpenModal(true, field, options.data);
                  })
              );

              // 값이 존재하면 '-' 버튼 추가
              if (value.trim()) {
                divTag.append(
                    createIcon(DelIcon, async () => {
                      await this.setCellDelete(options, 'number');
                    })
                );
              }

              container.append(divTag);
            },
          },
          {
            caption: '화면 표시',
            dataField: 'label',
            allowSorting: false,
            calculateDisplayCellValue: rowData => {
              return rowData?.label || '';
            },
            cellTemplate: (container, options) => {
              const span = document.createElement('span');
              if (options.data.field === 'SURL') {
                span.innerText = '-';
              } else {
                span.innerText = options.value;
              }
              container.append(span);
            },
          },
          {
            caption: '이력 표시',
            dataField: 'display',
            allowSorting: false,
            fixedPosition: 'left', // left or right
            calculateDisplayCellValue: rowData => {
              return rowData?.display || '';
            },
            cellTemplate: (container, options) => {
              const isSpecialField = ['SURL', 'SD', 'BLF'].includes(options.data.field);
              options.value = isSpecialField ? '-' : options.data.display ? (options.value && options.value !== 'null' ? options.value : '') : '';

              container.append(options.value);
            },
          },
          {
            caption: '파티션',
            i18n: 'UC.WORD.PARTITION',
            dataField: 'routepartitionname',
            width: 120,
            allowEditing: false,
            allowSorting: false,
            calculateDisplayCellValue: rowData => {
              if (rowData.field !== 'LINE') {
                return '-';
              }

              return rowData?.routepartitionname || 'NONE';
            },
          },
          {
            caption: '발신검색',
            i18n: 'UC.WORD.CSS',
            dataField: 'callingsearchspacename',
            width: 200,
            allowEditing: false,
            allowSorting: false,
            fixedPosition: 'left', // left or right
            calculateDisplayCellValue: rowData => {
              if (rowData.field !== 'LINE') {
                return '-';
              }

              if (rowData?.callingsearchspacename) {
                if (rowData.callingsearchspacename.toUpperCase() === 'NONE') {
                  return rowData.callingsearchspacename.toUpperCase();
                }
                return rowData.callingsearchspacename;
              } else {
                return 'NONE';
              }
            },
          },
          {
            caption: '외부발신번호',
            dataField: 'e164mask',
            width: 150,
            allowSorting: false,
            fixedPosition: 'left', // left or right
            cellTemplate: (container, options) => {
              if (!options?.value) {
                options.value = '';
              }

              if (options.data.field === 'LINE') {
                container.append(options.value);
              } else {
                const span = document.createElement('span');
                span.innerText = '-';
                container.append(span);
              }
            },
            validationRules: [
              {
                type: 'custom',
                validationCallback: (e) => {
                  if (e.value === '') {
                    return true;
                  }
                  const regex = /^\d+[0-9X]*$/;
                  return regex.test(e.value);
                },
                message: '숫자와 대문자 X만 입력가능합니다.',
              },
            ],
          },
          {
            caption: '착신전환 번호',
            i18n: 'UC.WORD.FORWARDNO',
            dataField: 'cfnoansin',
            width: 150,
            allowSorting: false,
            fixedPosition: 'left', // left or right
            cellTemplate: (container, options) => {
              if (!options?.value) {
                options.value = '';
              }

              if (options.data.field === 'LINE') {
                container.append(options.value);
              } else {
                const span = document.createElement('span');
                span.innerText = '-';
                container.append(span);
              }
            },
            validationRules: [
              {
                type: 'custom',
                validationCallback: (e) => {
                  if (e.value === '') {
                    return true;
                  }
                  return !isNaN(e.value) && e.value !== null && e.value !== '';
                },
                message: this.$_lang('UC.MESSAGE.NUMBER_ENTER'),
              },
            ],
          },
          {
            caption: '착신 CSS',
            i18n: 'UC.WORD.ALL_FORWARDNO',
            dataField: 'cfnoansinCSS',
            width: 200,
            visible: this.$_getSystemData('use_all_forward_number')?.configValue === 'Y',
            allowEditing: false,
            allowSorting: false,
          },
          {
            caption: '당겨받기',
            i18n: 'UC.WORD.PICKUPGROUP',
            dataField: 'pickupgroupname',
            allowEditing: false,
            allowSorting: false,
            cellTemplate: (container, options) => {
              options.value = options.value ?? '';

              if (options.data.field !== 'LINE') {
                container.append(document.createElement('span').innerText = '-');
                return;
              }

              // 설명 찾기
              const group = this.pickupGroupList.find(group => group.name === options.value);
              const description = group?.description ?? '설명 없음';

              container.append(description + ' ');

              // 아이콘 생성 함수
              const createIcon = (src, clickHandler) => {
                const img = document.createElement('img');
                img.setAttribute('src', src);
                img.style.cursor = 'pointer';
                img.addEventListener('click', clickHandler);
                return img;
              };

              const divTag = document.createElement('div');
              divTag.classList.add('fr');

              // '+' 버튼 추가
              divTag.append(
                  createIcon(PlusIcon, async () => {
                    options.data.pickupDescription = description;
                    await this.setRowInfo(options);
                    await this.isOpenModal(true, 'pickup', options.data);
                  })
              );

              // '-' 버튼 추가 (값이 존재하는 경우만)
              if (description !== '') {
                divTag.append(
                    createIcon(DelIcon, async () => {
                      await this.setCellDelete(options, 'pickup');
                    })
                );
              }

              container.append(divTag);
            },
          },
          {
            caption: '녹취',
            i18n: 'UC.WORD.REC',
            dataField: 'recprofile',
            width: 120,
            allowEditing: false,
            allowSorting: false,
            fixedPosition: 'left',
            calculateDisplayCellValue: rowData => {
              return rowData?.recprofile || '';
            },
            cellTemplate: (container, options) => {
              if (!options?.value || options.value === 'NONE') {
                options.value = null;
              }
              const selectBox = new DxSelectBox({
                propsData: {
                  placeholder: this.$_lang('COMPONENTS.SELECT'),
                  items: this.getRecList,
                  displayExpr: 'codeNm',
                  valueExpr: 'codeValue',
                  value: options.value,
                  stylingMode: this.stylingMode,
                  onValueChanged: e => {
                    let mediaChildrenCodeValue = null;
                    let flagChildrenCodeValue = null;
                    if (e.value) {
                      const mediaChildren = this.getRecList.find((rec) => rec.codeValue === e.value).children[0];
                      mediaChildrenCodeValue = mediaChildren.codeValue;

                      const flagChildren = mediaChildren.children[0];
                      flagChildrenCodeValue = flagChildren.codeValue;
                    }
                    this.$refs.deviceGrid.getInstance.cellValue(options.rowIndex, 'recmedia', mediaChildrenCodeValue);
                    this.$refs.deviceGrid.getInstance.cellValue(options.rowIndex, 'recflag', flagChildrenCodeValue);
                    this.$refs.deviceGrid.getInstance.cellValue(options.rowIndex, options.columnIndex, e.value);

                    this.$refs.deviceGrid.getInstance.saveEditData();
                  },
                },
              });
              selectBox.$mount();
              container.append(selectBox.$el);
            },
          },
          {
            dataField: 'recmedia',
            visible: false
          },
          {
            dataField: 'recflag',
            visible: false
          }
        ],
      },
    };
  },
  computed: {
    getRecList() {
      return [{codeValue: null, codeNm: '미사용'}].concat(this.recList.codes.children);
    },
  },
  methods: {
    onCloseModal(type = 'expansion') {
      this.isOpenModal(false, type);
    },
    /**@description 교환기 장치정보 조회하는 메서드 */
    async selectDataList() {
      if (!this.reqParams) {
        this.$_goPrePage();
        return;
      }

      const data = this.reqParams.deviceData;
      this.deviceRegDt = data.regDt;
      const payload = {
        actionName: 'EUC_DEVICE_CISCO_DETAIL',
        data: {
          mac: data.name,
          cmNm: data.cmNm,
          loginId: this.$store.getters.getLoginId,
          type: 'MAC'
        },
        loading: true,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        const deviceInfo = res.data.data[0];
        if (deviceInfo.LIST?.length > 0) {
          this.deviceInfo = deviceInfo.LIST[0];
          this.devicelist = deviceInfo.LIST[0].devicelist;
          await this.findModelByExpansion();
        } else {
          await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NO_DATA'));
          await this.$_goPrePage();
          return;
        }

        if (this.deviceInfo?.model) {
          this.deviceImage = `${getUCServerPath()}/euc/api/v1/resource/DEVICE_BIG_IMAGE_DIR/${this.deviceInfo.model}.png`;
        } else {
          this.deviceImage = this.deviceInfo.model;
        }
        if (this.deviceInfo?.addonmodules) {
          const addOnModules = this.deviceInfo.addonmodules.split(',')
          this.deviceExpImage = `${getUCServerPath()}/euc/api/v1/resource/DEVICE_BIG_IMAGE_DIR/${addOnModules[0]}.png`;
        } else {
          this.deviceExpImage = this.deviceInfo.addonmodules;
        }
        this.selectedPool = this.deviceInfo.devicepool;
        this.expansion = this.deviceInfo.addonmodules;
        this.dataGrid.dataSource = this.devicelist;
      } else {
        await this.$_Msg(res.data.header.resMsg);
        await this.$_goPrePage();
      }
      await this.$refs.deviceGrid.getInstance.clearSelection();
    },
    /**@description : DevicePool selectBox 변경 이벤트 */
    onChangeSelectPool(event) {
      this.selectedPool = event.value;
      this.updateFlag = true;
    },
    /**@description : Expansion selectBox 변경 이벤트 */
    onChangeExpansion(event) {
      this.expansion = event.value;
      this.updateFlag = true;
    },
    getCSSItem(number) {
      const item = this.cssGroupList.find((css) => {
        const regex = new RegExp(css.pattern);
        return regex.test(number);
      });
      return item?.groupCd || null;
    },
    /**@description 조회버튼 클릭 이벤트  */
    async onSelectDevice() {
      this.changedDeviceInfo = []; //재조회시 그리드 수정내역 변경
      const payload = {
        actionName: 'EUC_DEVICE_CISCO_DETAIL',
        data: {
          mac: this.deviceInfo.name,
          cmNm: this.deviceInfo.cmname,
          loginId: this.$store.getters.getLoginId,
          type: 'MAC',
        },
        loading: true,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        if (res.data.data?.length) {
          const deviceInfo = res.data.data[0];
          await this.setDeviceData(deviceInfo);
        }
      } else {
        await this.$_Msg(res.data.header.resMsg);
        await this.$_goPrePage();
      }
      await this.$refs.deviceGrid.getInstance.clearSelection();
    },
    /** @description: 단말장치 데이터 상세 조회 메서드 */
    async setDeviceData(result) {
      if (!result) {
        this.$_goPrePage();
        return;
      }
      const refDeviceData = result.LIST[0];
      const refGridData = refDeviceData.devicelist;

      if (refDeviceData?.model) {
        this.deviceImage = `${getUCServerPath()}/euc/api/v1/resource/DEVICE_BIG_IMAGE_DIR/${refDeviceData.model}.png`;
      } else {
        this.deviceImage = '';
      }
      if (refDeviceData?.addonmodules) {
        const addOnModules = refDeviceData?.addonmodules.split(',');
        this.deviceExpImage = `${getUCServerPath()}/euc/api/v1/resource/DEVICE_BIG_IMAGE_DIR/${addOnModules[0]}.png`;
      } else {
        this.deviceExpImage = '';
      }
      if (refGridData.length > 0) {
        this.devicelist = refGridData;
      }
      this.deviceInfo = refDeviceData;
      this.dataGrid.dataSource = this.devicelist;
      this.selectedPool = refDeviceData.devicepool;
      this.expansion = this.deviceInfo.addonmodules;
    },
    /**@description 그리드 customEvent @onRowUpdating*/
    onRowUpdating(e) {
      //display
      let column = Object.keys(e.newData)[0] !== 'display' ? Object.keys(e.newData)[0] : 'label';
      const isRecChange = ['recmedia', 'recflag', 'recprofile'].includes(column);
      column = isRecChange ? 'recprofile' : column;
      let value = column !== 'display' ? e.newData[column] : e.newData['label'];

      let number = e.oldData.number;
      let field = e.oldData.field;
      let newRow = {column: column, value: value, number: number, field: field};
      if (isRecChange) {
        newRow.recmedia = e.newData.recmedia;
        newRow.recflag = e.newData.recflag;
      }

      const existingRow = this.changedDeviceInfo.find(row => row.column === column && row.number === number);
      if (existingRow) {
        Object.assign(existingRow, newRow);
      } else {
        this.changedDeviceInfo.push(newRow);
      }
    },
    /**@description  단말기 정보 업데이트
     * */
    async onUpdateDevice() {
      if (this.$refs.deviceGrid.hasEditData()) {
        await this.$_Msg('수정중인 항목이있습니다. ');
        return false;
      }

      const changeArr = [];
      for (let i = 0; i < this.changedDeviceInfo.length; i++) {
        let row = {
          TYPE: '',
          DEVICE: '',
          ROUTEPARTITION: '',
        };
        const routeRow = this.dataGrid.dataSource.find(row => row.number === this.changedDeviceInfo[i].number);

        row.DEVICE = this.changedDeviceInfo[i].number;
        if (routeRow.field === 'LINE') {
          row.ROUTEPARTITION = routeRow.routepartitionname;
        } else {
          row.ROUTEPARTITION = '';
        }
        switch (this.changedDeviceInfo[i].column) {
          case 'label':
            row.TYPE = this.changedDeviceInfo[i].field + 'LABEL';
            row.LABEL = this.changedDeviceInfo[i]?.value || routeRow.label;
            //display 추가
            row.DISPLAY = routeRow?.display;
            break;
          case 'cfnoansin':
            row.TYPE = 'FORWARDCALL';
            row.FORWARD_NO = this.changedDeviceInfo[i].value;
            row.FORWARD_TYPE = 'NOANS';
            row.CSS = this.getCSSItem(row.DEVICE);
            break;
          case 'pickupgroupname':
            row.TYPE = 'PICKUP';
            row.PICKUPGROUP = this.changedDeviceInfo[i].value;
            break;
          case 'recprofile':
            row.TYPE = 'REC';
            row.RECPROFILE = this.changedDeviceInfo[i].value;
            row.RECFLAG = this.changedDeviceInfo[i].recflag;
            row.RECMEDIASOURCE = this.changedDeviceInfo[i].recmedia;
            break;
          case 'e164mask': // 발신검색 항목 추가 필요
            row.TYPE = 'E164MASK';
            row.E164MASK = this.changedDeviceInfo[i].value;
            break;
        }
        changeArr.push(row);
      }
      if (changeArr.length === 0 && this.updateFlag === false) {
        await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NO_CHANGED'));
      } else if (changeArr.length > 0 || this.updateFlag === true) {
        const confirm = this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE');

        if (!(await this.$_Confirm(confirm))) {
          return;
        }

        const params = {
          cmNm: this.deviceInfo.cmname,
          mac: this.deviceInfo.name,
          devicepool: this.selectedPool,
          description: this.deviceInfo.description,
          expansionModule: this.expansion,
          list: JSON.stringify(changeArr),
          loginId: this.$store.getters.getLoginId,
        };
        const payload = {
          actionName: 'EUC_DEVICE_CISCO_UPDATE',
          data: params,
          loading: true,
        };
        const res = await this.CALL_EUC_API(payload);

        if (isSuccess(res)) {
          await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS'));
          this.changedDeviceInfo = [];
          await this.onSelectDevice();
        } else {
          await this.$_Msg(res.data.header.resMsg);
        }
      }
    },
    /**@description : 설명 입력창 onChange이벤트*/
    onChangeInput(event) {
      this.updateFlag = true;
      this.deviceInfo.description = event.value;
    },
    /**@description : 팝업 Open/Close */
    isOpenModal(data, type, row) {
      if (row && type === 'sd') {
        this.modal[type].data = JSON.parse(
            JSON.stringify(this.dataGrid.dataSource.filter(d => d.field === 'SD' || d.field === 'BLF')),
        );
      }
      this.modal[type].isOpened = data;
    },
    /**@description : 당겨받기 데이터 저장하는 메서드 */
    setRowInfo(rowData) {
      this.rowInfo = Object.assign({}, rowData.data);
      this.rowIndex = rowData.rowIndex;
      this.columnIndex = rowData.columnIndex;
    },
    /**@description : 교환기 목록 가져오는 메서드 */
    async selectCmList(sort = '+cmOrd') {
      const payload = {
        actionName: 'EUC_CM_SELECT',
        data: {
          sort,
          viewFl: 'Y'
        },
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.cmList = res.data.data.map((item) => {
          return {
            cmNm: item.cmNm,
            cmOrd: item.cmOrd,
            description: item.description,
            editId: item.editId,
            editDt: item.editDt,
            regId: item.regId,
          };
        });
      }
    },
    /** @description 교환기 이름 한글 변환 메서드
     *  @param {String} cm Name
     *  @returns {String} 한글명
     */
    getCmNm(cm) {
      return this.cmList?.find(d => d.cmNm === cm)?.description || '';
    },
    /**@description devicepool 목록 가져오는 메서드 */
    async selectDevicePool(sort = '+devicePoolOrd') {
      const payload = {
        actionName: 'EUC_DEVICE_POOL_SELECT',
        data: {
          sort,
          viewFl: 'Y'
        },
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.devicePoolList = res.data.data;
      }
    },
    /**@description 파티션 목록 가져오는 메서드 */
    async selectPartitionList(sort = '+routepartitionOrd') {
      const payload = {
        actionName: 'EUC_PARTITION_SELECT',
        data: {
          sort,
          viewFl: 'Y'
        },
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.partitionList = res.data.data;
      }
    },
    /**@description 발신검색 목록 가져오는 메서드 */
    async selectCss(sort = '+cssOrd') {
      const payload = {
        actionName: 'EUC_CSS_SELECT',
        data: {
          sort,
          viewFl: 'Y'
        },
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.cssList = res.data.data;
      }
    },
    /**@description 당겨받기그룹 목록 가져오는 메서드 */
    async selectPickupGroupList(sort = '+callpickupGroupOrd') {
      const payload = {
        actionName: 'EUC_PICKUPGROUP_SELECT',
        data: {
          sort,
          viewFl: 'Y'
        },
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.pickupGroupList = res.data.data;
      }
    },
    /**@description 녹취 프로파일 값을 받아 해당하는 다른 녹취키 값 리턴하는 메서드
     * @returns {String} 해당하는 녹취 키에 대한 value
     * @param data
     * @param type
     */
    getRecInfo(data, type) {
      const recArr = this.recList;
      let returnData = '';
      for (let i = 0; i < recArr.length; i++) {
        if (data === recArr[i].recprofile) {
          returnData = recArr[i][type];
        }
      }
      return returnData;
    },
    /** @description : 날짜 형식 변환 메서드 */
    formatDt(date) {
      return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
    },
    /**@description 당겨받기설정 모달 종료 후 콜백 메서드 , 넘겨받은 데이터 grid에 반영
     * @param row
     * @param pickupName
     * @param gridNumber
     * @param columnIndex
     */
    async setPickup(row, pickupName, gridNumber, columnIndex) {
      this.isOpenModal(false, 'pickup');
      const gridData = this.dataGrid.dataSource;
      for (let i = 0; i < gridData.length; i++) {
        if (gridData[i].index === row.index && gridData[i].seq === row.seq && gridData[i].field === row.field) {
          this.$refs.deviceGrid.getInstance.cellValue(i, columnIndex, pickupName);
          this.$refs.deviceGrid.getInstance.saveEditData();
        }
      }
    },
    /**@description 내선설정 모달 종료 후 콜백 메서드 , 넘겨받은 데이터 grid에 반영
     * @param row
     * @param lineData
     * @param type
     */
    async setLine(row, lineData, type) {
      this.isOpenModal(false, type);
      if (type === 'line') {
        for (let i = 0; i < this.dataGrid.dataSource.length; i++) {
          if (this.dataGrid.dataSource[i].index === row.index && this.dataGrid.dataSource[i].seq === row.seq && this.dataGrid.dataSource[i].field === row.field) {
            this.dataGrid.dataSource[i].number = lineData.pattern;
            this.dataGrid.dataSource[i].label = lineData.description;
            this.dataGrid.dataSource[i].routepartitionname = lineData.routepartitionname;
            this.dataGrid.dataSource[i].css = lineData.css;
            this.dataGrid.dataSource[i].e164mask = lineData.externalcallcontrolprofile;
          }
        }
      } else {
        for (let i = 0; i < this.dataGrid.dataSource.length; i++) {
          for (let j = 0; j < lineData.length; j++) {
            if (
                this.dataGrid.dataSource[i].index === lineData[j].index &&
                this.dataGrid.dataSource[i].seq === lineData[j].seq &&
                this.dataGrid.dataSource[i].field === lineData[j].field
            ) {
              this.$refs.deviceGrid.getInstance.cellValue(i, 1, lineData[j].number);
              this.$refs.deviceGrid.getInstance.cellValue(i, 2, lineData[j].label);
            }
          }
        }
        this.$refs.deviceGrid.getInstance.saveEditData();
      }
      await this.onCreateDevice();
    },
    /**@description 할당된 내선 삭제하는 메서드 */
    async onDeleteDevice() {
      const dataSource = this.dataGrid.dataSource;
      const mergeArray = [];
      for (let i = 0; i < dataSource.length; i++) {
        if (dataSource[i].number !== null && dataSource[i].number !== '') {
          const data = {};
          data.INDEX = dataSource[i].seq;
          data.FIELD = dataSource[i].field;
          data.PATTERN = dataSource[i].number === null ? '' : dataSource[i].number;
          data.LABEL = dataSource[i].label === null ? '' : dataSource[i].label;
          if (dataSource[i].field === 'LINE') {
            data.ROUTEPARTITION =
                dataSource[i].routepartitionname === null ? 'None' : dataSource[i].routepartitionname;
            data.CSS = dataSource[i].callingsearchspacename === null ? 'None' : dataSource[i].callingsearchspacename;
            data.E164MASK = dataSource[i].e164mask === '' ? 'None' : dataSource[i].e164mask;
            data.RECPROFILE = dataSource[i].recprofile === '' ? 'None' : dataSource[i].recprofile;
            data.RECFLAG = this.getRecInfo(
                dataSource[i].recprofile === '' ? 'None' : dataSource[i].recprofile,
                'recflag',
            );
            data.RECMEDIASOURCE = this.getRecInfo(
                dataSource[i].recprofile === '' ? 'None' : dataSource[i].recprofile,
                'recmediasource',
            );
            const recData = this.recPatternCheck(dataSource[i].number);
            data.RECPROFILE = recData.recProfile;
            data.RECMEDIASOURCE = recData.recMedia;
            data.RECFLAG = recData.recFlag;
            data.FORWARDNOCSS = this.allTransCheck(dataSource[i].number);
          }
          mergeArray.push(data);
        }
      }

      const payload = {
        actionName: 'EUC_DEVICE_CISCO_MERGE',
        data: {
          mac: this.deviceInfo.name,
          cmNm: this.deviceInfo.cmname,
          loginId: this.$store.getters.getLoginId,
          list: JSON.stringify(mergeArray),
        },
        loading: true,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS'));
      } else {
        await this.$_Msg(res.data.header.resMsg);
      }
      await this.onSelectDevice();
    },
    /**@description : 내선 설정 함수 */
    async onCreateDevice() {
      const dataSource = this.dataGrid.dataSource;
      const mergeArray = [];
      for (let i = 0; i < dataSource.length; i++) {
        if (dataSource[i].number != null && dataSource[i].number !== '') {
          let data = {};
          data.INDEX = dataSource[i].seq;
          data.FIELD = dataSource[i].field;
          data.PATTERN = dataSource[i].number == null ? '' : dataSource[i].number;
          data.LABEL = dataSource[i].label == null ? '' : dataSource[i].label;
          if (dataSource[i].field === 'LINE') {
            data.ROUTEPARTITION =
                dataSource[i].routepartitionname == null ? 'None' : dataSource[i].routepartitionname;
            data.CSS = dataSource[i]?.css || 'None';
            data.E164MASK = dataSource[i].e164mask === '' ? 'None' : dataSource[i].e164mask;
            data.RECPROFILE = dataSource[i].recprofile === '' ? 'None' : dataSource[i].recprofile;
            data.RECFLAG = this.getRecInfo(
                dataSource[i].recprofile === '' ? 'None' : dataSource[i].recprofile,
                'recflag',
            );
            data.RECMEDIASOURCE = this.getRecInfo(
                dataSource[i].recprofile === '' ? 'None' : dataSource[i].recprofile,
                'recmediasource',
            );
            const recData = this.recPatternCheck(dataSource[i].number);
            data.RECPROFILE = recData.recProfile;
            data.RECMEDIASOURCE = recData.recMedia;
            data.RECFLAG = recData.recFlag;
            data.FORWARDNOCSS = this.allTransCheck(dataSource[i].number);
          }
          mergeArray.push(data);
        }
      }

      const payload = {
        actionName: 'EUC_DEVICE_CISCO_MERGE',
        data: {
          mac: this.deviceInfo.name,
          cmNm: this.deviceInfo.cmname,
          loginId: this.$store.getters.getLoginId,
          list: JSON.stringify(mergeArray),
        },
        loading: true,
      };
      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS'));
      } else {
        await this.$_Msg(res.data.header.resMsg);
      }
      await this.onSelectDevice();
    },
    /** @description : 그리드 셀 관련 준비 커스텀 이벤트 */
    onCellPrepared(e) {
      if (e.rowType === 'data') {
        if (e.columnIndex === 0 || e.columnIndex === 4 || e.columnIndex === 5 || e.data.field === 'SURL') {
          e.cellElement.classList.add('grid-edit-false');
        }

        if (e.data.field !== 'LINE') {
          if (e.data.field === 'SURL') {
            e.cellElement.classList.add('grid-edit-false');
          } else if (e.data.field === 'SD' || e.data.field === 'BLF') {
            if (e.columnIndex > 3) {
              e.cellElement.classList.add('grid-edit-false');
            }
          }
        }
      }
    },

    /** @description 그리드 셀 편집기가 생성된 후 실행되는 함수 */
    onEditorPrepared(e) {
      if (e.row.cells[0].value === 'SURL') {
        e.component.cancelEditData();
      }

      if (e.row.cells[0].value === 'SD' || e.row.cells[0].value === 'BLF') {
        if (e.index > 2) {
          e.component.cancelEditData();
        }
      }
      if (e.row.cells[1].value === null || e.row.cells[1].value === '') {
        e.component.cancelEditData();
      }
    },
    /** @description 이전페이지 이동 함수 */
    goListPage() {
      if (this.$store.getters?.getPreviousPath) {
        this.$_goPrePage();
      } else {
        this.$router.push('/euc/device');
      }
    },
    /**@description 내선, 당겨받기 항목의 X버튼 클릭 이벤트.
     * @param row
     * @param type
     */
    setCellDelete(row, type) {
      this.$refs.deviceGrid.getInstance.cellValue(row.rowIndex, row.columnIndex, '');
      this.$refs.deviceGrid.getInstance.saveEditData();
      if (type === 'number') {
        this.onDeleteDevice();
      }
    },
    /**
     * @description 확장모듈리스트
     */
    async selectExpansionList(sort = '+moduleOrd, +id') {
      const payload = {
        actionName: 'EUC_EXPANSION_SELECT',
        data: {
          sort,
          viewFl: 'Y'
        },
        loading: true,
      };
      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.expansionList = res.data.data;
      }
    },

    /**
     * @description 모델리스트
     */
    async selectModelList(sort = '+modelOrd , +id') {
      const payload = {
        actionName: 'EUC_MODEL_SELECT',
        data: {
          sort,
          viewFl: 'Y'
        },
        loading: true,
      };
      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.modelList = res.data.data;
      }
    },
    /** @description model별 확장모듈 리스트 변경 */
    async findModelByExpansion() {
      let model = this.deviceInfo.model;
      if (model) {
        const data = this.modelList.filter(d => d.name === model);
        if (data.length > 0 && data[0]?.expansionModId) {
          this.expansionList = [
            { moduleNm: 'None', description: '미사용' },
            ...this.expansionList
          ]
          this.expFlag = true;
        } else {
          this.expFlag = false;
        }
      }
    },
    /**@description 그룹 목록 가져오는 메서드 */
    async selectGroupList(sort = '+groupOrd,-regDt') {
      const params = {
        sort,
        viewFl: 'Y'
      };

      const value = this.$_getSystemData('inout_type_rec')?.configValue;
      if (value) {
        params.inoutTypeCd = value;
      }
      const payload = {
        actionName: 'EUC_DEVICE_GROUP_SELECT',
        data: params,
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        if (res.data.data.length > 0) {
          this.groupList = res.data.data;
        }
      }
    },
    /**@description 내선패턴으로 녹취정보 비교 */
    recPatternCheck(number) {
      const rec = {
        recProfile: '',
        recMedia: '',
        recFlag: '',
      };

      const matchedGroup = this.groupList.find(item => new RegExp(item.pattern).test(number));

      if (matchedGroup) {
        rec.recProfile = matchedGroup.groupCd;
      }

      if (rec?.recProfile) {
        const cm = rec.recProfile.split('_')[0].toLowerCase();

        let cmType = '';
        if (cm.includes('hq')) {
          cmType = 'hq';
        } else if (cm.includes('bo')) {
          cmType = 'bo';
        }

        if (cmType) {
          rec.recMedia = this.$_getCode('euc_rec_media_' + cmType)[0].codeValue;
          rec.recFlag = this.$_getCode('euc_rec_flag_' + cmType)[0].codeValue;
        }
      } else {
        rec.recProfile = 'None';
        rec.recMedia = 'None';
        rec.recFlag = 'None';
      }
      return rec;
    },
    allTransCheck(number) {
      const trans = this.allTransList.find(item => new RegExp(item.pattern).test(number));
      return trans?.groupCd || null;
    },
    async selectAllTransList() {
      const params = {
        sort: '+groupOrd,-regDt',
        viewFl: 'Y'
      };

      const value = this.$_getSystemData('inout_type_all_trans')?.configValue;
      if (value) {
        params.inoutTypeCd = value;
      }

      const payload = {
        actionName: 'EUC_DEVICE_GROUP_SELECT',
        data: params,
        loading: false,
      }

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.allTransList = res.data.data;
      }
    },
    selectCSSGroupList() {
      const params = {};
      const configValue = this.$_getSystemData('inout_type_all_trans')?.configValue;
      if(configValue) {
        params.inoutTypeCd = configValue;
      }

      const payload = {
        actionName: 'EUC_DEVICE_GROUP_SELECT',
        data: params,
        loading: false,
      }
      this.CALL_EUC_API(payload).then((res) => {
        if (isSuccess(res)) {
          this.cssGroupList = res.data.data;
        }
      });
    },
    /**@description : 라이프사이클 created시 호출되는 메서드 */
    async createdData() {
      this.selectCSSGroupList();
      this.reqParams = this.$store.getters.getDetailParams;
      this.recList = await this.$_getCodeMapList('root_euc_rec_profile');
      await this.selectExpansionList();
      await this.selectModelList();
      await this.selectDataList();
    },
    /**@description : 라이프사이클 mounted시 호출되는 메서드 */
    async mountedData() {
      await this.selectAllTransList();
      await this.selectCmList();
      await this.selectDevicePool();
      await this.selectPartitionList();
      await this.selectCss();
      await this.selectPickupGroupList();
      await this.selectGroupList();
    },
    /**@description : 라이프사이클 destroyed시 호출되는 메서드 */
    destroyedData() {
      this.$store.commit('setDetailParams', null);
    },
  },
  async created() {
    await this.createdData();
  },
  async mounted() {
    await this.mountedData();
  },
  destroyed() {
    this.destroyedData();
  },
};
</script>

<style scoped>
.device-img > img {
  align-self: center;
}

.fl {
  border-right: 0 !important;
}

tbody {
  display: table-row-group;
  vertical-align: top;
}

.table_form tbody td {
  border: 1px solid #ddd;
  padding: 8px 18px;
}

.table_form tbody th {
  border: 1px solid #ddd;
  padding: 8px 18px;
}
</style>
<style>
.grid-edit-false {
  background-color: #f9f9f9;
}
</style>